import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
import axios from 'axios';
//import moment from 'moment';
import {convertValueByMetric} from '../../../utility/units-conversion'
import * as kegRoomActions from '../../kegroom/store/kegroom.actions';
import * as monitorActions from '../store/dashboard-actions';

class KegsLineWidget extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            colorMappings:[],
            lineChart:{
                series:[],
                options:{}
            },
            masterData:null,
            kegs_by_line:"",
            last_pour_item:{
              last_pour_date:"",
              volume_poured:"",
              beer_name:"",
              pour_id:0,
              pour_volume:0,
              line:0
            }
        };
        this.chartRef=React.createRef();
        
    }

    static getDerivedStateFromProps(props, state) {
      return {
        last_pour_item:props.last_pour_item,
        kegs_by_line:props.kegs_by_line
      }
      //return null;
  }

  async componentDidMount () {
    var selected_site_id = this.props.site_id;
    await this.props.getKegRooms(selected_site_id);
    this.props.setSelKegRoom(Object.assign({}, this.props.kegRooms[0]));
    this.getChartData()
  }


    componentDidUpdate(prevProps,prevState){
      if(prevProps.last_pour_item===null &&  this.state.last_pour_item===null){

      }
      else if(prevProps.last_pour_item.pour_id!==this.state.last_pour_item.pour_id){
        if(this.state.kegs_by_line==="volume"){
          this.updateLineVolume()
        }
        else if(this.state.kegs_by_line==="%left" || this.state.kegs_by_line==="%days_left"){
          this.updateLinePercentLeft()
        }
      }
      // toggle between volume and % left chart
      if(prevProps.kegs_by_line!==this.state.kegs_by_line){
        if(this.state.kegs_by_line==="volume"){
          this.loadChartByVolume(this.state.masterData);
        }
        else if(this.state.kegs_by_line==="%left" || this.state.kegs_by_line==="%days_left"){
          this.loadChartByPercentLeft(this.state.masterData);
        }
      }
    }

    updateLinePercentLeft(){
      var kegs_line_key=this.props.kegs_by_line;
      var pour_item=this.state.last_pour_item;
      var _lineChart=this.state.lineChart;
      var _seriesData=_lineChart.series[0].data;
      //const units=this.props.units;
      var item=_seriesData.find(s=>s.x===pour_item.line);
      var index=_seriesData.indexOf(item);
      debugger
      if(index!==-1){
        item=_seriesData[index];
        console.log(`Pour distribution updating for ${item.x} with volume ${item.y}`);
        item.y=kegs_line_key==="%left"?parseFloat(pour_item.percent_remaining).toFixed(2):parseFloat(pour_item.days_to_empty).toFixed(2);
        //let volumePoured=(convertValueByMetric(pour_item.volume_poured,units,"vol")/(units==="metric"?1000:1)).toFixed(2)
        item.moreInfo={
          beer_name:pour_item.beer_name,
          percent_remaining:item.y,
          volume_poured:pour_item.volume_poured,
          pints:pour_item.number_pints,
          days_to_empty:pour_item.days_to_empty
        };
        item.fillColor="#FFE302";
        console.log(`Pour distribution updated for ${item.x} with volume ${item.y}`);        
      }
      _seriesData.splice(index, 1);
      _seriesData.splice( index, 0, item );
      console.log(item);
      const data = _seriesData.map( x => { return {...x}} )
      this.setState({
        ...this.state.lineChart,
        lineChart:{
          ...this.state.lineChart.options,
          series:[{
            ...this.state.lineChart.series[0].data,
            data:data
          }]
        }
      },()=>{
        setTimeout(()=>{
          this.removeLastLinePercentLeftUpdate()
        },15000)
      })
    }

    removeLastLinePercentLeftUpdate(){
      var kegs_line_key=this.props.kegs_by_line;
      var pour_item=this.state.last_pour_item;
      var _lineChart=this.state.lineChart;
      var _seriesData=_lineChart.series[0].data;
      var item=_seriesData.find(s=>s.x===pour_item.line);
      var index=_seriesData.indexOf(item);
      if(index!==-1){
        item=_seriesData[index];
        item.fillColor=kegs_line_key==="%left"?"#2E93fA":"#66DA99"
      }
      _seriesData.splice(index, 1);
      _seriesData.splice( index, 0, item );
      const data = _seriesData.map( x => { return {...x}} )
      this.setState({
        ...this.state.lineChart,
        lineChart:{
          ...this.state.lineChart.options,
          series:[{
            ...this.state.lineChart.series[0].data,
            data:data
          }]
        }
      })
    }

    updateLineVolume(){
      var pour_item=this.state.last_pour_item;
      var _lineChart=this.state.lineChart;
      //var seriesLength=_lineChart.series.length;
      var _seriesData=_lineChart.series[0].data.map((item)=>{
        let size= (convertValueByMetric(parseFloat(item.kegSize)*1000,this.props.units,"vol")/(this.props.units==="metric"?1000:1)).toFixed(2);
        var _cMapping=this.state.colorMappings.find(s=>s.key===parseFloat(size));
        return {
          ...item,
          fillColor:_cMapping===undefined?"red": _cMapping.color
        }
      });
      var _lastSeriesData=_lineChart.series[1].data.map((item)=>{
        let size= (convertValueByMetric(parseFloat(item.kegSize)*1000,this.props.units,"vol")/(this.props.units==="metric"?1000:1)).toFixed(2);
        var _cMapping=this.state.colorMappings.find(s=>s.key===parseFloat(size));
        return {
          ...item,
          fillColor:_cMapping===undefined?"red": _cMapping.color
        }
      });
      console.log(pour_item)
      var item=_seriesData.find(s=>s.x===pour_item.line);
      var index=_seriesData.indexOf(item);
      if(index!==-1){
        item=_seriesData[index];
        console.log(`Pour distribution updating for ${item} with volume ${item.y}`);
        item.y= (convertValueByMetric(parseFloat(pour_item.volume_poured)*1000,this.props.units,"vol")/(this.props.units==="metric"?1000:1)).toFixed(2);
        console.log(`Pour distribution updated for ${item} with volume ${item.y}`);
        //var _cMapping=this.state.colorMappings.find(s=>s.key===parseFloat(size));
        item.fillColor="#FFE302";
        item.moreInfo={
          beer_name:pour_item.beer_name,
          percent_remaining:pour_item.percent_remaining,
          volume_poured:item.y,
          pints:pour_item.number_pints,
          days_to_empty:pour_item.days_to_empty
        }
      }
      _seriesData.splice(index, 1);
      _seriesData.splice( index, 0, item );
      _lastSeriesData.splice(index, 1);
      _lastSeriesData.splice( index, 0, item );
      console.log(item);
      const data = _seriesData.map( x => { return {...x}} )
      const _lineData = this.state.lineChart.series.slice(2,5)
      this.setState({
        ...this.state,
        lineChart:{
          options:{
            ...this.state.lineChart.options
          },
          series:[
            //...this.state.lineChart.series.slice(0,1),
            {
              ...this.state.lineChart.series[0],
              name:"Volume",type:"column",data:data
            },
            {
              ...this.state.lineChart.series[0],
              name:"Volume",type:"line",data:data
            },
            ..._lineData]
          //...this.state.lineChart.series.slice(1)]
        }
      },()=>{
        this.removeVolumeLegend();
        setTimeout(()=>{
          this.RemoveColorForUpdatedVolume()
        },15000)
      });
    }


    RemoveColorForUpdatedVolume(){
      var _lineChart=this.state.lineChart;
      var _seriesData=_lineChart.series[0].data.map(s=>{
        let size= (convertValueByMetric(parseFloat(s.kegSize)*1000,this.props.units,"vol")/(this.props.units==="metric"?1000:1)).toFixed(2);
        var _cMapping=this.state.colorMappings.find(s=>s.key===parseFloat(size));
        return {
          ...s,
          fillColor:_cMapping===undefined?"red": _cMapping.color
        }
      });
      const data = _seriesData.map( x => { return {...x}} )
      this.setState({
        ...this.state,
        lineChart:{
          options:{
            ...this.state.lineChart.options
          },
          series:[
            {
              ...this.state.lineChart.series[0],
              name:"Volume",type:"column",data:data
            },
            {
              ...this.state.lineChart.series[0],
              name:"Volume",type:"line",data:data
            },
            ...this.state.lineChart.series.slice(2,5)]
        }
      },()=>{
        this.removeVolumeLegend();
      });
    }

    loadChartByVolume=(response)=>{
      var colors= ['#2E93fA', '#66DA99','#66DA26', '#007FFF', '#E91E63', '#4F86F7','#D70040'];
      const units=this.props.units;
      var colorMappings=[];
      var groupBySize=  response.data.reduce(function (r, a) {
          r[a.size] = r[a.size] || [];
          r[a.size].push(a);
          return r;
      }, Object.create(null));
      var fixedSeriesKeys=[];
      var colorCounter=0;
      for(var key in groupBySize){
          var volume=(convertValueByMetric(key,units,"vol")/(units==="metric"?1000:1)).toFixed(2);
          fixedSeriesKeys.push(volume);
          colorMappings.push({key:parseFloat(volume),color:colors[colorCounter+2]});
          colorCounter++;
      }
      var volumeLine={name:"Volume",type:'line',data:[]};
      var columnLine={name:"Volume Histogram",type:'column',data:[]};
      var seriesLines=[];
      var xaxis=[];
      var fixedSeries=[];
      var markersSize=[0,7];
      var seriesWidth=[1,0];
      for(var i=0;i<fixedSeriesKeys.length;i++){
          fixedSeries.push({name:fixedSeriesKeys[i]+(units==="metric"?" L":" OU"),data:[]})
          markersSize.push(0);
          seriesWidth.push(1);
      }
      debugger
      var maxLineNumber=Math.max(...response.data.map(s=>s.line_number));
      for(var lineCount=1;lineCount<=maxLineNumber;lineCount++){
        // eslint-disable-next-line no-loop-func
        var line_data=response.data.filter(s=>s.line_number===lineCount);
        xaxis.push(lineCount);
         
        if(line_data.length>0){
          var item=line_data[0];
          var seriesCounter=0;
          // eslint-disable-next-line no-loop-func
          fixedSeries.forEach((series)=>{
            series.data.push({x:item.line_number,y:line_data.length>0?fixedSeriesKeys[seriesCounter]:0})
            seriesCounter++;
          })
          let volumePoured=(convertValueByMetric(item.volume_poured,units,"vol")/(units==="metric"?1000:1)).toFixed(2)
          let size=(convertValueByMetric(item.size,units,"vol")/(units==="metric"?1000:1)).toFixed(2)
          var _cMapping=colorMappings.find(s=>s.key===parseFloat(size));
          volumeLine.data.push({
            x:item.line_number,
            y:volumePoured,
            moreInfo:{
              beer_name:item.beer_name,
              percent_remaining:(item.percent_remaining*100).toFixed(2),
              volume_poured:volumePoured,
              pints:((parseFloat(item.volume_poured)/(item.mlperpint))).toFixed(2),
              days_to_empty:item.days_to_empty.toFixed(2)
            },
            kegSize:size,
            fillColor:_cMapping===undefined?"red": _cMapping.color
          })
          columnLine.data.push({
            x:item.line_number,
            y:volumePoured,
            moreInfo:{
              beer_name:item.beer_name,
              percent_remaining:(item.percent_remaining*100).toFixed(2),
              volume_poured:volumePoured,
              pints:((parseFloat(item.volume_poured)/(item.mlperpint))).toFixed(2),
              days_to_empty:item.days_to_empty.toFixed(2)
            }, 
            kegSize:size,
            fillColor:_cMapping===undefined?"red": _cMapping.color
          })
        }
        else{
          volumeLine.data.push({
            x:lineCount,
            y:"0",
            moreInfo:{
              beer_name:"",
              percent_remaining:0,
              volume_poured:0,
              pints:0,
              days_to_empty:0
            },
            kegSize:"0 L",
            fillColor:"red"
          })
          columnLine.data.push({
            x:lineCount,
            y:"0",
            moreInfo:{
              beer_name:"",
              percent_remaining:0,
              volume_poured:0,
              pints:0,
              days_to_empty:0
            }, 
            kegSize:"0 L",
            fillColor:"red"
          })
        }
      }
      // response.data.forEach((item)=>{
      //     xaxis.push(item.line_number);
      //     var seriesCounter=0;
      //     fixedSeries.forEach((series)=>{
      //         series.data.push({x:item.line_number,y:fixedSeriesKeys[seriesCounter]})
      //         seriesCounter++;
      //     })
      //     let volumePoured=(convertValueByMetric(item.volume_poured,units,"vol")/(units==="metric"?1000:1)).toFixed(2)
      //     let size=(convertValueByMetric(item.size,units,"vol")/(units==="metric"?1000:1)).toFixed(2)
      //     var _cMapping=colorMappings.find(s=>s.key===parseFloat(size));
      //     volumeLine.data.push({
      //       x:item.line_number,
      //       y:volumePoured,
      //       moreInfo:{
      //         beer_name:item.beer_name,
      //         percent_remaining:(item.percent_remaining*100).toFixed(2),
      //         volume_poured:volumePoured,
      //         pints:((parseFloat(item.volume_poured)/(item.mlperpint))).toFixed(2),
      //         days_to_empty:item.days_to_empty.toFixed(2)
      //       },
      //       kegSize:size,
      //       fillColor:_cMapping===undefined?"red": _cMapping.color
      //     })
      //     columnLine.data.push({
      //       x:item.line_number,
      //       y:volumePoured,
      //       moreInfo:{
      //         beer_name:item.beer_name,
      //         percent_remaining:(item.percent_remaining*100).toFixed(2),
      //         volume_poured:volumePoured,
      //         pints:((parseFloat(item.volume_poured)/(item.mlperpint))).toFixed(2),
      //         days_to_empty:item.days_to_empty.toFixed(2)
      //       }, 
      //       kegSize:size,
      //       fillColor:_cMapping===undefined?"red": _cMapping.color
      //     })
      // })
      
      seriesLines=seriesLines.concat(columnLine);
      seriesLines=seriesLines.concat(volumeLine);
      seriesLines=seriesLines.concat(fixedSeries);
      var chartData={
          series:seriesLines,
          options: {
              chart: {
                height: 400,
                type: 'line',
                zoom: {
                  enabled: true
                }
              },
              colors: colors,
              tooltip:{
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                  //var data =seriesIndex===0 ||seriesIndex===w.globals.initialSeries.length-1?  w.globals.initialSeries[0].data[dataPointIndex]:{};
                  //data.moreInfo.sort((a, b) => parseFloat(b.volume) - parseFloat(a.volume));
                  var data =w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                  if(data.moreInfo===undefined) return ""
                  var line_info=data.moreInfo !==undefined?
                  `<div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">Beer: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.beer_name}</span>
                  </div>
                  <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">% Left: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.percent_remaining}</span>
                  </div>
                  <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">Volume Poured: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.volume_poured}</span>
                  </div>
                  <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">#Pints: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.pints}</span>
                  </div>
                  <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">Days to empty: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.days_to_empty}</span>
                  </div>`
                  :[`<div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">Volume : </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.y}</span>
                  </div>`]
                  return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">Line Number : ${data.x}</div>
                  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                  <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                  ${line_info}                                
                 </div></div>`
              }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'stepline',
                width: seriesWidth,
                //dashArray: [0, 100]
              },
              markers: {
                  size: markersSize
              },
              title: {
                text: `Connected Kegs By Volume`,
                align: 'left'
              },
              grid: {
                row: {
                  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },
              legend:{
                //customLegendItems:fixedSeriesKeys,
                show:true,
                labels: {
                  colors: colors,
                  useSeriesColors: false
              },
              },
              xaxis: {
                title:{
                  text:'Line',
                  //offsetX:50,
                  offsetY:80
                },
                categories: xaxis,
                type: "categories",
                tickAmount: 'dataPoints',
                //tickAmount:20,
                labels: {
                 
                  datetimeUTC: false,
                  rotateAlways: false,
                  rotate: 0,
                  //tickPlacement: 'on',
                  formatter: function(val, timestamp) {
                      return val; // formats to hours:minutes
                  }  
                }
              },
              yaxis: [
                  {
                    seriesName: 'Volume',
                    axisTicks: {
                      show: true
                    },
                    axisBorder: {
                      show: true,
                      color: "#FF1654"
                    },
                    labels: {
                      style: {
                        colors: "#FF1654"
                      },
                      formatter: function (value) {
                          return value.toFixed(0)
                      }
                    },
                    title: {
                      text: units==="metric"?"Volume (L)":"Volume (ou)",
                      style: {
                        color: "#FF1654"
                      }
                    }
                  }
                ]
            }
      }
      this.setState({
          ...this.state,
          lineChart:chartData,
          masterData:response,
          colorMappings:colorMappings
      },()=>{
        this.removeVolumeLegend();
      });
    }

    loadChartByPercentLeft=(response)=>{
      var kegs_line_key=this.props.kegs_by_line;
      const units=this.props.units;
      var volumeLine={name:"Volume",type:'column',data:[]};
      var seriesLines=[];
      var xaxis=[];

      var maxLineNumber=Math.max(...response.data.map(s=>s.line_number));
      for(var lineCount=1;lineCount<=maxLineNumber;lineCount++){
        // eslint-disable-next-line no-loop-func
        var line_data=response.data.filter(s=>s.line_number===lineCount);
        xaxis.push(lineCount);
        if(line_data.length>0){
          var item=line_data[0];
          let volumePoured=(convertValueByMetric(item.volume_poured,units,"vol")/(units==="metric"?1000:1)).toFixed(2)
          let size=(convertValueByMetric(item.size,units,"vol")/(units==="metric"?1000:1)).toFixed(2)
          volumeLine.data.push({
            x:item.line_number,
            y:kegs_line_key==="%left"?(item.percent_remaining*100).toFixed(2):item.days_to_empty.toFixed(2),
            fillColor:kegs_line_key==="%left"?"#2E93fA":"#66DA99",
            moreInfo:{
              beer_name:item.beer_name,
              percent_remaining:(item.percent_remaining*100).toFixed(2),
              volume_poured:volumePoured,
              pints:((parseFloat(volumePoured)/(item.mlperpint))*(units==="metric"?1000:1)).toFixed(2),
              days_to_empty:item.days_to_empty.toFixed(2)
            },
            kegSize:size,
          })
        }
        else{
          volumeLine.data.push({
            x:lineCount,
            y:"0",
            moreInfo:{
              beer_name:"",
              percent_remaining:0,
              volume_poured:0,
              pints:0,
              days_to_empty:0
            },
            kegSize:"0 L",
            fillColor:"red"
          })
        }
      }

      // response.data.forEach((item)=>{
      //     xaxis.push(item.line_number);
      //     let volumePoured=(convertValueByMetric(item.volume_poured,units,"vol")/(units==="metric"?1000:1)).toFixed(2)
      //     let size=(convertValueByMetric(item.size,units,"vol")/(units==="metric"?1000:1)).toFixed(2)
      //     volumeLine.data.push({
      //       x:item.line_number,
      //       y:kegs_line_key==="%left"?(item.percent_remaining*100).toFixed(2):item.days_to_empty.toFixed(2),
      //       fillColor:kegs_line_key==="%left"?"#2E93fA":"#66DA99",
      //       moreInfo:{
      //         beer_name:item.beer_name,
      //         percent_remaining:(item.percent_remaining*100).toFixed(2),
      //         volume_poured:volumePoured,
      //         pints:((parseFloat(volumePoured)/(item.mlperpint))*(units==="metric"?1000:1)).toFixed(2),
      //         days_to_empty:item.days_to_empty.toFixed(2)
      //       },
      //       kegSize:size,
      //     })
      // })
      seriesLines=seriesLines.concat(volumeLine);
      var chartData={
          series:seriesLines,
          options: {
              chart: {
                height: 400,
                type: 'line',
                zoom: {
                  enabled: true
                }
              },
              tooltip:{
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                  var data =w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                  if(data.moreInfo===undefined) return ""
                  var line_info=data.moreInfo !==undefined?
                  `<div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">Beer: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.beer_name}</span>
                  </div>
                  <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">% Left: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.percent_remaining}</span>
                  </div>
                  <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">Volume Poured: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.volume_poured}</span>
                  </div>
                  <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">#Pints: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.pints}</span>
                  </div>
                  <div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">Days to empty: </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.days_to_empty}</span>
                  </div>`
                  :[`<div class="apexcharts-tooltip-y-group">
                      <span class="apexcharts-tooltip-text-y-label">Volume : </span>
                      <span class="apexcharts-tooltip-text-y-value">${data.y}</span>
                  </div>`]
                  return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">Line Number : ${data.x}</div>
                  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                  <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                  ${line_info}                                
                 </div></div>`
              }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'straight',
                width: [1]
              },
              markers: {
                  size: [7]
              },
              title: {
                text: kegs_line_key==="%left"?`Connected Kegs By % Left`:`Connected Kegs By Days Left`,
                align: 'left'
              },
              grid: {
                row: {
                  colors: ['#f3f3f3', 'transparent'], 
                  opacity: 0.5
                },
              },
              legend:{
                show:true,
              },
              xaxis: {
                title:{
                  text:'Line',
                  //offsetX:50,
                  offsetY:80
                },
                categories: xaxis,
                type: "categories",
                tickAmount: 'dataPoints',
                labels: {
                  datetimeUTC: false,
                  rotateAlways: false,
                  rotate: 0,
                  formatter: function(val, timestamp) {
                      return val;
                  }  
                }
              },
              yaxis: [
                  {
                    seriesName: 'Volume',
                    axisTicks: {
                      show: true
                    },
                    axisBorder: {
                      show: true,
                      color: kegs_line_key==="%left"?"#2E93fA":"#66DA99"
                    },
                    labels: {
                      style: {
                        colors: kegs_line_key==="%left"?"#2E93fA":"#66DA99"
                      },
                      formatter: function (value) {
                          return value.toFixed(0)
                      }
                    },
                    title: {
                      text: kegs_line_key==="%left"?"% Left":"Days Left",
                      style: {
                        color: kegs_line_key==="%left"?"#2E93fA":"#66DA99"
                      }
                    }
                  }
                ]
            }
      }
      this.setState({
          ...this.state,
          lineChart:chartData,
          masterData:response,
      },()=>{
        this.removeVolumeLegend();
      });
    }

    getChartData=()=>{
        var params={
            kr_id:this.props.selKegRoom.kr_id
        }
        let newState=this;
        var kegs_line_key=this.props.kegs_by_line;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/ConnectedKegsChart`,{params:params})
        .then(function(response){
            if(response.data!==undefined){
                console.log(response)
                if(kegs_line_key==="volume"){
                  newState.loadChartByVolume(response);
                }
                else if(kegs_line_key==="%left" || kegs_line_key==="%days_left"){
                  newState.loadChartByPercentLeft(response);
                }
                
            }
        })
    }
    
    removeVolumeLegend=()=>{
      if(this.chartRef.current.chartRef.current!==null){
        var selectedEle=document.getElementById(this.chartRef.current.chartRef.current.childNodes[0].id).getElementsByClassName('apexcharts-legend-series');
        var volumeLegends=[...selectedEle].filter(s=>s.childNodes[1].innerHTML.indexOf("Vol")!==-1);
        for (let index = 0; index < volumeLegends.length; index++) {
          volumeLegends[index].remove()
        }
      }
    }

    render(){
        return(
        <>
        <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} ref={this.chartRef} />
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        kegs: state.monitor.kegs,
        last_pour_item: state.monitor.last_pour_item,
        kegRooms:state.kegRooms.kegRooms,
        selKegRoom:state.kegRooms.selKegRoom,
        kegs_by_line:state.monitor.kegs_by_line,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
        setSelKegRoom:(kr)=>dispatch(kegRoomActions.setSelKegRoom(kr)),
        setLastPour:(item)=>dispatch(monitorActions.setLastPourItem(item))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(KegsLineWidget);