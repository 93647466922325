import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import {
    Card,
    CardHeader,
    Row,
    Col,
    Label,
    CardBody,
    Button
} from "reactstrap";
import Input from "reactstrap/lib/Input";
var DatePicker = require("reactstrap-date-picker");

class PourVolumeReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('DD/MM/YYYY')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            top_num:0,
            kegRepData: [],
            report_url:'',
            showReport:false,
            chartHtml:'',
            errorMessage:'',
            fileUrls:[]
        };
        this.chartRef=React.createRef();
    }

    componentDidMount() {
        this.fetchKegRooms();
        
    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]});
            }
        });
    }

    getReprtData = () => {
        this.setState({showReport:true});
        const formData = {
            customer_email:this.props.userDetails.person.email,
            kr_id: this.state.selKegRoom.value,
            top_num:this.state.top_num,
            //beer_in:this.state.selBeer==null?null:this.state.selBeer.value,
            start_date: moment(this.state.from_date).format('YYYY-MM-DD'),
            end_date: moment(this.state.to_date).format('YYYY-MM-DD')
        };
        axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/reports/PourVolumeReport`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
            data: formData
        })
            .then((response) => {
                var urls=response.data.response;
                console.log(urls);
                
                if(!response.data.error){
                    urls.forEach((url)=>{
                        let newState=this;
                        axios(`${process.env.REACT_APP_API_BASE_URL}/api/reports/GetFileContent`,{params:{url:url}}).then(function(response){
                            newState.setState({...newState.state,chartHtml:response.data,showReport:false,errorMessage:""})
                        })
                        // const link = document.createElement('a');
                        // link.href = url;
                        // link.target="_blank";
                        // link.setAttribute(
                        // 'download',
                        // `Report.pdf`,
                        // );
                        // // Append to html link element page
                        // document.body.appendChild(link);
                        // // Start download
                        // link.click();
                        // // Clean up and remove the link
                        // link.parentNode.removeChild(link);
                        // axios(url).then(function(response){
                        //     this.chartRef.current.innerHTML = response.data;
                        // })
                    })
                    //this.setState({...this.state,showReport:false})
                    // if(Array.isArray(urls)){
                    //     this.setState({...this.state,fileUrls:urls,showReport:false})
                    // }
                    // else{
                    //     this.setState({...this.state,fileUrls:[urls],showReport:false})
                    // }
                }
                else{
                    this.setState({...this.state,errorMessage:response.data.message,showReport:false})
                }
            });
    }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    render() {
        return (
            <React.Fragment>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Pour Volume By Date Plot</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Select Top</Label>
                                <Input type="number" size="sm" value={this.state.top_num} onChange={(e) => this.handleChangeSelection(e.target.value, 'top_num')}/>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                           
                        </Row>
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button onClick={this.getReprtData} disabled={this.state.showReport ? 'disabled' : ''}>{this.state.showReport?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                        {/* <Row>
                        {
                                this.state.fileUrls.map((item,index)=>{
                                    return <p><a href={item} target="_blank" rel="noreferrer" key={index}>View Report {index+1} |</a></p>
                                })
                            }
                        </Row> */}
                        <Row>
                            <Col lg="12" sm="12" md="12">
                            <iframe title="new" style={{width:'100%',height:'700px'}} srcDoc={this.state.chartHtml} ref={this.chartRef}></iframe>
                            </Col>
                        </Row>
                        
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails
    }
}

export default connect(mapStateToProps, null)(PourVolumeReport);
