import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selKeg: {
        keg_id:0,
        volume:'',
        percent_remaining:'',
        volume_poured:'',
        connected:false,
        draft_beer_id:0,
        draft_beer_name:'',
        kr_id:0,
        kr_name:''
    },
    showList:false,
    kegs: [],
    currPage: 1
};

export const getKegs = (site_id) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.kegs.kegs.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={
                site_id:site_id
            }
            if(state.kegRooms.selKegRoom.kr_id!==0){
                siteParams.kr_id=state.kegRooms.selKegRoom.kr_id
            }
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    let _kegs = [];
                    response.data.forEach((item) => {
                        //const beer = state.beers.beers.find(p => p.id === item.draft_beer_id);
                        //const kr = state.kegRooms.kegRooms.find(p => p.kr_id === item.kr_id);
                        _kegs.push({
                            keg_id: item.keg_id,
                            volume: item.volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                            percent_remaining: item.percent_remaining.toFixed(2),
                            volume_poured: item.volume_poured.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                            connected: item.connected,
                            draft_beer_id: item.draft_beer_id,
                            draft_beer_name: item.beer_name,
                            kr_id: item.kr_id,
                            kr_name: item.kr_name,
                       })
                    });
                    _kegs = _kegs.sort((a, b) => a.draft_beer_name.localeCompare(b.draft_beer_name));
                    dispatch(handleActionDispatch(actionTypes.SET_KEGS, _kegs));
                    resolve(_kegs);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_KEG_PAGE, page));
    }
}

export const setSelKeg = (keg) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SELECTED_KEG, keg));
    }
}

export const clearSelKeg = () => {
    return setSelKeg({
        keg_id:0,
        volume:'',
        percent_remaining:'',
        volume_poured:'',
        connected:false,
        draft_beer_id:0,
        draft_beer_name:'',
        kr_id:0,
        kr_name:''
    });
}

export const addKeg = (kegData) => {
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/keg`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: kegData
			}).then(response => {
                const _keg = {
                    ...kegData,
                    volume:kegData.volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    keg_id:response.data.keg_id
                };
                dispatch(handleActionDispatch(actionTypes.ADD_KEG, _keg));
                successToaster("Added");
                resolve();
            });
		});

	}
}

export const updateKeg = (kegInfo) => {
		return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/keg/${kegInfo.keg_id}`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: kegInfo
			}).then(response => {
                const _keg = {
                    ...kegInfo,
                    volume:kegInfo.volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                };
                dispatch(handleActionDispatch(actionTypes.UPDATE_KEG, _keg));
			    successToaster("Updated");
			    resolve();
            })
		})
	}
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}