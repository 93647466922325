import React from 'react';
import {
    Input,
    Row,
    Col,
} from "reactstrap";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Button from 'reactstrap/lib/Button';

class AdvancedFilter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataFields: props.datafields,
            conditions: [],
            selectedFilters: [],
            selectedDataField: {},
            operators: [{ value: 'and', label: 'And' }, { value: 'or', label: 'Or' }],
            data: props.data,
            searchCallback: props.datacallback
        }
        this.addFilter();
    }

    addFilter = () => {
        const newState = Object.assign({}, this.state);
        const context = this;
        const count = newState.selectedFilters.length;
        newState.selectedFilters.push({
            key: count + 1,
            dataField: null,
            condition: null,
            operator: null,
            value: '',
            minValue: '',
            maxValue: '',
            onFieldChange: function (row, e) {
                context.handleChangeDataField(row, e);
            },
            onConditionChange: function (row, e) {
                context.handleChangeCondition(row, e);
            },
            onValueChange: function (row, e, propName) {
                context.handleChangeValue(row, e, propName);
            },
            onOperatorChange: function (row, e) {
                context.handleChangeOperator(row, e);
            },
            onRemove: function (key) {
                context.remove(key);
            }
        });
        this.setState(newState);
    }

    filterField = (search, value) => value.toLowerCase().indexOf(search.toLowerCase()) >= 0;

    orFilter = (search, values) => values.some(this.filterField.bind(null, search));

    callBackSearch = (loadType) => {
        if (loadType !== undefined && loadType === "default") {
            this.state.searchCallback(this.state.data);
            return;
        }
        let andfilteredData = [], orFilterData = [];
        const _selectedFilters = this.state.selectedFilters;
        _selectedFilters.forEach((item, i) => {
            var _data = [];
            if (_selectedFilters[i - 1] === undefined) {
                _data = this.state.data.filter((dItem) => {
                    if (dItem[item.dataField.value] == null) {
                        return false;
                    }
                    else {
                        if (item.condition.value === "equals") {
                            return item.dataField.type === "text" ?
                                dItem[item.dataField.value].toLowerCase() === item.value.toLowerCase() :
                                dItem[item.dataField.value] === parseInt(item.value)
                        }
                        else if (item.condition.value === "contains") {
                            return dItem[item.dataField.value].toLowerCase().indexOf(item.value.toLowerCase()) >= 0;
                        }
                        else if (item.condition.value === "lessthan") {
                            return parseInt(dItem[item.dataField.value]) < parseInt(item.value);
                        }
                        else if (item.condition.value === "greaterthan") {
                            return parseInt(dItem[item.dataField.value]) > parseInt(item.value);
                        }
                        else if (item.condition.value === "range") {
                            return parseInt(dItem[item.dataField.value]) >= parseInt(item.minValue)
                                && parseInt(dItem[item.dataField.value]) <= parseInt(item.maxValue);
                        }
                        else {
                            return false;
                        }
                    }
                })
                andfilteredData = _data;
            }
            else if (_selectedFilters[i - 1] !== undefined && _selectedFilters[i - 1].operator.value === "and") {
                _data = andfilteredData.filter((dItem) => {
                    if (dItem[item.dataField.value] == null) {
                        return false;
                    }
                    else {
                        if (item.condition.value === "equals") {
                            return item.dataField.type === "text" ?
                                dItem[item.dataField.value].toLowerCase() === item.value.toLowerCase() :
                                dItem[item.dataField.value] === parseInt(item.value)
                        }
                        else if (item.condition.value === "contains") {
                            return dItem[item.dataField.value].toLowerCase().indexOf(item.value.toLowerCase()) >= 0;
                        }
                        else if (item.condition.value === "lessthan") {
                            return parseInt(dItem[item.dataField.value]) < parseInt(item.value);
                        }
                        else if (item.condition.value === "greaterthan") {
                            return parseInt(dItem[item.dataField.value]) > parseInt(item.value);
                        }
                        else if (item.condition.value === "range") {
                            return parseInt(dItem[item.dataField.value]) >= parseInt(item.minValue)
                                && parseInt(dItem[item.dataField.value]) <= parseInt(item.maxValue);
                        }
                        else {
                            return false;
                        }
                    }
                })
                andfilteredData = _data;
            }
            else if (_selectedFilters[i - 1] !== undefined && _selectedFilters[i - 1].operator.value === "or") {
                _data = this.state.data.filter((dItem) => {
                    if (dItem[item.dataField.value] == null) {
                        return false;
                    }
                    else {
                        if (item.condition.value === "equals") {
                            return item.dataField.type === "text" ?
                                dItem[item.dataField.value].toLowerCase() === item.value.toLowerCase() :
                                dItem[item.dataField.value] === parseInt(item.value)
                        }
                        else if (item.condition.value === "contains") {
                            return dItem[item.dataField.value].toLowerCase().indexOf(item.value.toLowerCase()) >= 0;
                        }
                        else if (item.condition.value === "lessthan") {
                            return parseInt(dItem[item.dataField.value]) < parseInt(item.value);
                        }
                        else if (item.condition.value === "greaterthan") {
                            return parseInt(dItem[item.dataField.value]) > parseInt(item.value);
                        }
                        else if (item.condition.value === "range") {
                            return parseInt(dItem[item.dataField.value]) >= parseInt(item.minValue)
                                && parseInt(dItem[item.dataField.value]) <= parseInt(item.maxValue);
                        }
                        else {
                            return false;
                        }
                    }

                })
                orFilterData = orFilterData.length === 0 ? _data : this.concatDataSource(orFilterData, _data);
            }

        })
        this.state.searchCallback(this.concatDataSource(andfilteredData, orFilterData));
    }

    concatDataSource = (source1, source2) => {
        let result = source1.concat(source2);
        result = result.filter((item, index) => {
            return (result.indexOf(item) === index)
        })
        return result;
    }

    remove = (key) => {
        const newState = Object.assign({}, this.state);
        let _selectedFilter = newState.selectedFilters.find(p => p.key === parseInt(key));
        if (_selectedFilter !== undefined) {
            newState.selectedFilters.splice(newState.selectedFilters.indexOf(_selectedFilter.key), 1);
        }
        if (newState.selectedFilters.length === 0) {
            this.callBackSearch("default");
            this.addFilter();
        }
        this.setState(newState);
    }

    handleChangeDataField = (row, selectedItem) => {
        const newState = Object.assign({}, this.state);
        let _selectedFilter = newState.selectedFilters.find(p => p.key === row.key);
        _selectedFilter.dataField = selectedItem;
        _selectedFilter.value = "";
        newState.conditions = [];
        if (_selectedFilter && _selectedFilter.dataField) {
            if (_selectedFilter.dataField.type === 'text') {
                newState.conditions = [
                    { label: "Contains", value: "contains" },
                    { label: "Equals", value: "equals" }
                ];
            }
            if (_selectedFilter.dataField.type === 'select') {
                newState.conditions = [
                    { label: "Equals", value: "equals" }
                ];
            }
            if (_selectedFilter.dataField.type === 'int') {
                newState.conditions = [
                    { label: "Equals", value: "equals" },
                    { label: "Less Than", value: "lessthan" },
                    { label: "Greater Than", value: "greaterthan" },
                    { label: "Range", value: "range" }
                ];
            }
        }
        this.setState(newState);
    }

    handleChangeCondition = (row, selectedItem) => {
        const newState = Object.assign({}, this.state);
        let _selectedFilter = newState.selectedFilters.find(p => p.key === row.key);
        _selectedFilter.condition = selectedItem;
        _selectedFilter.value = "";
        _selectedFilter.minValue = "";
        _selectedFilter.maxValue = "";
        this.setState(newState);
    }

    handleChangeOperator = (row, selectedItem) => {
        const newState = Object.assign({}, this.state);
        let _selectedFilter = newState.selectedFilters.find(p => p.key === row.key);
        _selectedFilter.operator = selectedItem;
        this.setState(newState);
    }

    handleChangeValue = (row, selectedItem, propName) => {
        const newState = Object.assign({}, this.state);
        let _selectedFilter = newState.selectedFilters.find(p => p.key === row.key);
        _selectedFilter[propName] = _selectedFilter.condition != null && _selectedFilter.condition.value === "range" ?
            parseInt(selectedItem.currentTarget.value) :
            selectedItem.currentTarget.value;
        this.setState(newState);
    }

    render() {
        const { dataFields, conditions, selectedFilters, operators } = this.state;
        return (
            <>
                <div>
                    {
                        selectedFilters.map(function (item, i) {
                            return (<Row className="mt-1">
                                <Col sm="2" lg="2" md="2">
                                    <Select size="sm" onChange={(e) => item.onFieldChange(item, e)} value={item.dataField}
                                        options={dataFields}></Select>
                                </Col>
                                <Col sm="2" lg="2" md="2">
                                    <Select size="sm" onChange={(e) => item.onConditionChange(item, e)} value={item.condition}
                                        options={conditions}></Select>
                                </Col>
                                <Col sm="2" lg="2" md="2">
                                    {
                                        item.condition !== null && item.condition.value === "range" ?
                                            <div>
                                                <Input value={item.minValue} bsSize="sm" type="number" placeholder="min" style={{ width: '75px', display: 'unset' }}
                                                    onChange={(e) => item.onValueChange(item, e, "minValue")} autoComplete="new-name" />
                                                <Input value={item.maxValue} bsSize="sm" type="number" placeholder="max" style={{ width: '75px', display: 'unset' }}
                                                    onChange={(e) => item.onValueChange(item, e, "maxValue")} autoComplete="new-name" />
                                            </div> :
                                            <Input value={item.value} bsSize="sm" type="text" placeholder="enter value"
                                                onChange={(e) => item.onValueChange(item, e, "value")} autoComplete="new-name" />

                                    }

                                </Col>
                                <Col sm="2" lg="2" md="2">
                                    <Select size="sm" onChange={(e) => item.onOperatorChange(item, e)} value={item.operator}
                                        options={operators}></Select>
                                </Col>
                                <button className="btn btn-sm btn-danger" onClick={() => item.onRemove(`${item.key}`)}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                            </Row>)
                        })
                    }
                    <br />
                    <Button className="btn btn-primary btn-sm" onClick={this.addFilter}>Add</Button> &nbsp;&nbsp;
                    <Button className="btn btn-primary btn-sm" onClick={() => this.callBackSearch("filtered")}>Search</Button>
                </div>
            </>
        )
    }
}

export default AdvancedFilter