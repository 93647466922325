import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import Input from "reactstrap/lib/Input";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import CustomStackedBar from "./stacked-bar/index"
//import CustomPieChart from "./pie/index"
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import colorsData from '../../utility/colors.json'
import { ResponsiveBar } from "@nivo/bar";
import {convertValueByMetric} from '../../utility/units-conversion';
import {sortByDataType} from '../../utility/common';

var DatePicker = require("reactstrap-date-picker");

class PourVolumeDateReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            activeTab:"1",
            loading:false,
            beerData:[],
            top_num:0,
            title:'',
            pourDetailHistData:[],
            enablePourChartTab:false,
            keys: [],
            data:[],
            pData:[],
            showChart:false,
            errorMessage:'',
            logoBase64:null,
            masterData:[],
            profileBase64:null,
            logoUrl:'https://cdndraftco.nyc3.digitaloceanspaces.com/assets/logo/Black/draftco-logo-black.png',
            profileUrl:'https://cdndraftco.nyc3.digitaloceanspaces.com/assets/images/42cdb2f4-0b4e-4824-aa4d-3c8bf23ecba9.png'
        };
    }
    
    componentDidMount() {
        this.fetchKegRooms();
    }

    fetchLogoData=()=>{
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/reports/GetImageToBase64`, { params: { url: this.state.logoUrl } })
        .then(response => {
            if (response.data !== undefined) {
                this.setState({...this.state,logoBase64:response.data});
            }
        });
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/reports/GetImageToBase64`, { params: { url: this.state.profileUrl } })
        .then(response => {
            if (response.data !== undefined) {
                this.setState({...this.state,profileBase64:response.data});
            }
        });
    }


    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]},()=>{this.fetchLogoData()});
            }
        });
    }

    
    getChartData=()=>{
        this.setState({showChart:false,loading:true});
        let newState=this;
        const units=this.props.units;
        var params={
            from_date:moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD"),
            kr_id:this.state.selKegRoom.value,
            top_num:this.state.top_num
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/PourVolumeByDateChart`,{params:params})
        .then(function(response){
            if(response.data.result!==undefined && response.data.result.length>0){
                var TotalVolume_Poured=parseFloat(convertValueByMetric(response.data.result1[0].volume_poured,units,"vol")/(units==="metric"?1000:1)).toFixed(2);
                response.data=response.data.result;
                response.data.map(function(item){
                    var datePart=item.end_date.substring(0,10)+ " 00:00:00";
                    var d=moment(datePart);
                    //var d1=moment(item.local_end_period);
                    item["end_date"]=moment(d._d).format("YYYY-MM-DD");
                    return item
                }) 
                response.data.sort((a, b) => new Date(a.end_date) - new Date(b.end_date));
                var data=[];
                var groupByBeer=  response.data.reduce(function (r, a) {
                    r[a.beer_name] = r[a.beer_name] || [];
                    r[a.beer_name].push(a);
                    return r;
                }, Object.create(null));
                var beer_counter=1;
                var beer_data=[];
                for(var beerKey in groupByBeer)
                {
                    var groupByDate=groupByBeer[beerKey].reduce(function (r, a) {
                        var end_date=moment(a.end_date.substring(0,10));
                        r[end_date] = r[end_date] || [];
                        r[end_date].push(a);
                        return r;
                    }, Object.create(null));
                    var bar_counter=1;
                    for(var key in groupByDate){
                        var volume_poured=groupByDate[key].map(o => parseFloat(o.pour_volume)).reduce((a, c) => { return a + c });
                        data.push({
                            id:bar_counter,
                            name:beerKey,
                            end_date:key,
                            volume_poured:convertValueByMetric(volume_poured,units,"vol").toFixed(2)
                        });
                        bar_counter++;
                    }
                    var volume_poured_b=groupByBeer[beerKey].map(o => parseFloat(convertValueByMetric(o.pour_volume,units,"vol"))).reduce((a, c) => { return a + c });
                    var duration_b=groupByBeer[beerKey].map(o => parseFloat(o.duration_sec)).reduce((a, c) => { return a + c });
                    var temp_b=groupByBeer[beerKey].map(o => parseFloat(convertValueByMetric(o.temperature_c,units,"c"))).reduce((a, c) => { return a + c });
                    var ambient_b=groupByBeer[beerKey].map(o => parseFloat(o.ambient_c)).reduce((a, c) => { return a + c });
                    beer_data.push({
                        id:beer_counter,
                        name:beerKey,
                        //end_date:key,
                        volume_poured:parseFloat(volume_poured_b).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        number_pints:(volume_poured_b/convertValueByMetric(groupByBeer[beerKey][0].mlperpint,units,"vol")).toFixed(2),
                        volume_avg:(parseFloat(volume_poured_b)/(groupByBeer[beerKey].length)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        duration:parseFloat(duration_b/groupByBeer[beerKey].length).toFixed(2),
                        temp:parseFloat(temp_b/groupByBeer[beerKey].length).toFixed(2),
                        ambient:parseFloat(ambient_b/groupByBeer[beerKey].length).toFixed(2)
                    });
                    beer_counter++;
                }
                var chartKeys = [...new Set(data.map(data => data.name))]
                var minDate=Math.min(...data.map(s=>moment(s.end_date)));
                var maxDate=Math.max(...data.map(s=>moment(s.end_date)));
                var diffDays=moment(maxDate).diff(moment(minDate),'days');
                var days_bin=parseInt(diffDays/14);
                var datesDin=[];
                while(moment(minDate)<=moment(maxDate)){
                    var xPoint={
                        "date":moment(minDate).format("YYYY-MM-DD"),
                        fromDate:moment(minDate).format("YYYY-MM-DD"),
                        toDate:moment(minDate).add(days_bin,'days')>moment(maxDate)?moment(maxDate).format("YYYY-MM-DD"):moment(minDate).add(days_bin,'days').format("YYYY-MM-DD")
                    }
                    // eslint-disable-next-line no-loop-func
                    chartKeys.forEach((item)=>{
                        xPoint[item]=0;
                    })
                    datesDin.push(xPoint);
                    minDate=moment(minDate).add(days_bin+1,'days');
                }
                var chartData=[];
                datesDin.forEach((item)=>{
                    var fromDate=moment(item.fromDate);
                    var toDate=moment(item.toDate);
                    var subData=data.filter(s=>moment(s.end_date)>=fromDate && moment(s.end_date)<=toDate)
                    var groupedData=  subData.reduce(function (r, a) {
                        r[a.name] = r[a.name] || [];
                        r[a.name].push(a);
                        return r;
                    }, Object.create(null));
                    for(var key in groupedData){
                        var volumePouredSum=groupedData[key].map(o => parseFloat(o.volume_poured)).reduce((a, c) => { return a + c });
                        item[key]=parseFloat(volumePouredSum/(units==="metric"?1000:1)).toFixed(2);
                    }
                    chartData.push(item);
                })
                data.sort((a, b) => a.name.localeCompare(b.name))
                var groupByBeer_p=  data.reduce(function (r, a) {
                    r[a.name] = r[a.name] || [];
                    r[a.name].push(a);
                    return r;
                }, Object.create(null));
                var pieData=[];
                let totalSum = data.map(o => parseFloat(o.volume_poured)).reduce((a, c) => { return a + c });
                totalSum=parseFloat(totalSum/(units==="metric"?1000:1)).toFixed(2);
                var  othersSum=0,othersPercent=0,others=[];
                var othersColor=colorsData[0].hex;
                var colorCount=1,total=0;
                for(var key1 in groupByBeer_p){
                    var beerData=groupByBeer_p[key1];
                    let sum = beerData.map(o => parseFloat(o.volume_poured)).reduce((a, c) => { return a + c });
                    total+=(sum/(units==="metric"?1000:1));
                    sum=(sum/(units==="metric"?1000:1)).toFixed(2);
                    var percent=(parseFloat(sum*100)/parseFloat(totalSum)).toFixed(2);
                    if(percent>5){
                        pieData.push({
                            "id":key1,
                            "label":key1,
                            "value":sum,
                            "color":colorsData[colorCount].hex,
                            "percent":(parseFloat(sum*100)/parseFloat(totalSum)).toFixed(2),
                            "actualPercent":(parseFloat(sum*100)/TotalVolume_Poured).toFixed(2)
                        });
                        colorCount++;
                     }
                     else{
                        others.push({label:key1,color:othersColor,volume:sum,percent:percent});
                        othersSum+=parseFloat(sum);
                        othersPercent+=parseFloat(percent);
                       
                    }
                }
                pieData.push({
                    "id":"Others",
                    "label":"Others",
                    "color":othersColor,
                    "value":othersSum.toFixed(2),
                    "percent":othersPercent.toFixed(2),
                    "others":others,
                    "actualPercent":othersPercent.toFixed(2)
                })
                var title=newState.state.top_num===0?`Top selling beers by volume (${(total).toFixed(2)} ${(units==="metric"?'L':'OU')})`:`Top ${newState.state.top_num} selling beers by volume (${(total).toFixed(2)} ${(units==="metric"?'L':'OU')}) `;
                newState.setState({
                    ...newState.state,
                    keys:chartKeys,
                    pData:pieData,
                    data:chartData,
                    showChart:true,
                    title:title,
                    masterData:response.data,
                    loading:false,
                    errorMessage:"",
                    beerData:beer_data.sort((a, b) => a.name.localeCompare(b.name))
                });
            }
            else{
                newState.setState({
                    ...newState.state,
                    showChart:true,
                    loading:false,
                    errorMessage:"No Data Found"
                });
            }
            
        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    showPourDetails=(row)=>{
        const columns = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "duration",
              text: "Duration (s)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "pour_volume",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
            },
            {
              dataField: "end_time",
              text: "Time",
              sort: true,
              align:'center',
              headerAlign:'center'
            },
            {
              dataField: "temperature",
              text: this.props.units==="metric"?"Temp (C)":"Temp (F)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Ambient (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];
        var pourByKeg=this.state.masterData.filter(p=>p.beer_name===row.name && moment(p.end_date.substring(0,10)).format("YYYY-MM-DD")===row.end_date);
        var pourData=[],counter=1,totalVolume=0,totalDuration=0,totalTemp=0;
        pourByKeg.forEach((item)=>{
            pourData.push({
                name:item.beer_name,
                pour_volume:convertValueByMetric(item.pour_volume,this.props.units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                number_pints:(item.pour_volume/item.mlperpint).toFixed(2),
                pour_id:counter,
                keg_id:item.keg_id,
                duration:item.duration_sec,
                temperature:convertValueByMetric(item.temperature_c,this.props.units,"c").toFixed(2),
                ambient:item.ambient_c===null?0:item.ambient_c.toFixed(2),
                end_date:moment(item.end_date.substring(0,10)).format("YYYY-MM-DD"),
                end_time:item.end_time
            })
            totalVolume+=convertValueByMetric(item.pour_volume,this.props.units,"vol");
            totalDuration+=item.duration_sec;
            totalTemp+=convertValueByMetric(item.temperature_c,this.props.units,"c");
            counter++;
        })
        if(pourData.length>0){
            pourData.push({
                end_date:"",
                name:"Summary",
                //end_date:key,
                pour_volume:totalVolume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                duration:(totalDuration/pourByKeg.length).toFixed(2),
                temperature:(totalTemp/pourByKeg.length).toFixed(2),
                ambient:(pourData.map(o => parseFloat(o.ambient)).reduce((a, c) => { return a + c })/pourData.length).toFixed(2)
            });
        }

        var title=`Pour Detail Report for Date: ${row.end_date}`;
        if(pourData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                    <Button onClick={()=>this.pourHistChart(pourData)} size="sm">Chart</Button>
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,title,this.props.companyLogo,this.props.userLogo,"Pour-Volume-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pours"
                                filename="Total Pour"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_pours"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="pour_id"
                                data={pourData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    showPourDetailsByDate=(row)=>{
        const columns = [
            {
              dataField: "end_date",
              text: "End Date",
              hidden: false,
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true
            },
            {
                dataField: "duration",
                text: "Avg Duration (s)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
              },
              {
                dataField: "volume_poured",
                text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
              },
              {
                dataField: "volume_avg",
                text: this.props.units==="metric"?"Avg Volume (ml)":"Avg Volume (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
              },
              {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
              },
              {
                dataField: "temp",
                text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
              },
            //   {
            //     dataField: "ambient",
            //     text: "Avg Ambient (C)",
            //     sort: true,
            //     align: 'right',
            //     headerAlign:'center'
            //   }
          ];
        var pourByKeg=this.state.masterData.filter(p=>p.beer_name===row.name);
        var pourData=[];
        var groupByDate=  pourByKeg.reduce(function (r, a) {
            var end_date=moment(a.end_date.substring(0,10)).format("YYYY-MM-DD")
            r[end_date] = r[end_date] || [];
            r[end_date].push(a);
            return r;
        }, Object.create(null));
        var totalTemp=0,totalDuration=0,totalVolume=0;
        var totalAmbient=0;
        for(var key in groupByDate){
            var _subData=groupByDate[key];
            var volume_poured_b=_subData.map(o => parseFloat(convertValueByMetric(o.pour_volume,this.props.units,"vol"))).reduce((a, c) => { return a + c });
            var duration_b=_subData.map(o => parseFloat(o.duration_sec)).reduce((a, c) => { return a + c });
            var temp_b=_subData.map(o => parseFloat(convertValueByMetric(o.temperature_c,this.props.units,"c"))).reduce((a, c) => { return a + c });
            var ambient_b=_subData.map(o => parseFloat(o.ambient_c)).reduce((a, c) => { return a + c });
            pourData.push({
                end_date:key,
                name:row.name,
                //end_date:key,
                volume_poured:parseFloat(volume_poured_b).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                duration:parseFloat(duration_b/_subData.length).toFixed(2),
                temp:parseFloat(temp_b/_subData.length).toFixed(2),
                ambient:parseFloat(ambient_b/_subData.length).toFixed(2),
                number_pints:(volume_poured_b/convertValueByMetric(_subData[0].mlperpint,this.props.units,"vol")).toFixed(2),
                volume_avg:parseFloat(volume_poured_b/(_subData.length)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            });
            totalTemp+=temp_b;
            totalDuration+=duration_b;
            totalVolume+=volume_poured_b;
            totalAmbient+=ambient_b;
        }

        if(pourData.length>0){
            pourData.push({
                end_date:"",
                name:"Summary",
                //end_date:key,
                volume_poured:(totalVolume).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                duration:(totalDuration/pourByKeg.length).toFixed(2),
                temp:(totalTemp/pourByKeg.length).toFixed(2),
                ambient:(totalAmbient/pourByKeg.length).toFixed(2)
            });
        }

        const expandDateRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return this.showPourDetails(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
              this.setState({...this.state,enablePourChartTab:expanded})
              if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
            }
          };
          
        var title=`${row.name} Pour Volume from ${moment(this.state.from_date).format("YYYY-MM-DD")} to ${moment(this.state.to_date).format("YYYY-MM-DD")}`
        if(pourData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,title,this.props.companyLogo,this.props.userLogo,"Pour-Volume-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pours_date"
                                filename="Total Pour"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_pours_date"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="pour_id"
                                data={pourData}
                                expandRow={expandDateRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    pourHistChart=(data)=>{
        data=data.filter(s=>s.name.toLowerCase()!=="summary");
        var histData=[];
        var maxPoint=Math.max(...data.map(s=>parseFloat(s.pour_volume)));
        var counter1=0,increment=Math.round(maxPoint/15);
        increment=increment+((this.props.units==="metric"?100:(100*0.033814))-(increment%100))
        maxPoint+=increment;
        while(counter1<maxPoint){
            // eslint-disable-next-line no-loop-func
            var hisSubData=data.filter(s=>s.pour_volume>=(counter1-increment) && s.pour_volume<=counter1);
            histData.push({id:counter1,value:hisSubData.length})
            counter1+=increment;
        }
        this.setState({
            ...this.state,
            activeTab:"3",
            pourDetailHistData:histData
        })
    }

    expandBeerColumn=(expanded)=>{
        this.setState({...this.state,enablePourChartTab:expanded})
        if (expanded) {
            return (
              <i className="fa fa-caret-down fa-md"></i>
            );
          }
          return (
            <i className="fa fa-caret-right fa-md"></i>
          );
    }

    render() {
        const {showPourDetailsByDate}=this;
        const expandBeerRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return showPourDetailsByDate(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
             return this.expandBeerColumn(expanded)
            }
          };
        const columns = [
            {
              dataField: "id",
              text: "Beer Id",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true
            },
            {
                dataField: "duration",
                text: "Avg Duration (s)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
              },
              {
                dataField: "volume_poured",
                text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
              },
              {
                dataField: "volume_avg",
                text: this.props.units==="metric"?"Avg Volume (ml)":"Avg Volume (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
              },
              {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
              },
              {
                dataField: "temp",
                text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
              },
            //   {
            //     dataField: "ambient",
            //     text: "Avg Ambient (C)",
            //     sort: true,
            //     align: 'right',
            //     headerAlign:'center'
            //   }
          ];
      
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Top Selling Beers</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Select Top</Label>
                                <Input type="number" size="sm" value={this.state.top_num} onChange={(e) => this.handleChangeSelection(e.target.value, 'top_num')}/>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                           
                        </Row>
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Nav tabs>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                            {this.state.enablePourChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}            >
                                Pour Detail Histogram
                                </NavLink>
                            </NavItem>:""
                            }
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {
                                this.state.showChart?
                                <>
                                <CustomStackedBar data={this.state.data} keys={this.state.keys} title={this.state.title}
                                xtitle="Date" ytitle={this.props.units==="metric"?"Total Volume (L)":"Total Volume (OU)"} />
                                {/* <CustomPieChart data={this.state.pData} title={this.state.title} /> */}
                                </>
                                :""
                            }
                        </TabPane>
                        <TabPane tabId="2">
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.beerData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.beerData,this.state.title,this.props.companyLogo,this.props.userLogo,"Pour-Volume-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pour_volume_data"
                                filename="Pour Volume By Date"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                       
                            <BootstrapTable id="tbl_pour_volume_data"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="id"
                                data={this.state.beerData}
                                expandRow={expandBeerRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                        <TabPane tabId="3">
                            <div style={{width:'100%',height:'400px'}}>
                                        <ResponsiveBar 
                                        borderRadius={1}
                                        data={this.state.pourDetailHistData}
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        //padding={{left:50,right:50,top:50,bottom:50}}
                                        // valueScale={{"type":"linear"}}
                                        xScale={{
                                            type: "linear",
                                            min: 0,
                                            max: 100
                                        }}
                                        yScale={{
                                            type:"linear",
                                            max:'auto'
                                        }}
                                        colorBy="id"
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: this.props.units==="metric"?'Pour Volume (ML)':'Pour Volume (OU)',
                                            legendPosition: 'middle',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Count',
                                            legendPosition: 'middle',
                                            legendOffset: -30,
                                            tickValues:10
                                        }}
                                        enableGridX={true}
                                        enableGridY={true}
                                        gridXValues={20}

                                    />
                                </div>
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

export default connect(mapStateToProps, null)(PourVolumeDateReport);
