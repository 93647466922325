import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input } from 'reactstrap';
import axios from "axios";
import AxiosLoader from "../shared/loader-new";

const DownloadModal = (props) => {

    const [loading, setLoading] = useState(false);
    const [reportLinks, setReportLinks] = useState([]);
    const [fields, setFields] = useState([{ name: "", email: "" }])

    const downloadReport = () => {
        let emails = fields.map((f) => f.email);
        emails = emails.filter((field) => {
            return field !== null && field !== '';
        });

        props.filter.customer_email = [...props.filter.customer_email, ...emails];

        setLoading(true);
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_BASE_URL}/api/reports/KegReport`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'true'
            },
            data: props.filter
        })
            .then((response) => {
                if (response.data.response) {
                    setLoading(false);
                    setReportLinks(response.data.response);
                }
            });
    }

    const addEmailField = () => {
        setFields([...fields, { name: "", email: "" }])
    }

    const delField = (i) => {
        if (fields.length === 1) {
            return;
        }
        let newfields = [...fields];
        newfields.splice(i, 1);
        setFields(newfields)
    }

    const handleInputChange = (event, inputPropName, index) => {
        let newfields = [...fields];

        if (event.target) {
            newfields[index].email = event.target.value;
        }
        setFields(newfields);
    }

    return (
        <div>


            <Modal scrollable={true} isOpen={props.isOpen}>
                {loading && <AxiosLoader />}
                <ModalHeader >Download links</ModalHeader>
                <ModalBody>
                    <ul style={{ overflowWrap: 'anywhere' }}>
                        {reportLinks.map((item, index) => {
                            return (
                                <li key={index + 'link'}><a href={item}>{item}</a></li>
                            )
                        })}
                    </ul>

                    <Form>
                        {fields.map((element, index) => (
                            <FormGroup key={index + 'fields'}>
                                <Input onChange={(e) => handleInputChange(e, 'email', index)} type="email" name="email" placeholder="Enter email address" value={element.email} />

                                <Button disabled={index === 0} color="danger" onClick={() => delField(index)}>-</Button>
                            </FormGroup>

                        ))}
                        <Button onClick={addEmailField}>Add</Button>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={downloadReport}>Generate Report</Button>
                    <Button color="secondary" onClick={props.onClose}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default DownloadModal;