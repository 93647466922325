import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./kr_temperature.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SEL_KR_TEMP_SENSOR):
            return setSelKts(state, action);
        case (actionTypes.SET_KR_TEMP_SENSOR):
            return setKts(state, action);
        case (actionTypes.SET_CURR_KR_TEMP_SENSOR_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        case (actionTypes.ADD_KR_TEMP_SENSOR):
            return addKts(state, action);
        case (actionTypes.UPDATE_KR_TEMP_SENSOR):
            return updateKts(state, action);
        default:
            return state;
    }
};

const addKts = (state, action) => {
    let krTempSensors = [...state.krTempSensors];
    krTempSensors.push(action.data);
    return updateObject(state, {
        krTempSensors: krTempSensors
    });
};

const updateKts = (state, action) => {
    const index = state.krTempSensors.findIndex(b => b.kts_id === action.data.kts_id);
    return {
        ...state,
        krTempSensors: [...state.krTempSensors.slice(0, index), action.data, ...state.krTempSensors.slice(index + 1)]
    };
};


const setSelKts = (state, action) => {
    return updateObject(state, {
        selKts: action.data
    });
};

const setKts = (state, action) => {
    return updateObject(state, {
        krTempSensors: action.data,
        showList:true
    });
};

const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selKts: initialState.selGs,
        krTempSensors: initialState.krTempSensors,
        currPage: initialState.currPage
    });
};

export default reducer;