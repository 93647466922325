import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import LoaderComponent from '../../views/shared/loader';
import AdvancedFilter from "../../views/shared/advancedFilter";
import { connect } from "react-redux";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      person_id:0,
      filteredUserList: [],
      fields: [{ value: 'id', label: 'Id', type: 'int' }, { value: 'username', label: 'Username', type: 'text' }, { value: 'first_name', label: 'First Name', type: 'text' }, { value: 'middle_name', label: 'Middle Name', type: 'text' }, { value: 'last_name', label: 'Last Name', type: 'text' }
        , { value: 'gender', label: 'Gender', type: 'text' }, { value: 'email', label: 'Email', type: 'text' }, { value: 'phone', label: 'Phone', type: 'text' }, { value: 'responsibility_name', label: 'Responsibility', type: 'text' }],
      showAdvancedSearch: false,
      showList: false,
    };
  }

  componentDidMount() {
    this.fetchUsers(this.props.person_id);
  }

//   componentWillReceiveProps(nextProps) {
//     this.fetchUsers(nextProps.person_id);
//  }

  fetchUsers=(personId)=>{
    const data_only=this.props.data_only;
    const logged_in_user_id=this.props.user_id;
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/users`,{ params: {site_id:this.props.site_id,isSuperUser:this.props.isSuperUser}}).then((response) => {
      if (response.data !== undefined) {
        let usersData = [];
        response.data.forEach((item) => {
          if(data_only && logged_in_user_id!==item.user_id) return 
          usersData.push({
            id: item.user_id,
            username: item.username,
            first_name: item.person.first_name,
            last_name: item.person.last_name,
            middle_name: item.person.middle_name,
            gender: item.person.gender,
            email: item.person.email,
            phone: item.person.phone,
            person_id:item.person.person_id,
          });
        });

        usersData.sort((a, b) => a.first_name.localeCompare(b.username));
        this.setState({ users: usersData, filteredUserList: usersData, showList: true,person_id:personId });
      }
    });
  }

  fetchUsersFromParent=(personId)=>{
    let newState=Object.assign({},this.state);
    newState.person_id=personId;
    this.setState(newState);
    this.fetchUsers();
  }

  toggleAdvancedSearch = () => {
    let newState = Object.assign({}, this.state);
    newState.showAdvancedSearch = !newState.showAdvancedSearch;
    if (!newState.showAdvancedSearch) {
      newState.filteredUserList = newState.users;
    }
    this.setState(newState);
  }

  callBackFromAdvancedSearch = (data) => {
    let newState = Object.assign({}, this.state);
    newState.filteredUserList = data;
    this.setState(newState);
  }

  addUser = () => {
    window.location = `#/admin/adduser/0/person/0`;
  };

  filterUserList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredUserList: [...this.state.users] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredUsers = this.state.users.filter(
        (com) =>
          com.username.toLowerCase().indexOf(searchText) >= 0 ||
          com.email.toLowerCase().indexOf(searchText) >= 0 ||
          com.phone.toLowerCase().indexOf(searchText) >= 0 ||
          com.gender.toLowerCase().indexOf(searchText) >= 0
      );

      this.setState({ filteredUserList: [...filteredUsers] });
    }
  };

  deleteUser = (row) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: "DELETE",
              url: `${process.env.REACT_APP_API_BASE_URL}/api/users/${row.id}`,
            }).then((response) => {
              if (response.data !== undefined) {
                alert('Deleted succesfully');
                const updatedUsers = this.state.users.filter(
                  (com) =>
                    com.id !== row.id
                );

                this.setState({ filteredUserList: [...updatedUsers] });
              }
            });
          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });

  };

  render() {
    
    const options = {
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const columns = [
      {
        dataField: "id",
        text: "User Id",
        hidden: true,
      },
      {
        dataField: "username",
        text: "Username",
        sort: true,
      },
      {
        dataField: "first_name",
        text: "First Name",
        sort: true,
      },
      {
        dataField: "middle_name",
        text: "Middle Name",
        sort: true,
      },
      {
        dataField: "last_name",
        text: "Last Name",
        sort: true,
      },
      {
        dataField: "gender",
        text: "Gender",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "phone",
        text: "Phone",
        sort: true,
      },
      {
        text: "Manage",
        dataField: "manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link
                className="btn btn-sm btn-info"
                to={`/admin/adduser/${row.id}/person/${row.person_id}`}
                params={{ testvalue: "hello" }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => this.deleteUser(row)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterUserList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              {this.props.data_only?"":
                <Col md="3" lg="3">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="mr-5">
                      <Button
                        className="btn btn-outline-default btn-sm"
                        onClick={this.addUser}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                        Add&nbsp;
                      </Button>
                    </div>
                  </div>
                </Col>
              }
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.users} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="id"
                    data={this.state.filteredUserList}
                    pagination={paginationFactory(options)}
                    columns={columns}
                  />
                ):<LoaderComponent />}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    user_id: state.userInfo.userDetails.user_id,
    data_only:state.userInfo.security.sec_level.data_only && !state.userInfo.security.sec_level.user_only,
  }
}

export default connect(mapStateToProps,null)(UserList);
