import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { connect } from "react-redux";



class CustomPieChart extends React.Component{
  constructor(props){
    super(props)
    this.state={
      data:props.data,
      title:props.title,
      others:props.data.filter(s=>s.label==="Others").length===0?[]:props.data.filter(s=>s.label==="Others")[0].others
    }
    this.chartRef=React.createRef();
  }

  Title=(w,h)=>{
      
    const style = {fontWeight: 'bold',textAlign:'center'}
  return (
            <text
            x={500}
            y={-10}
            textAnchor="middle"
            style={style}
        >
        {this.props.title}
        </text>
    )
  }
  
  render(){
    const theme = {
        labels:{
            text:{
                fontSize:'14px'
            }
        }
      };
    return(<>
    <div style={{width:'100%',height:'500px',padding:'10px'}}>
       <h4 style={{textAlign:'center'}}>{this.state.title}</h4> 
      <ResponsivePie ref={this.chartRef}
       theme={theme}
       
        data={this.state.data}
        colors={{ datum: 'data.color' }}
        tooltip={({ datum: { id, value, color,data } }) => (
            <div style={{padding: 5,color:'#fff',background: color}}>
                <strong>
                    {id}: {value} ({data.actualPercent}%)
                </strong>   
            </div>
        )}
        margin={{ top: 40, right: 80, bottom: 80, left: -250 }}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabel={d => `${d.id} (${d.data.percent}%)`}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'ruby'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'c'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'go'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'python'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'scala'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'lisp'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'elixir'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'javascript'
                },
                id: 'lines'
            }
        ]}
        layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends']}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 90,
                translateY: 60,
                itemWidth: 100,
                itemHeight: 21,
                itemsSpacing: 0,
                symbolSize: 20,
                itemDirection: 'right-to-left',
                data: this.state.others.map((item, index) => ({
                    color: item.color,
                    id:item.label,
                    label: `${item.label} - ${item.volume}${this.props.units==="metric"?"L":"OU"} (${item.percent}%)`
                })),
            }
        ]}
       
    />
      </div>
    </>)
  }
}


const mapStateToProps = (state) => {
    return {
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

export default connect(mapStateToProps, null)(CustomPieChart);