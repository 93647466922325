import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
//import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
//import moment from 'moment';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import LoaderComponent from '../shared/loader';
import SensorList from "../sensor";
import * as shActions from './store/sensor_hub.actions';
import * as sensorActions from '../sensor/store/sensor.actions';

class SensorHubList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //mcu_id: this.props.mcu_id === undefined ? 0 : this.props.mcu_id,
      sensorHubs: [],
      filteredShList: [],
      //expanded: [],
      showList: false,
      //depthLevel: this.props.depth_level ? this.props.depth_level : 0,
      selSenorHub: null,
      collapse: false
    };

    // Create the child instance using react createRef
    //this.refSensors = React.createRef();
  }

  async componentDidMount () {
    var selected_site_id = this.props.site_id;
    await this.props.getSensorHubs(selected_site_id,true)
    if(this.props.selSensorHub.id===0){
      this.props.setSelSensorHub(Object.assign({}, this.props.sensorHubs[0]));
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      sensorHubs:props.sensorHubs,
      selSensorHub:props.selSensorHub,
      filteredShList: props.sensorHubs,
      showList: props.sensorHubs.length>0
    }
  }

  // fetchSh = () => {
  //   //var selected_site_id = parseInt(localStorage.getItem("site_id"));
  //   var selected_site_id = this.props.site_id;
  //   if (this.state.mcu_id) {
  //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensor_hub`, { params: { mcu_id: this.state.mcu_id, site_id: selected_site_id } }).then(response => {

  //       if (response.data !== undefined) {
  //         this.setShModel(response.data);
  //       }
  //     });
  //   } else {
  //     if (this.state.depthLevel === 0) {
  //       axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensor_hub?site_id=${selected_site_id}`).then((response) => {
  //         if (response.data !== undefined) {
  //           this.setShModel(response.data);
  //         }
  //       });
  //     }
  //     else {
  //       this.setShModel([]);
  //     }
  //   }
  // }

  // setShModel = (shs) => {
  //   var shData = [];
  //   shs.forEach((item) => {
  //     shData.push({
  //       id: item.sh_id,
  //       serial_number: item.sh_serial_number,
  //       status: item.sh_status,
  //       last_reboot_date: moment(item.sh_last_reboot_date).format('YYYY-MM-DD'),
  //       mcu_serial_number: item.mcu_serial_number
  //     });
  //   });

  //   shData = shData.sort((a, b) => a.serial_number.localeCompare(b.serial_number));

  //   if (this.state.depthLevel < 2 && shData.length) {
  //     this.loadChildComponents(shData[0].id);
  //   }
  //   else {
  //     this.loadChildComponents(0);
  //   }

  //   this.setState({ sensorHubs: shData, filteredShList: shData, showList: true });
  // }

  // fetchSensorHubsFromParent(mcuId) {
  //   this.setState({
  //     mcu_id: mcuId
  //   });
  //   this.fetchSh();
  // }

  addSensorHub = () => {
    window.location = `#/admin/sensor_hub/0/mcu/${this.props.selMcu.id}`;
  };

  filterShList = (event) => {
    let searchText = event.target.value;
    let filteredSh = [];
    if (!searchText) {
      filteredSh = this.state.sensorHubs;
    } else {
      searchText = searchText.toLowerCase();
      filteredSh = this.state.sensorHubs.filter(
        (com) =>
          com.serial_number.toLowerCase().indexOf(searchText) >= 0 ||
          com.status.toLowerCase().indexOf(searchText) >= 0
      );
    }

    // if (this.state.depthLevel < 2 && filteredSh.length) {
    //   this.loadChildComponents(filteredSh[0].id);
    // }
    // else {
    //   this.loadChildComponents(0);
    // }

    this.setState({ filteredShList: [...filteredSh] });
  };

  // loadChildComponents = (sensorHubId) => {
  //   let newState = Object.assign({}, this.state);
  //   newState.selectedSensorHubId = sensorHubId;
  //   //newState.depthLevel=2;
  //   this.setState(newState);
  //   if (this.state.depthLevel < 2 && this.refSensors.current !== null) {
  //     this.refSensors.current.fetchSensorsFromParent(sensorHubId);
  //   }
  // }

  setRowSelected = (elem) => {
    for (let i = 0; i < elem.parentNode.getElementsByTagName('tr').length; i++) {
      elem.parentNode.getElementsByTagName('tr')[i].className = '';
    }
    elem.className = 'selected';
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  setSensorHub=async(sh)=>{
    this.props.setSelSensorHub(Object.assign({}, sh));
    await this.props.getSensors(true);
    this.props.setSelSensor(Object.assign({}, this.props.sensors[0]))
  }

  render() {

    const options = {
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setSensorHub(row)
        this.setRowSelected(e.currentTarget);
      }
    };

    const rowClasses = (row, rowIndex) => {
      return this.props.selSensorHub.id === row.id ? 'selected' : '';
    };

    const columns = [
      {
        dataField: "id",
        text: "Sh Id",
        hidden: true,
      },
      {
        dataField: "serial_number",
        text: "Serial Number",
        sort: true,
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
      },
      {
        dataField: "last_reboot_date",
        text: "Last Reboot Date",
        sort: true,
      },
      {
        dataField: "mcu_serial_number",
        text: "MCU Serial Number",
        sort: true,
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link
                className="btn btn-sm btn-info"
                to={`/admin/sensor_hub/${row.id}/mcu/${this.props.selMcu.id}`}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
              {/* <button
                className="btn btn-sm btn-danger"
                onClick={() => this.customDelete(row)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button> */}
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" id="/admin/sensor_hub">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
              <h3 className="dft-primary-color">Sensor hubs</h3>
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterShList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="6" lg="6">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addSensorHub}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--5">
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="id"
                    data={this.state.filteredShList}
                    pagination={paginationFactory(options)}
                    columns={columns}
                    rowEvents={rowEvents}
                    rowClasses={rowClasses}
                  />
                ) : this.state.showList === false ? (
                  <LoaderComponent />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        {
          this.props.selSensorHub.id!==0?
          <>
            <SensorList />
          </>
          :""
        }
        {/* {this.state.depthLevel < 2 ? (
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Row>
                <h3 className="dft-primary-color">Sensors</h3>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <SensorList sh_id={this.state.selectedSensorHubId} ref={this.refSensors} depth_level={this.state.depthLevel + 1} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        ) : ""} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    currPage: state.sensorHubs.currPage,
    sensorHubs:state.sensorHubs.sensorHubs,
    selSensorHub:state.sensorHubs.selSensorHub,
    sensors:state.sensors.sensors,
    selMcu:state.mcus.selMcu
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrPage: (page) => dispatch(shActions.setCurrPage(page)),
    getSensorHubs:(site_id,forceReload)=>dispatch(shActions.getSensorHubs(site_id,forceReload)),
    setSelSensorHub:(sh)=>dispatch(shActions.setSelSensorHub(sh)),
    getSensors:(forceReload)=>dispatch(sensorActions.getSensors(forceReload)),
    setSelSensor:(sensor)=>dispatch(sensorActions.setSelSensor(sensor))
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SensorHubList);
