
const INCREMENT = "#90ED7D";
const DECREMENT = "#F47560";
const transparent = "rgba(0,0,0,0)";

  export const calcWaterfallDataWSign = (incomeData,initialValue) => {
    if (!incomeData.length) return [];
    let prev = initialValue || 0;
    let currentSign = Math.sign(prev);
  
    const waterFallData = incomeData.map(({ x, y,showFull }) => {
      let color = y > 0 ? INCREMENT : DECREMENT;
      let subtotal = 0;
      let amount = y;
      let paintSubtotal = 0;
      const next = prev + y;

      if(showFull!==undefined && showFull){
        color="#90ED7D"
        return{
          subtotal,
          amount,
          color,
          paintSubtotal,
          accumulated: next,
          x,
          y,
          showFull
        }
      }
      
      // no sign change
      if (Math.sign(next) === currentSign) {
        // stays as positive value
        if (currentSign === 1) {
          if (y < 0) {
            subtotal = next;
            amount = Math.abs(y);
          } else {
            subtotal = prev;
            amount = y;
          }
        } else if (currentSign === -1) {
          // stays as negative value
          subtotal = y < 0 ? prev : next;
          if (y < 0) {
            subtotal = prev;
            amount = y;
          } else {
            subtotal = next;
            amount = -y;
          }
        }
      } else if (Math.sign(next) !== currentSign) {
        // sign change
        if (currentSign === 1) {
          // from + to -
          amount = prev;
          subtotal = next;
          paintSubtotal = 1;
        } else if (currentSign === -1) {
          // from - to +
          amount = next;
          subtotal = prev;
          paintSubtotal = 1;
        }
      }
  
      prev = next;
      currentSign = Math.sign(prev);
  
      return {
        subtotal,
        amount,
        color,
        paintSubtotal,
        accumulated: next,
        x,
        y,
        prev
      };
    });
    return waterFallData;
  };
  
  // This function is to determine the color of the bar
  export const setColor = ({ id, data: _data }) =>{
    return id !== "subtotal" || !!_data.paintSubtotal ? _data.color : transparent;
  }
    