import React from "react";
import {
  Button,
  Card,
  Label,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import * as kegRoomActions from '../kegroom/store/kegroom.actions';
import * as lineActions from './store/line.actions';
import LineCleaningWidget from "../dashboard/widgets/line-cleaning-widget";
import * as monitorActions from '../dashboard/store/dashboard-actions';

class LineCleaning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     kegRooms:[],
     selKegRoom:null,
     loading:false,
     lineCleaningStatus:false
    };
  }

  async componentDidMount () {
    var selected_site_id = this.props.site_id;
    await this.props.getKegRooms(selected_site_id);
    this.props.setSelKegRoom(Object.assign({}, this.props.kegRooms[0]));
    this.props.getLineCleaningStatus(this.props.kegRooms[0].kr_id)
  }

  static getDerivedStateFromProps(props, state) {
    var _selKr=props.selKegRoom.kr_id!==0? {label:props.selKegRoom.kr_name,value:props.selKegRoom.kr_id}:null
    return {
      kegRooms:props.kegRooms.length>0?props.kegRooms.map((item)=>{return {label:item.kr_name,value:item.kr_id}}):[],
      selKegRoom:_selKr,
      lineCleaningStatus:props.lineCleaningStatus
    }
  }

  handleChangeSelection = (item,inputPropName) => {
    this.setState({ [inputPropName]: item }, () => {
    });
  }

  lineCleaning=async()=>{
    this.setState({...this.state,loading:true})
    if(!this.props.lineCleaningStatus){
      await this.props.startLineCleaning(this.state.selKegRoom.value);
      this.props.setWidget('lc')
      this.props.history.push('/admin/dashboard')
    }
    else{
      await this.props.stopLineCleaning(this.state.selKegRoom.value);
      await this.props.calculateLineCleaning(this.state.selKegRoom.value);
    }
    this.setState({...this.state,loading:false})
  }


  render() {
    const lineCleaningStatus=this.props.lineCleaningStatus?"Stop Cleaning":"Start Cleaning";
    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" id="/admin/lines">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
                <h3 className="dft-primary-color">Line Cleaning</h3>
                    <Row>
                        <Col md="6" sm="6" lg="6">
                            <Label>keg Room</Label>
                            <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                            </Select>
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col md="6" sm="6" lg="6">
                            <Button size="sm" onClick={this.lineCleaning} disabled={this.state.loading ? 'disabled' : ''}>
                              {this.state.loading?"Please wait...":lineCleaningStatus}
                            </Button>
                        </Col>
                    </Row>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--5">
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                <br /><br /><br />
                <LineCleaningWidget />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    kegRooms:state.kegRooms.kegRooms,
    selKegRoom:state.kegRooms.selKegRoom,
    lineCleaningStatus:state.lines.lineCleaningStatus,
    widgetKey:state.monitor.widgetKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
    setSelKegRoom:(kr)=>dispatch(kegRoomActions.setSelKegRoom(kr)),
    startLineCleaning:(kr_id)=>dispatch(lineActions.startLineCleaning(kr_id)),
    stopLineCleaning:(kr_id)=>dispatch(lineActions.stopLineCleaning(kr_id)),
    getLineCleaningStatus:(kr_id)=>dispatch(lineActions.getLineCleaningStatus(kr_id)),
    calculateLineCleaning:(kr_id)=>dispatch(lineActions.calculateLineCleaning(kr_id)),
    setWidget:(key)=>dispatch(monitorActions.setWidget(key))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LineCleaning);
