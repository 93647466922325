import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import {
    Card,
    CardHeader,
    Row,
    Col,
    Label,
    CardBody,
    Button
} from "reactstrap";
import AxiosLoader from "../shared/loader-new";
import DownloadModal from "./downloadModal";
var DatePicker = require("reactstrap-date-picker");

class KegDataView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            reportLinks: [],
            from_date: new Date(moment().startOf('month').format('DD/MM/YYYY')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            kegRepData: [],
            loading: false
        };
    }

    componentDidMount() {
        //if (prevProps.site_id !== this.props.site_id) {
        this.fetchKegRooms();
        //}

    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({
                    kegRooms: kegRooms,
                    selKegRoom: kegRooms[0]
                }, () => {
                    //this.getReprtData();
                });
            }
        });
    }

    getChartData=()=>{
        this.setState({showReport:true});
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/KegDataChart`,
        {params:{site_id:this.props.site_id,kr_id:this.state.selKegRoom.value,fromDate: moment(this.state.from_date).format('YYYY-MM-DD'),toDate:moment(this.state.to_date).format('YYYY-MM-DD')}})
        .then((response) => {
            const kegData=[];
            const pieChartData=[]
            response.data.forEach((item)=>{
                kegData.push({
                    name:item.beer_name,
                    avg_temp:item.avg_temp.toFixed(2),
                    size:item.size,
                    volume_poured:item.volume_poured.toFixed(2),
                    number_pours:item.number_pours,
                    percent_remaining:item.percent_remaining.toFixed(2),
                    connected_date:moment(item.connected_date).format('YYYY-MM-DD')
                });
                pieChartData.push({
                    name:item.beer_name,
                    y:parseFloat(item.volume_poured.toFixed(2)),
                    moreInfo:{
                    volume:item.size,
                    avg_temp:parseFloat(item.avg_temp.toFixed(2)),
                    }
                });
            })
            const uniqueConnectedDates = [],uniqueBeers=[],series=[];
            kegData.forEach(item => {
                if (uniqueConnectedDates.indexOf(item.connected_date) === -1) {
                    uniqueConnectedDates.push(item.connected_date)
                }
                if (uniqueBeers.indexOf(item.name) === -1) {
                    uniqueBeers.push(item.name)
                }
            });
            
            uniqueBeers.forEach((item_beer)=>{
                var _series={
                    name:item_beer,
                    data:[]
                }
               uniqueConnectedDates.forEach((item_date)=>{
                    
                    var pointInfo=kegData.find(s=>s.connected_date===item_date && s.name===item_beer);
                    if(pointInfo!==undefined){
                        _series.data.push(parseFloat(pointInfo.volume_poured));
                    }
                    else{
                        _series.data.push(0);
                    }
                    
                })
                series.push(_series);
            })
            var _chartOptions=this.initChart(uniqueConnectedDates,series);
            var _pieChartOptions=this.initPieChart(pieChartData);
            this.setState({...this.state,chartOptions:_chartOptions,showReport:false,pieChartOptions:_pieChartOptions});
        })
    }
    


    getReprtData = () => {
        this.setState({
            ...this.state,
            loading: true
        });
        const formData = {
            customer_email: this.props.userDetails.person.email,
            kr_id: this.state.selKegRoom.value,
            from_date: this.state.from_date,
            to_date: this.state.to_date
        };
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom/report`, { params: formData })
            .then((response) => {
                if (response.data) {
                    this.setState({
                        ...this.state,
                        kegRepData: response.data,
                        loading: false
                    })
                }
            });
    }

    downloadReport = () => {

        //this.setState({ showReport: true });
        this.setState({
            ...this.state,
            loading: true
        })
        const formData = {
            customer_email: this.props.userDetails.person.email,
            kr_id: this.state.selKegRoom.value,
            start_date: moment(this.state.from_date).format('YYYY-MM-DD'),
            end_date: moment(this.state.to_date).format('YYYY-MM-DD')
        };
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_BASE_URL}/api/reports/KegDataReport`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'true'
            },
            data: formData
        })
            .then((response) => {
                if (response.data.response) {
                    if(!response.data.error){
                        var urls=response.data.response;
                        urls.forEach((url)=>{
                            const link = document.createElement('a');
                            link.href = url;
                            link.target="_blank";
                            link.setAttribute(
                            'download',
                            `Report.pdf`,
                            );
                            // Append to html link element page
                            document.body.appendChild(link);
                            // Start download
                            link.click();
                            // Clean up and remove the link
                            link.parentNode.removeChild(link);
                        })
                    }
                    this.setState({
                        ...this.state,
                        loading: false,
                        //reportLinks: response.data.response
                    });
                }
            })
    }

    handleChangeSelection = (item) => {
        this.setState({ selKegRoom: item }, () => {
            // this.getReprtData();
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
            //this.getReprtData();
        });
    }
    closeDownloadModal = () => {
        this.setState({
            openModal: false
        });
    }

    openModal = () => {
        this.setState({
            openModal: true
        });

    }



    render() {
        return (
            <React.Fragment>
                 <DownloadModal links={this.state.reportLinks} onClose={this.closeDownloadModal}></DownloadModal>
                {/*<EmailModal onClose={this.closeDownloadModal}></EmailModal> */}
                <Card className="bg-secondary shadow border-0" id="/admin/customer">

                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Keg Inventory Report</h5>
                        <Row>
                            <Col md="4" sm="6" lg="4">
                                <Label>Keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selectedSite')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="6" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="6" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                            {/* <Button color='primary' onClick={this.getReprtData} >Search</Button> */}
                        </Row>

                    </CardHeader>
                    <CardBody >


                        <Row>

                            {/* <Col md="4" sm="6" lg="4">
                                <Button
                                    color={this.state.type === 'view' ? 'success' : ''}
                                    onClick={() => this.selectReportType('view')} >View report</Button>
                            </Col> */}

                            <Col md="4" sm="6" lg="4">
                                <Button disabled={this.state.loading}
                                    color={this.state.kegRepData.length > 0 ? 'primary' : 'secondary'}
                                    onClick={() => this.downloadReport()} >Download report</Button>
                            </Col>
                            {/* <Col md="4" sm="6" lg="4">
                                <Button disabled={this.state.kegRepData.length === 0}
                                    color={this.state.kegRepData.length > 0 ? 'primary' : 'secondary'}
                                    onClick={() => this.downloadReport()}>Email report</Button>
                            </Col> */}
                        </Row>

                        <Row>

                        {/* <PDFViewer
                            document={{
                                url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
                            }}
                        /> */}

                            {/* <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            Line number
                                        </th>
                                        <th>
                                            Beer
                                        </th>
                                        <th>
                                            Connected At
                                        </th>
                                        <th>
                                            Disconnected At
                                        </th>
                                        <th>
                                            Duration
                                        </th>
                                        <th>
                                            Volume
                                        </th>
                                        <th>
                                            Remaining(%)
                                        </th>
                                        <th>
                                            Avg Temprature
                                        </th>
                                        <th>
                                            Size
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.kegRepData.map((item, index) => {
                                        return (
                                            <tr key={`${item.local_line_number}${index}`}>
                                                <th scope="row">
                                                    {index + 1}
                                                </th>
                                                <td>
                                                    {item.local_line_number}
                                                </td>
                                                <td>
                                                    {item.beer_name}
                                                </td>
                                                <td>
                                                    {moment(item.connected_start_local).format('YYYY-MM-DD h:mm')}
                                                </td>
                                                <td>
                                                    {moment(item.connected_end_local).format('YYYY-MM-DD h:mm')}
                                                </td>
                                                <td>
                                                    {secondsToDaysHours(item.on_service_duration_sec)}
                                                </td>
                                                <td>
                                                    {auNumberFormat.format(item.volume_poured.toFixed(2))}
                                                </td>
                                                <td>
                                                    {parseFloat(item.percent_remaining).toFixed(2)}
                                                </td>
                                                <td>
                                                    {parseFloat(item.avg_temp).toFixed(2)}
                                                </td>
                                                <td>
                                                    {auNumberFormat.format(item.size)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>*/}
                            {this.state.loading && <AxiosLoader />} 
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails: state.userInfo.userDetails
    }
}

export default connect(mapStateToProps, null)(KegDataView);
