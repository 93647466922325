import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import moment from "moment";
//import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selMcu: {
        id:0,
        serial_number:'',
        status:'',
        last_reboot_date:'',
        connectivity:false,
        kr_name:''
    },
    mcus: [],
    currPage: 1
};


export const getMcus = (site_id,forceReload) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.mcus.mcus.length > 0 && !forceReload) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={
                site_id:site_id
            }
            if(state.kegRooms.selKegRoom.kr_id!==0){
                siteParams.kr_id=state.kegRooms.selKegRoom.kr_id
            }
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/mcu`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    let _mcus = [];
                    response.data.forEach((item) => {
                        _mcus.push({
                            id: item.mcu_id,
                            serial_number: item.mcu_serial_number,
                            status: item.mcu_status,
                            last_reboot_date: moment(item.mcu_last_reboot_date).format("YYYY-MM-DD"),
                            connectivity: item.mcu_connectivity,
                            kr_name: item.kr_name
                       })
                    });
                    _mcus = _mcus.sort((a, b) => a.serial_number.toString().localeCompare(b.serial_number.toString(), 'en', { numeric: true }));
                    dispatch(handleActionDispatch(actionTypes.SET_MCU, _mcus));
                    resolve(_mcus);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_MCU_PAGE, page));
    }
}

export const setSelMcu = (mcu) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SELECTED_MCU, mcu));
    }
}

export const clearSelKeg = () => {
    return setSelMcu(initialState.selMcu);
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}