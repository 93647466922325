import React from "react";
import "react-filter-box/lib/react-filter-box.css"
import {
    Row,
    Col,
} from "reactstrap";
import { connect } from "react-redux";
import moment from 'moment';
import * as monitorActions from '../store/dashboard-actions';
//import moment from 'moment';
import LoaderComponent from "../../shared/loader";
import {convertValueByMetric} from '../../../utility/units-conversion'
import * as kegRoomActions from '../../kegroom/store/kegroom.actions'

class ConnectedKegsWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kegs: this.props.kegs,
            filteredKegs: this.props.kegs.slice(0,6),
            connected: true,
            showKegList: this.props.showKegList,
        };
        this.eventSource=null
    }

    initConnection=()=>{
        this.eventSource = new EventSource(`https://dev-events.draftco.ca/events?site_id=${this.props.site_id}`);
        this.eventSource.onmessage = (e) => {
            if (e.type === "message") {
                
                console.log(e.data);
                var result = JSON.parse(e.data)
                if (result.length === 0) return;
                var selectedKeg = this.state.kegs.filter(s => s.keg_id === result[0].keg_id);
                selectedKeg = selectedKeg[0];
                result = result[0];
                if (selectedKeg === undefined) return;
                var logo = result.logo.includes("https://")
                    ? result.logo
                    : `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${result.logo}`;
                var updatedItem = {
                    beerName: result.short_name===null?result.beer_name.substring(0,15):result.short_name,
                    beer_name:result.beer_name,
                    connected_start_local:result.connected_date===null?"":moment(result.connected_date.substring(0, 10) + ' ' + result.connected_time).format('MM/DD/yyyy hh:mm'),
                    volume: result.size.toFixed(0),
                    percent_remaining: (result.percent_remaining * 100).toFixed(2),
                    volume_poured: result.volume_poured ? (result.volume_poured/1000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0,
                    pour_volume:result.pour_volume,
                    number_pours: result.number_pours,
                    days_to_empty:parseFloat(result.days_to_empty).toFixed(1),
                    temp_running_total: result.avg_temp.toFixed(2),
                    keg_id: result.keg_id,
                    last_pour_date:moment(result.last_pour_date).format('MM/DD/yyyy HH:mm'),
                    number_pints:(result.volume_poured/result.mlperpint).toFixed(2),
                    kr_id: result.kr_id,
                    pour_id:result.pour_id,
                    logo: logo,
                    connected: result.connected,
                    line: result.line_number,
                };

                var _kegs = [...this.state.kegs];
                var index = _kegs.indexOf(selectedKeg)
                if (index !== -1) {
                    this.props.setLastPour(updatedItem);
                    _kegs.splice(index, 1);
                    var updatedKegs=[updatedItem].concat(_kegs);
                    updatedKegs.sort((a, b) => new Date(b.last_pour_date) - new Date(a.last_pour_date));
                    var totalKegs=[...updatedKegs];
                    this.setState({ ...this.state, filteredKegs: updatedKegs.splice(0,6),kegs:totalKegs });
                    this.props.updateKegs(totalKegs);
                    var elem = document.getElementById('connected_kegs_widget');
                    for(var i=0;i<elem.childNodes.length;i++){
                        elem.childNodes[i].className =  'col-md-11 col-sm-12 bg-grey-500 monitor-default';
                    }
                    elem.childNodes[0].className = 'col-md-11 col-sm-12 bg-grey-500 monitor-active';
                    setTimeout(() => {
                        elem.childNodes[0].className =  'col-md-11 col-sm-12 bg-grey-500 monitor-default';
                    }, 30000);
                }
            }
        }

        this.eventSource.onerror = (e) => {
            console.log(e)
            this.eventSource.close();
            setTimeout(() => {
                this.initConnection()
            }, 3000);
        }
    }

    

    static getDerivedStateFromProps(props, state) {
        if (state.kegs.length === 0) {
            let kegs=props.kegs.filter(m => m.connected)
            return {
                kegs: props.kegs,
                filteredKegs: kegs.slice(0,6),
                showKegList: props.showKegList
            }
        }
        return null;
    }

    async componentDidMount() {
        await this.props.getKegRooms(this.props.site_id);
        await this.props.getKegs(this.props.kegRooms[0].kr_id);
        let from_date=moment(new Date()).add('days',-1).format('YYYY-MM-DD');
        let to_date= moment(new Date()).format('YYYY-MM-DD');
        await this.props.setHighAlarms(this.props.kegRooms[0].kr_id,from_date,to_date)
        //await this.props.getLinesForGlycol(this.props.kegRooms[0].kr_id)
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.kegs.length!==this.state.kegs.length){
          this.initConnection()
        }
      }
    
    
    componentWillUnmount() {
       if(this.eventSource){
           this.eventSource.close()
       }
    }

    render() {
        return (
            <React.Fragment>
                <Row onClick={this.props.kegsDashboard} id="connected_kegs_widget" className='row' style={{ cursor:'pointer',textAlign: 'left', marginLeft: '0','overflowX':'none','overflowY':'auto','height':'390px' }} >
                    {this.state.showKegList ? this.state.filteredKegs.map(k => (
                        <Col key={k.beer_name} className="col-md-11 col-sm-12 bg-grey-500 monitor-default" style={{ backgroundColor: '#ededed', padding: '0.5em', margin: 5 }}>
                            <div className="" style={{ fontSize: '0.8em' }}>
                            <img style={{ width: '6%' }} src={k.logo} alt={k.last_pour_date} title={k.last_pour_date}></img>&nbsp;
                                <b title={k.last_pour_date}>{k.beerName}</b>&nbsp;
                                (<label style={{fontSize:'10px'}}>Volume:{(convertValueByMetric(k.volume_poured*1000,this.props.units,"vol")/(this.props.units==="metric"?1000:1)).toFixed(1)} {this.props.units==="metric"?"L":"OU"},</label>&nbsp;
                                <label style={{fontSize:'10px'}}>#Pours:{k.number_pours},</label>&nbsp;
                                <label style={{fontSize:'10px'}}>#Pints:{parseFloat(k.number_pints).toFixed(1)},</label>&nbsp;
                                <label style={{fontSize:'10px'}}>Days to empty:{parseFloat(k.days_to_empty).toFixed(1)},</label>&nbsp;
                                <label style={{fontSize:'10px'}}>% Left:{parseFloat(k.percent_remaining).toFixed(1)}</label>)
                            </div>
                        </Col>
                    )) : <LoaderComponent />}
                </Row>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        currPage: state.monitor.currPage,
        site_id: state.userInfo.security.site_id,
        kegs: state.monitor.kegs,
        kegsHistory: state.monitor.kegsHistory,
        showKegList: state.monitor.showKegList,
        showKegHistoryList: state.monitor.showKegHistoryList,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        kegRooms:state.kegRooms.kegRooms,
        highAlarms:state.monitor.highAlarms
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrPage: (page) => dispatch(monitorActions.setCurrPage(page)),
        getLinesForGlycol: (kr_id) => dispatch(monitorActions.getLinesForGlycol(kr_id)),
        clearStates: () => dispatch(monitorActions.clearStates()),
        getKegs: (kr_id) => dispatch(monitorActions.getKegs(kr_id)),
        getKegsHistory: (site_id) => dispatch(monitorActions.getKegsHistory(site_id)),
        updateKegs:(kegs)=>dispatch(monitorActions.updateKegs(kegs)),
        setLastPour:(item)=>dispatch(monitorActions.setLastPourItem(item)),
        getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
        setHighAlarms:(kr_id,fromDate,toDate)=>dispatch(monitorActions.getHighAlarms(kr_id,fromDate,toDate)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedKegsWidget);
