import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./alarm-definition.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_AD_VIEWS):
            return setAdViews(state, action);
        case (actionTypes.SET_SEL_AD):
            return setSelAd(state, action);
        case (actionTypes.SET_AD_PROCEDURES):
            return setAdProcedures(state, action);
        case (actionTypes.SET_AD_SEVERITIES):
            return setAdSeverities(state, action);
        case (actionTypes.SET_AD_LIST):
            return setAdList(state, action);
        case (actionTypes.ADD_AD):
            return addAd(state, action);
        case (actionTypes.UPDATE_AD):
            return updateAd(state, action);
        case (actionTypes.SET_CURR_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        default:
            return state;
    }

};

const addAd = (state, action) => {
    let alarmDefinitions = [...state.alarmDefinitions];
    alarmDefinitions.push(action.data);
    return updateObject(state, {
        alarmDefinitions: alarmDefinitions
    });
};

const updateAd = (state, action) => {
    const index = state.alarmDefinitions.findIndex(b => b.alarm_id === action.data.alarm_id);
    return {
        ...state,
        alarmDefinitions: [...state.alarmDefinitions.slice(0, index), action.data, ...state.alarmDefinitions.slice(index + 1)]
    };
};


const setSelAd = (state, action) => {
    return updateObject(state, {
        selAd: action.data
    });
};

const setAdViews = (state, action) => {
    return updateObject(state, {
        views: action.data
    });
};

const setAdProcedures = (state, action) => {
    return updateObject(state, {
        procedures: action.data
    });
};

const setAdSeverities = (state, action) => {
    return updateObject(state, {
        severities: action.data
    });
};

const setAdList = (state, action) => {
    return updateObject(state, {
        alarmDefinitions: action.data,
        showList:true
    });
};


// set current page number of beers list page 
const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selAd: initialState.selAd,
        views: initialState.views,
        procedures:initialState.procedures,
        severities:initialState.severities,
        alarmDefinitions:initialState.alarmDefinitions,
        currPage: initialState.currPage
    });
};





export default reducer;