import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";

const initialState = {
    countries: [],
    beerTypes: [],
    kegCouplers: [],
    beerCategories: [],
    genders:[]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_COUNTRIES):
            return setCountries(state, action);
        case (actionTypes.SET_BEER_TYPES):
            return setBeerTypes(state, action);
        case (actionTypes.SET_KEG_COUPLERS):
            return setKegCouplers(state, action);
        case (actionTypes.SET_BEER_CATEGORIES):
            return setBeerCategories(state, action);
        case (actionTypes.SET_GENDER):
                return setGenders(state, action);
        default:
            return state;
    }

};

const setCountries = (state, action) => {
    return updateObject(state, {
        countries: action.data
    });
};

const setGenders = (state, action) => {
    return updateObject(state, {
        genders: action.data
    });
};

const setBeerTypes = (state, action) => {
    return updateObject(state, {
        beerTypes: action.data
    });
};

const setKegCouplers = (state, action) => {
    return updateObject(state, {
        kegCouplers: action.data
    });
};

const setBeerCategories = (state, action) => {
    return updateObject(state, {
        beerCategories: action.data
    });
};

export default reducer;