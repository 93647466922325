import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./mcu.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SELECTED_MCU):
            return setSelMCU(state, action);
        case (actionTypes.SET_MCU):
            return setMCU(state, action);
        case (actionTypes.SET_CURR_MCU_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        default:
            return state;
    }
};

const setSelMCU = (state, action) => {
    return updateObject(state, {
        selMcu: action.data
    });
};

const setMCU = (state, action) => {
    return updateObject(state, {
        mcus: action.data
    });
};

const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selMCU: initialState.selMCU,
        mcus: initialState.mcus,
        currPage: initialState.currPage
    });
};

export default reducer;