import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as beersActions from '../beers/store/beers.actions';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import WaterfallChart from "./waterfall";
import { ResponsiveBar } from "@nivo/bar";
import {convertValueByMetric} from '../../utility/units-conversion';
import {sortByDataType} from '../../utility/common';

var DatePicker = require("reactstrap-date-picker");

class TotalPourChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            beerData:[],
            pourDetailHistData:[],
            data:[],
            beers:[],
            selBeer:null,
            enablePourChartTab:false,
            enableBeerChartTab:false,
            chartTitle:'',
            showChart:false,
            activeTab:"2",
            errorMessage:'',
            loading:false,
            waterFallData:[]
        };
    }

    componentDidMount() {
        this.fetchKegRooms();
    }

    static getDerivedStateFromProps(props,state){
        let _beers=[];
        if(props.beers_kr.length>0){
            _beers.push({value:0,label:"All Beers"})
            props.beers_kr.forEach((item)=>{
                _beers.push({value:item.id,label:item.name});
            })
        }
        if (_beers.length !== 0) {
            return {
              ...state,
              beers: _beers,
              selBeer:state.selBeer===null?{value:0,label:"All Beers"}:state.selBeer
            }
          }
          return null;
    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]},()=>{this.props.getBeersByKegRoom(kegRooms[0].value)});
            }
        });
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    getChartData=()=>{
        const units=this.props.units;
        this.setState({loading:true,showChart:false,showHcChart:false});
        let newState=this;
        var params={
            from_date:moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD"),
            kr_id:this.state.selKegRoom.value,
            beer_name:this.state.selBeer.value!==0?this.state.selBeer.label.replace(new RegExp("'", 'g'), "''"):null
        }
        var title=`Total Pour - From ${params.from_date} To ${params.to_date}`;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/TotalPourReport`,{params:params})
        .then(function(response){
             if(response.data!==undefined && response.data.length>0){
                response.data.map(function(item){
                    var datePart=item.end_date.substring(0,10)+ " 00:00:00";
                    var d=moment(datePart);
                    //var d1=moment(item.local_end_period);
                    item["end_date"]=moment(d._d).format("YYYY-MM-DD");
                    return item
                }) 
                response.data.sort((a, b) => new Date(a.end_date) - new Date(b.end_date));
                var groupByBeer=  response.data.reduce(function (r, a) {
                    r[a.beer_name] = r[a.beer_name] || [];
                    r[a.beer_name].push(a);
                    return r;
                }, Object.create(null));
                var beerData=[],counter=0,total_avg_duration=0,total_volume=0,total_avg_volume=0,total_pints=0,total_count=0,total_avg_temp=0;
                for(var key in groupByBeer){
                    var _subData=groupByBeer[key];
                    var beer_volume = _subData.map(o => o.pour_volume).reduce((a, c) => { return a + c });
                    var beer_duration = _subData.map(o => o.duration_sec).reduce((a, c) => { return a + c });
                    var beer_ambient = _subData.map(o => o.ambient_c).reduce((a, c) => { return a + c });
                    var beer_temp = _subData.map(o => convertValueByMetric(o.temperature_c,units,"c")).reduce((a, c) => { return a + c });
                    total_avg_duration+=(beer_duration/_subData.length);
                    total_volume+=beer_volume;
                    total_avg_volume+=(beer_volume/_subData.length);
                    total_pints+=beer_volume/_subData[0].mlperpint;
                    total_count+=_subData.length;
                    total_avg_temp+=beer_temp/_subData.length;
                    beerData.push({
                        name:key,
                        duration:(beer_duration/_subData.length).toFixed(1),
                        total_volume:convertValueByMetric(beer_volume,units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        avg_volume:(convertValueByMetric(beer_volume,units,"vol")/_subData.length).toFixed(2),
                        number_pints:(beer_volume/_subData[0].mlperpint).toFixed(2),
                        count:_subData.length,
                        temp:(beer_temp/_subData.length).toFixed(2),
                        ambient:(convertValueByMetric(beer_ambient,units,"c")/_subData.length).toFixed(2),
                        pour_id:counter
                    })
                    counter++;
                }
                beerData.push({
                    name:"Summary",
                    duration:(total_avg_duration/counter).toFixed(1),
                    total_volume:convertValueByMetric(total_volume,units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    avg_volume:(convertValueByMetric(total_avg_volume,units,"vol")/counter).toFixed(2),
                    number_pints:total_pints.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    count:total_count.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    temp:(total_avg_temp/counter).toFixed(2),
                    pour_id:counter+1
                })
                
                newState.setState({
                    ...newState.state,
                    beerData:beerData,
                    data:response.data,
                    showChart:true,
                    chartTitle:title,
                    loading:false,
                    errorMessage:""
                  })
             }
             else{
                newState.setState({
                    ...newState.state,
                    showChart:true,
                    loading:false,
                    errorMessage:"No data found"
                  })
             }
              
        })
    }

    

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    pourHistChart=(data)=>{
        var histData=[];
        var maxPoint=Math.max(...data.map(s=>parseFloat(s.pour_volume)));
        var counter1=0,increment=Math.round(maxPoint/15);
        increment=increment+((this.props.units==="metric"?100:(100*0.033814))-(increment%100))
        maxPoint+=increment;
        while(counter1<maxPoint){
            // eslint-disable-next-line no-loop-func
            var hisSubData=data.filter(s=>s.pour_volume>=(counter1-increment) && s.pour_volume<=counter1);
            histData.push({id:counter1,value:hisSubData.length})
            counter1+=increment;
        }
        this.setState({
            ...this.state,
            activeTab:"3",
            pourDetailHistData:histData
        })
    }

    rowClicked=(row)=>{
        var context=this;
        var pourData=this.state.data.filter(p=>p.beer_name===row.name);
       
        var minDate=Math.min(...pourData.map(s=>moment(s.end_date)));
        var maxDate=Math.max(...pourData.map(s=>moment(s.end_date)));
        var diffDays=moment(maxDate).diff(moment(minDate),'days');
        var days_bin=parseInt(diffDays/14);
        var datesBin=[];
        while(moment(minDate)<=moment(maxDate)){
            var point={
                x:moment(minDate).format("YYYY-MM-DD"),
                fromDate:moment(minDate).format("YYYY-MM-DD"),
                toDate:moment(minDate).add(days_bin,'days')>moment(maxDate)?moment(maxDate).format("YYYY-MM-DD"):moment(minDate).add(days_bin,'days').format("YYYY-MM-DD"),
                y:0
            }
            datesBin.push(point);
            minDate=moment(minDate).add(days_bin+1,'days');
        }
        var _waterfall_data=[...[]];
        datesBin.forEach((item)=>{
            var fromDate=moment(item.fromDate);
            var toDate=moment(item.toDate);
            var subData=pourData.filter(s=>moment(s.end_date)>=fromDate && moment(s.end_date)<=toDate)
            if(subData.length!==0){
                var vol_poured=subData.map(o => parseFloat(o.pour_volume)).reduce((a, c) => { return a + c });
                item.y=parseFloat(convertValueByMetric(vol_poured,this.props.units,"vol")/(this.props.units==="metric"?1000:1));
                _waterfall_data.push(item);
            }
        })
        var totalPour_Volume=_waterfall_data.map(o => parseFloat(o.y)).reduce((a, c) => { return a + c });
        _waterfall_data.push({x:'Total Volume',y:totalPour_Volume,"showFull":true})
        var title=`${context.state.selKegRoom.label} ${row.name} Pour Volume Inventory`
        context.setState({
            ...context.state,
            activeTab:"1",
            title:title,
            waterFallData:_waterfall_data,
            showChart:true
        });
    }

    showPourDetails=(row)=>{
        const columns = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
            },
            {
                dataField: "name",
                text: "Beer",
                sort: true,
                align:'center',
                headerAlign:'center'
            },
            {
              dataField: "end_time",
              text: "Time",
              sort: true,
              align:'center',
              headerAlign:'center'
            },
            {
              dataField: "duration",
              text: "Duration (s)",
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "pour_volume",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              sort: true,
              align: 'right',
              headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                sort: true,
                align:'right',
                headerAlign:'center'
            },
            {
              dataField: "temperature",
              text: this.props.units==="metric"?"Temp (C)":"Temp (F)",
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              sort: true,
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Ambient (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];
        var pourByKeg=this.state.data.filter(p=>p.beer_name===row.name && moment(p.end_date.substring(0,10)).format("YYYY-MM-DD")===row.end_date);
        var pourData=[],totalVolume=0,totalDuration=0,totalTemp=0;
        pourByKeg.forEach((item)=>{
            pourData.push({
                name:item.beer_name,
                pour_volume:convertValueByMetric(item.pour_volume,this.props.units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                pour_id:item.id,
                keg_id:item.keg_id,
                duration:item.duration_sec,
                temperature:convertValueByMetric(item.temperature_c,this.props.units,"c").toFixed(2),
                number_pints:(item.pour_volume/item.mlperpint).toFixed(2),
                ambient:item.ambient_c===null?0:convertValueByMetric(item.ambient_c,this.props.units,"c").toFixed(2),
                end_date:moment(item.end_date).format("YYYY-MM-DD"),
                end_time:item.end_time
            })
            totalVolume+=item.pour_volume;
            totalDuration+=item.duration_sec;
            totalTemp+=convertValueByMetric(item.temperature_c,this.props.units,"c");
        })
        pourData.push({
          name:"Summary",
          pour_volume:convertValueByMetric(totalVolume,this.props.units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          pour_id:0,
          keg_id:0,
          duration:(totalDuration/pourByKeg.length).toFixed(2),
          temperature:(totalTemp/pourByKeg.length).toFixed(2),
          ambient:(pourData.map(o => parseFloat(convertValueByMetric(o.ambient,this.props.units,"c"))).reduce((a, c) => { return a + c })/pourData.length).toFixed(2),
          end_date:"",
          end_time:""
      })
        var title=`Pour Detail Report for Date: ${row.end_date}`;
        if(pourData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                            {/* <button className="btn btn-sm btn-info" onClick={() => this.rowClicked(row)}>Chart</button> */}
                            <Button onClick={()=>this.pourHistChart(pourData)} size="sm">Chart</Button>
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,title,this.props.companyLogo,this.props.userLogo,"Total-Pour-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pours"
                                filename="Total Pour"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_pours" 
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="pour_id"
                                data={pourData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    showPourDetailsByDate=(row)=>{
        const columns = [
            {
                dataField: "pour_id",
                text: "Pour Id",
                hidden: true,
            },
            {
                dataField: "name",
                text: "Beer",
                sort: true,
                headerAlign:'center'
            },
            {
              dataField: "end_date",
              text: "End Date",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "duration",
              text: "Avg Dur (s)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
                dataField: "total_volume",
                text: this.props.units==="metric"?"Total Volume (ml)":"Total Volume (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
             },
            {
              dataField: "avg_volume",
              text: this.props.units==="metric"?"Avg Volume (ml)":"Avg Volume (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
              dataField: "count",
              text: "Count",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
              align: 'right',
              headerAlign:'center'
            },
            {
              dataField: "temp",
              text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Avg Amb (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];

        const expandDateRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return this.showPourDetails(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
                this.setState({...this.state,enablePourChartTab:expanded,enableBeerChartTab:true})
              if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
            }
          };
          
        var pourByKeg=this.state.data.filter(p=>p.beer_name===row.name);
        var groupByDate=  pourByKeg.reduce(function (r, a) {
            var end_date=moment(a.end_date.substring(0,10)).format("YYYY-MM-DD")
            r[end_date] = r[end_date] || [];
            r[end_date].push(a);
            return r;
        }, Object.create(null));
        var pourData=[];
        var totalTemp=0;
        var totalAmbient=0;
        var totalDuration=0;
        var totalVolume=0;
        for(var key in groupByDate){
            var _subData=groupByDate[key];
            var beer_volume = _subData.map(o => o.pour_volume).reduce((a, c) => { return a + c });
            var beer_duration = _subData.map(o => o.duration_sec).reduce((a, c) => { return a + c });
            var beer_ambient = _subData.map(o => o.ambient_c).reduce((a, c) => { return a + c });
            var beer_temp = _subData.map(o => convertValueByMetric(o.temperature_c,this.props.units,"c")).reduce((a, c) => { return a + c });
                  
            pourData.push({
                end_date:key,
                name:row.name,
                duration:(beer_duration/_subData.length).toFixed(1),
                total_volume:convertValueByMetric(beer_volume,this.props.units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                avg_volume:(convertValueByMetric(beer_volume,this.props.units,"vol")/_subData.length).toFixed(2),
                count:_subData.length,
                temp:(beer_temp/_subData.length).toFixed(2),
                number_pints:(beer_volume/_subData[0].mlperpint).toFixed(2),
                ambient:(convertValueByMetric(beer_ambient,this.props.units,"c")/_subData.length).toFixed(2)
            })
            totalTemp+=beer_temp;
            totalAmbient+=beer_ambient;
            totalDuration+=beer_duration;
            totalVolume+=beer_volume;
        }
        if(pourData.length>0){
          pourData.push({
            end_date:"",
            name:"Summary",
            duration:(totalDuration/pourByKeg.length).toFixed(1),
            total_volume:convertValueByMetric(totalVolume,this.props.units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            avg_volume:(convertValueByMetric(totalVolume,this.props.units,"vol")/pourByKeg.length).toFixed(2),
            count:_subData.length,
            temp:(totalTemp/pourByKeg.length).toFixed(2),
            ambient:(convertValueByMetric(totalAmbient,this.props.units,"c")/pourByKeg.length).toFixed(2)
        })
        }
        if(pourData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                            <button className="btn btn-sm btn-info" onClick={() => this.rowClicked(row)}>Chart</button>
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,this.state.chartTitle,this.props.companyLogo,this.props.userLogo,"Total-Pour-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pours_ByDate"
                                filename="Total Pour"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_pours_ByDate"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="end_date"
                                data={pourData}
                                expandRow={expandDateRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    showWaterFallChart=()=>{
        if(this.state.waterFallData.length>0 && this.state.activeTab==="1"){
            return(<>
                <WaterfallChart initialValue={0} data={this.state.waterFallData} title={this.state.title} />
            </>)
        }
        return "";
    }

    expandBeerColumn=(expanded)=>{
        this.setState({...this.state,enableBeerChartTab:expanded,enablePourChartTab:expanded})
        if (expanded) {
            return (
              <i className="fa fa-caret-down fa-md"></i>
            );
          }
          return (
            <i className="fa fa-caret-right fa-md"></i>
          );
    }
  

    render() {
        const {showPourDetailsByDate}=this;
        const expandBeerRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return showPourDetailsByDate(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
              return this.expandBeerColumn(expanded)
            }
          };
        const columns = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "duration",
              text: "Avg Dur (s)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
                dataField: "total_volume",
                text: this.props.units==="metric"?"Total Volume (ml)":"Total Volume (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
             },
            {
              dataField: "avg_volume",
              text: this.props.units==="metric"?"Avg Volume (ml)":"Avg Volume (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                sort: true,
                align:'right',
                headerAlign:'center'
            },
            {
              dataField: "count",
              text: "Count",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
              align: 'right',
              headerAlign:'center'
            },
            {
              dataField: "temp",
              text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              sort: true,
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Avg Amb (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];
          
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Total Pour Chart</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Beer</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selBeer}
                                    options={this.state.beers}
                                    onChange={(e) => this.handleChangeSelection(e, 'selBeer')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                {/* <Button  size="sm" onClick={this.getChartData}>Chart</Button> */}
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Report"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Row>
                        <Col lg="12" sm="12" md="12">
                        <Nav tabs>
                        <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                            {this.state.enableBeerChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>:""
                            }
                            {this.state.enablePourChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}            >
                                Pour Detail Histogram
                                </NavLink>
                            </NavItem>:""
                            }
                           
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="2">
                        {/* <Button onClick={()=>this.printPourVolume(columns)} size="sm">Print</Button>
                        <button onClick={this.generatePdf} className="btn btn-secondary btn-sm">Download Pdf</button> */}
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.beerData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.beerData,this.state.chartTitle,this.props.companyLogo,this.props.userLogo,"Total-Pour-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_Beers"
                                filename="Total Pour"
                                sheet="Beers"
                                buttonText="Download Excel"/>
                        </div>
                            <BootstrapTable id="tbl_Beers"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="pour_id"
                                data={this.state.beerData}
                                expandRow={expandBeerRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                        <TabPane tabId="1">
                        {this.showWaterFallChart()}
                       
                        </TabPane>
                        <TabPane tabId="3">
                            <div style={{width:'100%',height:'400px'}}>
                                <ResponsiveBar 
                                        borderRadius={1}
                                        data={this.state.pourDetailHistData}
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        //padding={{left:50,right:50,top:50,bottom:50}}
                                        // valueScale={{"type":"linear"}}
                                        xScale={{
                                            type: "linear",
                                            min: 0,
                                            max: 100
                                        }}
                                        yScale={{
                                            type:"linear",
                                            max:'auto'
                                        }}
                                        colorBy="id"
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: this.props.units==="metric"?'Pour Volume (ML)':'Pour Volume (OU)',
                                            legendPosition: 'middle',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Count',
                                            legendPosition: 'middle',
                                            legendOffset: -30,
                                            tickValues:10
                                        }}
                                        enableGridX={true}
                                        enableGridY={true}
                                        gridXValues={20}

                                    />
                                </div>
                        </TabPane>
                        
                    </TabContent>
                           
                        </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        beers_kr: state.beers.beers_kr,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBeersByKegRoom: (kr_id) => dispatch(beersActions.getBeersByKegRoom(kr_id))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TotalPourChart);
