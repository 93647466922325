export const strengthColor = function (count) {
    if (count <= 25)
        return 'red';
    //if (count < 3)
    //    return 'yellow';
    if (count <= 50)
        return 'yellow';
    if (count <= 75)
        return 'pink';
    if (count <= 100)
        return 'green';
}
export const strengthIndicator = value => {
    let strengths = 0;
    if (value.length > 5)
        strengths = strengths + 25;
    //if (value.length > 7)
    //    strengths = strengths + 10;
    if (new RegExp(/[0-9]/).test(value))
        strengths = strengths + 25;
    if (new RegExp(/[!#@$%^&*)(+=._-]/).test(value))
        strengths = strengths + 25;
    if (new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value))
        strengths = strengths + 25;
    return strengths;
}