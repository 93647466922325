import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import LoaderComponent from "../shared/loader";
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import { convertValueByMetric } from "../../utility/units-conversion";
import {sortByDataType} from '../../utility/common';

class LineReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selKegRoom: null,
            kegRooms: [],
            expanded:[0],
            lineData:[],
            lineCleaningData:[],
            loading:false,
            activeTab:"2",
            title:'',
            showChart:false,
            showLineData:false,
            errorMessage:'',
            isProcessed:false,
        };
    }
    
    componentDidMount() {
        this.fetchKegRooms();
    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]});
            }
        });
    }


    getChartData=()=>{
        this.setState({showChart:false,loading:true});
        var units=this.props.units;
        let newState=this;
        var title=`Line data for ${this.state.selKegRoom.label}`
        var params={
            kr_id:this.state.selKegRoom.value
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetLineData`,{params:params})
        .then(function(response){
            if(response.data!==undefined){
                var _lineData=[];
                response.data.forEach((item)=>{
                    _lineData.push({
                       line_id:item.line_id,
                       local_line_number:item.local_line_number,
                       keg_id:item.keg_id,
                       beer_name:item.beer_name,
                       volume_poured:convertValueByMetric(item.volume_poured,units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                       line_last_clean_date:moment(item.line_last_clean_date).format("YYYY-MM-DD"),
                       pressure:item.pressure,
                       sensor_serial_number:item.sensor_serial_number
                    })
                })
                newState.setState({
                    ...newState.state,
                    lineData:_lineData.sort((a,b)=>a.line_id-b.line_id),
                    showChart:true,
                    title:title,
                    loading:false,
                });
            }

        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    onLineExpand=(row,isExpand,rowIndex,e)=>{
        if (isExpand &&this.state.lineCleaningData.filter(a => a.line_id === row.line_id).length === 0 ) {
            this.setState({...this.state,isProcessed:false});
            this.showLineCleaningDetails(row);
        }
        else if(!isExpand){
            this.setState({...this.state,showLineData:true,expanded:[],isProcessed:false});
        }
    }

    showLineCleaningDetails=(row)=>{
        var context=this;
        var units=this.props.units;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetLineCleaningDetails`,{params:{line_id:row.line_id}})
        .then(function(response){
          
            var _lineCleaningData=[],total_vol=0,total_temp=0;
            response.data.forEach((item)=>{
                _lineCleaningData.push({
                    lc_id:item.lc_id,
                    line_id:item.line_id,
                    local_line_number:item.line_number,
                    duration:item.cleaning_duration,
                    clean_date:moment(item.cleaning_end_time).format("YYYY-MM-DD"),
                    cleaning_volume:item.cleaning_volume===null?0:convertValueByMetric(item.cleaning_volume,units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    cleaning_temperature:item.cleaning_temperature===null?0:convertValueByMetric(item.cleaning_temperature,units,"c").toFixed(2),
                    beer_name:item.beer_name
                })
                total_vol+=convertValueByMetric(item.cleaning_volume,units,"vol");
                total_temp+=convertValueByMetric(item.cleaning_temperature,units,"c");
            })
            if(_lineCleaningData.length>0){
                _lineCleaningData.push({
                    lc_id:0,
                    line_id:0,
                    local_line_number:"Summary",
                    duration:0,
                    clean_date:"",
                    cleaning_volume:total_vol.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    cleaning_temperature:(total_temp/_lineCleaningData.length).toFixed(2),
                    beer_name:""
                })
            }
            context.setState({...context.state,lineCleaningData:_lineCleaningData,showLineData:true,expanded:[row.line_id],isProcessed:true});
        })
        
    }


    onLineRender=(row)=>{
        const columns = [
            {
              dataField: "lc_id",
              text: "LC Id",
              hidden: true,
              headerAlign:'center'
            },
            {
                dataField: "local_line_number",
                text: "Line #",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
                headerAlign:'center'
            },
            {
              dataField: "beer_name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "cleaning_volume",
              text: this.props.units==="metric"?"Cleaning Volume (ml)":"Cleaning Volume (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "clean_date",
              text: "Clean End Date",
              sort: true,
              align:'center',
              headerAlign:'center'
            },
            {
                dataField: "cleaning_temperature",
                text: this.props.units==="metric"?"Cleaning Temp (C)":"Cleaning Temp (F)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            }
          ];
          var title=`Line Cleaning Details ${row.beer_name}`
          if(this.state.lineCleaningData){
            if(this.state.lineCleaningData.filter(a => a.line_id === row.line_id).length !== 0 || this.state.isProcessed){
                return(
                    <>
                    <div style={{padding:'10px',float:'right'}}>
                        {/* <button className="btn btn-sm btn-info" onClick={() => this.rowClicked(row)}>Chart</button> */}
                        <Button onClick={()=>printReport(columns,this.state.lineCleaningData,"ifmcontentstoprint")} size="sm">Print</Button>
                        <button onClick={()=>DownloadPdf(columns,this.state.lineCleaningData,title,this.props.companyLogo,this.props.userLogo,"Connected-Kegs-Report.pdf")} 
                        className="btn btn-secondary btn-sm">Download Pdf</button>
                        <ReactHTMLTableToExcel
                            className="btn btn-secondary btn-sm"
                            table="tbl_LineCleaningData"
                            filename="Line Cleaning Data"
                            sheet="Pour Data"
                            buttonText="Download Excel"/>
                    </div>
                        <BootstrapTable id="tbl_LineCleaningData"
                                    classes="custom-table-css dft-bg-primary-color tbl-inner"
                                    striped
                                    hover
                                    condensed
                                    keyField="lc_id"
                                    data={this.state.lineCleaningData}
                                    //pagination={paginationFactory(pagingOptions)}
                                    columns={columns}>
                                </BootstrapTable>
                    </>
                )
            }
            else{
                return(<><LoaderComponent /></>);
            }
          }else{
            if(this.state.lineCleaningData.filter(a => a.line_id === row.line_id).length === 0){
                return(<><LoaderComponent /></>);
            }
          }
        }

    render() {
        const expandLineRow = {
            onlyOneExpanding: true,
            expanded:this.state.expanded,
            onExpand:this.onLineExpand,
            parentClassName: 'selected',
            renderer: this.onLineRender,
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              return(<div onClick={(e) => { e.stopPropagation() }}></div>);
            },
            expandColumnRenderer: ({ expanded }) => {
              if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
            }
          };
        const columns = [
            {
              dataField: "line_id",
              text: "Line Id",
              hidden: true,
              headerAlign:'center'
            },
            {
                dataField: "local_line_number",
                text: "Line #",
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
                sort: true,
                headerAlign:'center'
            },
            {
              dataField: "beer_name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "volume_poured",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "line_last_clean_date",
              text: "Last Clean Date",
              sort: true,
              align:'center',
              headerAlign:'center'
            },
            {
                dataField: "pressure",
                text: "Pressure",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "sensor_serial_number",
                text: "Sensor",
                sort: true,
                align:'left',
                headerAlign:'center'
            }
          ];
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Line Data Report</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Nav tabs>
                            
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem> */}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                       
                        </TabPane>
                        <TabPane tabId="2">
                        {/* <Button onClick={()=>this.printPourVolumeByDate(columns)} size="sm">Print</Button>
                        <button onClick={this.generatePdf} className="btn btn-secondary btn-sm">Download Pdf</button> */}
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.lineData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.lineData,this.state.title,this.props.companyLogo,this.props.userLogo,"Connected-Kegs-Report.pdf")} 
                            className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_lines"
                                filename="Line Data"
                                sheet="Kegs"
                                buttonText="Download Excel"/>
                            </div>
                            <BootstrapTable id="tbl_lines"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="line_id"
                                data={this.state.lineData}
                                expandRow={expandLineRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

export default connect(mapStateToProps, null)(LineReport);
