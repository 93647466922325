import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./sensor.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SELECTED_SENSOR):
            return setSelSensor(state, action);
        case (actionTypes.SET_SENSOR):
            return setSensors(state, action);
        case (actionTypes.SET_CURR_SENSOR_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        case (actionTypes.SET_FREE_SENSOR):
            return setFreeSensors(state,action);
        default:
            return state;
    }
};

const setFreeSensors = (state, action) => {
    return updateObject(state, {
        freeSensors: action.data
    });
};

const setSelSensor = (state, action) => {
    return updateObject(state, {
        selSensor: action.data
    });
};

const setSensors = (state, action) => {
    return updateObject(state, {
        sensors: action.data
    });
};

const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selSensor: initialState.selSensor,
        sensors: initialState.sensors,
        currPage: initialState.currPage,
        freeSensors:initialState.freeSensors
    });
};

export default reducer;