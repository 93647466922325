/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Select from "react-select";
import axios from 'axios';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import service from '../../services';
import { connect } from "react-redux";
import CustomBreadCrumb from "../shared/breadCrumb";
import * as UserInfoActions from "../../redux/userDetail/action";
import * as beersActions from '../../views/beers/store/beers.actions';
import {failureToaster} from '../../views/toaster/toaster';

class AdminNavbar extends React.Component {
  constructor() {
    super();
    this.state = {
      // userName: this.props.userDetails.person.name,
      profilePic: service.baseImgURL + "default.png",
      selectedSite: null,
      userSites: []
    }
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];
    this.warn = this.warn.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    //for (var e in this.events) {
      //window.addEventListener(this.events[e], this.resetTimeout);
    //}
    //this.setTimeout();
  }

  clearTimeout=() => {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout=() =>{
    this.warnTimeout = setTimeout(this.warn, 60 * 5 * 1000);
    this.logoutTimeout = setTimeout(this.logout, 60 * 6 * 1000);
  }

  resetTimeout=()=> {
    //this.clearTimeout();
    //this.setTimeout();
  }

  warn=()=> {
    failureToaster("You will be logged out automatically in 1 minute.");
  }

  destroy=()=> {
    this.clearTimeout();
    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }
  
  handleChangeSelection = (item, inputPropName) => {
    // const newState = Object.assign({}, this.state);
    // newState.selectedSite = item;
    // this.setState(newState);
    // localStorage.setItem('site_id', item.value);
    // localStorage.setItem("_dft_user_security_level", JSON;.stringify(item.moreInfo));
    this.props.updateUserSec(item);
    this.props.clearStates();
    this.props.setUserLogo(item.customerUrl)
    localStorage.setItem("user_site_resp_selected",JSON.stringify(item));
    //window.location = '#/admin/dashboard';
    window.location.reload();
  }

   logout = async () => {
    const res = await axios(`${process.env.REACT_APP_API_BASE_URL}/api/login/logout`);
    localStorage.clear();
    if(res.data.success){
      //this.destroy();
      this.props.history.push(`/login/auth`);
    }
  }

  render() {
    //const { userName, profilePic } = this.state
    const { profilePic } = this.state
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <div className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              <CustomBreadCrumb links={this.props.links} />
            </div>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav className="d-md-flex">
                <Select className="dft-header-drpdwn-sites" size="xs" value={this.props.userSec}
                  options={this.props.userSites}
                  onChange={(e) => this.handleChangeSelection(e, 'selectedSite')}>
                </Select>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={this.props.userDetails.user_id!==0?`${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${this.props.userDetails.person.profile}` : profilePic}
                      />{" "}
                    </span>{" "}
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.userDetails ? this.props.userDetails?.person.name : ''}{" "}
                      </span>{" "}
                    </Media>{" "}
                  </Media>{" "}
                </DropdownToggle>{" "}
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0"> Welcome! {this.props.userDetails.person.first_name} </h6>{" "}
                  </DropdownItem>{" "}
                  <DropdownItem onClick={this.logout}>
                    <i className="ni ni-user-run" />
                    <span> Logout </span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.userInfo.userDetails,
    userSites: state.userInfo.userSites,
    userSec: state.userInfo.security,
    links: state.dashboard.links
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserSec: (userSec) => dispatch(UserInfoActions.setUserSec(userSec)),
    clearStates: () => dispatch(beersActions.clearStates()),
    setUserLogo:(url)=>dispatch(UserInfoActions.setUserLogo(url))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);

