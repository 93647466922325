import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import routes from "../routes/index"

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("dft-header-bg")
  }
  componentWillUnmount() {
    document.body.classList.remove("dft-header-bg")
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  render() {
    let logoUrl = `${process.env.REACT_APP_CDN_BASE_URL}/assets/logo/Black/draftco-logo-black.png`
    return (
      <div className="main-content">
        <Container className="pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="6" className="mt-3">
              {/* <h1 className="text-white">Welcome To Draftco!</h1> */}
              <img
                alt="Draftco logo"
                className="media draftco-logo"
                src={logoUrl}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Switch>
              {this.getRoutes(routes)}
              <Redirect to="/auth/login" />
              {/* {accessToken !== undefined ? <Redirect from="*" to="/admin/*" /> :  }
               */}
              {/* {accessToken !== undefined ? <Redirect from="*" to="/admin/*" /> : <Redirect from="*" to="/auth/login" />} */}
            </Switch>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Auth
