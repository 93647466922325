import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./kegroom.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SELECTED_KEGROOM):
            return setSelKegRoom(state, action);
        case (actionTypes.SET_KEGROOMS):
            return setKegRooms(state, action);
        case (actionTypes.SET_CURR_KEGROOM_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        default:
            return state;
    }

};

const setSelKegRoom = (state, action) => {
    return updateObject(state, {
        selKegRoom: action.data
    });
};

const setKegRooms = (state, action) => {
    return updateObject(state, {
        kegRooms: action.data
    });
};

// set current page number of beers list page 
const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selKegRoom: initialState.selKegRoom,
        kegRooms: initialState.kegRooms,
        currPage: initialState.currPage
    });
};

export default reducer;