import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import { successToaster } from '../../toaster/toaster';

export const initialState = {
	selAd: {
		alarm_id:0,
        view_name:'',
        procedure_name:'',
        alarm_severity:'',
		control_limit:0
	},
	views:[],
    procedures:[],
    severities:[],
    alarmDefinitions:[],
	currPage: 1,
    showList:false
};

export const getViews = () => {
	const state = store.getState();
	return (dispatch) => {
        if (state.alarmDefinitions.views.length > 0) {
			return;
		}
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/alarm/views`).then((response) => {
			if (response.data !== undefined) {
				let _views = [];
				var counter=1;
				response.data.forEach((item) => {
					_views.push({
						value: counter,
						label: item.table_name
					});
					counter++;
				});
				_views.sort((a, b) => a.label.localeCompare(b.label));
				dispatch(handleActionDispatch(actionTypes.SET_AD_VIEWS, _views));				
			}
		});
	}
}

export const getProcedures = () => {
	const state = store.getState();
	return (dispatch) => {
        if (state.alarmDefinitions.procedures.length > 0) {
			return;
		}
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/alarm/procedures`).then((response) => {
			if (response.data !== undefined) {
				let _procedures = [];
				var counter=1;
				response.data.forEach((item) => {
					_procedures.push({
						value: counter,
						label: item.routine_name
					});
					counter++;
				});
				_procedures.sort((a, b) => a.label.localeCompare(b.label));
				dispatch(handleActionDispatch(actionTypes.SET_AD_PROCEDURES, _procedures));				
			}
		});
	}
}

export const getSeverities = () => {
	const state = store.getState();
	return (dispatch) => {
        if (state.alarmDefinitions.severities.length > 0) {
			return;
		}
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/alarm/severities`).then((response) => {
			if (response.data !== undefined) {
				let _severities = [];
				response.data.forEach((item) => {
					_severities.push({
						value: item.alarm_severity_id,
						label: item.severity
					});
				});
				_severities.sort((a, b) => a.label.localeCompare(b.label));
				dispatch(handleActionDispatch(actionTypes.SET_AD_SEVERITIES, _severities));				
			}
		});
	}
}


export const getAlarmDefinitions = () => {
	const state = store.getState();
	return (dispatch) => {
        if (state.alarmDefinitions.alarmDefinitions.length > 0) {
			return;
		}
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/alarm`).then((response) => {
			if (response.data !== undefined) {
				let _alarmDefinitions = [];
				response.data.forEach((item) => {
					_alarmDefinitions.push({
						alarm_id:item.alarm_id,
                        view_name:item.view_name,
                        procedure_name:item.procedure_name,
                        alarm_severity:item.alarm_severity,
						control_limit:item.control_limit
					});
				});
				//_alarmDefinitions.sort((a, b) => a.label.localeCompare(b.label));
				dispatch(handleActionDispatch(actionTypes.SET_AD_LIST, _alarmDefinitions));				
			}
		});
	}
}

export const addAd = (item) => {
	const formData = {
		alarm_id:item.alarm_id,
        view_name:item.view_name,
        procedure_name:item.procedure_name,
        alarm_severity:item.alarm_severity,
		control_limit:item.control_limit
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/alarm`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			}).then(response => {
                const _adItem = {
                    ...item,
                    alarm_id:response.data.alarm_id
                };
                dispatch(handleActionDispatch(actionTypes.ADD_AD, _adItem));
                successToaster("Added");
                resolve();
            });
		});

	}
}

export const updateAd = (item) => {
	const formData = {
		alarm_id:item.alarm_id,
        view_name:item.view_name,
        procedure_name:item.procedure_name,
        alarm_severity:item.alarm_severity,
		control_limit:item.control_limit
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/alarm/${item.alarm_id}`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			dispatch(handleActionDispatch(actionTypes.UPDATE_AD, item));
			successToaster("Updated");
			resolve();
		})
	}
}

export const setSelAd = (gs) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SEL_AD, gs));
    }
}

export const clearSelAd = () => {
    return setSelAd({
		alarm_id:0,
        view_name:'',
        procedure_name:'',
        alarm_severity:'',
		control_limit:0
    });
}

export const handleActionDispatch = (actionName, data) => {
	return {
		type: actionName,
		data: data
	}
}