import {AXIOS_LOADER} from './actionType'

export const UPDATE_LOADER=(data)=>{
    return (dispatch) => {
		dispatch(handleActionDispatch(AXIOS_LOADER, data));
	}   
}

export const handleActionDispatch = (actionName, data) => {
	return {
		type: actionName,
		payload: data
	}
}