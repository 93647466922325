import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./keg-sizes.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SEL_KEG_SIZE):
            return setSelKegSize(state, action);
        case (actionTypes.SET_KEG_SIZES):
            return setKegSizes(state, action);
        case (actionTypes.SET_CURR_KEG_SIZE_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        case (actionTypes.ADD_KEG_SIZE):
            return addKegSize(state, action);
        case (actionTypes.UPDATE_KEG_SIZE):
            return updateKegSize(state, action);
        default:
            return state;
    }
};

const addKegSize = (state, action) => {
    let kegSizes = [...state.kegSizes];
    kegSizes.push(action.data);
    return updateObject(state, {
        kegSizes: kegSizes
    });
};

const updateKegSize = (state, action) => {
    const index = state.kegSizes.findIndex(b => b.ks_id === action.data.ks_id);
    return {
        ...state,
        kegSizes: [...state.kegSizes.slice(0, index), action.data, ...state.kegSizes.slice(index + 1)]
    };
};


const setSelKegSize = (state, action) => {
    return updateObject(state, {
        selKegSize: action.data
    });
};

const setKegSizes = (state, action) => {
    return updateObject(state, {
        kegSizes: action.data,
        showList:true
    });
};

const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selKegSize: initialState.selKegRoom,
        kegSizes: initialState.kegRooms,
        currPage: initialState.currPage
    });
};

export default reducer;