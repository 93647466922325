import {AXIOS_LOADER} from './actionType'

const initialState={
    showLoader:false   
};

const loaderReducer=(state=initialState,action)=>{
    switch(action.type){
        case AXIOS_LOADER:
            return {
                showLoader:action.payload
            }
        default:
            return state
    }
};

export default loaderReducer;