import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import LoaderComponent from '../shared/loader';
import AdvancedFilter from "../shared/advancedFilter";
import { connect } from "react-redux";
import * as personActions from './store/person.actions';

// Update person

class PersonList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      persons: [],
      //person_id:0,
      filteredPersonList: [],
      fields:[{value:'id',label:'Id',type:'int'},{value:'first_name',label:'First Name',type:'text'},{value:'middle_name',label:'Middle Name',type:'text'},{value:'last_name',label:'Last Name',type:'text'},{value:'gender',label:'Gender',type:'text'},{value:'email',label:'Email',type:'text'},{value:'phone',label:'Phone',type:'text'}],
      showAdvancedSearch:false,
      //expanded: [],
      showList: false,
      //site_id: this.props.site_id,
      //disable_first_load: this.props.disable_first_load,
    };
    //this.refUsers = React.createRef();
  }

  componentDidMount() {
    this.props.getPersons();
  }  

  static getDerivedStateFromProps(props, state) {
    if (state.persons.length === 0) {
      var isData_only=props.data_only;
      var personId=props.userDetails.person_id;
      return {
        persons: isData_only ? props.persons.filter(s=>s.id===parseInt(personId)):props.persons,
        filteredPersonList: isData_only ? props.persons.filter(s=>s.id===parseInt(personId)):props.persons,
        showList: props.persons.length>0
      }
    }
    return null;
  }

  componentWillUnmount() {
    // clear the curr page number from redux if user routes to other page then beer detail    
    if (!this.props.history.location.pathname.includes('/addperson/')) {
      this.props.clearStates();
    }
  }

  toggleAdvancedSearch=()=>{
    let newState = {
      showAdvancedSearch: !this.state.showAdvancedSearch
    }
    if (!newState.showAdvancedSearch) {
      newState.filteredPersonList = this.state.persons;
    }
    this.setState({
      ...this.state,
      ...newState
    });
  }

  callBackFromAdvancedSearch=(data)=>{
    this.setState({
      ...this.state,
      filteredPersonList: data
    });
  }

  addPerson = () => {
    this.props.clearSelPerson();
    window.location = "#/admin/addperson/0";
  };
  

  onEdit = (person) => {
    this.props.setSelPerson(Object.assign({}, person));
    this.props.history.push(`/admin/addperson/${person.id}`);
  }

  filterPersonList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredPersonList: [...this.state.persons] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredPersons = this.state.persons.filter(
        (com) =>
          com.first_name.toLowerCase().indexOf(searchText) >= 0 ||
          com.middle_name.toLowerCase().indexOf(searchText) >= 0 ||
          com.last_name.toLowerCase().indexOf(searchText) >= 0 ||
          com.email.toLowerCase().indexOf(searchText) >= 0 ||
          com.phone.toLowerCase().indexOf(searchText) >= 0 ||
          com.gender.toLowerCase().indexOf(searchText) >= 0
      );

      this.setState({ filteredPersonList: [...filteredPersons] });
    }
  };

  setRowSelected = (elem) => {
    // regular for loop
    for (let i = 0; i < elem.parentNode.getElementsByTagName('tr').length; i++) {
      elem.parentNode.getElementsByTagName('tr')[i].className = '';
    }
    elem.className = 'selected';
  }


  updateStateForPerson=(id)=>{
    let newState=Object.assign({},this.state);
    newState.person_id=id;
    this.setState(newState);
  }

  render() {
    const options = {
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      page: this.props.currPage,
      onPageChange: (page) => {
        this.props.setCurrPage(page)
      }
    };

    const rowEvents = {
      onClick: (e, row) => { 
        this.updateStateForPerson(row.id);
        this.setRowSelected(e.currentTarget)
      }
    };

    const rowClasses = (row, rowIndex) => {
      return rowIndex === 0 ? 'selected' : '';
    };

    const columns = [
      {
        dataField:"Logo",
        text: "Logo",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <img src={row.profile} style={{ width: 85 + "px" }} alt="logo" />
            </div>
          );
        },
      },
      {
        dataField: "id",
        text: "Person Id",
        hidden: true,
      },
      {
        dataField: "first_name",
        text: "First Name",
        sort: true,
      },
      {
        dataField: "middle_name",
        text: "Middle Name",
        sort: true,
      },
      {
        dataField: "last_name",
        text: "Last Name",
        sort: true,
      },
      {
        dataField: "gender",
        text: "Gender",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "phone",
        text: "Phone",
        sort: true,
      },
      {
        dataField:"manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
               <button className="btn btn-sm btn-info" onClick={() => this.onEdit(row)}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
              {/* <button className="btn btn-sm btn-danger" onClick={() => this.deletePerson(row)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </button> */}
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterPersonList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
               <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              {
                this.props.data_only?"":
                <Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addPerson}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
              }
            </Row>
            {
              this.state.showAdvancedSearch?
              <Row >
                <Col lg="12" md="12" sm="12">
                  <AdvancedFilter data={this.state.persons} datacallback={this.callBackFromAdvancedSearch} 
                  datafields={this.state.fields}  />
                </Col>
              </Row>
              :
              ""
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="id"
                    data={this.state.filteredPersonList}
                    pagination={paginationFactory(options)}
                    columns={columns}
                    rowEvents={ rowEvents }
                    rowClasses={ rowClasses }
                  />
                ) : <LoaderComponent />}
              </Col>
            </Row>
          </CardBody>
        </Card>
        
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currPage: state.persons.currPage,
    persons: state.persons.persons,
    userDetails: state.userInfo.userDetails,
    data_only:state.userInfo.security.sec_level.data_only && !state.userInfo.security.sec_level.user_only,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPersons: (persons) => dispatch(personActions.setPersons(persons)),
    setSelPerson: (person) => dispatch(personActions.setSelPerson(person)),
    setCurrPage: (page) => dispatch(personActions.setCurrPage(page)),
    clearSelPerson: () => dispatch(personActions.clearSelPerson()),
    clearStates: () => dispatch(personActions.clearStates()),
    getPersons: () => dispatch(personActions.getPersons())
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PersonList);
