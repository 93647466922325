import { SET_USER_SEC, SET_USER_DETAIL, SET_USER_SITES, SET_COMPANY_LOGO_BASE64, SET_USER_LOGO_BASE64 } from './actionTypes'
const initialState = {
    userDetails: {
        person_id:0,
        user_id:0,
        username:'',
        superuser:false,
        person:{
            email:'',
            first_name:'',
            middle_name:'',
            last_name:'',
            person_id:0,
            phone:'',
            profile:'',
            gender:'',
        },
        user_site_resp:[]
    },
    userSites: [],
    security: {
        label:'',
        site_id:0,
        sec_level:{
            data_only:false,
            tech_only:false,
            user_only:false,
            sl_name:'',
            security_level_id:0
        }
    },
    companyLogoBase64:null,
    companyLogoUrl:'https://cdndraftco.nyc3.digitaloceanspaces.com/assets/logo/Black/draftco-logo-black.png',
    profileLogoBase64:null,
};

const userDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        // case LOGIN_USER_INFO:
        //     return {
        //         ...state,
        //         userDetails: action.payload.userDetail,
        //         security: action.payload.security
        //     }
        case SET_USER_SEC:
            return {
                ...state,
                security: action.payload
            }
        case SET_USER_DETAIL:
            return {
                ...state,
                userDetails: action.payload
            }
        case SET_USER_SITES:
            return {
                ...state,
                userSites: action.payload
            }
        case SET_COMPANY_LOGO_BASE64:
            return {
                ...state,
                companyLogoBase64:action.payload
            }
        case SET_USER_LOGO_BASE64:
            return {
                ...state,
                profileLogoBase64:action.payload
            }
        default:
            return state
    }
};

export default userDetailReducer;