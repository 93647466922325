////////////////////////////// beers //////////////////////////////////////////////
export const SET_BEERS = 'SET_BEERS';
export const SET_BEERS_KR = 'SET_BEERS_KR';
export const SET_BEER_DISTRIBUTORS = 'SET_BEER_DISTRIBUTORS';
export const UPDATE_BEERS_KR = 'UPDATE_BEERS_KR';
export const SET_SEL_BEERS = 'SET_SEL_BEERS';
export const ADD_BEER = 'ADD_BEER';
export const UPDATE_BEER = 'UPDATE_BEER';
export const SET_CURR_PAGE = 'SET_CURR_PAGE';
export const CLEAR = 'CLEAR';
////////////////////////////// enums ///////////////////////////////////////////////
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_BEER_TYPES = 'SET_BEER_TYPES';
export const SET_KEG_COUPLERS = 'SET_KEG_COUPLERS';
export const SET_BEER_CATEGORIES = 'SET_BEER_CATEGORIES';
export const SET_GENDER = 'SET_GENDER';

///////////////////////////// toaster //////////////////////////////////////////////
export const SHOW_TOASTER = 'SHOW_TOASTER';
export const HIDE_TOASTER = 'HIDE_TOASTER';
///////////////////////////////Persons////////////////////////////////////////////
export const ADD_PERSON='ADD_PERSON';
export const UPDATE_PERSON='UPDATE_PERSON';
export const SET_PERSONS='SET_PERSONS'
export const SET_SEL_PERSON='SET_SEL_PERSON'
////////////////////////////Users////////////////////////////////////////////
export const ADD_USER='ADD_USER';
export const UPDATE_USER='UPDATE_USER';
export const GET_USERS='GET_USERS';
//////////////////////////////////////////////////////////////////////////////////
export const SHOW_SITE_KEGS='SHOW_SITE_KEGS';
export const SHOW_KEG_HISTORY='UPDATE_USER';
///////////////////////////////audit trails///////////////////////////////////////////////////
export const SET_AUDIT_LOGS = 'SET_AUDIT_LOGS';
////////////////////////////Customers//////////////////////////////////////////////
export const SET_CUSTOMERS='SET_CUSTOMERS'
export const SET_SEL_CUSTOMER = 'SET_SEL_CUSTOMER';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const SET_CURR_CUSTOMER_PAGE = 'SET_CURR_CUSTOMER_PAGE';
/////////////////////////////////////Charts Action Types//////////////////////////////////////////////
export const SET_KEG_ROOMS = 'SET_KEG_ROOMS';
export const SET_LINES_GLYCOL = 'SET_LINES_GLYCOL';
export const CLEAR_LINES_GLYCOL = 'CLEAR_LINES_GLYCOL';
export const SET_PORT = 'SET_PORT';
export const KEG_BY_LINE = 'KEG_BY_LINE';
export const SET_WIDGET = 'SET_WIDGET';
export const SET_LAST_POUR_ITEM = 'SET_LAST_POUR_ITEM';
////////////////////////////////////SITES////////////////////////////////////////
export const SET_SITES = 'SET_SITES';
export const SET_SELECTED_SITE = 'SET_SELECTED_SITE';
export const CLEAR_SELECTED_SITE = 'CLEAR_SELECTED_SITE';
export const SET_CURR_SITE_PAGE = 'SET_CURR_SITE_PAGE';
export const SET_SITE_CITIES = 'SET_SITE_CITIES';
export const SET_SITE_COUNTRIES = 'SET_SITE_COUNTRIES';
////////////////////////////////////KEG ROOMS////////////////////////////////////////
export const SET_KEGROOMS = 'SET_KEGROOMS';
export const SET_SELECTED_KEGROOM = 'SET_SELECTED_KEGROOM';
export const CLEAR_SELECTED_KEGROOM = 'CLEAR_SELECTED_KEGROOM';
export const SET_CURR_KEGROOM_PAGE = 'SET_CURR_KEGROOM_PAGE';
////////////////////////////////////KEGS////////////////////////////////////////
export const SET_KEGS = 'SET_KEGS';
export const ADD_KEG = 'ADD_KEG';
export const UPDATE_KEG = 'UPDATE_KEG';
export const SET_SELECTED_KEG = 'SET_SELECTED_KEG';
export const CLEAR_SELECTED_KEG = 'CLEAR_SELECTED_KEG';
export const SET_CURR_KEG_PAGE = 'SET_CURR_KEG_PAGE';
////////////////////////////////////MCU////////////////////////////////////////
export const SET_MCU = 'SET_MCU';
export const SET_SELECTED_MCU = 'SET_SELECTED_MCU';
export const CLEAR_SELECTED_MCU = 'CLEAR_SELECTED_MCU';
export const SET_CURR_MCU_PAGE = 'SET_CURR_MCU_PAGE';
////////////////////////////////////SENSOR HUB////////////////////////////////////////
export const SET_SENSOR_HUB = 'SET_SENSOR_HUB';
export const SET_SELECTED_SENSOR_HUB = 'SET_SELECTED_SENSOR_HUB';
export const CLEAR_SELECTED_SENSOR_HUB = 'CLEAR_SELECTED_SENSOR_HUB';
export const SET_CURR_SENSOR_HUB_PAGE = 'SET_CURR_SENSOR_HUB_PAGE';
////////////////////////////////////SENSORS////////////////////////////////////////
export const SET_SENSOR = 'SET_SENSOR';
export const SET_FREE_SENSOR = 'SET__FREE_SENSOR';
export const SET_SELECTED_SENSOR = 'SET_SELECTED_SENSOR';
export const CLEAR_SELECTED_SENSOR = 'CLEAR_SELECTED_SENSOR';
export const SET_CURR_SENSOR_PAGE = 'SET_CURR_SENSOR_PAGE';
////////////////////////////////////Line////////////////////////////////////////
export const SET_LINES = 'SET_LINES';
export const SET_SELECTED_LINE = 'SET_SELECTED_LINE';
export const CLEAR_SELECTED_LINE = 'CLEAR_SELECTED_LINE';
export const SET_CURR_LINE_PAGE = 'SET_CURR_LINE_PAGE';
export const SET_LINE_CLEANING_STATUS = 'SET_LINE_CLEANING_STATUS';
////////////////////////////////////TOWERS////////////////////////////////////////
export const SET_TOWERS = 'SET_TOWERS';
export const SET_SELECTED_TOWER = 'SET_SELECTED_TOWER';
export const CLEAR_SELECTED_TOWER = 'CLEAR_SELECTED_TOWER';
export const SET_CURR_TOWER_PAGE = 'SET_CURR_TOWER_PAGE';
export const SET_AVAILABLE_LINES = 'SET_AVAILABLE_LINES';
export const SET_TOWER_BEERS = 'SET_TOWER_BEERS';
////////////////////////////////////KEG SIZES////////////////////////////////////////
export const SET_KEG_SIZES = 'SET_KEG_SIZES';
export const SET_SEL_KEG_SIZE = 'SET_SEL_KEG_SIZE';
export const ADD_KEG_SIZE = 'ADD_KEG_SIZE';
export const UPDATE_KEG_SIZE = 'UPDATE_KEG_SIZE';
export const SET_CURR_KEG_SIZE_PAGE = 'SET_CURR_KEG_SIZE_PAGE';
////////////////////////////////////GLYCOL SYSTEM////////////////////////////////////////
export const SET_GS = 'SET_GS';
export const SET_SEL_GS = 'SET_SEL_GS';
export const ADD_GS = 'ADD_GS';
export const UPDATE_GS = 'UPDATE_GS';
export const SET_CURR_GS_PAGE = 'SET_CURR_GS_PAGE';
////////////////////////////////////KR Temp Sesnor////////////////////////////////////////
export const SET_KR_TEMP_SENSOR = 'SET_KR_TEMP_SENSOR';
export const SET_SEL_KR_TEMP_SENSOR = 'SET_SEL_KR_TEMP_SENSOR';
export const ADD_KR_TEMP_SENSOR = 'ADD_KR_TEMP_SENSOR';
export const UPDATE_KR_TEMP_SENSOR = 'UPDATE_KR_TEMP_SENSOR';
export const SET_CURR_KR_TEMP_SENSOR_PAGE = 'SET_CURR_KR_TEMP_SENSOR_PAGE';
////////////////////////////////////Alarm Definitions////////////////////////////////////////
export const SET_AD_VIEWS = 'SET_AD_VIEWS';
export const SET_AD_PROCEDURES = 'SET_AD_PROCEDURES';
export const SET_AD_SEVERITIES = 'SET_AD_SEVERITIES';
export const ADD_AD = 'ADD_AD';
export const UPDATE_AD = 'UPDATE_AD';
export const SET_AD_LIST = 'SET_AD_LIST';
export const SET_SEL_AD='SET_SEL_AD'
export const SET_HIGH_ALARMS='SET_HIGH_ALARMS'
export const SET_MONTHLY_VOLUME_POURED='SET_MONTHLY_VOLUME_POURED'
export const UPDATE_CONFIG_TREE='UPDATE_CONFIG_TREE'
////////////////////////////////////Report Subscriptions////////////////////////////////////////
export const SET_REPORT_SUBSCRIPTIONS = 'SET_REPORT_SUBSCRIPTIONS';
export const SET_AVAILABLE_REPORTS = 'SET_AVAILABLE_REPORTS';
export const ADD_REPORT_SUBSCRIPTION='ADD_REPORT_SUBSCRIPTION';
export const UPDATE_REPORT_SUBSCRIPTION='UPDATE_REPORT_SUBSCRIPTION';
export const REMOVE_REPORT_SUBSCRIPTION='REMOVE_REPORT_SUBSCRIPTION';
export const EDIT_REPORT_SUBSCRIPTION='EDIT_REPORT_SUBSCRIPTION';
export const Update_PARAMS_REPORT_SUBSCRIPTION='Update_PARAMS_REPORT_SUBSCRIPTION';
export const CANCEL_EDIT_REPORT_SUBSCRIPTION='CANCEL_EDIT_REPORT_SUBSCRIPTION';
