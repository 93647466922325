import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import { successToaster } from '../../toaster/toaster';



export const initialState = {
    selCustomer: {
        customer_id: 0,
        name: "",
        legal_entity: "",
        contact_id: 0,
        sales_rep_id: 0,
        sites: [],
        image: '',
    },
    customers: [],
    currPage: 1
};

export const getCustomers = (selected_site_id) => {
    const state = store.getState();
    return (dispatch) => {
        if (state.customers.customers.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/customers?site_id=${selected_site_id}`).then((response) => {
                if (response.data !== undefined) {
                    let customersData = [];
                    
                    response.data.forEach((item) => {
                        //let contact = state.persons.persons.find(p => p.id === item.contact_id);
                        //let salesrep = state.persons.persons.find(p => p.id === item.sales_rep_id);
                        customersData.push({
                            customer_id: item.customer_id,
                            name: item.name,
                            legal_entity: item.legal_entity,
                            contact_id: item.contact_id,
                            contact: item.contact,//contact.first_name + ' ' + contact.last_name,
                            sales_rep_id: item.sales_rep_id,
                            sales_rep: item.sales_rep,//salesrep.first_name + ' ' + salesrep.last_name,
                            //sites: item.sites,
                            image: item.image.includes("https://") ? item.image : `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${item.image}`
                        });
                    });
                    customersData = customersData.sort((a, b) => a.name.localeCompare(b.name));
                    dispatch(handleActionDispatch(actionTypes.SET_CUSTOMERS, customersData));
                    resolve(customersData);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const setCustomers = (customers) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CUSTOMERS, customers));
    }
}

export const getCustomer = (customerId) => {
    const state = store.getState();
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/customers/${customerId}`).then((response) => {
                if (response.data) {
                    let customer = response.data;
                    if (response.data.contact_id != null) {
                        let contact = state.persons.persons.find(p => p.id === response.data.contact_id);
                        customer.selectedContact = { value: contact.id, label: contact.first_name + ' ' + contact.last_name };
                    } else {
                        customer.selectedContact = {};
                    }
                    if (response.data.contact_id != null) {
                        let salesrep = state.persons.persons.find(p => p.id === response.data.sales_rep_id);
                        customer.selectedSales_rep = { value: salesrep.id, label: salesrep.first_name + ' ' + salesrep.last_name };
                    } else {
                        customer.selectedSales_rep = {};
                    }
                    customer.image=response.data.image.includes("https://")
						? response.data.image
						: `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${response.data.image}`
                    dispatch(handleActionDispatch(actionTypes.SET_SEL_CUSTOMER, customer));
                    resolve(customer);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const addCustomer = (customer) => {
    return (dispatch) => {
        return new Promise(async function (resolve, reject) {
            await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_BASE_URL}/api/customers`,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'true'
                },
                data: customer
            }).then(response => {
                const customerRec = {
                    ...customer,
                    customer_id: response.customer_id
                };
                dispatch(handleActionDispatch(actionTypes.ADD_CUSTOMER, customerRec));
                successToaster("Added");
                resolve();
            });
        });

    }
}

export const updateCustomer = (customer) => {
    return (dispatch) => {
        return new Promise(async function (resolve, reject) {
            await axios({
                method: 'PUT',
                url: `${process.env.REACT_APP_API_BASE_URL}/api/customers/${customer.customer_id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'true'
                },
                data: customer
            });
            dispatch(handleActionDispatch(actionTypes.UPDATE_CUSTOMER, customer));
            successToaster("Updated");
            resolve();
        })
    }
}
export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_CUSTOMER_PAGE, page));
    }
}

export const setSelCustomer = (customer) => {
    customer.selectedContact = { value: customer.contact_id, label: customer.contact };
    customer.selectedSales_rep = { value: customer.sales_rep_id, label: customer.sales_rep};
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SEL_CUSTOMER, customer));
    }
}

export const clearSelCustomer = () => {
    return setSelCustomer(initialState.selCustomer);
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}