import React, {useState} from "react";
import styled from "styled-components";
import Select from "react-select";
import { connect } from 'react-redux';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import Uploady, {
  useItemStartListener,
  useItemFinalizeListener,
  useItemFinishListener
} from "@rpldy/uploady";
import { getMockSenderEnhancer } from "@rpldy/mock-sender";
import withPasteUpload from "@rpldy/upload-paste";
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import SitesList from '../sites/sitesList';
import * as customerActions from './store/customer.actions';
import * as personActions from '../persons/store/person.actions';
import * as dashboardActions from '../dashboard/store/dashboard-actions';
import Compressor from 'compressorjs';
const mockSenderEnhancer = getMockSenderEnhancer();

const StyledInput = styled.input`
  width: 400px;
  height: 22px;
  font-size: 20px;
  margin: 10px 0;
`;

const PasteInput = withPasteUpload(StyledInput);

const UploadStatus = (moreProps) => {
  const [status, setStatus] = useState(null);

  useItemStartListener(() => setStatus("Uploading..."));
  useItemFinalizeListener(() => setStatus("Finished!"));

  useItemFinishListener((item) => {
    let file = item.file;
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      convertSize: 100000, // will convert all images more than 100kb 
      success: (compressedFile) => {
        let reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          moreProps.callback(reader.result);
        }
      },
    });
  });
  return status;
};

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {
        customer_id: parseInt(props.match.params.id),
        name: "",
        legal_entity: "",
        selectedContact: null,
        selectedSales_rep: null,
      },
      persons: [],
      sites: [],
      saveInProgress: false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  async componentDidMount() {
    // this.fetchPersons();
    const customerId = this.props.match.params.id;
    await this.props.getPersons();
    await this.props.getCustomer(customerId);
    let personsData = [];
    this.props.persons.forEach((item) => {
      personsData.push({
        value: item.id,
        label: item.first_name + ' ' + item.last_name,
      });
    });
    this.setState({ persons: personsData, customer: this.props.selCustomer });
  }

  updatePreview = (base64Data) => {
    let newState = Object.assign({}, this.state);
    newState.customer.image = base64Data;
    this.setState(newState);
  }

  static getDerivedStateFromProps(props, state) {
    let _persons=[];
    if(props.persons.length>0){
      props.persons.forEach((item) => {
        _persons.push({value:item.id,label:item.first_name+ ' '+item.last_name});
      })
    
    }
    return {
      customer: props.selCustomer,
      persons:_persons
    }
  }



  // fetchPersons = () => {
  //   // Load the list
  //   axios(`${process.env.REACT_APP_API_BASE_URL}/api/persons`).then((response) => {
  //     if (response.data !== undefined) {
  //       let personsData = [];
  //       response.data.forEach((item) => {
  //         personsData.push({
  //           value: item.person_id,
  //           label: item.first_name + ' ' + item.last_name,
  //         });
  //       });
  //       this.setState({ persons: personsData, customer: { ...this.state.customer, selectedContact: personsData[0], selectedSales_rep: personsData[0] } }, () => {
  //         this.fetchCustomer();
  //       });
  //     }
  //   });
  // }

  // fetchCustomer = () => {
  //  
  //   if (customerId !== 0) {
  //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/customers/${customerId}`).then(response => {
  //       if (response.data) {
  //         let newState = Object.assign({}, this.state);
  //         newState.customer.name = response.data.name;
  //         newState.customer.legal_entity = response.data.legal_entity;
  //         newState.customer.contact_id = response.data.contact_id;
  //         newState.customer.sales_rep_id = response.data.sales_rep_id;
  //         if (response.data.contact_id != null) {
  //           let contact = this.state.persons.find(p => p.value === response.data.contact_id);
  //           newState.customer.selectedContact = { value: contact.value, label: contact.label };
  //         } else {
  //           newState.customer.selectedContact = {};
  //         }
  //         if (response.data.contact_id != null) {
  //           let salesrep = this.state.persons.find(p => p.value === response.data.sales_rep_id);
  //           newState.customer.selectedSales_rep = { value: salesrep.value, label: salesrep.label };
  //         } else {
  //           newState.customer.selectedSales_rep = {};
  //         }
  //         newState.sites = response.data.sites;
  //         this.setState(newState);
  //       }
  //     });
  //   }
  // }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.person[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.customer[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }


  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.customer[inputPropName] = item;
    this.setState(newState);
  }

  saveCustomer = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    this.setState({ saveInProgress: true });

    const customerInfo = this.state.customer;
    const formData = {
      customer_id: customerInfo.customer_id,
      name: customerInfo.name,
      legal_entity: customerInfo.legal_entity,
      contact_id: customerInfo.selectedContact.value,
      sales_rep_id: customerInfo.selectedSales_rep.value,
      image: customerInfo.image
    };

    if (customerInfo.customer_id === 0) {
      await this.props.addCustomer(formData);
      this.setState({ saveInProgress: false });
      this.handleCancel();
    }
    else {
      await this.props.updateCustomer(formData);
      this.setState({ saveInProgress: false });
      this.handleCancel();
    }
  }

  handleCancel = async() => {
    //await this.props.updateConfigTree();
    this.props.history.goBack();
  }

  render() {

    const profilePicStyle = {
      width: '400px',
      height: '300px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-Repeat',
      backgroundPosition: 'center',
      backgroundImage: 'url(' + this.state.customer.image + ')',
      borderRadius: '5px',
      backgroundColor: '#ebebeb'
    }

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.customer.customer_id === 0 ? "Add Customer" : "Edit Customer"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="6">
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label> Name <span style={{ color: 'red' }}>*</span></Label>
                    <Input value={this.state.customer.name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'name')} autoComplete="new-name" />
                    {this.validator.message('Name', this.state.customer.name, 'required')}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label> Legal Entity<span style={{ color: 'red' }}>*</span></Label>
                    <Input value={this.state.customer.legal_entity} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'legal_entity')} autoComplete="new-name" />
                    {this.validator.message('Legal entity', this.state.customer.legal_entity, 'required')}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label> Contact</Label>
                    <Select size="sm" value={this.state.customer.selectedContact}
                      options={this.state.persons}
                      onChange={(e) => this.handleChangeSelection(e, 'selectedContact')}></Select>
                    {this.validator.message('Select contact', this.state.customer.selectedContact, 'required|selectValidator:""')}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label> Sales Rep</Label>
                    <Select size="sm" value={this.state.customer.selectedSales_rep}
                      options={this.state.persons}
                      onChange={(e) => this.handleChangeSelection(e, 'selectedSales_rep')}></Select>
                    {this.validator.message('Select Sales rep', this.state.customer.selectedSales_rep, 'required|selectValidator:""')}
                  </Col>
                </Row>
              </Col>
              <Col md="6" sm="1" lg="6" className="mb-3 position-relative">
                <Row>
                  <Col md="12" sm="12" lg="12">
                    <br />
                    <Uploady enhancer={mockSenderEnhancer} destination={{ url: `${process.env.REACT_APP_HOST_BASE_URL}` }}>
                      {/* <PasteUploadDropZone params={{ test: "paste" }}>
                                            You can drop a file here
                                            <br />
                                            OR
                                            <br />
                                            click and paste a file to upload
                                            </PasteUploadDropZone> */}
                      <PasteInput extraProps={{ placeholder: "paste image to upload" }} />
                      <UploadStatus extraProps={{ source: this.state.bee }} callback={this.updatePreview} />
                      {/* <PreviewContainer>
                                            <UploadPreview  />
                                            </PreviewContainer> */}
                    </Uploady>
                    <div style={profilePicStyle}></div>
                  </Col>
                </Row>

              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="2" lg="2" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveCustomer} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.customer.customer_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
        {/* {this.state.customer.customer_id === 0 ? "" : (
          <Card>
            <CardBody>
              <Row>
                <h3 className="dft-primary-color">Sites</h3>
              </Row>
              <Row>
                <SitesList sites={this.state.sites} customer_id={this.state.customer.customer_id} />
              </Row>
            </CardBody>
          </Card>
        )} */}
        {this.props.selCustomer.customer_id!==0 ? (
         <SitesList  />
        ) : ""}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    persons: state.persons.persons,
    customers: state.customers.customers,
    selCustomer: state.customers.selCustomer
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getPersons: () => dispatch(personActions.getPersons()),
    getCustomers: () => dispatch(customerActions.getCustomers()),
    getCustomer: (customerId) => dispatch(customerActions.getCustomer(customerId)),
    addCustomer: (customer) => dispatch(customerActions.addCustomer(customer)),
    updateCustomer: (customer) => dispatch(customerActions.updateCustomer(customer)),
    updateConfigTree:()=>dispatch(dashboardActions.updateConfigTree())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
