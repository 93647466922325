import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import CustomPieChart from "./pie/index"
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import colorsData from '../../utility/colors.json'
import { ResponsiveBar } from "@nivo/bar";
import {convertValueByMetric} from '../../utility/units-conversion'
import {sortByDataType} from '../../utility/common';

var DatePicker = require("reactstrap-date-picker");
//const pdfGenerator=require('../../utility/pdfGenerator')

class PourVolumeChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            chartData:[],
            data:[],
            activeTab:"1",
            pourDetailHistData:[],
            enablePourChartTab:false,
            loading:false,
            title:'',
            plotData:[],
            masterData:[],
            showChart:false,
            errorMessage:'',
        };
    }

    componentDidMount() {
        this.fetchKegRooms();
    }

    
    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]});
            }
        });
    }

   
    getChartData=()=>{
        this.setState({showChart:false,loading:true});
        const units=this.props.units;
        let newState=this;
        var title=`Volume Chart from ${moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD")} to ${moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD")}`
        var params={
            from_date:moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD"),
            kr_id:this.state.selKegRoom.value
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/PourVolumeChart`,{params:params})
        .then(function(response){
            if(response.data!==undefined && response.data.length>0){
                response.data.map(function(item){
                    var datePart=item.end_date.substring(0,10)+ " 00:00:00";
                    var d=moment(datePart);
                    //var d1=moment(item.local_end_period);
                    item["end_date"]=moment(d._d).format("YYYY-MM-DD");
                    return item
                }) 
                var data=response.data.map((item,index)=>{
                    return {
                        id:index+1,
                        name:item.beer_name,
                        end_date:moment(item.end_date.substring(0,10)).format("YYYY-MM-DD"),
                        pour_volume:convertValueByMetric(item.pour_volume,units,"vol").toFixed(2)
                    }
                })
                response.data.sort((a, b) => a.beer_name.localeCompare(b.beer_name))
                var groupByBeer=  response.data.reduce(function (r, a) {
                    r[a.beer_name] = r[a.beer_name] || [];
                    r[a.beer_name].push(a);
                    return r;
                }, Object.create(null));
                var _pourData=[];
                var view_data=[],counter=1;
                let totalSum = response.data.map(o => convertValueByMetric(o.pour_volume,units,"vol")).reduce((a, c) => { return a + c });
                totalSum=parseFloat(totalSum/(units==="metric"?1000:1)).toFixed(2);
                var  othersSum=0,othersPercent=0,others=[];
                var othersColor=colorsData[0].hex;
                var colorCount=1;
                for(var key in groupByBeer){
                    var beerData=groupByBeer[key];
                    let sum = beerData.map(o => convertValueByMetric(o.pour_volume,units,"vol")).reduce((a, c) => { return a + c });
                    let duration = beerData.map(o => o.duration_sec).reduce((a, c) => { return a + c });
                    let temp = beerData.map(o => convertValueByMetric(o.temperature_c,units,"c")).reduce((a, c) => { return a + c });
                    let ambient = beerData.map(o => o.ambient_c).reduce((a, c) => { return a + c });
                    //sum=
                    view_data.push({
                        keg_id:counter,
                        name:key,
                        volume_avg:parseFloat(sum/beerData.length).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        pour_volume:(sum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        duration:(duration/beerData.length).toFixed(2),
                        temp:(temp/groupByBeer[key].length).toFixed(2),
                        number_pints:(sum/convertValueByMetric(beerData[0].mlperpint,units,"vol")).toFixed(2),
                        ambient:(ambient/groupByBeer[key].length).toFixed(2)
                    })
                    counter++;
                    sum=(sum/(units==="metric"?1000:1)).toFixed(2);
                    var percent=(parseFloat(sum*100)/parseFloat(totalSum)).toFixed(2);
                    if(percent>5){
                        var color=colorsData[colorCount].hex;
                        _pourData.push({
                            "id":key,
                            "label":key,
                            "color":color,
                            "value":sum,    
                            "percent":percent,
                            "actualPercent":percent
                        });
                        colorCount++;
                    }
                    else{
                        others.push({label:key,color:othersColor,volume:sum,percent:percent});
                        othersSum+=parseFloat(sum);
                        othersPercent+=parseFloat(percent);
                    }
                }
                _pourData.push({
                    "id":"Others",
                    "label":"Others",
                    "color":othersColor,
                    "value":othersSum.toFixed(2),
                    "percent":othersPercent.toFixed(2),
                    "actualPercent":othersPercent.toFixed(2),
                    "others":others
                })
                newState.setState({
                    ...newState.state,
                    chartData:_pourData,
                    data:view_data,
                    showChart:true,
                    plotData:data,
                    masterData:response.data,
                    title:title,
                    errorMessage:"",
                    loading:false,
                });
            }
            else{
                newState.setState({
                    ...newState.state,
                    showChart:true,
                    errorMessage:"Np data found",
                    loading:false,
                });
            }

        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    showPourDetails=(row)=>{
        const columns = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "duration",
              text: "Duration (s)",
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "pour_volume",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
            },
            {
              dataField: "end_time",
              text: "Time",
              sort: true,
              align:'center',
              headerAlign:'center'
            },
            {
              dataField: "temperature",
              text: this.props.units==="metric"?"Temp (C)":"Temp (F)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Ambient (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];
        var pourByKeg=this.state.masterData.filter(p=>p.beer_name===row.name && moment(p.end_date.substring(0,10)).format("YYYY-MM-DD")===row.end_date);
        var pourData=[],counter=1,totalVolume=0,totalDuration=0,totalTemp=0;
        pourByKeg.forEach((item)=>{
            pourData.push({
                name:item.beer_name,
                pour_volume:convertValueByMetric(item.pour_volume,this.props.units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                pour_id:counter,
                keg_id:item.keg_id,
                number_pints:(item.pour_volume/item.mlperpint).toFixed(2),
                duration:item.duration_sec,
                temperature:convertValueByMetric(item.temperature_c,this.props.units,"c").toFixed(2),
                ambient:item.ambient_c===null?0:item.ambient_c.toFixed(2),
                end_date:moment(item.end_date.substring(0,10)).format("YYYY-MM-DD"),
                end_time:item.end_time
            })
            totalVolume+=convertValueByMetric(item.pour_volume,this.props.units,"vol");
            totalDuration+=item.duration_sec;
            totalTemp+=convertValueByMetric(item.temperature_c,this.props.units,"c");
            counter++;
        })
        if(pourData.length>0){
          pourData.push({
              end_date:"",
              name:"Summary",
              //end_date:key,
              pour_volume:totalVolume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              duration:(totalDuration/pourByKeg.length).toFixed(2),
              temperature:(totalTemp/pourByKeg.length).toFixed(2),
              ambient:(pourData.map(o => parseFloat(o.ambient)).reduce((a, c) => { return a + c })/pourData.length).toFixed(2)
          });
      }
        var title=`Pour Detail Report for Date: ${row.end_date}`;

        if(pourData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>this.pourHistChart(pourData)} size="sm">Chart</Button>
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,title,this.props.companyLogo,this.props.userLogo,"Pour-Volume-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pours"
                                filename="Pour Volume"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_pours"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="pour_id"
                                data={pourData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    pourHistChart=(data)=>{
        data=data.filter(s=>s.name.toLowerCase()!=="summary");
        var histData=[];
        var maxPoint=Math.max(...data.map(s=>parseFloat(s.pour_volume)));
        var counter1=0,increment=Math.round(maxPoint/15);
        increment=increment+((this.props.units==="metric"?100:(100*0.033814))-(increment%100))
        maxPoint+=increment;
        while(counter1<maxPoint){
            // eslint-disable-next-line no-loop-func
            var hisSubData=data.filter(s=>s.pour_volume>=(counter1-increment) && s.pour_volume<=counter1);
            histData.push({id:counter1,value:hisSubData.length})
            counter1+=increment;
        }
        this.setState({
            ...this.state,
            activeTab:"3",
            pourDetailHistData:histData
        })
    }

    showPourDetailsByDate=(row)=>{
        const columns = [
            
            {
              dataField: "name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
                dataField: "end_date",
                text: "Date",
                sort: true,
                align:'center',
                headerAlign:'center'
            },
            {
              dataField: "duration",
              text: "Avg Duration (s)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "pour_volume",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            {
                dataField: "volume_avg",
                text: this.props.units==="metric"?"Avg Volume (ml)":"Avg Volume (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
            },
            {
              dataField: "temp",
              text: this.props.units==="metric"?"Temp (C)":"Temp (F)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Ambient (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];
        var pourByKeg=this.state.masterData.filter(p=>p.beer_name===row.name);
        var pourData=[];
        var groupByDate=  pourByKeg.reduce(function (r, a) {
            var end_date=moment(a.end_date.substring(0,10)).format("YYYY-MM-DD")
            r[end_date] = r[end_date] || [];
            r[end_date].push(a);
            return r;
        }, Object.create(null));
        var totalTemp=0,totalDuration=0,totalVolume=0;
        var totalAmbient=0;
        for(var key in groupByDate){
            var beerData=groupByDate[key];
            let sum = beerData.map(o =>convertValueByMetric(o.pour_volume,this.props.units,"vol")).reduce((a, c) => { return a + c });
            let duration = beerData.map(o => o.duration_sec).reduce((a, c) => { return a + c });
            let temp = beerData.map(o => convertValueByMetric(o.temperature_c,this.props.units,"c")).reduce((a, c) => { return a + c });
            let ambient = beerData.map(o => o.ambient_c).reduce((a, c) => { return a + c });
            //sum=
            pourData.push({
                end_date:key,
                name:row.name,
                volume_avg:parseFloat(sum/beerData.length).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                pour_volume:(sum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                number_pints:(sum/convertValueByMetric(beerData[0].mlperpint,this.props.units,"vol")).toFixed(2),
                duration:(duration/beerData.length).toFixed(2),
                temp:(temp/beerData.length).toFixed(2),
                ambient:(ambient/beerData.length).toFixed(2)
            })
            totalDuration+=duration;
            totalVolume+=sum;
            totalTemp+=temp;
            totalAmbient+=ambient;
        }
        if(pourData.length>0){
          pourData.push({
              end_date:"",
              name:"Summary",
              //end_date:key,
              pour_volume:totalVolume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              duration:(totalDuration/pourByKeg.length).toFixed(2),
              temp:(totalTemp/pourByKeg.length).toFixed(2),
              ambient:(totalAmbient/pourByKeg.length).toFixed(2)
          });
      }
        const expandDateRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return this.showPourDetails(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
                this.setState({...this.state,enablePourChartTab:expanded})
              if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
            }
          };
          var title=`${row.name} Pour Volume from ${moment(this.state.from_date).format("YYYY-MM-DD")} to ${moment(this.state.to_date).format("YYYY-MM-DD")}`
        if(pourData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,title,this.props.companyLogo,this.props.userLogo,"Pour-Volume-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pours_date"
                                filename="Pour Volume"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_pours_date"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="end_date"
                                data={pourData}
                                expandRow={expandDateRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    expandBeerColumn=(expanded)=>{
        this.setState({...this.state,enablePourChartTab:expanded})
        if (expanded) {
            return (
              <i className="fa fa-caret-down fa-md"></i>
            );
          }
          return (
            <i className="fa fa-caret-right fa-md"></i>
          );
    }


    render() {
        const {showPourDetailsByDate}=this;
        const expandBeerRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return showPourDetailsByDate(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
             return this.expandBeerColumn(expanded)
            }
          };

        const columns = [
            {
              dataField: "id",
              text: "Keg Id",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true,
              headerAlign:'center',
            },
            {
              dataField: "duration",
              text: "Duration (s)",
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              sort: true,
              headerAlign:'center',
            },
            {
              dataField: "pour_volume",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center',
            },
            {
                dataField: "volume_avg",
                text: this.props.units==="metric"?"Avg Volume (ml)":"Avg Volume (ou)",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center',
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center',
            },
            {
              dataField: "temp",
              text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center',
            },
            // {
            //   dataField: "ambient",
            //   text: "Avg Amb (C)",
            //   sort: true,
            //   align:'right',
            //   headerAlign:'center',
            // }
          ];
      
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Pie Chart By Brand</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                           
                        </Row>
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Nav tabs>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                            {this.state.enablePourChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}            >
                                Pour Detail Histogram
                                </NavLink>
                            </NavItem>:""
                            }
                    </Nav>
                    
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                        {
                                this.state.showChart?
                                <>
                                <CustomPieChart data={this.state.chartData} title={this.state.title} />
                                </>
                                :""
                            }
                        </TabPane>
                        <TabPane tabId="2">
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.data,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.data,this.state.title,this.props.companyLogo,this.props.userLogo,"Pour-Volume-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pour_volume_data"
                                filename="Pour Volume"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                            <BootstrapTable id="tbl_pour_volume_data"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="keg_id"
                                data={this.state.data}
                                expandRow={expandBeerRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                        <TabPane tabId="3">
                            <div style={{width:'100%',height:'400px'}}>
                                        <ResponsiveBar 
                                        borderRadius={1}
                                        data={this.state.pourDetailHistData}
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        //padding={{left:50,right:50,top:50,bottom:50}}
                                        // valueScale={{"type":"linear"}}
                                        xScale={{
                                            type: "linear",
                                            min: 0,
                                            max: 100
                                        }}
                                        yScale={{
                                            type:"linear",
                                            max:'auto'
                                        }}
                                        colorBy="id"
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Pour Volume (ML)',
                                            legendPosition: 'middle',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Count',
                                            legendPosition: 'middle',
                                            legendOffset: -30,
                                            tickValues:10
                                        }}
                                        enableGridX={true}
                                        enableGridY={true}
                                        gridXValues={20}

                                    />
                                </div>
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

export default connect(mapStateToProps, null)(PourVolumeChart);
