import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import {sortByDataType} from '../../utility/common';
import {
    Card,
    CardHeader,
    Row,
    Col,
    Label,
    CardBody,
    Button
} from "reactstrap";
import * as beersActions from '../beers/store/beers.actions';
import {convertValueByMetric} from '../../utility/units-conversion'
import * as kegRoomActions from '../kegroom/store/kegroom.actions';
import * as kegSizeActions from '../keg-sizes/store/keg-sizes.actions';

class KegVolumeUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        selKegRoom: null,
        kegRooms: [],
        beers:[],
        kegs:[],
        selBeer:null,
        kegSizes:[],
        selKegSize:null,
        isLoading:false,
        kegTypes:[{label:'Connected Kegs',value:1},{label:'Used Kegs',value:2}],
        selKegType:{label:'Connected Kegs',value:1},
        selectedKegs:[],
        isVolumeUpdated:false
    };
  }

  async componentDidMount() {
    //this.fetchKegRooms();
    await this.props.getKegRooms(this.props.site_id)
    await this.props.getBeersByKegRoom(this.props.kegRooms[0].kr_id)
    await this.props.getKegSizes();

    let _selKr=null,_selBeer=null;
    if(this.state.kegRooms.length>0){
        _selKr=this.state.kegRooms[0]
    }
    if(this.state.beers.length>0){
        _selBeer=this.state.beers[0]
    }
    this.setState({
        ...this.state,
        selKegRoom:_selKr,
        selBeer:_selBeer,
        selKegSize:this.state.kegSizes[0]
      })
  }

    static getDerivedStateFromProps(props,state){
        let _beers=[],_kr_rooms=[],_sizes=[];
        if(props.beers_kr.length>0){
            props.beers_kr.forEach((item)=>{
                _beers.push({value:item.id,label:item.name});
            })
        }
        if(props.kegRooms.length>0){
            _kr_rooms =props.kegRooms.map((item)=>{
              return {
                label:item.kr_name,
                value:item.kr_id
              }
            })
        }
        if(props.kegSizes.length>0){
            _sizes =props.kegSizes.map((item)=>{
              var ouValue=convertValueByMetric(item.capacity_l*1000,props.units,"vol");
              return {
                label:`${item.nickname} - (${props.units==="metric" ? parseFloat(item.capacity_l)*1000:ouValue.toFixed(2)} ${props.units==="metric" ?"L":"OU" })`,
                value: parseFloat(item.capacity_l)*1000
              }
            })
          }
        return {
            ...state,
            beers: _beers,
            kegRooms:_kr_rooms,
            kegSizes:_sizes
        }
    }

    handleChangeSelection = (item,inputPropName) => {
        if(inputPropName!=='selKegSize'){
            this.setState({ [inputPropName]: item,kegs:[],selectedKegs:[] }, () => {
            });
        }
        else{
            this.setState({ [inputPropName]: item }, () => {
            });
        }
        
    }

    getUsedKegs=()=>{
        let newState=this;
        var units=this.props.units;
        this.setState({...this.state,isLoading:true});
        var params={
            kr_id:this.state.selKegRoom.value,
            beer_name:this.state.selBeer.label
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/KegsForMerge`,{params:params})
        .then(function(response){
              console.log(response)
              var _kegs=[];
              response.data.forEach((item)=>{
                _kegs.push({
                    name:item.beer_name,
                    keg_id:item.keg_id,
                    line:item.local_line_number,
                    connected_end:moment(item.connected_end_local.substring(0,10)).format("YYYY-MM-DD"),
                    connected_start:moment(item.connected_start_local.substring(0,10)).format("YYYY-MM-DD"),
                    volume_poured:(convertValueByMetric(item.volume_poured,units,"vol")/(units==="metric"?1000:1)).toFixed(2),
                    //volume_avg:(convertValueByMetric(item.volume_poured,units,"vol")/((units==="metric"?1000:1)*response.data.length)).toFixed(2),
                    avg_percent_left:((item.percent_remaining*100)).toFixed(2),
                    avg_temp:(convertValueByMetric(item.avg_temp,units,"c")).toFixed(2),
                    size:(convertValueByMetric(item.size,units,"vol")/((units==="metric"?1000:1))).toFixed(2),
                    number_pints:(convertValueByMetric(item.volume_poured,units,"vol")/convertValueByMetric(item.mlperpint,units,"vol")).toFixed(2),
                    duration:parseFloat((item.on_service_duration_days)).toFixed(2),
                })
            })
            newState.setState({
                ...newState.state,
                isLoading:false,
                kegs:_kegs,
                selectedKegs:[],
            });
        })
    }

    getConnectedKegs=()=>{
        let newState=this;
        var units=this.props.units;
        this.setState({...this.state,isLoading:true});
        var params={
            kr_id:this.state.selKegRoom.value,
            beer_name:this.state.selBeer.label.replace(new RegExp("'", 'g'), "''")
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/ConnectedKegsByBeer`,{params:params})
        .then(function(response){
              console.log(response)
              var _kegs=[];
              response.data.forEach((item)=>{
                _kegs.push({
                    days_to_empty:item.days_to_empty.toFixed(2),
                    keg_id:item.keg_id,
                    mlperpint:item.mlperpint,
                    name:item.beer_name,
                    line_number:item.line_number,
                    size:(convertValueByMetric((item.size),units,"vol")/(units==="metric"?1000:1)).toFixed(0),
                    percent_remaining:(item.percent_remaining*100).toFixed(2),
                    volume_poured:(convertValueByMetric((item.volume_poured),units,"vol")/(units==="metric"?1000:1)).toFixed(2),
                    volume_avg:item.volume_poured===0?0:(convertValueByMetric(((item.volume_poured/item.number_pours)),units,"vol")/(units==="metric"?1000:1)).toFixed(2),
                    connected_date:item.connected_date==null?"":moment(item.connected_date.substring(0,10) + " 00:00:00").format("YYYY-MM-DD"),
                    avg_temp:convertValueByMetric(item.avg_temp,units,"c").toFixed(2),
                    number_pints:(convertValueByMetric(item.volume_poured,units,"vol")/convertValueByMetric(item.mlperpint,units,"vol")).toFixed(2),
                    number_pours:item.number_pours
                })
            })
            newState.setState({
                ...newState.state,
                isLoading:false,
                kegs:_kegs,
                selectedKegs:[],
            });
        })
    }

    updateKegVolume=()=>{
        let newState=this;
        const units=this.props.units;
        this.setState({...this.state,isVolumeUpdated:true});
        var params={
            keg_id:this.state.selectedKegs[0],
            keg_volume:this.state.selKegSize.value
        }
        var api=this.state.selKegType.value===1?"ChangeCurrentKegSize":"ChangeUsedKegSize";
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/${api}`,{params:params})
        .then(function(response){
             alert(response.data.message)
             if(response.data.message==='Keg size updated'){
                const index = newState.state.kegs.findIndex(b => b.keg_id === params.keg_id);
                var size=(convertValueByMetric((params.keg_volume),units,"vol")/(units==="metric"?1000:1)).toFixed(2);
                let _keg = Object.assign({}, newState.state.kegs[index],{"size": size});
                newState.setState({
                    ...newState.state,
                    isVolumeUpdated:false,
                    selectedKegs:[],
                    kegs:[
                        ...newState.state.kegs.splice(0,index),
                        _keg,
                        ...newState.state.kegs.splice(index+1)
                    ]
                })
             }
        })
    }

    getKegs=()=>{
        if(this.state.selKegType.value===1){
            this.getConnectedKegs()
        }
        else{
            this.getUsedKegs()
        }
    }

    onRowSelect=(isSelected,kegId)=>{
        this.setState({
            ...this.state,
            selectedKegs:[kegId],
        })
    }



  render() {
    const columns_connected_kegs = [
        {
          dataField: "keg_id",
          text: "Keg Id",
          hidden: true,
          headerAlign:'center'
        },
        {
          dataField: "line_number",
          text: "Line #",
          sort: true,
          sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
          headerAlign:'center'
        },
        {
          dataField: "name",
          text: "Beer",
          sort: true,
          headerAlign:'center'
        },
        {
          dataField: "days_to_empty",
          text: "Days to empty",
          sort: true,
          align:'right',
          sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
          headerAlign:'center'
        },
        {
            dataField: "percent_remaining",
            text: "% Left",
            sort: true,
            align:'right',
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
            headerAlign:'center'
        },
        {
            dataField: "volume_poured",
            text: this.props.units==="metric"?"Volume Poured (L)":"Volume Poured (OU)",
            sort: true,
            //formatter:(cell)=>{convertValueByMetric(cell,this.props.units,"vol")},
            align:'right',
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
            headerAlign:'center'
        },
        {
            dataField: "volume_avg",
            text: this.props.units==="metric"?"Avg Volume (L)":"Avg Volume (OU)",
            sort: true,
            align:'right',
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
            headerAlign:'center'
        },
        {
            dataField: "number_pints",
            text: "#pints",
            sort: true,
            align:'right',
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
            headerAlign:'center'
        },
        {
            dataField: "connected_date",
            text: "Connected Date",
            sort: true,
            align:'center',
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'date')},
            headerAlign:'center'
        },
        {
            dataField: "avg_temp",
            text: this.props.units==="metric"?"Avg Temp  (C)":"Avg Temp  (F)",
            sort: true,
            align:'right',
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
            headerAlign:'center'
        },
        {
            dataField: "number_pours",
            text: "No.of Pours",
            sort: true,
            align:'right',
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
            headerAlign:'center'
        },
        {
            dataField: "size",
            text: this.props.units==="metric"?"Size (L)":"Size (OU)",
            sort: true,
            align:'right',
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
            headerAlign:'center'
          },
      ];

    const columns_used_kegs = [
        {
          dataField: "keg_id",
          text: "Keg Id",
          hidden: true,
          headerAlign:'center'
        },
        {
          dataField: "line",
          text: "Line #",
          sort: true,
          headerAlign:'center'
        },
        {
            dataField: "connected_start",
            text: "Connected Start",
            sort: true,
            headerAlign:'center'
        },
        {
            dataField: "connected_end",
            text: "Connected End",
            sort: true,
            headerAlign:'center'
        },
        {
          dataField: "volume_poured",
          text: this.props.units==="metric"?"Volume Poured (l)":"Volume Poured (ou)",
          sort: true,
          align:'right',
          headerAlign:'center'
        },
        {
            dataField: "avg_percent_left",
            text: "% Left",
            sort: true,
            align:'right',
            headerAlign:'center'
        },
        {
            dataField: "avg_temp",
            text: this.props.units==="metric"?"Temp (C)":"Temp (F)",
            sort: true,
            align:'right',
            headerAlign:'center'
        },
        {
            dataField: "size",
            text: this.props.units==="metric"?"Size (L)":"Size (ou)",
            sort: true,
            align:'right',
            headerAlign:'center'
        },
        {
            dataField: "number_pints",
            text: "#pints",
            sort: true,
            align:'right',
            headerAlign:'center'
        },
        {
            dataField: "duration",
            text: "Avg Duration (days)",
            sort: true,
            align:'right',
            headerAlign:'center'
        }
      ];
      const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        hideSelectAll: true,
        selected:this.state.selectedKegs,
        onSelect: (row, isSelect, rowIndex, e) => {
           this.onRowSelect(isSelect,row.keg_id);
        },
        nonSelectable:this.state.disabledRows
      };

    return (
      <React.Fragment>
            <Card className="bg-secondary shadow border-0" id="/admin/customer">
                <CardHeader className="bg-transparent py-3 border-0">
                    <h5>Change Keg Size</h5>
                    <Row>
                        <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                        </Col>
                        <Col md="3" sm="3" lg="3">
                                <Label>Beers</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selBeer}
                                    options={this.state.beers}
                                    onChange={(e) => this.handleChangeSelection(e, 'selBeer')}>
                                </Select>
                            </Col>
                        <Col md="3" sm="3" lg="3">
                            <Label>Select Keg Type</Label>
                             <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegType}
                                options={this.state.kegTypes}
                                onChange={(e) => this.handleChangeSelection(e, 'selKegType')}>
                            </Select>
                        </Col>
                    </Row>
                        <br /><br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button className="btn btn-sm btn-secondary" onClick={this.getKegs} disabled={this.state.isLoading ? 'disabled' : ''}>{this.state.isLoading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                        {
                            this.state.selectedKegs.length===0?"":
                            <>
                                <Row>
                                    <Col md="3" sm="3" lg="3">
                                        <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegSize}
                                                options={this.state.kegSizes}
                                                onChange={(e) => this.handleChangeSelection(e, 'selKegSize')}>
                                        </Select>
                                    </Col>
                                    <Col md="3" sm="3" lg="3">
                                        <Button className="btn btn-primary btn-sm" onClick={this.updateKegVolume} disabled={!this.state.selectedKegs.length===0 ? 'disabled' : ''}>{this.state.isVolumeUpdated?"Please wait...":"Update Volume"}</Button>&nbsp;&nbsp;
                                    </Col>
                                </Row>
                            </>
                        }
                        <br/>
                        {
                            this.state.selKegType.value===1?
                            <BootstrapTable id="tbl_connected_Kegs"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="keg_id"
                                data={this.state.kegs}
                                selectRow={selectRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns_connected_kegs}>
                            </BootstrapTable>:
                            <BootstrapTable id="tbl_used_kegs"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="keg_id"
                                data={this.state.kegs}
                                selectRow={selectRow}
                                columns={columns_used_kegs}>
                            </BootstrapTable>
                        }
                    </CardBody>
            </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        beers_kr: state.beers.beers_kr,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        kegRooms: state.kegRooms.kegRooms,
        kegSizes:state.kegSizes.kegSizes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
        getBeersByKegRoom: (kr_id) => dispatch(beersActions.getBeersByKegRoom(kr_id)),
        getKegSizes:()=>dispatch(kegSizeActions.getKegSizes()),
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KegVolumeUpdate);
