import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as beersActions from '../beers/store/beers.actions';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import {sortByDataType} from '../../utility/common';

import {convertValueByMetric} from '../../utility/units-conversion'

var DatePicker = require("reactstrap-date-picker");

class PourHourlyDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),//new Date(moment().startOf('month').format('DD/MM/YYYY')).toISOString(),
            to_date: moment(new Date()).endOf('month').toISOString(),
            selKegRoom: null,
            kegRooms: [],
            selBeer:null,
            masterData:[],
            beerData:[],
            errorMessage:'',
            activeTab:"1",
            loading:false,
            lineChart:{
                series:[],
                options:{}
            }
        };
    }

    componentDidMount() {
        this.fetchKegRooms();
    }

    static getDerivedStateFromProps(props,state){
        let _beers=[];
        if(props.beers_kr.length>0){
            _beers.push({value:0,label:"All Beers"})
            props.beers_kr.forEach((item)=>{
                _beers.push({value:item.id,label:item.name});
            })
        }
        if (_beers.length !== 0) {
            return {
              ...state,
              beers: _beers,
              selBeer:state.selBeer===null?{value:0,label:"All Beers"}:state.selBeer
            }
          }
          return null;
    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]},()=>{this.props.getBeersByKegRoom(kegRooms[0].value)});
            }
        });
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    getChartData=()=>{
        const units=this.props.units;
        this.setState({loading:true});
        let newState=this;
        var params={
            from_date:moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD"),
            kr_id:this.state.selKegRoom.value,
            beer_name:this.state.selBeer.value!==0?this.state.selBeer.label.replace(new RegExp("'", 'g'), "''"):null
        }
      
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetHourlyDistribution`,{params:params})
        .then(function(response){
            if(response.data!==undefined){
                var dt_h=moment(new Date()).format("YYYY-MM-DD");
                var _masterData=response.data.map((item)=>{
                    return {
                        name:item.beer_name,
                        pour_id:item.pour_id,
                        volume_poured:item.volume_poured,
                        end_date:moment(item.end_date).format("MM/DD/yyyy"),
                        hours:moment(dt_h+" "+item.hours+":00").format("LT")
                    }
                });
                var groupByBeer=  response.data.reduce(function (r, a) {
                    r[a.hours] = r[a.hours] || [];
                    r[a.hours].push(a);
                    return r;
                }, Object.create(null));
                var total_volume=0,counter=1;
                var _beerData=[];
                
                for(var key in groupByBeer){
                    var beer_volume = groupByBeer[key].map(o => convertValueByMetric(o.volume_poured,units,"vol")).reduce((a, c) => { return a + c });
                    _beerData.push({
                        pour_id:counter,
                        hours:moment(dt_h+" "+key+":00").format("LT"),
                        volume_poured:beer_volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    })
                    total_volume+=beer_volume;
                    counter++;
                }
                _beerData.push({
                    pour_id:counter++,
                    hours:"Summary",
                    volume_poured:total_volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                })
            }
            var volumeLine={name:"Volume",data:[]};
            var xaxis=[];
            var lineData=[];
            var groupByHour=  response.data.reduce(function (r, a) {
                r[a.hours] = r[a.hours] || [];
                r[a.hours].push(a);
                return r;
            }, Object.create(null));
            for(var h in groupByHour){
                var groupByBeer_h=  groupByHour[h].reduce(function (r, a) {
                    r[a.beer_name] = r[a.beer_name] || [];
                    r[a.beer_name].push(a);
                    return r;
                }, Object.create(null));

                var _moreInfo=[];
                for(var beer in groupByBeer_h){
                    var beer_volume_h = groupByBeer_h[beer].map(o => convertValueByMetric(o.volume_poured,units,"vol")).reduce((a, c) => { return a + c });
                    _moreInfo.push({
                        beer_name:beer,
                        volume_poured:beer_volume_h.toFixed(2),
                    })
                }
                var hour_volume = groupByHour[h].map(o => convertValueByMetric(o.volume_poured,units,"vol")).reduce((a, c) => { return a + c });
                var dt=moment(new Date()).format("YYYY-MM-DD");
                var dt1=moment(dt+" "+h+":00")
                xaxis.push(dt1._d);
                lineData.push({x:dt1._d,y:hour_volume.toFixed(2),moreInfo:_moreInfo})
            }
          
            for(var i=0;i<24;i++){
                // eslint-disable-next-line no-loop-func
                var item=lineData.filter(s=>moment(s.x).hour()===i);
                if(item.length>0){
                    volumeLine.data.push({x:item[0].x,y:item[0].y,moreInfo:item[0].moreInfo})
                }
                else{
                    var _dt=moment(new Date()).format("YYYY-MM-DD");
                    var _dt1=moment(_dt+" "+i+":00")
                    volumeLine.data.push({x:_dt1._d,y:0,moreInfo:[]})
                }
            }
            var chartData={
                series:[volumeLine],
                options: {
                    chart: {
                      height: 400,
                      type: 'line',
                      zoom: {
                        enabled: true
                      }
                    },
                    tooltip:{
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                            var d = data.moreInfo.slice(0,10).sort((a, b) => parseFloat(b.volume_poured) - parseFloat(a.volume_poured));
                            var beer_info=d.length!==0?d.map((item)=>{
                                return `<div class="apexcharts-tooltip-y-group">
                                            <span class="apexcharts-tooltip-text-y-label">${item.beer_name}: </span>
                                            <span class="apexcharts-tooltip-text-y-value">${item.volume_poured.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                        </div>`
                            }):[`<div class="apexcharts-tooltip-y-group">
                            <span class="apexcharts-tooltip-text-y-label">Volume : </span>
                            <span class="apexcharts-tooltip-text-y-value">${data.y}</span>
                        </div>`]
                            return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${moment(data.x).format("LT")} (${data.y===0?"0":data.y.replace(/\B(?=(\d{3})+(?!\d))/g, ',')})</div>
                            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            ${beer_info.join(" ")}                                
                           </div></div>`
                        }
                    },
                    dataLabels: {
                      enabled: false
                    },
                    stroke: {
                      curve: 'straight',
                      width: [1],
                      ///dashArray: [0, 0, 0,2,2]
                    },
                    title: {
                      text: `Pour Hourly Distribution`,
                      align: 'left'
                    },
                    grid: {
                      row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                      },
                    },
                    xaxis: {
                      categories: xaxis,
                      type: "datetime",
                      tickAmount:12,
                      labels: {
                        datetimeUTC: false,
                        rotateAlways: false,
                        rotate: 0,
                        //tickPlacement: 'on',
                        formatter: function(val, timestamp) {
                            return moment(val).format("LT");; // formats to hours:minutes
                        }  
                      }
                    },
                    yaxis: [
                        {
                          seriesName: 'Volume',
                          axisTicks: {
                            show: true
                          },
                          axisBorder: {
                            show: true,
                            color: "#FF1654"
                          },
                          labels: {
                            style: {
                              colors: "#FF1654"
                            },
                            formatter: function (value) {
                                return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            }
                          },
                          title: {
                            text: units==="metric"?"Volume (ml)":"Volume (ou)",
                            style: {
                              color: "#FF1654"
                            }
                          }
                        }
                      ]
                  }
            }
            newState.setState({
                ...newState.state,
                masterData:_masterData,
                lineChart:chartData,
                beerData:_beerData,
                loading:false
            });
        })
    }

    

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   
  
    expandBeerColumn=(expanded)=>{
        if (expanded) {
            return (
              <i className="fa fa-caret-down fa-md"></i>
            );
          }
          return (
            <i className="fa fa-caret-right fa-md"></i>
          );
    }
    
    showPourDetailsByHours=(row)=>{
        const columns = [
            {
                dataField: "key",
                text: "Pour Id",
                hidden: true,
            },
            {
              dataField: "end_date",
              text: "End Date",
              sort: true,
              headerAlign:'center'
            },
            {
                dataField: "volume_poured",
                text: this.props.units==="metric"?"Total Volume (ml)":"Total Volume (ou)",
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                sort: true,
                align:'right',
                headerAlign:'center'
             },
          ];

        const expandDateRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return this.showPourDetails(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
              if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
            }
          };
        var pourByBeer=this.state.masterData.filter(p=>p.hours===row.hours);
        var groupByDate=  pourByBeer.reduce(function (r, a) {
            var end_date=moment(a.end_date).format("YYYY-MM-DD")
            r[end_date] = r[end_date] || [];
            r[end_date].push(a);
            return r;
        }, Object.create(null));
        var pourData=[];
        var totalVolume=0;
        for(var key in groupByDate){
            var _subData=groupByDate[key];
            var beer_volume = _subData.map(o => convertValueByMetric(o.volume_poured,this.props.units,"vol")).reduce((a, c) => { return a + c });
            pourData.push({
                key:row.hours,
                end_date:key,
                volume_poured:beer_volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            })
            totalVolume+=beer_volume;
        }
        if(pourData.length>0){
          pourData.push({
            key:"Summary",
            end_date:"Summary",
            volume_poured:totalVolume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        })
        }
        if(pourData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,`Hourly Pour Distribution for ${row.name}`,this.props.companyLogo,this.props.userLogo,"Hourly-Pour-Distribution.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pours_ByDate"
                                filename="Total Pour"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_pours_ByDate"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="end_date"
                                data={pourData}
                                expandRow={expandDateRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    showPourDetails=(row)=>{
        const columns = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
            },
            {
                dataField: "name",
                text: "Beer name",
                sort: true,
                align:'center',
                headerAlign:'center'
            },
            {
              dataField: "volume_poured",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            }
          ];
        var pourByBeer=this.state.masterData.filter(p=>p.hours===row.key && moment(p.end_date).format("YYYY-MM-DD")===row.end_date);
        var pourData=[],totalVolume=0,counter=0;
        var groupByBeer=  pourByBeer.reduce(function (r, a) {
            r[a.name] = r[a.name] || [];
            r[a.name].push(a);
            return r;
        }, Object.create(null));

        for(var key in groupByBeer){
            var vol_poured=groupByBeer[key].map(o => convertValueByMetric(o.volume_poured,this.props.units,"vol")).reduce((a, c) => { return a + c });
            pourData.push({
                pour_id:counter,
                name:key,
                volume_poured:vol_poured.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            })
            counter++;
            totalVolume+=vol_poured;
        }

        pourData.push({
            pour_id:counter++,
            hours:"Summary",
            volume_poured:totalVolume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
         })
        var title=`Pour Distribution for Date: ${row.end_date}`;
        if(pourData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                            {/* <button className="btn btn-sm btn-info" onClick={() => this.rowClicked(row)}>Chart</button> */}
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,title,this.props.companyLogo,this.props.userLogo,"Total-Pour-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pours"
                                filename="Total Pour"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_pours" 
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="pour_id"
                                data={pourData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }


    render() {

        const expandBeerRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return this.showPourDetailsByHours(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
              return this.expandBeerColumn(expanded)
            }
          };

        const columns = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
            },
            {
              dataField: "hours",
              text: "Time",
              sort: true,
              headerAlign:'center'
            },
            {
                dataField: "volume_poured",
                text: this.props.units==="metric"?"Total Volume (ml)":"Total Volume (ou)",
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                sort: true,
                align:'right',
                headerAlign:'center'
             },
          ];
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Time Of Day Distribution</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Beer</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selBeer}
                                    options={this.state.beers}
                                    onChange={(e) => this.handleChangeSelection(e, 'selBeer')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                {/* <Button  size="sm" onClick={this.getChartData}>Chart</Button> */}
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Report"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >               
                        <Row>
                            <Col lg="12" sm="12" md="12">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Chart
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        View Data
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                            <br /><br /><br />
                                <div>
                                    <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} height="400" />
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                {/* <Button onClick={()=>this.printPourVolume(columns)} size="sm">Print</Button>
                                <button onClick={this.generatePdf} className="btn btn-secondary btn-sm">Download Pdf</button> */}
                                <div style={{padding:'10px',float:'right'}}>
                                    <Button onClick={()=>printReport(columns,this.state.beerData,"ifmcontentstoprint")} size="sm">Print</Button>
                                    <button onClick={()=>DownloadPdf(columns,this.state.beerData,"Pour Hourly Distribution",this.props.companyLogo,this.props.userLogo,"Hourly-Pour-Distribution.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                                    <ReactHTMLTableToExcel
                                        className="btn btn-secondary btn-sm"
                                        table="tbl_Beers"
                                        filename="Total Pour"
                                        sheet="Beers"
                                        buttonText="Download Excel"/>
                                </div>
                                    <BootstrapTable id="tbl_Beers"
                                        classes="custom-table-css dft-bg-primary-color "
                                        striped
                                        hover
                                        condensed
                                        keyField="pour_id"
                                        data={this.state.beerData}
                                        expandRow={expandBeerRow}
                                        //pagination={paginationFactory(pagingOptions)}
                                        columns={columns}>
                                    </BootstrapTable>
                                </TabPane>
                            </TabContent>
                                
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        beers_kr: state.beers.beers_kr,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBeersByKegRoom: (kr_id) => dispatch(beersActions.getBeersByKegRoom(kr_id))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PourHourlyDistribution);
