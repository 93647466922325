import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selKegSize: {
        ks_id:0,
        size_name:"",
        nickname: "",
        capacity_oz: 0,
        capacity_gal: 0,
        capacity_l: 0,
        height_in: 0,
        height_cm: 0,
        width_in: 0,
        width_cm: 0,
        weight_kg: 0,
        weight_lbs: 0
    },
    kegSizes: [],
    currPage: 1,
    showList:false
};

export const getKegSizes = () => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.kegSizes.kegSizes.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegsizes`,).then((response) => {
                if (response.data !== undefined) {
                    let _kegSizes = [];
                    response.data.forEach((item) => {
                        _kegSizes.push({
                            ks_id:item.ks_id,
                            size_name:item.size_name,
                            nickname: item.nickname,
                            capacity_oz: item.capacity_oz.toFixed(2),
                            capacity_gal: item.capacity_gal.toFixed(2),
                            capacity_l: item.capacity_l.toFixed(2),
                            height_in:item.height_in.toFixed(2),
                            height_cm: item.height_cm.toFixed(2),
                            width_in: item.width_in.toFixed(2),
                            width_cm: item.width_cm.toFixed(2),
                            weight_kg: item.weight_kg.toFixed(2),
                            weight_lbs: item.weight_lbs.toFixed(2)
                       })
                    });
                    _kegSizes = _kegSizes.sort((a, b) => a.size_name.localeCompare(b.size_name));
                    dispatch(handleActionDispatch(actionTypes.SET_KEG_SIZES, _kegSizes));
                    resolve(_kegSizes);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const addKegSize = (item) => {

	const formData = {
		size_name:item.size_name,
        nickname: item.nickname,
        capacity_oz: item.capacity_oz,
        capacity_gal: item.capacity_gal,
        capacity_l: item.capacity_l,
        height_in:item.height_in,
        height_cm: item.height_cm,
        width_in: item.width_in,
        width_cm: item.width_cm,
        weight_kg: item.weight_kg,
        weight_lbs: item.weight_lbs
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/kegsizes`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			}).then(response => {
                const _kegSize = {
                    ...item,
                    ks_id:response.data.ks_id
                };
                dispatch(handleActionDispatch(actionTypes.ADD_KEG_SIZE, _kegSize));
                successToaster("Added");
                resolve();
            });
		});

	}
}

export const updateKegSize = (item) => {
	const formData = {
		size_name:item.size_name,
        nickname: item.nickname,
        capacity_oz: item.capacity_oz,
        capacity_gal: item.capacity_gal,
        capacity_l: item.capacity_l,
        height_in:item.height_in,
        height_cm: item.height_cm,
        width_in: item.width_in,
        width_cm: item.width_cm,
        weight_kg: item.weight_kg,
        weight_lbs: item.weight_lbs
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/kegsizes/${item.ks_id}`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			dispatch(handleActionDispatch(actionTypes.UPDATE_KEG_SIZE, item));
			successToaster("Updated");
			resolve();
		})
	}
}

export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_KEG_SIZE_PAGE, page));
    }
}

export const setSelKegSize = (kegSize) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SEL_KEG_SIZE, kegSize));
    }
}

export const clearSelKegSize = () => {
    return setSelKegSize({ks_id:0,
        size_name:"",
        nickname: "",
        capacity_oz: 0,
        capacity_gal: 0,
        capacity_l: 0,
        height_in: 0,
        height_cm: 0,
        width_in: 0,
        width_cm: 0,
        weight_kg: 0,
        weight_lbs: 0});
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}