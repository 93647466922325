import { getMockSenderEnhancer } from "@rpldy/mock-sender";
import withPasteUpload from "@rpldy/upload-paste";
import Uploady, {
  useItemFinalizeListener,
  useItemFinishListener, useItemStartListener
} from "@rpldy/uploady";
import React, { useState } from "react";
import Select from "react-select";
import {
  Card, CardBody, CardFooter,
  CardHeader, Col, Input,
  Label,
  Row
} from "reactstrap";
import { isObject } from "reactstrap/lib/utils";
import SimpleReactValidator from "simple-react-validator";
import styled from "styled-components";
import * as personActions from './store/person.actions';
import * as enumsActions from '../shared/actions/enums.action';
import * as UserInfoActions from "../../redux/userDetail/action";
import { connect } from "react-redux";
import Compressor from 'compressorjs';
import axios from "axios";

const mockSenderEnhancer = getMockSenderEnhancer();


const StyledInput = styled.input`
  width: 400px;
  height: 22px;
  font-size: 20px;
  margin: 10px 0;
`;

const PasteInput = withPasteUpload(StyledInput);

const UploadStatus = (moreProps) => {
  const [status, setStatus] = useState(null);

  useItemStartListener(() => setStatus("Uploading..."));
  useItemFinalizeListener(() => setStatus("Finished!"));
  useItemFinishListener((item) => {
    let file = item.file;
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      convertSize: 100000, // will convert all images more than 100kb 
      success: (compressedFile) => {
        let reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          moreProps.callback(reader.result);
        }
      },
    });
    // let file = item.file;
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onloadend = () => {
    //   moreProps.callback(reader.result);
    // }
  });
  return status;
};

class PersonDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: null,
      genders: [],
      isEmailExists:false,
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        },
        emailValidator: {
          message: "Email already exists",
          rule: (val, params, validator) => {
            return !val;
          },
          required: true, // optional
        },
      }
    });
  }

  componentDidMount() {
    //this.getPerson();
    this.props.getGenders();
    const person_id=this.props.match.params.id;
    this.props.getPerson(person_id);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      person: props.selPerson,
      genders: props.enums.genders
    }
  }

  // getPerson = () => {
  //   const personId = this.state.person.id;
  //   if (personId !== 0) {
  //     axios({
  //       method: 'GET',
  //       url: `${process.env.REACT_APP_API_BASE_URL}/api/persons/${personId}`,
  //       headers: {
  //         'Access-Control-Allow-Origin': 'true'
  //       }
  //     }).then(response => {
  //       if (response.data) {
  //         let newState = Object.assign({}, this.state);
  //         newState.person.first_name = response.data.first_name;
  //         newState.person.middle_name = response.data.middle_name;
  //         newState.person.last_name = response.data.last_name;
  //         newState.person.gender = response.data.gender;
  //         newState.person.email = response.data.email;
  //         newState.person.phone = response.data.phone;

  //         newState.person.selectedGender = { value: response.data.gender, label: response.data.gender };

  //         newState.person.profile = response.data.profile.includes("https://") ? response.data.profile : `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${response.data.profile}`;
  //         newState.person.avatar = response.data.profile.includes("https://") ? response.data.profile : `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${response.data.profile}`;

  //         this.setState(newState);
  //       }
  //     });
  //   }
  // }

  updatePreview = (base64Data) => {
    let newState = Object.assign({}, this.state);
    newState.person.avatar = base64Data;
    newState.person.profile = base64Data;
    this.setState(newState);
  }


  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.person[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.person[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }

  getPersonsByEmail=(email,personId)=>{
    let url=''
    if(personId===0){
      url=`${process.env.REACT_APP_API_BASE_URL}/api/persons/getPersonsByEmail?email=${email}`
    }
    else{
      url=`${process.env.REACT_APP_API_BASE_URL}/api/persons/getPersonsByEmail?email=${email}&person_id=${personId}`
    }
    axios({
      method: "GET",
      url: url,
    }).then((response) => {
      if (response.data !== undefined) {
        this.setState({...this.state,isEmailExists:response.data}); 
      }
    })
  }

  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.person[inputPropName] = item;
    this.setState(newState);
  }

  addPerson = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    } 
    this.setState({ saveInProgress: true });
    const personInfo = this.state.person;
    
    if (personInfo.id === 0) {
      await this.props.addPerson(personInfo);
      // axios({
      //   method: 'POST',
      //   url: `${process.env.REACT_APP_API_BASE_URL}/api/persons/`,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Access-Control-Allow-Origin': 'true'
      //   },
      //   data: formData
      // })
      //   .then(() => {
      //     successToaster("Added");
      //     this.setState({ saveInProgress: false });          
      //     this.handleCancel();
      //   });
    }
    else {

      if (personInfo.profile.indexOf(`${process.env.REACT_APP_CDN_BASE_URL}/assets/images/`) !== -1) {
        personInfo.profile = personInfo.profile.replace(`${process.env.REACT_APP_CDN_BASE_URL}/assets/images/`, '');
      }
      
      await this.props.updatePerson(personInfo);
      // axios({
      //   method: 'PUT',
      //   url: `${process.env.REACT_APP_API_BASE_URL}/api/persons/${personInfo.id}`,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Access-Control-Allow-Origin': 'true'
      //   },
      //   data: formData
      // })
      //   .then(() => {
      //     successToaster("Updated");
      //     this.setState({ saveInProgress: false });          
      //     this.handleCancel();
      //   });
    }
    if(this.props.userDetails.person.person_id === personInfo.id) {
      this.props.setUserDetail({...this.props.userDetails, person : {...this.props.userDetails.person, profile : personInfo.profile}})
    }
    this.setState({ saveInProgress: false });
    this.props.history.push(`/admin/persons`);
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {
    const profilePicStyle = {
      width: '400px',
      height: '300px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-Repeat',
      backgroundPosition: 'center',
      backgroundImage: 'url(' + this.state.person.profile + ')',
      borderRadius: '5px',
      backgroundColor: '#ebebeb'
    }

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <form>
            <CardHeader className="bg-transparent py-4">
              <Row className="text-muted">
                <Col md="6" lg="6">
                  <h3>{this.state.person.id === 0 ? "Add Person" : "Update Person"}</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Row>
                <Col md="6">
                  <Row className="mb-3">
                    <Col md="9" sm="9" lg="9">
                      <Label> First Name<span style={{color:'red'}}>*</span></Label>
                      <Input value={this.state.person.first_name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'first_name')} autoComplete="new-name" />
                      {this.validator.message('First name', this.state.person.first_name, 'required')}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="9" sm="9" lg="9">
                      <Label> Middle Name</Label>
                      <Input value={this.state.person.middle_name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'middle_name')} autoComplete="new-name" />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="9" sm="9" lg="9">
                      <Label> Last Name<span style={{color:'red'}}>*</span></Label>
                      <Input value={this.state.person.last_name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'last_name')} autoComplete="new-name" />
                      {this.validator.message('Last name', this.state.person.last_name, 'required')}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="9" sm="9" lg="9">
                      <Label> Gender<span style={{color:'red'}}>*</span></Label>
                      <Select size="sm" value={this.state.person.selectedGender}
                        options={this.state.genders}
                        onChange={(e) => this.handleChangeSelection(e, 'selectedGender')}></Select>
                      {this.validator.message('gender', this.state.person.selectedGender, 'required|selectValidator:""')}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="9" sm="9" lg="9">
                      <Label> Email<span style={{color:'red'}}>*</span></Label>
                      <Input value={this.state.person.email} bsSize="sm" type="text" 
                      onChange={(e) => this.handleInputChange(e, 'email')} autoComplete="new-name"
                      onBlur={()=>this.getPersonsByEmail(this.state.person.email,this.props.match.params.id)} />
                      {this.validator.message('email', this.state.person.email, 'required|email')}
                      {this.validator.message('Email exists', this.state.isEmailExists, 'emailValidator:false')}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="9" sm="9" lg="9">
                      <Label>Phone<span style={{color:'red'}}>*</span></Label>
                      <Input value={this.state.person.phone} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'phone')} autoComplete="new-name" />
                      {this.validator.message('phone', this.state.person.phone, 'required')}
                    </Col>
                  </Row>
                </Col>
                <Col md="6" sm="1" lg="6" className="mb-3 position-relative">
                  <Row>
                    <Col md="12" sm="12" lg="12">
                      <br />
                      <Uploady enhancer={mockSenderEnhancer} destination={{ url: `${process.env.REACT_APP_HOST_BASE_URL}/upload` }}>
                        {/* <PasteUploadDropZone params={{ test: "paste" }}>
                                            You can drop a file here
                                            <br />
                                            OR
                                            <br />
                                            click and paste a file to upload
                                            </PasteUploadDropZone> */}
                        <PasteInput extraProps={{ placeholder: "paste image to upload" }} />
                        <UploadStatus extraProps={{ source: this.state.person.profile }} callback={this.updatePreview} />
                        {/* <PreviewContainer>
                                            <UploadPreview  />
                                            </PreviewContainer> */}
                      </Uploady>
                      <div style={profilePicStyle}></div>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="2" lg="2" >
                  <div>
                    <button className="btn btn-sm btn-success" onClick={this.addPerson} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                      {this.state.person.id === 0 ? "Add" : "Update"}
                    </button>
                    <button className="btn btn-sm btn-default" onClick={this.handleCancel}>Cancel</button>
                  </div>

                </Col>
              </Row>
            </CardFooter>
          </form>
        </Card>

      </>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    selPerson: state.persons.selPerson,
    userDetails: state.userInfo.userDetails,
    enums: state.enums
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addPerson: (person) => dispatch(personActions.addPerson(person)),
    updatePerson: (person) => dispatch(personActions.updatePerson(person)),
    getGenders: () => dispatch(enumsActions.getGenders()),
    setUserDetail: (person) => dispatch(UserInfoActions.setUserDetail(person)),
    getPerson:(personId)=>dispatch(personActions.getPerson(personId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonDetail);
