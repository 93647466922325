import React from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import GlycolWidget from './widgets/glycol-widget';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import ConnectedKegsWidget from '../dashboard/widgets/connected-kegs-widget';
import HourlyPourVolumeWidget from './widgets/hourly-pour-volume-widget';
import LineCleaningWidget from './widgets/line-cleaning-widget';
import KrTemperatureWidget from './widgets/kr_temperature_widget';
import { connect } from 'react-redux';
import KegsLineWidget from './widgets/kegs-line-widget';

const ResponsiveReactGridLayout = WidthProvider(Responsive)

class DashboardUpdated extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            widgetPositions:[
                { i: "a", x: 0, y: 0, w: 6, h: 1.2, color:"white" },
                { i: "b", x: 6, y: 0, w: 6, h: 1.6, color:"white" },
                //{ i: "b", x: 6, y: 0, w: 6, h: 1, color:"white" },
                // { i: "c", x: 0, y: 1, w: 6, h: 1, color:"white" },
                // { i: "d", x: 6, y: 1, w: 6, h: 1, color:"white" },
              ],
            layouts:null,
            widgetKey:"pour"
        }
    }

    static getDerivedStateFromProps(props, state) {
     return{
      widgetKey:props.widgetKey
     }
    }

    handleModify = (layouts, layout) => {
        const tempArray = this.state.widgetPositions;
       
        layouts?.forEach((position) => {
          tempArray[Number(position.i)].x = position.x;
          tempArray[Number(position.i)].y = position.y;
          tempArray[Number(position.i)].width = position.w;
          tempArray[Number(position.i)].height = position.h;
        });
        this.setState({...this.state,layout:layout,widgetPositions:tempArray})
      };
    
    handleAdd = () => {
        this.setState({...this.state,widgetPositions:[
          ...this.state.widgetPositions,
          { i: "widget" + (this.state.widgetPositions.length + 1), x: 0, y: 0, w: 2, h: 2 },
        ]});
      };
    
    handleDelete = (key) => {
        const tempArray = this.state.widgetPositions.slice();
        const index = tempArray.indexOf(tempArray.find((data) => data.i === key));
        tempArray.splice(index, 1);
        this.setState({...this.state,widgetPositions:tempArray})
      };

    leftWidget=()=>{
      const key=this.props.widgetKey;
      if(key==="lc"){
        return(<LineCleaningWidget />)
      }
      else if(key==="glycol"){
        return(<GlycolWidget port={this.props.port} />)
      }else if(key==="kegs"){
        return(<KegsLineWidget />)
      }
      else if(key==="kr_temp"){
        return(<KrTemperatureWidget />)
      }
      else{
        return(<HourlyPourVolumeWidget />)
      }
    }

    render(){
      var layout = [
        { i: "a", x: 0, y: 0, w: 4, h: 1 },
        { i: "b", x: 4, y: 0, w: 4, h: 1 },
        { i: "c", x: 8, y: 0, w: 4, h: 1 },
        { i: "d", x: 0, y: 1, w: 4, h: 1 },
        { i: "e", x: 4, y: 1, w: 4, h: 1 },
        { i: "f", x: 8, y: 1, w: 4, h: 1 },
      ];
        return(<>
                <ResponsiveReactGridLayout
                className="layout"
                layouts={layout}
                breakpoints={{ lg: 1200 }}
                cols={{ lg: 12 }}
                rowHeight={281}
                width={1200}>
                {this.state.widgetPositions?.map((widget, index) => {
          return (
            <div
              className="reactGridItem"
              key={index}
              style={{backgroundColor:widget?.color}}
              data-grid={{
                x: widget?.x,
                y: widget?.y,
                w: widget?.w,
                h: widget?.h,
                i: widget.i,
                minW: 2,
                maxW: Infinity,
                minH: 2,
                maxH: Infinity,
                isDraggable: false,
                isResizable: false,
              }}>
              {
                index===0?<div>
                {this.leftWidget()}
              </div>:<div>
                <ConnectedKegsWidget kegsDashboard={this.props.kegsDashboard}/>
              </div>
              }
              
              
            </div>
          );
        })}
              </ResponsiveReactGridLayout>
        </>)
    }
}

const mapStateToProps = (state) => {
  return {
      widgetKey:state.monitor.widgetKey,
  }
}

export default connect(mapStateToProps,null)(DashboardUpdated)