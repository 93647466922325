import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import beersReducers from '../views/beers/store/beers.reducer';
import enumsReducer from '../views/shared/reducers/enums.reducer';
import breadCrumReducer from './breadcrum/reducer';
import userDetailReducer from './userDetail/reducer';
import loaderReducer from './loader/reducer';
import personsReducer from '../views/persons/store/person.reducer';
import MonitorReducer from '../views/dashboard/store/dashboard-reducer';
import auditTrailsReducer from '../views/auditlogs/store/auditlogs.reducer';
import customersReducers from '../views/customer/store/customers.reducer';
import chartReducer from '../views/charts/store/chart-reducer'
import siteReducer from '../views/sites/store/site.reducer'
import kegRoomReducer from '../views/kegroom/store/kegroom.reducer'
import kegReducer from '../views/keg/store/keg.reducer'
import mcuReducer from '../views/mcu/store/mcu.reducer'
import sensorHubReducer from '../views/sensor_hub/store/sensor_hub.reducer'
import sensorReducer from '../views/sensor/store/sensor.reducer'
import lineReducer from '../views/line/store/line.reducer'
import towerReducer from '../views/towers/store/tower.reducer'
import kegSizeReducer from '../views/keg-sizes/store/keg-sizes.reducer'
import glycolSystemReducer from '../views/glycol_system/store/glycol_system.reducer'
import krTempSensor from '../views/kr_temperature/store/kr_temperature.reducer'
import alarmDefinition from '../views/alarms/store/alarm-definition.reducer';
import reportSubscriptions from '../views/subscriptions/store/subscriptions.reducer';

// reducers
let reducers = {
    auditlogs:auditTrailsReducer,
    beers: beersReducers,
    enums: enumsReducer,
    dashboard:breadCrumReducer,
    userInfo:userDetailReducer,
    loader:loaderReducer,   
    persons:personsReducer,
    monitor:MonitorReducer,
    customers:customersReducers,
    charts:chartReducer,
    sites:siteReducer,
    kegRooms:kegRoomReducer,
    kegs:kegReducer,
    mcus:mcuReducer,
    sensorHubs:sensorHubReducer,
    sensors:sensorReducer,
    lines:lineReducer,
    towers:towerReducer,
    kegSizes:kegSizeReducer,
    glycolSystems:glycolSystemReducer,
    krTempSensors:krTempSensor,
    alarmDefinitions:alarmDefinition,
    reportSubscriptions:reportSubscriptions
}
const rootReducer = combineReducers(reducers);

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : null || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
