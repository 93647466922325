import React from "react";
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";
import * as adActions from './store/alarm-definition.actions';
import Select from "react-select";

class AlarmDefinitionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selAd: {
        alarm_id:0,
        view_name:'',
        procedure_name:'',
        alarm_severity:'',
        control_limit:0
      },
      views:[],
      procedures:[],
      severities:[],
      selView:null,
      selProcedure:null,
      selSeverity:null,
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  componentDidMount() {
  //  this.props.getViews();
  //  this.props.getProcedures();
  //  this.props.getSeverities();
   let _selView=null,_selProcedure=null,_selSeverity=null;
   if(this.props.selAd.alarm_id!==0){
    if(this.state.views.length>0){
      _selView=this.state.views.find(s=>s.label===this.props.selAd.view_name);
    }
    if(this.state.procedures.length>0){
      _selProcedure=this.state.procedures.find(s=>s.label===this.props.selAd.procedure_name);
    }
    if(this.state.severities.length>0){
      _selSeverity=this.state.severities.find(s=>s.label===this.props.selAd.alarm_severity);
    }
   }
   this.setState({
    ...this.state,
    selView:_selView,
    selProcedure:_selProcedure,
    selSeverity:_selSeverity
   })
  }

  static getDerivedStateFromProps(props, state) {
    return {
        views:props.views,
        procedures:props.procedures,
        severities:props.severities,
        selAd:props.selAd
    }
  }
  

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.selAd[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.selAd[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }

  handleChangeSelection = async(item, inputPropName) => {
    let newState = Object.assign({}, this.state);
    newState[inputPropName] = item;
    this.setState(newState);
  }

  saveAd = async () => {
    if (!this.validator.allValid()) {
        this.validator.showMessages();
        return;
      } 
    this.setState({ saveInProgress: true });
    const adInfo = {
        alarm_id:this.state.selAd.alarm_id,
        view_name:this.state.selView.label,
        procedure_name:this.state.selProcedure.label,
        alarm_severity:this.state.selSeverity.label,
        control_limit:this.state.selAd.control_limit
    }
    if (adInfo.alarm_id === 0) {
      await this.props.addAd(adInfo);
    }
    else {
      await this.props.updateAd(adInfo);
    }
    this.setState({ saveInProgress: false });
    this.props.history.push(`/admin/alarmDefinitions`);
  }


  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.selAd.alarm_id === 0 ? "Add Alarm Definition" : "Edit Alarm Definition"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
                <Col md="4" sm="4" lg="4">
                    <Label> Select View<span style={{color:'red'}}>*</span></Label>
                    <Select size="sm" value={this.state.selView}
                        options={this.state.views}
                        onChange={(e) => this.handleChangeSelection(e, 'selView')}></Select>
                    {this.validator.message('Select View', this.state.selView, 'required|selectValidator:""')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label> Select Procedure<span style={{color:'red'}}>*</span></Label>
                    <Select size="sm" value={this.state.selProcedure}
                        options={this.state.procedures}
                        onChange={(e) => this.handleChangeSelection(e, 'selProcedure')}></Select>
                    {this.validator.message('Select Procedure', this.state.selProcedure, 'required|selectValidator:""')}
                </Col>
            </Row>
            <br />
            <Row>
                <Col md="4" sm="4" lg="4">
                    <Label> Select Severity<span style={{color:'red'}}>*</span></Label>
                    <Select size="sm" value={this.state.selSeverity}
                        options={this.state.severities}
                        onChange={(e) => this.handleChangeSelection(e, 'selSeverity')}></Select>
                    {this.validator.message('Select Severity', this.state.selSeverity, 'required|selectValidator:""')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label>Control Limit<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selAd.control_limit} bsSize="sm" min="0" type="number" onChange={(e) => this.handleInputChange(e, 'control_limit')} autoComplete="new-name" />
                    {this.validator.message('control_limit', this.state.selAd.control_limit, 'required')}
                </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveAd} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.selAd.alarm_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    views:state.alarmDefinitions.views,
    procedures:state.alarmDefinitions.procedures,
    severities:state.alarmDefinitions.severities,
    selAd:state.alarmDefinitions.selAd
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      getViews:()=>dispatch(adActions.getViews()),
      getProcedures:()=>dispatch(adActions.getProcedures()),
      getSeverities:()=>dispatch(adActions.getSeverities()),
      addAd:(ad)=>dispatch(adActions.addAd(ad)),
      updateAd:(ad)=>dispatch(adActions.updateAd(ad))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AlarmDefinitionDetails);
