import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { calcWaterfallDataWSign, setColor } from "./constants";
import { Label, Row } from "reactstrap";
import Col from "reactstrap/lib/Col";



class WaterfallChart extends React.Component{
  constructor(props){
    super(props)
    this.state={
      waterfallData:[],
      data:props.data,
      title:props.title,
      initialValue:0,
      yAxisTitle:props.yAxisTitle
    }
  }

  tootTip=(point)=>{
    return (<>
      <div style={{padding:'0 !important',margin:'0 !important',border:'1px solid grey',backgroundColor:`${point.data.color}`,color:'white',fontSize:'14px'}}>
       {
        !point.data.showFull?
        <>
          <Row>
            <Col lg="12" md="12" sm="12">
              {point.data.x} , {point.data.y.toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="6">
              <Label>Volume</Label>
            </Col>
            <Col lg="6" md="6" sm="6" style={{textAlign:'right'}}>
              {point.data.y.toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="6">
              <Label>Initial</Label>
            </Col>
            <Col lg="6" md="6" sm="6" style={{textAlign:'right'}}>
              {point.data.subtotal?point.data.subtotal.toFixed(2):0}
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="6">
              <Label>Total Volume</Label>
            </Col>
            <Col lg="6" md="6" sm="6" style={{textAlign:'right'}}>
              {point.data.accumulated.toFixed(2)}
            </Col>
          </Row>
        </>:
        <>
          <Row>
            <Col lg="12" md="12" sm="12">
              {point.data.x} , {point.data.y.toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col lg="5" md="5" sm="5">
              
            </Col>
            <Col lg="7" md="7" sm="7" style={{textAlign:'right'}}>
              {point.data.y.toFixed(2)}
            </Col>
          </Row>
        </>
        
       } 
      
      </div>
    </>)
  }

  Title=(w,h)=>{
    const style = {fontWeight: 'bold',textAlign:'center'}
  return (
    <text
    x={500}
    y={-10}
    textAnchor="middle"
    style={style}
>
{this.props.title}
</text>
    )
  }

  formatLabel=(x,y)=>{
    return x.id==="subtotal"?"":parseFloat(x.formattedValue).toFixed(2);
  }



  componentDidMount(){
    this.setState({...this.state,waterfallData:calcWaterfallDataWSign(this.state.data,this.state.initialValue)})
  }

  
  render(){
    const theme = {
      background: "#fff",
      axis: {
        fontSize: "14px",
        fontWeight:'bold',
        tickColor: "#000000",
        ticks: {
          line: {
            stroke: "#e6e6e6"
          },
          text: {
            fill: "#000000",
            strokeWidth:'5'
          }
        },
        legend: {
          text: {
            fill: "#000000"
          }
        }
      },
      grid: {
        line: {
          stroke: "#e6e6e6"
        }
      }
    };
    return(<>
    <div style={{width:'100%',height:'300px',padding:'10px'}}>
      {/* <h4 style={{textAlign:'center'}}>{this.state.title}</h4> */}
      <ResponsiveBar
        data={this.state.waterfallData}
        keys={["subtotal", "amount"]}
        indexBy="x"
        colors={setColor}
        enableLabel={true}
        padding={0.3}
        theme={theme}
        
        margin={{ left: 50, bottom: 40,top:50 }}
        axisBottom={{
            tickSize: 3,
            tickPadding: 5,
            tickRotation: -10,
            legend: 'Date',
            legendPosition: 'middle',
            legendOffset: 30
        }}
        axisLeft={{
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            //tickValues:[0,20,40,60,80,100],
            legend: this.state.yAxisTitle,
            legendPosition: 'middle',
            legendOffset: -35
        }}
        label={this.formatLabel}
        tooltip={(x,y)=>this.tootTip(x,y)}
        layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations',this.Title]}
      />
      </div>
    </>)
  }
}

export default WaterfallChart;