import {KEG_BY_LINE,UPDATE_CONFIG_TREE,SET_MONTHLY_VOLUME_POURED,SHOW_KEG_HISTORY,SHOW_SITE_KEGS,SET_CURR_PAGE,CLEAR, SET_LINES_GLYCOL,SET_PORT,SET_LAST_POUR_ITEM, SET_WIDGET, SET_HIGH_ALARMS} from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from './dashboard-actions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (SHOW_SITE_KEGS):
            return setKegs(state, action);
        case (SHOW_KEG_HISTORY):
            return setKegsHistory(state, action);
        case (SET_CURR_PAGE):
            return setCurrPage(state, action);
        case (CLEAR):
            return clearState(state, action);
        case (SET_LINES_GLYCOL):
            return setLinesGlycol(state,action);
        case (SET_PORT):
            return setPortForGlycol(state,action);
        case (SET_WIDGET):
            return setWidget(state,action);
        case (SET_LAST_POUR_ITEM):
            return setLastPourData(state,action);
        case (SET_HIGH_ALARMS):
            return setHighAlarms(state,action);
        case (SET_MONTHLY_VOLUME_POURED):
            return setMonthlyVolumePoured(state,action);
        case (UPDATE_CONFIG_TREE):
            return updateConfigTree(state,action);
        case (KEG_BY_LINE):
            return setKegByLine(state,action);
        default:
            return state;
    }
};

const setMonthlyVolumePoured=(state,action)=>{
    return updateObject(state, {
        monthlyVolumePoured:action.data
    });
}

const updateConfigTree=(state,action)=>{

}

const setPortForGlycol=(state,action)=>{
    return updateObject(state, {
       port:action.data
    });
}

const setKegByLine=(state,action)=>{
    return updateObject(state, {
        kegs_by_line:action.data
    });
}

const setHighAlarms=(state,action)=>{
    return updateObject(state, {
        highAlarms:action.data
    });
}

const setWidget=(state,action)=>{
    return updateObject(state, {
       widgetKey:action.data
    });
}

const setLastPourData=(state,action)=>{
    return updateObject(state, {
        last_pour_item:action.data
    });
}

const setLinesGlycol=(state,action)=>{
    return updateObject(state, {
       linesForGlycol:action.data
    });
}

const setKegs = (state, action) => {
    return updateObject(state, {
        kegs: action.data,
        showKegList:true
    });
};

const setKegsHistory = (state, action) => {
    return updateObject(state, {
        kegsHistory: action.data,
        showKegHistoryList:true
    });
};



// set current page number of beers list page 
const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        kegs: initialState.kegs,
        kegsHistory: initialState.kegsHistory,
        selKeg:initialState.selKeg,
        currPage: initialState.currPage,
        linesForGlycol:initialState.linesForGlycol,
        port:0,
        last_pour_item:null
    });
};

export default reducer;