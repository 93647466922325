import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selGs: {
        gs_id:0,
        gs_name:"",
        kr_id:0,
        kr_name:"",
        sensor_id:0,
        controller_sn:"",
        sensor_hub_sn:"",
        port:0,
        sensor_serial_number:""
    },
    glycolSystems: [],
    currPage: 1,
    showList:false
};

export const getGlycolSystems = (site_id) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.glycolSystems.glycolSystems.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/glycol`,{params:{site_id:site_id}}).then((response) => {
                if (response.data !== undefined) {
                    let _glycolSystem = [];
                    response.data.forEach((item) => {
                        _glycolSystem.push({
                           gs_id:item.gs_id,
                           gs_name:item.gs_name,
                           kr_id:item.kr_id,
                           kr_name:item.kr_name,
                           controller_sn:item.controller_sn,
                           sensor_hub_sn:item.sensor_hub_sn,
                           port:item.port,
                           sensor_id:item.sensor_id,
                           sensor_serial_number:item.sensor_serial_number
                       })
                    });
                    _glycolSystem = _glycolSystem.sort((a, b) => a.gs_name.localeCompare(b.gs_name));
                    dispatch(handleActionDispatch(actionTypes.SET_GS, _glycolSystem));
                    resolve(_glycolSystem);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const addGs = (item) => {

	const formData = {
        kr_id:item.kr_id,
        controller_sn:item.controller_sn,
        sensor_hub_sn:item.sensor_hub_sn,
        port:item.port,
        gs_name:item.gs_name,
        kr_name:item.kr_name,
        sensor_id:item.sensor_id,
        sensor_serial_number:item.sensor_serial_number
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/glycol`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			}).then(response => {
                const _gsItem = {
                    ...item,
                    gs_id:response.data.gs_id
                };
                dispatch(handleActionDispatch(actionTypes.ADD_GS, _gsItem));
                successToaster("Added");
                resolve();
            });
		});

	}
}

export const updateGs = (item) => {
	const formData = {
        gs_id:item.gs_id,
        kr_id:item.kr_id,
        gs_name:item.gs_name,
        controller_sn:item.controller_sn,
        sensor_hub_sn:item.sensor_hub_sn,
        port:item.port,
        kr_name:item.kr_name,
        sensor_id:item.sensor_id,
        sensor_serial_number:item.sensor_serial_number
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/glycol/${item.gs_id}`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			dispatch(handleActionDispatch(actionTypes.UPDATE_GS, item));
			successToaster("Updated");
			resolve();
		})
	}
}

export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_GS_PAGE, page));
    }
}

export const setSelGs = (gs) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SEL_GS, gs));
    }
}

export const clearSelGs = () => {
    return setSelGs({
        gs_id:0,
        gs_name:"",
        kr_id:0,
        kr_name:"",
        sensor_id:0,
        controller_sn:"",
        sensor_hub_sn:"",
        port:0,
        sensor_serial_number:""
    });
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}