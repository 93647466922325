import React from "react";
import Select from "react-select";
import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col} from "reactstrap";
import { successToaster } from '../toaster/toaster';
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import * as dashboardActions from '../dashboard/store/dashboard-actions';
import { connect } from "react-redux";

class SensorDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ports: [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }],
      sensor: {
        id: parseInt(props.match.params.id),
        sh_id: props.match.params.shid === undefined ? 0 : parseInt(props.match.params.shid),
        serial_number: "",
        status: {label:"Online",value:"Online"},
        statusOptions:[{label:"Online",value:"Online"},{label:"Offline",value:"Offline"}],
        deployed_date: moment(new Date()).format('YYYY-MM-DD'),
        volume_measured: 0.00,
        selectedPort: { label: 1, value: 1 }
      },
      sh: {
        sh_id: props.match.params.shid === undefined ? 0 : parseInt(props.match.params.shid),
      },
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  componentDidMount() {
    this.fetchSensorDetails();
    this.fetchShDetails();
  }


  fetchSensorDetails = () => {
    const sensor_id = this.state.sensor.id;
    if (sensor_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensors/${sensor_id}`).then(response => {
        if (response.data) {
          let newState = Object.assign({}, this.state);
          newState.sensor.serial_number = response.data.sensor_serial_number;
          const statusOption=newState.sensor.statusOptions.filter(s=>s.value===response.data.sensor_status)
          newState.sensor.status = statusOption.length===0?null:statusOption[0];//response.data.sensor_status;
          newState.sensor.deployed_date = response.data.sensor_deployed_date;
          newState.sensor.volume_measured = response.data.sensor_volume_measured;
          newState.sensor.selectedPort = newState.ports.find(a => a.value === response.data.port);
          this.setState(newState);
        }
      });
    }
  }

  fetchShDetails = () => {
    const sh_id = this.state.sensor.sh_id;
    if (sh_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensor_hub/${sh_id}`).then(response => {
        if (response.data) {
          let newState = Object.assign({}, this.state);
          newState.sh.serial_number = response.data.sh_serial_number;
          newState.sh.status = response.data.sh_status;
          newState.sh.last_reboot_date = response.data.sh_last_reboot_date;
          newState.sh.sensor1_deployment = response.data.sh_sensor_deployment;
          this.setState(newState);
        }
      });
    }
  }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.sensor[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.sensor[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }


  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.sensor[inputPropName] = item;
    this.setState(newState);
  }

  saveSensor = async() => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    } 
    this.setState({saveInProgress:true});
    const sensorInfo = this.state.sensor;    
    const formData = {
      //sensor_id: sensorInfo.id,
      sensor_serial_number: sensorInfo.serial_number,
      sensor_status: sensorInfo.status.value,
      sensor_deployed_date: sensorInfo.deployed_date,
      sensor_volume_measured: sensorInfo.volume_measured == null ? 0 : sensorInfo.volume_measured,
      sh_id: sensorInfo.sh_id,
      port: sensorInfo.selectedPort.value
    };

    if (sensorInfo.id === 0) {

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/sensors`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
        data: formData
      })
        .then(response => {
          //sensorInfo.id = response.sensor_id;
          //newState.sensor=sensorInfo;
          //this.setState(newState);
          successToaster("Added");
          this.setState({ saveInProgress: false });          
          this.handleCancel();
        });
    }
    else {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/sensors/${sensorInfo.id}`,
        data: formData
      })
        .then(response => {
          successToaster("Updated");
          this.setState({ saveInProgress: false });          
          this.handleCancel();
        });
    }
  }

  handleCancel = () => {
    //this.props.updateConfigTree();
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.sensor.id === 0 ? "Add Sensor" : "Edit Sensor"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Label>Sensor Hub {this.state.sh.serial_number}</Label>
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Serial Number<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.sensor.serial_number} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'serial_number')} autoComplete="new-name" />
                {this.validator.message('Serial number', this.state.sensor.serial_number, 'required')}
              </Col>
              <Col md="2" sm="3" lg="2">
                <Label> Port<span style={{color:'red'}}>*</span></Label>
                <Select className="" value={this.state.sensor.selectedPort}
                  options={this.state.ports}
                  onChange={(e) => this.handleChangeSelection(e, 'selectedPort')}>
                </Select>
                {this.validator.message('Select Port', this.state.sensor.selectedPort, 'required|selectValidator:""')}
              </Col>
              <Col md="2" sm="3" lg="2">
                <Label> Status<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.sensor.status}
                  options={this.state.sensor.statusOptions}
                  onChange={(e) => this.handleChangeSelection(e, 'status')}
                ></Select>
                {this.validator.message('Select Status', this.state.sensor.status, 'required|selectValidator:""')}
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Deployed Date<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.sensor.deployed_date} bsSize="sm" type="date" onChange={(e) => this.handleInputChange(e, 'deployed_date')} autoComplete="new-name" />
                {this.validator.message('Deployed date', this.state.sensor.deployed_date, 'required')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Volume Measured (ml)<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.sensor.volume_measured} bsSize="sm" type="number"
                  min="0" max="100000" step="0.25"
                  onChange={(e) => this.handleInputChange(e, 'volume_measured')} autoComplete="new-name" />
                {this.validator.message('Volume measured', this.state.sensor.volume_measured, 'required')}  
              </Col>
            </Row>

          </CardBody>
          <CardFooter>
            <Row>
              <Col md="2" lg="2" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveSensor} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.sensor.id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateConfigTree:()=>dispatch(dashboardActions.updateConfigTree())
  }
}

export default connect(null, mapDispatchToProps)(SensorDetail);
