import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import { successToaster } from '../../toaster/toaster';



export const initialState = {
	selBeer: {
		id: 0,
		name: "",
		short_name:'',
		company: "",
		distributor:"",
		keg_coupler: null,
		type: null,
		logo: "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-51g0co/4851.png?position=1",
		abv: '',
		countryoforigin: null,
		category: null
	},
	beers: [],
	distributors:[],
	beers_kr:[],
	currPage: 1
};

export const getBeers = () => {
	const state = store.getState();
	return (dispatch) => {
		if (state.beers.beers.length > 0) {
			return;
		}
		return new Promise(function (resolve, reject) {
			axios(`${process.env.REACT_APP_API_BASE_URL}/api/beers`).then((response) => {
				if (response.data !== undefined) {
					let beersData = [];
					response.data.forEach((item) => {
						var logo = item.logo.includes("https://")
							? item.logo
							: `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${item.logo}`;
						beersData.push({
							id: item.draft_beer_id,
							name: item.name,
							short_name:item.short_name,
							type: item.type,
							company: item.company,
							distributor:item.distributor,
							keg_Coupler: item.keg_coupler,
							logo: logo,
							country: item.countryoforigin,
							category: item.category,
							abv: item.ABV
						});
					});
					beersData.sort((a, b) => a.name.localeCompare(b.name));
					dispatch(handleActionDispatch(actionTypes.SET_BEERS, beersData));	
					resolve(beersData);			
					//this.setState({ beers: beersData, filteredBeerList: beersData, showList: true });
				}
			},
			(error) => {
				reject(error);
			});
		})
	}
}

export const getBeersByKegRoom = (kr_id) => {
	//const state = store.getState();
	return (dispatch) => {
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/reports/GetBeersByKegRoom`,{params:{kr_id:kr_id}}).then((response) => {
			if (response.data !== undefined) {
				console.log(response);
				let beersData = [];
				response.data.forEach((item) => {
					beersData.push({
						id: item.beer_id,
						name: item.beer_name,
						line_number:item.line_number
					});
				});
				beersData.sort((a, b) => a.name.localeCompare(b.name));
				dispatch(handleActionDispatch(actionTypes.SET_BEERS_KR, beersData));				
				//this.setState({ beers: beersData, filteredBeerList: beersData, showList: true });
			}
		});
	}
}

export const getBeerDistributors = (kr_id) => {
	//const state = store.getState();
	return (dispatch) => {
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/getBeerDistributors`,{params:{kr_id:kr_id}}).then((response) => {
			if (response.data !== undefined) {
				let _distributors = [];
				var counter=1;
				response.data.forEach((item) => {
					_distributors.push({
						value: counter,
						label: item.distributor
					});
					counter++;
				});
				_distributors.sort((a, b) => a.label.localeCompare(b.label));
				dispatch(handleActionDispatch(actionTypes.SET_BEER_DISTRIBUTORS, _distributors));				
				//this.setState({ beers: beersData, filteredBeerList: beersData, showList: true });
			}
		});
	}
}

export const setBeers = (beers) => {
	return (dispatch) => {
		dispatch(handleActionDispatch(actionTypes.SET_BEERS, beers));
	}
}

export const addBeer = (beer) => {

	const formData = {
		draft_beer_id: beer.id,
		name: beer.name,
		company: beer.company,
		distributor:beer.distributor,
		type: beer.type.value,
		keg_coupler: beer.keg_coupler.value,
		logo: beer.logo,
		ranking: 1,
		category: beer.category.label,
		countryoforigin: beer.countryoforigin.label,
		ABV: beer.abv,
		short_name:beer.short_name
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/beers`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			const beerRec = {
				...beer,
				type: beer.type.value,
				keg_Coupler: beer.keg_coupler.value,
				country: beer.countryoforigin.value,
				category: beer.category.value
			};
			dispatch(handleActionDispatch(actionTypes.ADD_BEER, beerRec));
			successToaster("Added");
			resolve();
		});

	}
}

export const updateBeer = (beer) => {
	const formData = {
		draft_beer_id: beer.id,
		name: beer.name,
		company: beer.company,
		distributor:beer.distributor,
		type: beer.type.value,
		keg_coupler: beer.keg_coupler.value,
		logo: beer.logo,
		ranking: 1,
		category: beer.category.label,
		countryoforigin: beer.countryoforigin.label,
		ABV: beer.abv,
		short_name:beer.short_name
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/beers/${beer.id}`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});

			const beerRec = {
				...beer,
				type: beer.type.value,
				keg_Coupler: beer.keg_coupler.value,
				country: beer.countryoforigin.value,
				category: beer.category.value
			};
			dispatch(handleActionDispatch(actionTypes.UPDATE_BEER, beerRec));
			successToaster("Updated");
			resolve();
		})
	}
}
export const setCurrPage = (page) => {
	return (dispatch) => {
		dispatch(handleActionDispatch(actionTypes.SET_CURR_PAGE, page));
	}
}

export const updateKrBeers = (data) => {
	return (dispatch) => {
		dispatch(handleActionDispatch(actionTypes.UPDATE_BEERS_KR, data));
	}
}


export const setSelBeer = (beer) => {
	beer.type = beer.type ? { label: beer.type, value: beer.type } : "";
	beer.keg_coupler = beer.keg_Coupler ? { label: beer.keg_Coupler, value: beer.keg_Coupler } : "";
	beer.category = beer.category ? { label: beer.category, value: beer.category } : "";
	beer.countryoforigin = beer.country ? { label: beer.country, value: beer.country } : "";
	return (dispatch) => {
		dispatch(handleActionDispatch(actionTypes.SET_SEL_BEERS, beer));
	}
}

export const clearSelBeer = () => {
	return setSelBeer(initialState.selBeer);
}

export const clearStates = () => {
	return (dispatch) => {
		dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
	}
}


export const handleActionDispatch = (actionName, data) => {
	return {
		type: actionName,
		data: data
	}
}