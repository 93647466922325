import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./glycol_system.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SEL_GS):
            return setSelGs(state, action);
        case (actionTypes.SET_GS):
            return setGs(state, action);
        case (actionTypes.SET_CURR_GS_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        case (actionTypes.ADD_GS):
            return addGs(state, action);
        case (actionTypes.UPDATE_GS):
            return updateGs(state, action);
        default:
            return state;
    }
};

const addGs = (state, action) => {
    let glycolSystems = [...state.glycolSystems];
    glycolSystems.push(action.data);
    return updateObject(state, {
        glycolSystems: glycolSystems
    });
};

const updateGs = (state, action) => {
    const index = state.glycolSystems.findIndex(b => b.gs_id === action.data.gs_id);
    return {
        ...state,
        glycolSystems: [...state.glycolSystems.slice(0, index), action.data, ...state.glycolSystems.slice(index + 1)]
    };
};


const setSelGs = (state, action) => {
    return updateObject(state, {
        selGs: action.data
    });
};

const setGs = (state, action) => {
    return updateObject(state, {
        glycolSystems: action.data,
        showList:true
    });
};

const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selGs: initialState.selGs,
        glycolSystems: initialState.glycolSystems,
        currPage: initialState.currPage
    });
};

export default reducer;