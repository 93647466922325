import React, { Component } from "react"
import { HashRouter, Switch, Route, Redirect } from "react-router-dom"
import { Provider } from "react-redux"
import store from "./redux/store"
import AuthLayout from "./layouts/auth"
import AdminLayout from "./layouts/admin"
import { Toaster } from "react-hot-toast"

////import DateFnsUtils from '@date-io/date-fns';

class App extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedin: false,
      userDetail: null
    }
  }

  // getRoutes = () => {
  //   let routes = this.getDefaultRoute();

  //   const checkUser = this.checkUserExist();
  //   if (checkUser) {
  //     routes = this.getLayoutRoute();
  //   }
  //   return routes;
  // };

  // //check user exist or not
  // checkUserExist = () => {
  //   return localStorage.getItem('accessToken') ? true : false
  // };

  // getDefaultRoute = () => {
  //   return (
  //     <Switch>
  //       <Route
  //         path="/auth"
  //         render={(props) => <AuthLayout {...props} />}
  //       />

  //     </Switch>
  //   );
  // };

  // getLayoutRoute = () => {
  //   return (

  //     <Switch>
  //       <Route
  //         path="/admin"
  //         render={(props) => <AdminLayout {...props} />}
  //       />
  //     </Switch >
  //   );
  // };
  render() {
    return (
      <Provider store={store}>
        
          <HashRouter>
            <div>
              {/* {this.getRoutes()} */}
              <Switch>
                <Route
                  path="/auth"
                  render={(props) => <AuthLayout {...props} />}
                />
                <Route
                  path="/admin"
                  render={(props) => <AdminLayout  {...props} />}
                />
                {/* <ProtectedRoute path="/Dashboard" component={Dashboard} /> */}
                <Redirect from="/" to="/auth/login" />
              </Switch>
            </div>
          </HashRouter>
          <Toaster />
      </Provider>
    )
  }
}

export default App
