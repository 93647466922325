import React from "react";
import "react-filter-box/lib/react-filter-box.css"
import {
    Row,
    Col,
} from "reactstrap";
import { connect } from "react-redux";
import * as monitorActions from './store/dashboard-actions';
import moment from 'moment';
import LoaderComponent from "../shared/loader";
import * as kegRoomActions from '../kegroom/store/kegroom.actions';

class DashboardMonitor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            kegs: this.props.kegs,
            kegsHistory: this.props.kegsHistory,
            filteredKegs: [],
            connected: true,
            activeTab: "1",
            showKegList: false,
            showKegHistoryList: false
        };
        this.eventSource = new EventSource(`https://dev-events.draftco.ca/events?site_id=${this.props.site_id}`);
    }

    static getDerivedStateFromProps(props, state) {
        if (state.kegs.length === 0 || state.kegsHistory.length === 0) {
            return {
                kegs: props.kegs,
                kegsHistory: props.kegsHistory,
                filteredKegs: props.kegs.filter(m => m.connected),
                showKegList: props.showKegList,
                showKegHistoryList: props.showKegHistoryList
            }
        }
        return null;
    }

    async componentDidMount() {
        await this.props.getKegRooms(this.props.site_id);
        this.props.getKegs(this.props.kegRooms[0].kr_id);
        this.props.getKegsHistory(this.props.site_id);

        this.eventSource.onmessage = (e) => {
            if (e.type === "message") {
                console.log(e.data);
                var result = JSON.parse(e.data)
                if (result.length === 0) return;
                var selectedKeg = this.state.filteredKegs.filter(s => s.keg_id === result[0].keg_id);
                selectedKeg = selectedKeg[0];
                result = result[0];
                if (selectedKeg === undefined) return;
                var logo = result.logo.includes("https://")
                    ? result.logo
                    : `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${result.logo}`;
                var updatedItem = {
                    beerName: result.short_name===null?result.beerName.substring(0,15):result.short_name,
                    connected_start_local: moment(result.connected_date.substring(0, 10) + ' ' + result.connected_time).format('MM/DD/yyyy hh:mm'),
                    volume: result.size.toFixed(0),
                    days_to_empty:parseFloat(result.days_to_empty).toFixed(2),
                    percent_remaining: (result.percent_remaining * 100).toFixed(2),
                    volume_poured: result.volume_poured ? (result.volume_poured/1000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0,
                    number_pours: result.number_pours,
                    temp_running_total: result.avg_temp.toFixed(2),
                    keg_id: result.keg_id,
                    last_pour_date:moment(result.get_last_pour_date.substring(2,21)).format('MM/DD/yyyy HH:mm'),
                    number_pints:(result.volume_poured/result.ml_per_pint).toFixed(2),
                    kr_id: result.kr_id,
                    logo: logo,
                    connected: result.connected,
                    line: result.line_number,
                };

                var _kegs = [...this.state.filteredKegs];
                var index = _kegs.indexOf(selectedKeg)
                if (index !== -1) {
                    _kegs.splice(index, 1);
                    this.setState({ ...this.state, filteredKegs: [updatedItem].concat(_kegs) });
                    var elem = document.getElementById('connected_kegs_container');
                    elem.childNodes[0].className = 'col-md-2 col-sm-6 bg-grey-500 monitor-active';
                    setTimeout(() => {
                        elem.childNodes[0].className =  'col-md-2 col-sm-6 bg-grey-500 monitor-default';
                    }, 30000);
                }
            }
        }

        this.eventSource.onerror = (e) => {
            console.log(e)
            //this.eventSource.close();
            //this.eventSource=new EventSource(`https://dev-events.draftco.ca/events?site_id=${this.props.site_id}`);
        }
    }

    componentWillUnmount() {
        if (!window.location.pathname.includes('/dashboard/')) {
            //this.props.clearStates();
        }
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                ...this.state,
                activeTab: tab
            });
        }
    }

    toggleAdvancedSearch = () => {
        let newState = {
            showAdvancedSearch: !this.state.showAdvancedSearch
        }
        if (!newState.showAdvancedSearch) {
            newState.filteredBeerList = this.state.beers;
        }
        this.setState({
            ...this.state,
            ...newState
        });
    }

    handleCheckboxChange = (e) => {
        let _filteredKegs = [];
        if (e.currentTarget.checked) {
            _filteredKegs = this.state.kegs.filter(s => s.connected);
        }
        else {
            _filteredKegs = this.state.kegs.filter(s => !s.connected && s.kr_out_date === null);
        }
        this.setState({
            ...this.state,
            connected: e.currentTarget.checked,
            filteredKegs: _filteredKegs
        })
    }

    setRowSelected = () => {
        //tblKegsInventory
        var elem = document.getElementById('tblKegsInventory');
        for (let i = 0; i < elem.parentNode.getElementsByTagName('tr').length; i++) {
            elem.parentNode.getElementsByTagName('tr')[i].className = '';
        }

        elem.className = 'selected';
    }

    render() {
        return (
            <React.Fragment>
            <h4>Connected Kegs</h4>
                <Row id="connected_kegs_container" className='row' style={{ textAlign: 'left', marginLeft: '0','overflow-x':'none','overflow-y':'auto','height':'550px' }} >
                    {this.state.showKegList ? this.state.filteredKegs.map(k => (
                        <Col className="col-md-2 col-sm-6 bg-grey-500 monitor-default" style={{ backgroundColor: '#ededed', padding: '0.5em', margin: 5 }}>
                            <div className="" style={{ fontSize: '0.6em', display: "inline-flex", textAlign: "left", justifyContent: "left" }}>
                                <img className="media" style={{ width: '10%' }} src={k.logo} alt=""></img>&nbsp;&nbsp;
                                <span>Line# {k.line}</span>
                                <span style={{marginLeft:'auto'}}>{k.last_pour_date}</span>
                            </div>
                            <div className="" style={{ fontSize: '0.8em' }}><b>{k.beerName}</b></div>
                            <Row style={{fontSize:'0.7em'}}>
                                <Col md="7" sm="6" lg="7" style={{textAlign:'left'}}>
                                    <span>% Remaining</span>
                                </Col>
                                <Col md="5" sm="6" lg="5" style={{textAlign:'right'}}>
                                    <span>{k.percent_remaining}</span>
                                </Col>
                            </Row>
                            <Row style={{fontSize:'0.7em'}}>
                                <Col md="7" sm="6" lg="7" style={{textAlign:'left'}}>
                                    <span>Volume Poured (L)</span>
                                </Col>
                                <Col md="5" sm="6" lg="5" style={{textAlign:'right'}}>
                                    <span>{k.volume_poured}</span>
                                </Col>
                            </Row>
                            <Row style={{fontSize:'0.7em'}}>
                                <Col md="7" sm="6" lg="7" style={{textAlign:'left'}}>
                                    <span>Avg Temp (C)</span>
                                </Col>
                                <Col md="5" sm="6" lg="5" style={{textAlign:'right'}}>
                                    <span>{k.temp_running_total}</span>
                                </Col>
                            </Row>
                            <Row style={{fontSize:'0.7em'}}>
                                <Col md="7" sm="6" lg="7" style={{textAlign:'left'}}>
                                    <span>#Pours</span>
                                </Col>
                                <Col md="5" sm="6" lg="5" style={{textAlign:'right'}}>
                                    <span>{k.number_pours}</span>
                                </Col>
                            </Row>
                            <Row style={{fontSize:'0.7em'}}>
                                <Col md="7" sm="6" lg="7" style={{textAlign:'left'}}>
                                    <span>#Pints</span>
                                </Col>
                                <Col md="5" sm="6" lg="5" style={{textAlign:'right'}}>
                                    <span>{k.number_pints}</span>
                                </Col>
                            </Row>
                            <Row style={{fontSize:'0.7em'}}>
                                <Col md="7" sm="6" lg="7" style={{textAlign:'left'}}>
                                    <span>Days to empty</span>
                                </Col>
                                <Col md="5" sm="6" lg="5" style={{textAlign:'right'}}>
                                    <span>{parseFloat(k.days_to_empty).toFixed(2)}</span>
                                </Col>
                            </Row>
                            <Row style={{fontSize:'0.7em'}}>
                                <Col md="7" sm="6" lg="7" style={{textAlign:'left'}}>
                                    <span>Size (L)</span>
                                </Col>
                                <Col md="5" sm="6" lg="5" style={{textAlign:'right'}}>
                                    <span>{k.volume}</span>
                                </Col>
                            </Row>
                            {/* <div style={{ fontSize: '0.7em' }}>Temp(℃): <b>{k.temp_running_total}</b></div>
                            <div style={{ fontSize: '0.6em' }} >Size(ml):{k.volume}&nbsp; % remaining:{k.percent_remaining} &nbsp; pours# {k.number_pours}{' '}</div> */}
                        </Col>
                    )) : <LoaderComponent />}
                </Row>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        currPage: state.monitor.currPage,
        site_id: state.userInfo.security.site_id,
        kegs: state.monitor.kegs,
        kegsHistory: state.monitor.kegsHistory,
        showKegList: state.monitor.showKegList,
        showKegHistoryList: state.monitor.showKegHistoryList,
        kegRooms:state.kegRooms.kegRooms
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrPage: (page) => dispatch(monitorActions.setCurrPage(page)),
        clearStates: () => dispatch(monitorActions.clearStates()),
        getKegs: (kr_id) => dispatch(monitorActions.getKegs(kr_id)),
        getKegsHistory: (site_id) => dispatch(monitorActions.getKegsHistory(site_id)),
        getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMonitor);
