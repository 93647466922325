

export const convertValueByMetric=(value,unit,param)=>{
  if(unit==="metric"){
    if(param==="vol"){
        // volume value should be in ml
        return (parseFloat(value))
    }
    else if(param==="c" || param==="std"){
        // temp in centigrade
        return value
    }
  }
  if(param==="vol"){
      // volume value should be in ml
      return (parseFloat(value)*0.033814)
  }
  else if(param==="c"){
      // temp in centigrade
      return ((parseFloat(value)*(9/5))+32)
  }
  else if(param==="std"){
    // temp in centigrade
    return (parseFloat(value)*(9/5))
}
}

export const convertUnitByMetric=(value,unit,param)=>{
    if(unit==="metric"){
        if(param==="vol"){
            return "L"
        }
        else if(param==="c"){
            return "C"
        }
    }
    else if(unit!=="metric"){
        if(param==="vol"){
            return "OU"
        }
        else if(param==="c"){
            return "F"
        }
    }
    
  }