import {INTERNAL_LINK_CLICK, SIDE_LINK_CLICK} from './actionTypes'

const initialState={
    links:[]  
};

const breadCrumReducer=(state=initialState,action)=>{
    switch(action.type){
        case SIDE_LINK_CLICK:
            return {
                links:[action.payload]
            }
        case INTERNAL_LINK_CLICK:
            const _links=state.links;
            if(_links.indexOf(action.payload)===-1){
                _links.push(action.payload)
            }
            return {
                links:_links
            }
        default:
            return state
    }
};

export default breadCrumReducer;