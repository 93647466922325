import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./line.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SELECTED_LINE):
            return setSelLine(state, action);
        case (actionTypes.SET_LINES):
            return setLines(state, action);
        case (actionTypes.SET_CURR_LINE_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        case (actionTypes.SET_LINE_CLEANING_STATUS):
            return setLineCleaningStatus(state,action);
        default:
            return state;
    }

};

const setLineCleaningStatus = (state, action) => {
    return updateObject(state, {
        lineCleaningStatus: action.data
    });
};


const setSelLine = (state, action) => {
    return updateObject(state, {
        selLine: action.data
    });
};

const setLines = (state, action) => {
    return updateObject(state, {
        lines: action.data,
        showList:true
    });
};

// set current page number of beers list page 
const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selLine: initialState.selLine,
        lines: initialState.lines,
        currPage: initialState.currPage
    });
};

export default reducer;