import * as Constants from '../../../constants/enums';
import * as actionTypes from "../../../constants/actionTypes";
import axios from 'axios';
import store from '../../../redux/store';


export const getCountries = () => {
    const state = store.getState();
    return async (dispatch) => {
        if (state.enums.countries.length > 0) {
            return;
        }
        const countries = await fetchEnums(Constants.ENUMS.COUNTRY);
        dispatch(handleActionDispatch(actionTypes.SET_COUNTRIES, countries));
    }
}

export const getBeerTypes = () => {
    const state = store.getState();
    return async (dispatch) => {
        if (state.enums.beerTypes.length > 0) {
            return;
        }
        const beerTypes = await fetchEnums(Constants.ENUMS.BEER_TYPE);
        dispatch(handleActionDispatch(actionTypes.SET_BEER_TYPES, beerTypes));
    }
}

export const getKegCouplers = () => {
    const state = store.getState();
    return async (dispatch) => {
        if (state.enums.kegCouplers.length > 0) {
            return;
        }
        const kegCouplers = await fetchEnums(Constants.ENUMS.KEG_COUPLER);
        dispatch(handleActionDispatch(actionTypes.SET_KEG_COUPLERS, kegCouplers));
    }
}

export const getGenders = () => {
    const state = store.getState();
    return async (dispatch) => {
        if (state.enums.genders.length > 0) {
            return;
        }
        const genders = [{label:'Male',value:'Male'},{label:'Female',value:'Female'}];
        dispatch(handleActionDispatch(actionTypes.SET_GENDER, genders));
    }
}


export const getBeerCategories = () => {
    const state = store.getState();
    return async (dispatch) => {
        if (state.enums.beerCategories.length > 0) {
            return;
        }
        const beerCategories = await fetchEnums(Constants.ENUMS.BEER_CATEGORY);
        dispatch(handleActionDispatch(actionTypes.SET_BEER_CATEGORIES, beerCategories));
    }
}


const fetchEnums = async (type) => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/enums`, { params: { type: type } });
    if (response.data !== undefined) {
        const data = response.data.map((item) => {
            return {
                value: item.name,
                label: item.name
            }
        });
        return data;
    }
    return [];
};

export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}