import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
//import moment from "moment";
//import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selTower: {
        draft_tower_id:0,
        tower_name:'',
        number_taps:0,
        site_id:0,
        site_name:''
    },
    availableLines:[],
    towers: [],
    currPage: 1,
    towerBeers:[]
};


export const getTowers = (site_id) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.towers.towers.length >0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={site_id:site_id};       
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/towers`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    let _towers = [];
                    response.data.forEach((item) => {
                        _towers.push({
                            draft_tower_id: item.draft_tower_id,
                            tower_name: item.tower_name,
                            number_taps: item.number_taps,
                            site_id: item.site_id,
                            site_name: item.site_name,
                       })
                    });
                    _towers = _towers.sort((a, b) => a.tower_name.localeCompare(b.tower_name, 'en', { numeric: true }));
                    dispatch(handleActionDispatch(actionTypes.SET_TOWERS, _towers));
                    resolve(_towers);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const getAvailableLines = (site_id,forceReload) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.towers.availableLines.length >0 && !forceReload) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={site_id:site_id};       
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/line/findAvaiableLines`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    let towerLinesData = [];
                    response.data.forEach((item) => {
                        towerLinesData.push({
                            value: item.line_id,
                            label: `Line ${item.local_line_number} - Keg ${item.keg_id} - ${item.beer_name}`,
                        });
                    });
                    towerLinesData = towerLinesData.sort((a, b) => a.label.localeCompare(b.label));
                    dispatch(handleActionDispatch(actionTypes.SET_AVAILABLE_LINES, towerLinesData));
                    resolve(towerLinesData);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const getTowerBeers = (forceReload) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.towers.towerBeers.length >0 && !forceReload) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={draft_tower_id:state.towers.selTower.draft_tower_id};       
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/tower_beer`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    let _towerBeers = [];
                    let _availableLines=state.towers.availableLines;
                    response.data.forEach((item) => {
                        var selectedLine = _availableLines.find(a => a.value === item.line_id);
                        _towerBeers.push({
                            draft_tower_beers_id: item.draft_tower_beers_id,
                            draft_tower_id: item.draft_tower_id,
                            line_id: item.line_id,
                            beer_name: item.line_id != null ? (`Line ${item.tb_line.local_line_number} - Keg ${item.tb_line.keg.keg_id} - ${item.tb_line.keg.db_keg.name}`) : "",
                            tap_number: item.tap_number,
                            selectedLine: selectedLine ? selectedLine : ""
                        });
                        if (_availableLines.indexOf(selectedLine) !== -1) {
                            _availableLines.splice(selectedLine, 1);
                        }
                    });

                    for (let i = 1; i <= state.towers.selTower.number_taps; i++) {
                        if (_towerBeers.find(a => a.tap_number === i)) {
    
                        } else {
                            _towerBeers.push({
                                draft_tower_beers_id: 0,
                                tap_number: i,
                                draft_tower_id: state.towers.selTower.draft_tower_id,
                                beer_name: ""
                            });
                        }
                    }
                   
                    _towerBeers = _towerBeers.sort(function (a, b) { return a.tap_number - b.tap_number; });
                    dispatch(handleActionDispatch(actionTypes.SET_TOWER_BEERS, _towerBeers));
                    resolve(_towerBeers);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_TOWER_PAGE, page));
    }
}

export const setSelTower = (tower) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SELECTED_TOWER, tower));
    }
}

export const clearSelTower = () => {
    return setSelTower(initialState.selTower);
}

export const clearTowerBeers = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_TOWER_BEERS, []));
    }
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}