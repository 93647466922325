import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import * as chartActions from '../charts/store/chart-actions';
import ReactApexChart from 'react-apexcharts';

var DatePicker = require("reactstrap-date-picker");
//const pdfGenerator=require('../../utility/pdfGenerator')

class AlarmInstances extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().add('days',-1).format('YYYY-MM-DD')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            expanded:[],
            triggers:[],
            activeTab:"1",
            loading:false,
            errorMessage:'',
            chartData:{
              series:[],
              options:{}
            }
        };
    }
    
    async componentDidMount() {
        await this.props.getKegRooms(this.props.site_id);
        if(this.state.kegRooms.length>0){
          this.getChartData();
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
          kegRooms:props.kegRooms,
          selKegRoom:props.kegRooms.length>0?props.kegRooms[0]:null
        }
    }

    componentDidUpdate(prevProps,prevState){
      if(this.state.kegRooms.length!==prevProps.kegRooms.length){
        this.getChartData();
      }
    }

    getChartData=()=>{
        this.setState({loading:true});
        let newState=this;
        var params={
            kr_id:this.state.selKegRoom.value,
            from_date:moment(this.state.from_date).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date).format("YYYY-MM-DD")
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/alarm/instances`,{params:params})
        .then(function(response){
            if(response.data!==undefined){
                var _triggers=[],counter=1;
                response.data.forEach((item)=>{
                    _triggers.push({
                        instance_id:counter,
                        view_display_name:item.view_display_name,
                        procedure_display_name:item.procedure_display_name,
                        triggered_time:item.local_triggered_time,
                        triggered_time_display:moment(item.local_triggered_time).format("MM/DD/YYYY hh:mm A"),
                        y_variable:item.y_variable,
                        y_value:item.y_value.toFixed(2),
                        y_avg:item.y_avg.toFixed(2),
                        y_stddev:item.y_stddev.toFixed(2),
                        id:item.id,
                        alarm_severity:item.alarm_severity,
                        upper_control_limit:item.upper_control_limit.toFixed(2),
                        lower_control_limit:item.lower_control_limit.toFixed(2),
                        view_name:item.view_name
                    })
                    counter++;
                });
                newState.setState({
                    loading:false,
                    triggers:_triggers,
                    expanded:[]
                });
            }
        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }
    
    showAlarmHistoryGraph(row){
      var xAaxis=[];
      var alarmHistory={name:"Temperature",data:[]};
      var avgLine={name:"Average",data:[]};
      var uclLine={name:"UCL",data:[]};
      var lclLine={name:"LCL",data:[]};
      var params={
        kr_id:this.state.selKegRoom.value,
        from_date:moment(row.triggered_time).add('days',-1).format("YYYY-MM-DD"),
        to_date:moment(row.triggered_time).add('days',1).format("YYYY-MM-DD"),
        view_name:row.view_name
      };
      var context=this;
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/alarm/AlarmData`,{params:params})
      .then(function(response){
        console.log(response);
        response.data.forEach((item)=>{
          xAaxis.push(new Date(item.local_time));
          alarmHistory.data.push({
            x:new Date(item.local_time),
            y:item.temperature_c.toFixed(2),
            fillColor:row.id===item.id?"red":"blue",
            moreInfo:{
              id:item.id,
              temperature_c:item.temperature_c.toFixed(2),
              avg:row.y_avg,
              ucl:row.upper_control_limit,
              lcl:row.lower_control_limit
            }
          });
          avgLine.data.push({
            x:new Date(item.local_time),
            y:row.y_avg,
            
            moreInfo:{
              id:item.id,
              temperature_c:item.temperature_c.toFixed(2),
              avg:row.y_avg,
              ucl:row.upper_control_limit,
              lcl:row.lower_control_limit
            }
          });
          uclLine.data.push({
            x:new Date(item.local_time),
            y:row.upper_control_limit,
            // moreInfo:{
            //   id:item.id,
            //   temperature_c:item.temperature_c.toFixed(2),
            //   avg:row.y_avg,
            //   ucl:row.upper_control_limit,
            //   lcl:row.lower_control_limit
            // }
          });
          lclLine.data.push({
            x:new Date(item.local_time),
            y:row.lower_control_limit,
            // moreInfo:{
            //   id:item.id,
            //   temperature_c:item.temperature_c.toFixed(2),
            //   avg:row.y_avg,
            //   ucl:row.upper_control_limit,
            //   lcl:row.lower_control_limit
            // }
          });
        })
         var chartData={
            series:[alarmHistory,avgLine,uclLine,lclLine],
            options: {
                chart: {
                  height: 150,
                  type: 'line',
                  zoom: {
                    enabled: false
                  },
                  toolbar: {
                    show: false
                  }
                },
                dataLabels: {
                  enabled: false
                },
                markers: {
                  size:[4,0,0,0]
                },
                stroke: {
                  curve: 'straight',
                  width: [1,1,1,1],
                  //dashArray: [0, 0, 0,4,4]
                },
                title: {
                  text: `Alarm History`,
                  align: 'left'
                },
                tooltip:{
                  custom: function({series, seriesIndex, dataPointIndex, w}) {
                    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                          if(data.moreInfo===undefined) return ""
                          var alarm_info=`<div class="apexcharts-tooltip-y-group">
                            <span class="apexcharts-tooltip-text-y-value">Id:</span>&nbsp;
                            <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.id}</span>
                          </div>
                          <div class="apexcharts-tooltip-y-group">
                              <span class="apexcharts-tooltip-text-y-value">Temperature:</span>&nbsp;
                              <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.temperature_c}</span>
                            </div>`
                            return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">Alarm data - ${data.y} (${moment(data.x).format("ll")})</div>
                            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            ${alarm_info}                                
                           </div></div>`
                    }
                  },
                legend: {
                    show: false,
                },
                grid: {
                  row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                  },
                },
                xaxis: {
                  categories: xAaxis,
                  type: "datetime",
                  //min:volumeMin,
                  tickAmount:10,
                  labels: {
                    datetimeUTC: false,
                    rotateAlways: false,
                    rotate: 0,
                    tickPlacement: 'between',
                    formatter: function(val, timestamp) {
                        return moment(val).format("DD MMM hh:mm");; // formats to hours:minutes
                    }  
                  }
                },
              }
        }
       context.setState({...context.state,chartData:chartData, expanded:[row.instance_id]});
      })
    }

    onAlarmRender=()=>{
      if(this.state.expanded.length>0){
        return (
          <>
              <div style={{margin:'10px 0 0 0'}}>
                  <ReactApexChart options={this.state.chartData.options} series={this.state.chartData.series} height="400" />
              </div>
          </>)
      }
    }

    onAlarmExpand=(row,isExpand,rowIndex,e)=>{
      if (isExpand && this.state.chartData.series.length===0 ) {
          this.showAlarmHistoryGraph(row);
      }
      else if(!isExpand){
          this.setState({...this.state,chartData:{series:[],options:{}}, expanded:[]});
      }
   }

    render() {
        const expand = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            expanded:this.state.expanded,
            onExpand:this.onAlarmExpand,
            renderer: this.onAlarmRender,
            // renderer: (row) =>{
            //     return this.showAlarmHistoryGraph(row);
            // },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
              if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
            }
          };
          
          const rowClasses = (row, rowIndex) => {
            let classes = null;
            if (row.alarm_severity ==="HIGH") {
              classes = 'danger';
            }
            return classes;
          };

        const columns = [
            {
              dataField: "instance_id",
              text: "instance id",
              hidden: true,
              headerAlign:'center'
            },
            {
              dataField: "id",
              text: "Id",
              hidden: false,
              headerAlign:'center'
            },
            {
              dataField: "view_display_name",
              text: "data",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "procedure_display_name",
              text: "Condition",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "triggered_time_display",
              text: "Triggered Time",
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "y_value",
              text: "Value",
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "y_avg",
              text: "avg",
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "y_stddev",
              text: "stddev",
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "upper_control_limit",
              text: "UCL",
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "lower_control_limit",
              text: "LCL",
              sort: true,
              align:'right',
              headerAlign:'center'
            }
          ];
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Alarm Triggers</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                           
                        </Row>
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('1'); }}            >
                                View Data
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.kegData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.kegData,this.state.title,this.props.companyLogo,this.props.userLogo,"Alarm-trigger-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_alarm_instances"
                                filename="Alarm Instances"
                                sheet="Triggers"
                                buttonText="Download Excel"/>
                        </div>
                            <BootstrapTable id="tbl_alarm_instances"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="instance_id"
                                data={this.state.triggers}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}
                                expandRow={expand}
                                rowClasses={ rowClasses }>
                            </BootstrapTable>
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        kegRooms:state.charts.kegRooms,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKegRooms: (site_id) => dispatch(chartActions.getKegRooms(site_id))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AlarmInstances);
