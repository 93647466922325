import {GetComponent} from '../printComponent';
import ReactDOMServer from 'react-dom/server';

export const printReport=(columns,data,id)=>{
    var content = ReactDOMServer.renderToStaticMarkup(GetComponent(columns,data));
    var iframeElement = document.getElementById(id).contentWindow;
    iframeElement.document.open();
    iframeElement.document.write(content);
    iframeElement.document.close();
    iframeElement.focus();
    iframeElement.print();
}