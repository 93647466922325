import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as beersActions from '../beers/store/beers.actions';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import WaterfallChart from "../charts/waterfall";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import {generatePdfForMultipleTables} from '../../utility/pdfGenerator'
import { ResponsiveBar } from "@nivo/bar";
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import {convertValueByMetric} from '../../utility/units-conversion';
import {sortByDataType} from '../../utility/common';

var DatePicker = require("reactstrap-date-picker");


class PourDetailChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            waterFallData:[],
            pourData:[],
            beers:[],
            histData:[],
            pourDetailHistData:[],
            enablePourChartTab:false,
            kegData:[],
            pdfData:[],
            selBeer:null,
            data:[],
            chartTitle:'',
            showChart:false,
            activeTab:"1",
            errorMessage:'',
            loading:false,
        };
    }

    componentDidMount() {
        this.fetchKegRooms();
    }

    static getDerivedStateFromProps(props,state){
        let _beers=[];
        if(props.beers_kr.length>0){
            props.beers_kr.forEach((item)=>{
                _beers.push({value:item.id,label:item.name});
            })
        }
        if (_beers.length !== 0) {
            return {
              ...state,
              beers: _beers,
              selBeer:state.selBeer===null?_beers[0]:state.selBeer
            }
          }
          return null;
    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]},()=>{this.props.getBeersByKegRoom(kegRooms[0].value)});
            }
        });
    }


    generatePdf=(columns)=>{
        // define the columns we want and their titles
        const tableColumn = ["Beer","Duration (s)","Time","Volume Poured (ML)","Temp (C)","Ambient (C)"];
        const tableColumnStyles=[{halign:'left'},{halign:'right'},{halign:'right'},{halign:'right'},{halign:'right'},{halign:'right'}];
        const tableSummaryColumn = ["Beer","Avg Duration (s)","Total Volume (ML)","Avg Volume (ML)","Count","Avg Temp (C)","Avg Amb (C)"];
        const tableSummaryColumnStyles=[{halign:'left'},{halign:'right'},{halign:'right'},{halign:'right'},{halign:'right'},{halign:'right'},{halign:'right'}];
        var multipleTables=[];
        var duration=0,summary_total_volume=0,count=0,temp=0,ambient=0;

        this.state.pdfData.details.forEach((item)=>{
            var tableDetail={title:item.title,rows:[]}
            var total_volume=0.0;
            item.data.forEach((item1)=>{
                total_volume+=parseFloat(item1.pour_volume);
                temp+=parseFloat(item1.temp);
                duration+=item1.duration;
                ambient+=parseFloat(item1.ambient);
                tableDetail.rows.push([item1.name,item1.duration,item1.end_time,item1.pour_volume,item1.temp,item1.ambient]);
            })
            summary_total_volume+=total_volume;
            count+=item.data.length;
            tableDetail.rows.push(["Total Volume (ML)",total_volume.toFixed(1)]);
            multipleTables.push(tableDetail);
        })
        var summaryRow=[this.state.selBeer.label,(duration/count).toFixed(1),summary_total_volume.toFixed(1),(summary_total_volume/count).toFixed(1),count,(temp/count).toFixed(1),(ambient/count).toFixed(1)];
        var title=` Summary Report From ${this.state.from_date.substring(0,10)} To ${this.state.to_date.substring(0,10)}`;
        generatePdfForMultipleTables(tableColumn,multipleTables,tableSummaryColumn,[summaryRow],title,this.props.companyLogo,this.props.userLogo,
            "Detail-Pour-Report.pdf",tableColumnStyles,tableSummaryColumnStyles);
    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    getChartData=()=>{
        let beer_name=this.state.selBeer.label.replace(new RegExp("'", 'g'), "''");
        const units=this.props.units;
        this.setState({loading:true,showChart:false,showHcChart:false});
        let newState=this;
        var params={
            from_date:moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD"),
            kr_id:this.state.selKegRoom.value,
            beer_name:beer_name
        }
        var title=`Detail Pour for ${this.state.selKegRoom.label} ${this.state.selBeer.label} `;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/DetailPourReport`,{params:params})
        .then(function(response){
            if(response.data!==undefined && response.data.length>0){
                response.data.map(function(item){
                    var datePart=item.end_date.substring(0,10)+ " 00:00:00";
                    var d=moment(datePart);
                    //var d1=moment(item.local_end_period);
                    item["end_date"]=moment(d._d).format("YYYY-MM-DD");
                    return item
                }) 
                response.data.sort((a, b) => new Date(a.end_date) - new Date(b.end_date));
                var minDate=Math.min(...response.data.map(s=>moment(s.end_date.substring(0,10))));
                var maxDate=Math.max(...response.data.map(s=>moment(s.end_date.substring(0,10))));
                var diffDays=moment(maxDate).diff(moment(minDate),'days');
                var days_bin=parseInt(diffDays/14);
                var datesBin=[];
                while(moment(minDate)<=moment(maxDate)){
                    var point={
                        x:moment(minDate).format("YYYY-MM-DD"),
                        fromDate:moment(minDate).format("YYYY-MM-DD"),
                        toDate:moment(minDate).add(days_bin,'days')>moment(maxDate)?moment(maxDate).format("YYYY-MM-DD"):moment(minDate).add(days_bin,'days').format("YYYY-MM-DD"),
                        y:0
                    }
                    datesBin.push(point);
                    minDate=moment(minDate).add(days_bin+1,'days');
                }
                var waterFallData=[];
                datesBin.forEach((item)=>{
                    var fromDate=moment(item.fromDate);
                    var toDate=moment(item.toDate);
                    var subData=response.data.filter(s=>moment(s.end_date.substring(0,10))>=fromDate && moment(s.end_date.substring(0,10))<=toDate)
                    if(subData.length!==0){
                        var vol_poured=subData.map(o => parseFloat(o.pour_volume)).reduce((a, c) => { return a + c });
                        item.y=parseFloat(convertValueByMetric(vol_poured,units,"vol")/(units==="metric"?1000:1));
                        waterFallData.push(item);
                    }
                })
                

                var pourData=[],_pdfData={summary:[],details:[]};
                var groupByEndDate=  response.data.reduce(function (r, a) {
                    var end_date=moment(a.end_date.substring(0,10)).format("YYYY-MM-DD");
                    r[end_date] = r[end_date] || [];
                    r[end_date].push(a);
                    return r;
                }, Object.create(null));
                //var last = Object.keys(groupByEndDate)[Object.keys(groupByEndDate).length-1];
                for (var key in groupByEndDate){
                    var subData=groupByEndDate[key];
                    var end_date=moment(key).format("YYYY-MM-DD");
                    var detail={title:`Pour Detail Report for Date: ${end_date}`,data:[]};
                    // eslint-disable-next-line no-loop-func
                    subData.forEach((item)=>{
                        pourData.push({
                            name:params.beer_name,
                            end_date:end_date,
                            end_time:item.end_time,
                            pour_volume:convertValueByMetric(item.pour_volume,units,"vol").toFixed(1),
                            temp:convertValueByMetric(item.temperature_c,units,"c").toFixed(1),
                            ambient:item.ambient_c===null?0:item.ambient_c.toFixed(1),
                            duration:item.duration_sec,
                            pour_id:item.id
                        });
                        detail.data.push({
                            name:params.beer_name,
                            end_date:end_date,
                            end_time:item.end_time,
                            pour_volume:(convertValueByMetric(item.pour_volume,units,"vol")).toFixed(1),
                            temp:convertValueByMetric(item.temperature_c,units,"c").toFixed(1),
                            ambient:item.ambient_c===null?0:item.ambient_c.toFixed(1),
                            duration:item.duration_sec
                        });
                    })
                    _pdfData.details.push(detail);
                    //let detailSummary = detail.data.map(o => o.pour_volume).reduce((a, c) => { return a + c });
                    //waterFallData.push({x:connected_date,y:parseFloat((sum/1000).toFixed(2))});
                  }
                  var totalPour_Volume=waterFallData.map(o => parseFloat(o.y)).reduce((a, c) => { return a + c });
                  waterFallData.push({x:'Total Volume',y:totalPour_Volume,"showFull":true})
                  var histData=[];
                  var maxPoint=Math.max(...pourData.map(s=>parseFloat(s.pour_volume)));
                  //var minPoint=Math.min(...pourData.map(s=>s.pour_volume));
                  var counter1=0,increment=Math.round(maxPoint/(15));
                  increment=increment+((units==="metric"?100:(100*0.033814))-(increment%100))
                  maxPoint+=increment;
                  while(counter1<maxPoint){
                      // eslint-disable-next-line no-loop-func
                      var hisSubData=pourData.filter(s=>parseFloat(s.pour_volume)>=(counter1-increment) && parseFloat(s.pour_volume)<=counter1);
                      histData.push({id:counter1.toFixed(2),value:hisSubData.length})
                      counter1+=increment;
                  }
                  console.log(pourData.map(s=>s.pour_volume));
                  // data by kegid
                  var data=response.data;
                  var groupByKeg=  response.data.reduce(function (r, a) {
                    r[a.keg_id] = r[a.keg_id] || [];
                    r[a.keg_id].push(a);
                    return r;
                }, Object.create(null));
                  var _kegData=[],keg_counter=1;
                  for(var kegKey in groupByKeg){
                      var _subKegs=groupByKeg[kegKey];
                      var keg_volume = _subKegs.map(o => o.pour_volume).reduce((a, c) => { return a + c });
                      var keg_duration = _subKegs.map(o => o.duration_sec).reduce((a, c) => { return a + c });
                      var keg_ambient = _subKegs.map(o => o.ambient_c).reduce((a, c) => { return a + c });
                      var keg_temp = _subKegs.map(o => convertValueByMetric(o.temperature_c,units,"c")).reduce((a, c) => { return a + c });
                      _kegData.push({
                          keg_id:kegKey,
                          id:keg_counter,
                          beer_name:params.beer_name,
                          pour_volume:parseFloat(convertValueByMetric(keg_volume,units,"vol")).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                          duration:(keg_duration/_subKegs.length).toFixed(2),
                          volume_avg:parseFloat(convertValueByMetric(keg_volume,units,"vol")/(_subKegs.length)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                          ambient:parseFloat(convertValueByMetric(keg_ambient,units,"c")/_subKegs.length).toFixed(2),
                          temperature:parseFloat(keg_temp/_subKegs.length).toFixed(2),
                          number_pints:parseFloat(keg_volume/_subKegs[0].mlperpint).toFixed(2)
                      })
                      keg_counter++;
                  }
                  newState.setState(
                      {...newState.state,
                        waterFallData:waterFallData,
                        pourData:pourData,
                        histData:histData,
                        pdfData:_pdfData,
                        kegData:_kegData,
                        showChart:true,
                        data:data,
                        chartTitle:title,
                        errorMessage:"",
                        loading:false
                    })
            }
            else{
                newState.setState(
                    {...newState.state,
                      showChart:true,
                      loading:false,
                      errorMessage:"No data found"
                  })
            }
            
        })
    }

    pourHistChart=(data)=>{
        var histData=[];
        var maxPoint=Math.max(...data.map(s=>parseFloat(s.pour_volume)));
        var counter1=0,increment=Math.round(maxPoint/15);
        increment=increment+((this.props.units==="metric"?100:(100*0.033814))-(increment%100))
        maxPoint+=increment;
        while(counter1<maxPoint){
            // eslint-disable-next-line no-loop-func
            var hisSubData=data.filter(s=>s.pour_volume>=(counter1-increment) && s.pour_volume<=counter1);
            histData.push({id:counter1,value:hisSubData.length})
            counter1+=increment;
        }
        this.setState({
            ...this.state,
            activeTab:"3",
            pourDetailHistData:histData
        })
    }

    showPourDetails=(row)=>{
        const columns = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "duration",
              text: "Duration (s)",
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "pour_volume",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
            },
            {
              dataField: "end_time",
              text: "Time",
              sort: true,
              align:'center',
              headerAlign:'center'
            },
            {
              dataField: "temperature",
              text: this.props.units==="metric"?"Temp (C)":"Temp (F)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Ambient (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];
        var pourByKeg=this.state.data.filter(p=>p.beer_name===row.beer_name && moment(p.end_date.substring(0,10)).format("YYYY-MM-DD")===row.end_date);
        var pourData=[],counter=1;
        var totalDuration=0,totalVolume=0,total_temp=0;
        pourByKeg.forEach((item)=>{
            pourData.push({
                name:row.beer_name,
                pour_volume:convertValueByMetric(item.pour_volume,this.props.units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                number_pints:(item.pour_volume/item.mlperpint).toFixed(2),
                pour_id:counter,
                keg_id:item.keg_id,
                duration:item.duration_sec,
                temperature:convertValueByMetric(item.temperature_c,this.props.units,"c").toFixed(2),
                ambient:item.ambient_c===null?0:item.ambient_c.toFixed(2),
                end_date:moment(item.end_date.substring(0,10)).format("YYYY-MM-DD"),
                end_time:item.end_time
            })
            totalVolume+=item.pour_volume;
            totalDuration+=item.duration_sec;
            total_temp+=item.temperature_c;
            counter++;
        })
        if(pourData.length>0){
            pourData.push({
                end_date:0,
                name:"Summary",
                pour_volume:(convertValueByMetric(totalVolume,this.props.units,"vol")).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                duration:(totalDuration/pourByKeg.length).toFixed(2),
                ambient:(pourData.map(o => parseFloat(o.ambient)).reduce((a, c) => { return a + c })/pourData.length).toFixed(2),
                temperature:(convertValueByMetric(total_temp,this.props.units,"c")/pourData.length).toFixed(2)
            })
        }
        var title=`Pour Detail Report for Date: ${row.end_date}`;
        if(pourData.length!==0){
            return(
                    <>
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>this.pourHistChart(pourData)} size="sm">Chart</Button>
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,title,this.props.companyLogo,this.props.userLogo,"Pour-Detail-Report.pdf")} 
                            className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_PourData"
                                filename="Pour Details By Beer"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                        <BootstrapTable id="tbl_PourData"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="pour_id"
                                data={pourData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }


    showPourDetailsByDate=(row)=>{
        const columns =  [
            
            {
              dataField: "beer_name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
                dataField: "end_date",
                text: "End Date",
                hidden: false,
            },
            {
              dataField: "duration",
              text: "Avg Duration (s)",
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "pour_volume",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            {
                dataField: "volume_avg",
                text: this.props.units==="metric"?"Avg Volume (ml)":"Avg Volume (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                sort: true,
                align: 'right',
                headerAlign:'center'
            },
            {
              dataField: "temperature",
              text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Avg Ambient (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];
          const expandDateRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return this.showPourDetails(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
            this.setState({...this.state,enablePourChartTab:expanded})
              if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
            }
          };
          var pourByKeg=this.state.data.filter(p=>p.keg_id===parseInt(row.keg_id));
          
          var groupByDate=  pourByKeg.reduce(function (r, a) {
              var end_date=moment(a.end_date.substring(0,10)).format("YYYY-MM-DD")
              r[end_date] = r[end_date] || [];
              r[end_date].push(a);
              return r;
          }, Object.create(null));
          var pourData=[];
          var totalTemp=0,totalDuration=0,totalVolume=0;
          var totalAmbient=0;
        for(var key in groupByDate){
            var _subKegs=groupByDate[key];
            var keg_volume = _subKegs.map(o => o.pour_volume).reduce((a, c) => { return a + c });
            var keg_duration = _subKegs.map(o => o.duration_sec).reduce((a, c) => { return a + c });
            var keg_ambient = _subKegs.map(o => o.ambient_c).reduce((a, c) => { return a + c });
            var keg_temp = _subKegs.map(o => o.temperature_c).reduce((a, c) => { return a + c });
            pourData.push({
                end_date:key,
                beer_name:row.beer_name,
                pour_volume:parseFloat(convertValueByMetric(keg_volume,this.props.units,"vol")).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                duration:(keg_duration/_subKegs.length).toFixed(2),
                ambient:parseFloat(keg_ambient/_subKegs.length).toFixed(2),
                temperature:parseFloat(convertValueByMetric(keg_temp,this.props.units,"c")/_subKegs.length).toFixed(2),
                number_pints:(keg_volume/_subKegs[0].mlperpint).toFixed(2),
                volume_avg:parseFloat(convertValueByMetric(keg_volume,this.props.units,"vol")/(_subKegs.length)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            })
            totalTemp+=keg_temp;
            totalAmbient+=keg_ambient;
            totalDuration+=keg_duration;
            totalVolume+=keg_volume;
        }
        if(pourData.length>0){
            pourData.push({
                end_date:"",
                beer_name:"Summary",
                pour_volume:(convertValueByMetric(totalVolume,this.props.units,"vol")).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                duration:(totalDuration/pourByKeg.length).toFixed(2),
                ambient:(totalAmbient/pourByKeg.length).toFixed(2),
                temperature:(convertValueByMetric(totalTemp,this.props.units,"c")/pourByKeg.length).toFixed(2)
            })
        }
        
        var title=`Detail Pour for ${this.state.selKegRoom.label} ${this.state.selBeer.label} `;
        if(pourData.length!==0){
            return(
                    <>
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,pourData,title,this.props.companyLogo,this.props.userLogo,"Pour-Detail-Report.pdf")} 
                            className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_PourData_date"
                                filename="Pour Details By Beer"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                        <BootstrapTable id="tbl_PourData_date"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="end_date"
                                data={pourData}
                                expandRow={expandDateRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    expandBeerColumn=(expanded)=>{
        this.setState({...this.state,enablePourChartTab:expanded})
        if (expanded) {
            return (
              <i className="fa fa-caret-down fa-md"></i>
            );
          }
          return (
            <i className="fa fa-caret-right fa-md"></i>
          );
    }

    render() {
        const {showPourDetailsByDate}=this;
        const expandKegRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return showPourDetailsByDate(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
              return this.expandBeerColumn(expanded)
            }
          };

        const columns = [
            {
              dataField: "id",
              text: "Pour Id",
              hidden: true,
            },
            {
              dataField: "beer_name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "duration",
              text: "Avg Duration (s)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "pour_volume",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            {
                dataField: "volume_avg",
                text: this.props.units==="metric"?"Avg Volume (ml)":"Avg Volume (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align: 'right',
                headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                sort: true,
                align: 'right',
                headerAlign:'center'
            },
            {
              dataField: "temperature",
              text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Avg Ambient (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Pour Detail By Beer</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Beer</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selBeer}
                                    options={this.state.beers}
                                    onChange={(e) => this.handleChangeSelection(e, 'selBeer')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                {/* <Button  size="sm" onClick={this.getChartData}>Chart</Button> */}
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Report"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Row>
                        <Col lg="12" sm="12" md="12">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                            {this.state.enablePourChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}            >
                                Pour Detail Histogram
                                </NavLink>
                            </NavItem>:""
                            }
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                        { this.state.showChart?
                            <div >
                                <WaterfallChart initialValue={0} data={this.state.waterFallData} title={this.state.chartTitle} />
                                <div style={{width:'100%',height:'400px'}}>
                                    <ResponsiveBar 
                                    borderRadius={1}
                                    data={this.state.histData}
                                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                    //padding={{left:50,right:50,top:50,bottom:50}}
                                    // valueScale={{"type":"linear"}}
                                    xScale={{
                                        type: "linear",
                                        min: 0,
                                        max: 100
                                    }}
                                    yScale={{
                                        type:"linear",
                                        max:'auto'
                                    }}
                                    colorBy="id"
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: this.props.units==="metric"?'Pour Volume (ML)':'Pour Volume (OU)',
                                        legendPosition: 'middle',
                                        legendOffset: 32
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Count',
                                        legendPosition: 'middle',
                                        legendOffset: -30,
                                        tickValues:10
                                    }}
                                    enableGridX={true}
                                    enableGridY={true}
                                    gridXValues={20}

                                />
                            </div>
                            </div>
                            :""}
                            <br />
                                

                        </TabPane>
                        <TabPane tabId="2">
                        {/* <Button onClick={()=>this.printPourVolume(columns)} size="sm">Print</Button>
                        <button onClick={this.generatePdf} className="btn btn-secondary btn-sm">Download Pdf</button> */}
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.kegData,"ifmcontentstoprint")} size="sm">Print</Button>
                            {/* <button onClick={()=>DownloadPdf(columns,this.state.kegData,this.state.title,this.props.companyLogo,this.props.userLogo,"Keg-Inventory-Report.pdf")} 
                            className="btn btn-secondary btn-sm">Download Pdf</button> */}
                            <button onClick={this.generatePdf} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_Beers"
                                filename="Pour Detail"
                                sheet="Beer Data"
                                buttonText="Download Excel"/>
                        </div>
                            <BootstrapTable id="tbl_Beers"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="id"
                                data={this.state.kegData}
                                expandRow={expandKegRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                        <TabPane tabId="3">
                            <div style={{width:'100%',height:'400px'}}>
                                        <ResponsiveBar 
                                        borderRadius={1}
                                        data={this.state.pourDetailHistData}
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        //padding={{left:50,right:50,top:50,bottom:50}}
                                        // valueScale={{"type":"linear"}}
                                        xScale={{
                                            type: "linear",
                                            min: 0,
                                            max: 100
                                        }}
                                        yScale={{
                                            type:"linear",
                                            max:'auto'
                                        }}
                                        colorBy="id"
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Pour Volume (ML)',
                                            legendPosition: 'middle',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Count',
                                            legendPosition: 'middle',
                                            legendOffset: -30,
                                            tickValues:10
                                        }}
                                        enableGridX={true}
                                        enableGridY={true}
                                        gridXValues={20}

                                    />
                                </div>
                        </TabPane>
                    </TabContent>
                           
                        </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        beers_kr: state.beers.beers_kr,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBeersByKegRoom: (kr_id) => dispatch(beersActions.getBeersByKegRoom(kr_id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PourDetailChart);
