import React from "react";
import Select from "react-select";
import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import { successToaster } from '../toaster/toaster';
import TowerLineBeerList from "../towerlinebeers/towerLineBeerList";
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";

class TowerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tower: {
        draft_tower_id: parseInt(props.match.params.id),
        site_id: props.match.params.sid,
        tower_name: '',
        number_taps: 0,
        selectedSite: null,
      },
      towerBeers: [], // [{ tap: 0, selectedLine: { value: 0, label: "line0" }, draft_beer_id: 0, draft_beer_name: "corona-0", "keg_id": 0, keg_name: "keg-0" }],
      sites: [],
      towerLines: [],
      beers: [],
      kegs: [],
      lines: [],
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  componentDidMount() {
    this.fetchsites();
  }

  componentDidUpdate(prevProps,prevState){
    if(this.props.site_id!==prevProps.site_id && this.state.sites.length>0){
      this.setState({...this.state,tower:{...this.state.tower,selectedSite:this.state.sites.filter(s=>s.value===this.props.site_id)}});
    }
   
  }

  fetchsites = () => {
    // Load the list
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/sites`).then((response) => {
      if (response.data !== undefined) {
        let sitesData = [];
        response.data.forEach((item) => {
          sitesData.push({
            value: item.site_id,
            label: item.site_name,
          });
        });
        sitesData = sitesData.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({ sites: sitesData,tower:{...this.state.tower,selectedSite:sitesData.filter(s=>s.value=== parseInt(this.props.site_id))} }, () => {
          this.fetchTower();
        });
      }
    });
  }

  fetchTower = () => {
    const draft_tower_id = this.state.tower.draft_tower_id;
    if (draft_tower_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/towers/${draft_tower_id}`).then(response => {
        if (response.data) {
          let newState = Object.assign({}, this.state);
          newState.tower.draft_tower_id = response.data.draft_tower_id;
          newState.tower.tower_name = response.data.tower_name;
          newState.tower.site_id = response.data.site_id;
          newState.tower.number_taps = response.data.number_taps;

          if (response.data.site_id != null) {
            let site = this.state.sites.find(p => p.value === response.data.site_id);
            if (site) {
              newState.tower.selectedSite = { value: site.value, label: site.label };
            }
          } else {
            //newState.tower.selectedSite = {};
          }

          this.setState(newState);

          // this.fetchBeers();
        }
      });
    }
    else {
      let newState = Object.assign({}, this.state);
      if (this.state.tower.site_id != null) {
        let site = this.state.sites.find(p => p.value === this.state.tower.site_id);
        if (site) {
          newState.tower.selectedSite = { value: site.value, label: site.label };
        }
      } else {
        //newState.tower.selectedSite = {};
      }
      this.setState(newState);
    }
  }

  fetchBeers = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/beers`).then((response) => {
      if (response.data !== undefined) {
        this.setState({ beers: response.data }, () => {
          this.fetchKegs();
        });
      }
    });
  }

  fetchKegs = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg`).then((response) => {
      if (response.data !== undefined) {
        this.setState({ kegs: response.data }, () => {
          this.fetchLines();
          this.fetchAvaiableLines();
        });
      }
    });
  }

  fetchLines = () => {
    // Load the list
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/line`, { params: { site_id: this.state.tower.site_id } }).then((response) => {
      if (response.data !== undefined && response.data.length > 0) {
        response.data.sort((a, b) => a.local_line_number - b.local_line_number);
        this.setState({ lines: response.data }, () => {
          this.fetchTowerBeers();
        });
      }
      else {
        window.alert('No lines available. please first create lines.');
      }
    });
  }

  fetchAvaiableLines = () => {
    // Load the list
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/line/findAvaiableLines`, { params: { site_id: this.state.tower.site_id } }).then((response) => {
      if (response.data !== undefined && response.data.length > 0) {
        response.data.sort((a, b) => a.local_line_number - b.local_line_number);
        let towerLinesData = [];
        response.data.forEach((item) => {
          var selectedKeg = this.state.kegs.find(a => a.keg_id === item.keg_id);
          var selectedBeer = this.state.beers.find(a => a.draft_beer_id === selectedKeg.draft_beer_id);
          towerLinesData.push({
            value: item.line_id,
            label: `Line ${item.local_line_number} - Keg ${item.keg_id} - ${selectedBeer.name}`,
          });
        });

        towerLinesData = towerLinesData.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({ towerLines: towerLinesData });
      }
      else {
        window.alert('No lines available. please first create lines.');
      }
    });
  }

  fetchTowerBeers = () => {
    // Load the list
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/tower_beer`, { params: { draft_tower_id: this.state.tower.draft_tower_id } }).then((response) => {
      if (response.data !== undefined) {
        let newState = Object.assign({}, this.state);
        let _towerLines = newState.towerLines;
        let _towerBeers = [];
        response.data.forEach((item) => {
          var selectedLine = this.state.towerLines.find(a => a.value === item.line_id);
          var lineData = this.state.lines.find(a => a.line_id === item.line_id);
          var selectedKeg = lineData ? this.state.kegs.find(a => a.keg_id === lineData.keg_id) : null;
          var selectedBeer = selectedKeg ? this.state.beers.find(a => a.draft_beer_id === selectedKeg.draft_beer_id) : null;
          _towerBeers.push({
            draft_tower_beers_id: item.draft_tower_beers_id,
            draft_tower_id: item.draft_tower_id,
            line_id: item.line_id,
            beer_name: selectedBeer ? selectedBeer.name : "",
            tap_number: item.tap_number,
            selectedLine: selectedLine ? selectedLine : ""
          });
          if (_towerLines.indexOf(selectedLine) !== -1) {
            _towerLines.splice(selectedLine, 1);
          }
        });

        for (let i = 1; i <= this.state.tower.number_taps; i++) {
          if (_towerBeers.find(a => a.tap_number === i)) {

          } else {
            _towerBeers.push({
              draft_tower_beers_id: 0,
              tap_number: i,
              draft_tower_id: this.state.tower.draft_tower_id,
              beer_name: ""
            });
          }
        }

        _towerBeers.sort(function (a, b) { return a.tap_number - b.tap_number; });
        newState.towerBeers = _towerBeers;
        this.setState(newState);

        //this.setState({ towerBeers: towerBeers });
      }
    });
  }

  savetower = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    } 
    this.setState({ saveInProgress: true });

    const towerInfo = this.state.tower;
    const formData = {
      draft_tower_id: towerInfo.draft_tower_id,
      tower_name: towerInfo.tower_name,
      site_id: towerInfo.selectedSite[0].value,
      number_taps: towerInfo.number_taps,
    };

    if (towerInfo.draft_tower_id === 0) {

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/towers`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
        data: formData
      })
        .then(response => {
          successToaster("Added");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
    else {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/towers/${towerInfo.draft_tower_id}`,
        data: formData
      })
        .then(response => {
          successToaster("Updated");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
  }

  removeTowerBeerLines = (towerline) => {
    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/tower_beer/${towerline.draft_tower_beers_id}`)
      .then((response) => {
        window.location.reload();
      });
  }

  saveTowerLines = () => {
    const towerInfo = this.state.tower;
    const formData = {
      draft_tower_id: towerInfo.draft_tower_id,
      tower_lines: this.state.towerBeers,
      line_id: towerInfo.line_id
    };
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/tower_beer/towerbeers/`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'true'
      },
      data: formData
    })
      .then(response => {
        alert('Saved succesfully');
        window.location.reload();
      });
  }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.person[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.tower[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }

  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.tower[inputPropName] = item;
    this.setState(newState);
  }

  handleChangeSelectionLine = (item, inputPropName, index) => {
    const newState = Object.assign({}, this.state);
    var lineData = this.state.lines.find(a => a.line_id === item.value);
    var selectedKeg = this.state.kegs.find(a => a.keg_id === lineData.keg_id);
    var selectedBeer = this.state.beers.find(a => a.draft_beer_id === selectedKeg.draft_beer_id);

    newState.towerBeers[index].selectedLine = item;
    newState.towerBeers[index].line_id = item.value;
    newState.towerBeers[index].beer_name = selectedBeer.name;
    this.setState(newState);
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {
    // making sure that tower record has been fetched from APi and then only pass as prop
    // to the TowerLineBeerList component
    const towerRec = this.state.tower.tower_name ? this.state.tower : {};
    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.tower.draft_tower_id === 0 ? "Add Tower" : "Edit Tower"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Name<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.tower.tower_name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'tower_name')} autoComplete="new-name" />
                {this.validator.message('Name', this.state.tower.tower_name, 'required')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Site<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.tower.selectedSite}
                  options={this.state.sites}
                  isDisabled={!this.props.isSuperUser}
                  onChange={(e) => this.handleChangeSelection(e, 'selectedSite')}></Select>
                  {this.validator.message('Select Site', this.state.tower.selectedSite, 'required|selectValidator:""')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label>Number of Taps<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.tower.number_taps} bsSize="sm" type="number" min="1" max="1000" step="1" onChange={(e) => this.handleInputChange(e, 'number_taps')} autoComplete="new-name" />
                {this.validator.message('Taps', this.state.tower.number_taps, 'required')}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.savetower} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.tower.draft_tower_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
        {
          this.state.tower.draft_tower_id!==0?
          <>
            <TowerLineBeerList selectedTower={towerRec}></TowerLineBeerList>
          </>
          :""
        }
        
        {/* <Card>
          <CardHeader><h3 className="dft-primary-color">Tower Line Beers</h3></CardHeader>
          <CardBody>
            <table className="table table-bordered">
              <tr>
                <th>Tap#</th>
                <th>Line</th>
                <th>Beer</th>
                <th>Manage</th>
              </tr>
              {this.state.towerBeers && this.state.towerBeers.map((towerline, index) => (
                <tr data-index={index}>
                  <td>{towerline.tap_number}</td>
                  <td>
                    <Select size="sm" value={towerline.selectedLine}
                      options={towerLines}
                      onChange={(e) => handleChangeSelectionLine(e, 'selectedLine', index)}></Select>
                  </td>
                  <td>{towerline.beer_name}</td>
                  <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeTowerBeerLines(towerline)} ><i className="fa fa-trash"></i></button></td>
                </tr>
              ))}
            </table>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveTowerLines}>
                    {this.state.tower.draft_tower_id === 0 ? "Add" : "Update"}
                  </button>
                  <Link className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</Link>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card> */}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false
  }
}

export default connect(mapStateToProps, null)(TowerDetail);
