import React from "react";
import axios from "axios";
import Select from "react-select";
import {
    Card,
    CardFooter,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";
import { connect } from "react-redux";
import * as towerActions from '../towers/store/tower.actions';

class TowerLineBeerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTower: null,
            viewOnly: this.props.viewOnly || false,
            towerBeers: [], // [{ tap: 0, selectedLine: { value: 0, label: "line0" }, draft_beer_id: 0, draft_beer_name: "corona-0", "keg_id": 0, keg_name: "keg-0" }],
            towerLines: [],
            beers: [],
            kegs: [],
            lines: [],
            showList:false
        };
    }

    async componentDidMount () {
       // var selected_site_id = this.props.selTower.site_id;
       
        //this.props.setSelTower(Object.assign({}, this.props.towers[0]));
      }
    
      static getDerivedStateFromProps(props, state) {
        return {
            towerLines:props.availableLines,
            towerBeers:props.towerBeers,
            showList:props.towerBeers.length>0
        }
      }

    // componentDidUpdate(prevProps) {
    //     // Typical usage (don't forget to compare props):
    //     if (this.props.selectedTower && (this.props.selectedTower.draft_tower_id !== prevProps.selectedTower.draft_tower_id)) {
    //         this.fetchBeers();
    //     }
    // }

    // fetchBeers = () => {
    //     if (this.state.beers.length > 0) {
    //         this.fetchKegs();
    //         return;
    //     }
    //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/beers`).then((response) => {
    //         if (response.data !== undefined) {
    //             this.setState({ beers: response.data }, () => {
    //                 this.fetchKegs();
    //             });
    //         }
    //     });
    // }

    // fetchKegs = () => {
    //     if (this.state.kegs.length > 0) {
    //         this.fetchLines();
    //         return;
    //     }
    //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg`).then((response) => {
    //         if (response.data !== undefined) {
    //             this.setState({ kegs: response.data }, () => {
    //                 this.fetchLines();
    //             });
    //         }
    //     });
    // }

    // fetchLines = () => {
    //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/line/findAvaiableLines`, { params: { site_id: this.props.selectedTower.site_id } }).then((response) => {
    //         debugger
    //         if (response.data !== undefined) {
    //             response.data.sort((a, b) => a.local_line_number - b.local_line_number);
    //             let towerLinesData = [];
    //             response.data.forEach((item) => {
    //                 //var selectedKeg = this.state.kegs.find(a => a.keg_id === item.keg_id);
    //                 //var selectedBeer = this.state.beers.find(a => a.draft_beer_id === selectedKeg.draft_beer_id);
    //                 towerLinesData.push({
    //                     value: item.line_id,
    //                     label: `Line ${item.local_line_number} - Keg ${item.keg_id} - ${item.beer_name}`,
    //                 });
    //             });

    //             towerLinesData = towerLinesData.sort((a, b) => a.label.localeCompare(b.label));
    //             this.setState({ lines: response.data, towerLines: towerLinesData }, () => {
    //                 this.fetchTowerBeers();
    //             });
    //         }
    //         else {
    //             this.setState({
    //                 lines: [],
    //                 towerLines: [],
    //                 towerBeers: []
    //             });
    //         }
    //     })
    // }

    fetchTowerBeers = () => {
        // Load the list
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/tower_beer`, { params: { draft_tower_id: this.props.selectedTower.draft_tower_id } }).then((response) => {
            if (response.data !== undefined) {
                let newState = Object.assign({}, this.state);
                let _towerLines = newState.towerLines;
                let _towerBeers = [];
                response.data.forEach((item) => {
                    var selectedLine = this.state.towerLines.find(a => a.value === item.line_id);
                    //var lineData = this.state.lines.find(a => a.line_id === item.line_id);
                    //var selectedKeg = lineData ? this.state.kegs.find(a => a.keg_id === lineData.keg_id) : null;
                    //var selectedBeer = selectedKeg ? this.state.beers.find(a => a.draft_beer_id === selectedKeg.draft_beer_id) : null;
                    _towerBeers.push({
                        draft_tower_beers_id: item.draft_tower_beers_id,
                        draft_tower_id: item.draft_tower_id,
                        line_id: item.line_id,
                        beer_name: item.line_id != null ? (`Line ${item.tb_line.local_line_number} - Keg ${item.tb_line.keg.keg_id} - ${item.tb_line.keg.db_keg.name}`) : "",
                        tap_number: item.tap_number,
                        selectedLine: selectedLine ? selectedLine : ""
                    });
                    if (_towerLines.indexOf(selectedLine) !== -1) {
                        _towerLines.splice(selectedLine, 1);
                    }
                });
                for (let i = 1; i <= this.props.selectedTower.number_taps; i++) {
                    if (_towerBeers.find(a => a.tap_number === i)) {

                    } else {
                        _towerBeers.push({
                            draft_tower_beers_id: 0,
                            tap_number: i,
                            draft_tower_id: this.props.selectedTower.draft_tower_id,
                            beer_name: ""
                        });
                    }
                }
                

                _towerBeers.sort(function (a, b) { return a.tap_number - b.tap_number; });
                // newState.towerBeers = _towerBeers;
                // this.setState(newState);
                this.setState({ towerBeers: _towerBeers });
            }
        });
    }

    handleChangeSelectionLine = (item, inputPropName, index) => {
        const newState = Object.assign({}, this.state);
        var lineData = this.state.lines.find(a => a.line_id === item.value);
        var selectedKeg = this.state.kegs.find(a => a.keg_id === lineData.keg_id);
        var selectedBeer = this.state.beers.find(a => a.draft_beer_id === selectedKeg.draft_beer_id);

        newState.towerBeers[index].selectedLine = item;
        newState.towerBeers[index].line_id = item.value;
        newState.towerBeers[index].beer_name = selectedBeer.name;
        this.setState(newState);
    }

    removeTowerBeerLines=()=>{
        
    }

    saveTowerLines = () => {
        const towerInfo = this.props.selectedTower;
        const formData = {
            draft_tower_id: towerInfo.draft_tower_id,
            tower_lines: this.state.towerBeers,
            line_id: towerInfo.line_id
        };
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_BASE_URL}/api/tower_beer/towerbeers/`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'true'
            },
            data: formData
        })
            .then(response => {
                alert('Saved succesfully');
                window.location.reload();
            });
    }

    render() {
        const { handleChangeSelectionLine } = this;
        const { viewOnly, towerBeers } = this.state;
        var towerBeerList = [];
        towerBeerList = towerBeers;

        return (
            <React.Fragment>
                {
                    viewOnly ?
                        <Card>
                            <CardHeader><h3 className="dft-primary-color">Tower Line Beers</h3></CardHeader>
                            <CardBody>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Tap#</th>
                                            <th>Selected Line</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {towerBeerList && towerBeerList.map((towerBeer, index) => (
                                            <tr data-index={index} key={towerBeer.tap_number}>
                                                <td>{towerBeer.tap_number}</td>
                                                <td>{towerBeer.beer_name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                        :
                        <Card>
                            <CardHeader><h3 className="dft-primary-color">Tower Line Beers</h3></CardHeader>
                            <CardBody>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Tap#</th>
                                            <th>Selected Line</th>
                                            <th>Line</th>
                                            <th>Manage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {towerBeers && towerBeers.map((towerBeer, index) => (
                                            <tr data-index={index} key={towerBeer.draft_tower_beers_id}>
                                                <td>{towerBeer.tap_number}</td>
                                                <td>{towerBeer.beer_name}</td>
                                                <td>
                                                    <Select size="sm" value={towerBeer.selectedLine}
                                                        options={this.state.towerLines}
                                                        onChange={(e) => handleChangeSelectionLine(e, 'selectedLine', index)}></Select>
                                                </td>
                                                <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeTowerBeerLines(towerBeer)} ><i className="fa fa-trash"></i></button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col md="3" lg="3" >
                                        <div>
                                            <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveTowerLines}>
                                                {this.props.selectedTower.draft_tower_id === 0 ? "Add" : "Update"}
                                            </button>
                                            {/* <Link className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</Link> */}
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                }
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
      site_id: state.userInfo.security.site_id,
      isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
      selTower:state.towers.selTower,
      availableLines:state.towers.availableLines,
      towerBeers:state.towers.towerBeers
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
     getAvailableLines:(site_id,forceReload)=>dispatch(towerActions.getAvailableLines(site_id,forceReload))
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(TowerLineBeerList);
