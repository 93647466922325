import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as monitorActions from '../dashboard/store/dashboard-actions';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import ReactApexChart from 'react-apexcharts';
import * as chartActions from '../charts/store/chart-actions';
import {convertValueByMetric} from '../../utility/units-conversion';
import {sortByDataType} from '../../utility/common';

var DatePicker = require("reactstrap-date-picker");

class GlycolReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            selLine:null,
            glycolData:[],
            loading:false,
            glycolViewData:[],
            activeTab:"1",
            title:'',
            showChart:false,
            masterData:[],
            errorMessage:'',
            glycolDetails:[],
            volumeLineData:[],
            tempLineData:[],
            linesForGlycol:[],
            lineChart:{
                series:[],
                options:{}
            }
        };
    }
    
    componentDidMount(){
      this.props.getKegRooms(this.props.site_id);
      if(this.state.kegRooms.length>0){
        //this.getChartData()
      }
    }

    static getDerivedStateFromProps(props, state) {
      return {
        kegRooms:props.kegRooms,
        selKegRoom:props.kegRooms.length>0?props.kegRooms[0]:null,
        linesForGlycol:props.linesForGlycol,
        selLine:state.selLine===null && props.linesForGlycol.length>0?props.linesForGlycol[0]:state.selLine
      }
    }

    componentDidUpdate(prevProps,prevState){
      if(prevProps.kegRooms.length!==this.state.kegRooms.length){
        this.props.getLinesForGlycol(this.state.kegRooms[0].value)
      }
    }
  
    getChartData=()=>{
        this.setState({showChart:false,loading:true});
        let newState=this;
        var title=`Glycol data for ${this.state.selKegRoom.label}`
        var params={
            kr_id:this.state.selKegRoom.value,
            port:this.state.selLine.value
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetGlycolData`,{params:params})
        .then(function(response){
            if(response.data!==undefined){
                response.data.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
                var _glycolData=[];
                response.data.forEach((item)=>{
                    _glycolData.push({
                        gs_id:item.gs_id,
                        gs_name:item.gs_name,
                        temp_total:item.gs_temperature_total.toFixed(2),
                        temp_avg:item.gs_temperature_c_30_avg.toFixed(2),
                        temp_stdev:item.gs_temperature_30_stdev.toFixed(2),
                        flow_total:(item.gs_flow_total*3.6).toFixed(2),
                        flow_avg:(item.gs_flow_avg*3.6).toFixed(2),
                        flow_stdev:(item.gs_flow_stdev*3.6).toFixed(2),
                        vol_total:item.gs_volume_total.toFixed(2),
                        vol_avg:item.gs_volume_avg.toFixed(2),
                        vol_stdev:item.gs_volume_stdev.toFixed(2),
                        gs_count:item.gs_count
                    })
                })
                newState.setState({
                    ...newState.state,
                    glycolData:_glycolData,
                    showChart:true,
                    title:title,
                    //loading:false,
                },()=>{newState.getGlycolDetails()});
            }

        })
    }

    getGlycolDetails=()=>{
        let newState=this;
        let units=this.props.units;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetGlycolDetails`,{params:{
          kr_id:this.state.selKegRoom.value,
          port:this.state.selLine.value,
          fromDate:moment(this.state.from_date).format("MM/DD/YYYY"),
          toDate:moment(this.state.to_date).format("MM/DD/YYYY")}})
        .then(function(response){
            var lineChartData=[];
            var agg_data=newState.state.glycolData;

            var volumeLine={name:"Volume",data:[]};
            var tempLine={name:"Temperature",data:[]};
            var avg_temp_line={name:"Avg Temp",data:[]};
            var avg_temp_line1={name:"Avg + Std Dev Temp",data:[]};
            var avg_temp_line2={name:"Avg - Std Dev Temp",data:[]};
            var xaxis=[];
            if(response.data!==undefined && response.data.length>0){
                response.data.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
                var _glycolDetails=[];
                var groupByDate=  response.data.reduce(function (r, a) {
                  r[a.start_time] = r[a.start_time] || [];
                  r[a.start_time].push(a);
                  return r;
              }, Object.create(null));
              let counter=1,total_volume=0,total_temp=0;
              var groupByDateOnly=  response.data.reduce(function (r, a) {
                var date=moment(a.start_time).format("MM/DD/YYYY")
                r[date] = r[date] || [];
                r[date].push(a);
                return r;
             }, Object.create(null));
             for(var key1 in groupByDateOnly){
                  var vol_by_date=groupByDateOnly[key1].map(o => convertValueByMetric(o.volume,units,"vol")).reduce((a, c) => { return a + c });
                   var temp_by_date=groupByDateOnly[key1].map(o => convertValueByMetric(o.temperature_c,units,"c")).reduce((a, c) => { return a + c });
                  _glycolDetails.push({
                    id:counter,
                    volume:vol_by_date.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    temp:(temp_by_date/groupByDateOnly[key1].length).toFixed(2),
                    glycol_date:moment(key1).format("MM/DD/YYYY")
                  })
                  total_volume+=vol_by_date;
                  total_temp+=(temp_by_date/groupByDateOnly[key1].length);
                  counter++
             }
             _glycolDetails.push({
              id:0,
              volume:total_volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              temp:(total_temp/Object.keys(groupByDateOnly).length).toFixed(2),
              glycol_date:"Summary"
            })
            var temp_avg=convertValueByMetric(parseFloat(agg_data[0].temp_avg),units,"c");
            var temp_stdev=convertValueByMetric(parseFloat(agg_data[0].temp_stdev),units,"std");
            for(var key in groupByDate){
                  var item=groupByDate[key][0];
                  item.volume=convertValueByMetric(item.volume,units,"vol");
                  item.temperature_c=convertValueByMetric(item.temperature_c,units,"c");
                 
                  
                  lineChartData.push({
                    id:item.id,
                    start_time:item.start_time,
                    pour:item.pour,
                    volume:item.volume,
                    temperature:item.temperature_c
                })
                xaxis.push(new Date(item.start_time));
                  volumeLine.data.push({x:item.start_time,y:item.volume.toFixed(2)})
                  tempLine.data.push({x:item.start_time,y:item.temperature_c.toFixed(2)})
                  avg_temp_line.data.push({x:item.start_time,y:temp_avg})
                  avg_temp_line1.data.push({x:item.start_time,y:(temp_avg+temp_stdev).toFixed(2)})
                  avg_temp_line2.data.push({x:item.start_time,y:(temp_avg-temp_stdev).toFixed(2)})
              }
              var volume_min =(Math.min(...volumeLine.data.map(s=>s.y))-Math.min(...volumeLine.data.map(s=>s.y)))*6;
              var tempLine_min=Math.min(...tempLine.data.map(s=>s.y));
              var avg_temp_min =Math.min(...avg_temp_line.data.map(s=>s.y));
              var avg_temp_min1 =Math.min(...avg_temp_line1.data.map(s=>s.y));
              var avg_temp_min2 =Math.min(...avg_temp_line2.data.map(s=>s.y));
              var temp_min=Math.min(...[tempLine_min,avg_temp_min,avg_temp_min1,avg_temp_min2])-1;
debugger
                var chartData={
                    series:[volumeLine,tempLine,avg_temp_line,avg_temp_line1,avg_temp_line2],
                    options: {
                        chart: {
                          animations: {
                            enabled: false
                          },
                          markers: {
                            size: 0,
                          },
                          height: 350,
                          type: 'line',
                          zoom: {
                            enabled: true
                          }
                        },
                        dataLabels: {
                          enabled: false
                        },
                        stroke: {
                          curve: 'straight',  
                          width: [1, 1, 1],
                          dashArray: [0, 0, 0,4,4]
                        },
                        title: {
                          //text: `Glycol Chart with average temperature at ${parseFloat(agg_data[0]===undefined?"":convertValueByMetric(agg_data[0].temp_avg,units,"c")).toFixed(2)} ${units==="metric"?"C":"F"} and average flow at ${parseFloat(agg_data[0]===undefined?"":convertValueByMetric(agg_data[0].flow_avg*1000,units,"vol")/(units==="metric"?1000:1)).toFixed(2)} ${units==="metric"?"(L/HR)":"(OU/HR)"}`,
                          align: 'left'
                        },
                        grid: {
                          row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                          },
                        },
                        xaxis: {
                          categories: xaxis,
                          type: "datetime",
                          tickAmount:10,
                          labels: {
                            datetimeUTC: false,
                            rotateAlways: false,
                            rotate: 0,
                            tickPlacement: 'between',
                            formatter: function(val, timestamp) {
                              return moment(val).format("DD MMM hh:mm");; // formats to hours:minutes
                            }  
                          }
                        },
                        yaxis: [
                            {
                              seriesName: 'Volume',
                              tickAmount:volume_min,
                              axisTicks: {
                                show: true,
                              },
                              axisBorder: {
                                show: true,
                                color: "#018FFB"
                              },
                              labels: {
                                style: {
                                  colors: "#018FFB"
                                },formatter: function(val, timestamp) {
                                  return parseFloat(val).toFixed(2) // formats to hours:minutes
                                }  
                              },
                              title: {
                                text: units==="metric"?"Volume (ml)":"Volume (ou)",
                                style: {
                                  color: "#018FFB"
                                }
                              }
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              tickAmount:20,
                              min:temp_min,
                              //min:units==="metric"?0:29,
                              axisTicks: {
                                show: true
                              },
                              axisBorder: {
                                show: true,
                                color: "#58EAB9"
                              },
                              labels: {
                                style: {
                                  colors: "#58EAB9"
                                },formatter: function(val, timestamp) {
                                  return val.toFixed(2) // formats to hours:minutes
                                }
                              },
                              title: {
                                text: units==="metric"?"Temperature (C)":"Temperature (F)",
                                style: {
                                  color: "#58EAB9"
                                }
                              }
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              tickAmount:15,
                              min:temp_min,
                              show:false
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              show:false,
                              min:temp_min,
                              tickAmount:15,
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              show:false,
                              min:temp_min,
                              tickAmount:15,
                            }
                          ]
                      }
                }
                
                newState.setState({
                    ...newState.state,
                    glycolViewData:_glycolDetails,
                    masterData:response.data,
                    glycolDetails:lineChartData,
                    loading:false,
                    lineChart:chartData
                });
            }
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    showGlycolDetailsByDate=(row)=>{
        const columns = [
          {
            dataField: "id",
            text: "Id",
            hidden: true,
            headerAlign:'center'
          },
          {
            dataField: "glycol_time",
            text: "Time",
            sort: true,
            align:'left',
            headerAlign:'center'
          },
          {
            dataField: "volume",
            text: "Volume (ml)",
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
            sort: true,
            align:'right',
            headerAlign:'center'
          },
          {
            dataField: "temp",
            text: "Temp (C)",
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
            sort: true,
            align:'right',
            headerAlign:'center'
          }
        ];
        var glycolDataByTime=this.state.masterData.filter(p=>moment(p.start_time).format("MM/DD/YYYY")===row.glycol_date);
        var childData=[],total_volume=0,total_temp=0;
        glycolDataByTime.forEach((item)=>{
          var vol_by_date=convertValueByMetric(item.volume,this.props.units,"vol")
          var temp_by_date=convertValueByMetric(item.temperature_c,this.props.units,"c")
          childData.push({
            id:item.id,
            volume:vol_by_date.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            temp:(temp_by_date).toFixed(2),
            glycol_time:moment(item.start_time).format("LT")
          })
          total_volume+=vol_by_date;
          total_temp+=temp_by_date;
      })
      if(childData.length>0){
        childData.push({
            id:0,
            volume:total_volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            temp:(total_temp/glycolDataByTime.length).toFixed(2),
            glycol_time:"summary"
        })
      }
        if(childData.length!==0){
          var title=`Glycol data for ${this.state.selKegRoom.label} from ${moment(this.state.from_date).format("MM/DD/YYYY")} to ${moment(this.state.to_date).format("MM/DD/YYYY")}`
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,childData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,childData,title,this.props.companyLogo,this.props.userLogo,"Total-Pour-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_Glycol_ByTime"
                                filename="Total Pour"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_Glycol_ByTime"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="id"
                                data={childData}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    render() {
      const expandBeerRow = {
        onlyOneExpanding: true,
        parentClassName: 'selected',
        renderer: (row) =>{
            return this.showGlycolDetailsByDate(row)
        },
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
          if (isAnyExpands) {
            return <i className="fa fa-caret-down fa-md"></i>;
          }
          return <i className="fa fa-caret-right fa-md"></i>;
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return (
              <i className="fa fa-caret-down fa-md"></i>
            );
          }
          return (
            <i className="fa fa-caret-right fa-md"></i>
          );
        }
      };
        const columns = [
            {
              dataField: "id",
              text: "Id",
              hidden: true,
              headerAlign:'center'
            },
            {
              dataField: "glycol_date",
              text: "Date",
              sort: true,
              align:'left',
              headerAlign:'center'
            },
            {
              dataField: "volume",
              text: "Volume (ml)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "temp",
              text: "Avg Temp (C)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            }
            // {
            //     dataField: "temp_stdev",
            //     text: "St Dev Temp (C)",
            //     sort: true,
            //     align:'right',
            //     headerAlign:'center'
            // },
            // {
            //   dataField: "flow_avg",
            //   text: "Avg Flow (L/HR)",
            //   sort: true,
            //   align:'right',
            //   headerAlign:'center'
            // },
            // {
            //     dataField: "flow_stdev",
            //     text: "St Dev Flow (L/HR)",
            //     sort: true,
            //     align:'right',
            //     headerAlign:'center'
            // }
          ];
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Glycol Data</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Select Line</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selLine}
                                    options={this.state.linesForGlycol}
                                    onChange={(e) => this.handleChangeSelection(e, 'selLine')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                        </Row>
                        <br /><br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Nav tabs>
                        <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                         
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <br/> <br/> <br/>
                            <div>
                                <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} />
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                        {/* <Button onClick={()=>this.printPourVolumeByDate(columns)} size="sm">Print</Button>
                        <button onClick={this.generatePdf} className="btn btn-secondary btn-sm">Download Pdf</button> */}
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.glycolViewData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.glycolViewData,this.state.title,this.props.companyLogo,this.props.userLogo,"Connected-Kegs-Report.pdf")} 
                            className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_Kegs"
                                filename="Connected kegs"
                                sheet="Kegs"
                                buttonText="Download Excel"/>
                            </div>
                            <BootstrapTable id="tbl_Kegs"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="id"
                                expandRow={expandBeerRow}
                                data={this.state.glycolViewData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        kegRooms:state.charts.kegRooms,
        linesForGlycol:state.monitor.linesForGlycol,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getKegRooms: (site_id) => dispatch(chartActions.getKegRooms(site_id)),
      getLinesForGlycol: (kr_id) => dispatch(monitorActions.getLinesForGlycol(kr_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlycolReport);
