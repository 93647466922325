import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./person.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_PERSONS):
            return setPersons(state, action);
        case (actionTypes.SET_SEL_PERSON):
            return setSelPerson(state, action);
        case (actionTypes.SET_CURR_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.ADD_PERSON):
            return addPerson(state, action);
        case (actionTypes.UPDATE_PERSON):
            return updatePerson(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        default:
            return state;
    }

};

const setSelPerson = (state, action) => {
    return updateObject(state, {
        selPerson: action.data
    });
};

const setPersons = (state, action) => {
    return updateObject(state, {
        persons: action.data
    });
};

const addPerson = (state, action) => {
    let persons = [...state.persons];
    persons.push(action.data);
    return updateObject(state, {
        persons: persons
    });
};

const updatePerson = (state, action) => {
    const index = state.persons.findIndex(b => b.id === action.data.id);
    return {
        ...state,
        persons: [...state.persons.slice(0, index), action.data, ...state.persons.slice(index + 1)]
    };
};


// set current page number of beers list page 
const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selPerson: initialState.selPerson,
        persons: initialState.persons,
        currPage: initialState.currPage
    });
};

export default reducer;