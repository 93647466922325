import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import { successToaster } from '../../toaster/toaster';

export const initialState = {
    reportSubscriptions: [],
    availableReports:[],
    currPage: 1,
    showList:false,
    rowsExpanded:[],
    selEditReport:null,
    selEditFrequency:{label:"Daily",value:"Daily"}
};


export const getReportSubscriptions = (site_id,user_id) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.reportSubscriptions.reportSubscriptions.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={
                site_id:site_id,
                user_id:user_id
            }
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/subscriptions/getReportSubscriptions`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    let _subscriptions = [],counter=1;
                    response.data.forEach((item) => {
                        _subscriptions.push({
                            report_subscription_id:counter,
                            first_name:item.first_name,
                            last_name:item.last_name,
                            report_id:item.report_id,
                            report_name:item.report_name,
                            email:item.email,
                            run_frequency:item.run_frequency,
                            user_id:item.user_id,
                            site_id:item.site_id
                        })
                        counter++
                    });
                    //_sites = _sites.sort((a, b) => a.name.localeCompare(b.name));
                    dispatch(handleActionDispatch(actionTypes.SET_REPORT_SUBSCRIPTIONS, _subscriptions));
                    resolve(_subscriptions);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const getAvailableReports = () => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.reportSubscriptions.availableReports.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/availableReports/`).then((response) => {
                if (response.data !== undefined) {
                    let _availableReports = [];
                    response.data.forEach((item) => {
                        _availableReports.push({
                            value:item.report_id,
                            label:item.report_name
                        })
                    });
                    //_sites = _sites.sort((a, b) => a.name.localeCompare(b.name));
                    dispatch(handleActionDispatch(actionTypes.SET_AVAILABLE_REPORTS, _availableReports));
                    resolve(_availableReports);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}


export const addReportSubscription = (reportSubscription) => {
	const formData = {
		report_id:reportSubscription.report_id,
        user_id:reportSubscription.user_id,
        run_frequency:reportSubscription.run_frequency,
        site_id:reportSubscription.site_id
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
            debugger
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/subscriptions`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			dispatch(handleActionDispatch(actionTypes.ADD_REPORT_SUBSCRIPTION, reportSubscription));
			successToaster("Added Report subscription");
			resolve();
		});
	}
}

export const updateReportSubscription = (reportSubscription) => {
	const formData = {
		report_id:reportSubscription.report_id,
        user_id:reportSubscription.user_id,
        run_frequency:reportSubscription.run_frequency,
        site_id:reportSubscription.site_id
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
            debugger
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/subscriptions/updateReportSubscriptions`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			dispatch(handleActionDispatch(actionTypes.UPDATE_REPORT_SUBSCRIPTION, reportSubscription));
			successToaster("Updated Report subscription");
			resolve();
		});
	}
}

export const removeReportSubscription = (reportSubscription) => {
	const formData = {
		report_id:reportSubscription.report_id,
        user_id:reportSubscription.user_id,
        run_frequency:reportSubscription.run_frequency,
        site_id:reportSubscription.site_id
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
            debugger
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/subscriptions/deleteReportSubscriptions`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			dispatch(handleActionDispatch(actionTypes.REMOVE_REPORT_SUBSCRIPTION, reportSubscription));
			successToaster("Removed Report subscription");
			resolve();
		});
	}
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}

export const editReportSubscription = (reportSubscription) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.EDIT_REPORT_SUBSCRIPTION, reportSubscription));
    }
}

export const updateSubscriptionParams = (reportParams) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.Update_PARAMS_REPORT_SUBSCRIPTION, reportParams));
    }
}

export const cancelEdit = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CANCEL_EDIT_REPORT_SUBSCRIPTION, initialState));
    }
}