import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { connect } from 'react-redux';
import Uploady, {
  useItemStartListener,
  useItemFinalizeListener,
  useItemFinishListener
} from "@rpldy/uploady";
import { getMockSenderEnhancer } from "@rpldy/mock-sender";
import withPasteUpload from "@rpldy/upload-paste";
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import * as beersActions from './store/beers.actions';
import * as enumsActions from '../shared/actions/enums.action';
import Compressor from 'compressorjs';
const mockSenderEnhancer = getMockSenderEnhancer();

const StyledInput = styled.input`
  width: 400px;
  height: 22px;
  font-size: 20px;
  margin: 10px 0;
`;

const PasteInput = withPasteUpload(StyledInput);

const UploadStatus = (moreProps) => {
  const [status, setStatus] = useState(null);

  useItemStartListener(() => setStatus("Uploading..."));
  useItemFinalizeListener(() => setStatus("Finished!"));

  useItemFinishListener((item) => {
    let file = item.file;
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      convertSize: 100000, // will convert all images more than 100kb 
      success: (compressedFile) => {
        let reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          moreProps.callback(reader.result);
        }
      },
    });
  });


  return status;
};


class BeerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beer: null,
      kegCouplers: [],
      beerTypes: [],
      countries: [],
      beerCategories: [],
      saveInProgress: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      beer: props.selBeer,
      countries: props.enums.countries,
      beerTypes: props.enums.beerTypes,
      kegCouplers: props.enums.kegCouplers,
      beerCategories: props.enums.beerCategories
    }
  }

  componentDidMount() {
    this.props.getCountries();
    this.props.getBeerTypes();
    this.props.getKegCouplers();
    this.props.getBeerCategories();
  }


  updatePreview = (base64Data) => {
    let newState = Object.assign({}, this.state);
    newState.beer.avatar = base64Data;
    newState.beer.logo = base64Data;
    this.setState(newState);
  }

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.beer[inputPropName] = inputPropName === "name" ? event.target.value.toUpperCase() : event.target.value;
    }
    this.setState(newState);
  }


  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.beer[inputPropName] = item;
    this.setState(newState);
  }

  addBeer = async () => {
    this.setState({ saveInProgress: true });
    const beerInfo = this.state.beer;
    if (beerInfo.id === 0) {
      await this.props.addBeer(beerInfo);
    }
    else {
      await this.props.updateBeer(beerInfo);
    }
    this.setState({ saveInProgress: false });
    this.props.history.push(`/admin/Beers`);
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {

    const profilePicStyle = {
      width: '400px',
      height: '300px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-Repeat',
      backgroundPosition: 'center',
      backgroundImage: 'url(' + this.state.beer.logo + ')',
      borderRadius: '5px',
      backgroundColor: '#ebebeb'
    }

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3>{this.state.beer.id === 0 ? "Add Beer" : "Update Beer"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="6">
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label> Name</Label>
                    <Input value={this.state.beer.name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'name')} autoComplete="new-name" />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label> Short Name</Label>
                    <Input value={this.state.beer.short_name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'short_name')} autoComplete="new-name" />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label>Company</Label>
                    <Input value={this.state.beer.company} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'company')} autoComplete="new-name" />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label>Distributor</Label>
                    <Input value={this.state.beer.distributor} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'distributor')} autoComplete="new-name" />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label>Type</Label>
                    <Select bsSize="sm" value={this.state.beer.type} options={this.state.beerTypes} onChange={(e) => this.handleChangeSelection(e, 'type')}></Select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label>Keg Coupler</Label>
                    <Select bsSize="sm" value={this.state.beer.keg_coupler} options={this.state.kegCouplers} onChange={(e) => this.handleChangeSelection(e, 'keg_coupler')}></Select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label>Country of Origin</Label>
                    <Select bsSize="sm" value={this.state.beer.countryoforigin} options={this.state.countries} onChange={(e) => this.handleChangeSelection(e, 'countryoforigin')}></Select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label>Category</Label>
                    <Select bsSize="sm" value={this.state.beer.category} options={this.state.beerCategories} onChange={(e) => this.handleChangeSelection(e, 'category')}></Select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label>Alcohol by volume (ABV)</Label>
                    <Input value={this.state.beer.abv} bsSize="sm" type="number" min="0.0" max="100.0" step="0.5" onChange={(e) => this.handleInputChange(e, 'abv')} autoComplete="new-name" />
                  </Col>
                </Row>
              </Col>
              <Col md="6" sm="1" lg="6" className="mb-3 position-relative">
                <Row>
                  <Col md="12" sm="12" lg="12">
                    <br />
                    <Uploady enhancer={mockSenderEnhancer} destination={{ url: `${process.env.REACT_APP_HOST_BASE_URL}` }}>
                      {/* <PasteUploadDropZone params={{ test: "paste" }}>
                                            You can drop a file here
                                            <br />
                                            OR
                                            <br />
                                            click and paste a file to upload
                                            </PasteUploadDropZone> */}
                      <PasteInput extraProps={{ placeholder: "paste image to upload" }} />
                      <UploadStatus extraProps={{ source: this.state.bee }} callback={this.updatePreview} />
                      {/* <PreviewContainer>
                                            <UploadPreview  />
                                            </PreviewContainer> */}
                    </Uploady>
                    <div style={profilePicStyle}></div>
                  </Col>
                </Row>

              </Col>
            </Row>

          </CardBody>
          <CardFooter>
            <Row>
              <Col md="2" lg="2" >
                <div>
                  <button className="btn btn-sm btn-success" onClick={this.addBeer} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.beer.id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-default" onClick={this.handleCancel}>Cancel</button>
                </div>

              </Col>
            </Row>
          </CardFooter>
        </Card>

      </>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    selBeer: state.beers.selBeer,
    enums: state.enums
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBeer: (beer) => dispatch(beersActions.addBeer(beer)),
    updateBeer: (beer) => dispatch(beersActions.updateBeer(beer)),
    getCountries: () => dispatch(enumsActions.getCountries()),
    getBeerTypes: () => dispatch(enumsActions.getBeerTypes()),
    getKegCouplers: () => dispatch(enumsActions.getKegCouplers()),
    getBeerCategories: () => dispatch(enumsActions.getBeerCategories())
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(BeerDetail);
