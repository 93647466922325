import React from "react"
import { NavLink as NavLinkRRD, Link } from "react-router-dom"
import { PropTypes } from "prop-types"
import { scroller } from "react-scroll"

import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledCollapse,
} from "reactstrap"
import { connect } from "react-redux"

class Sidebar extends React.Component {
  /* openMenus - Whenever new menu is introduced, add new menu's parent menu name in openMenus array.
this array is used to show/update up down arrow icons near parent menu name. 
If menu is default open, it's name should be presented in initialization of openMenus array other wise it 
won't update arrow icons.
Do not update state in createLinks function as it's causing error of infinite loop */
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: true,
      openMenus: ["customers", "users"],
    }
    this.activeRoute.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sec_level !== this.props.sec_level) {
      /* When admin changes users from header drop down, it will affect side menu security policy.
     Sub-menu for each menu is default open, so if new menu added according to security policy,
     We need to add that menu in openMenus in order to show it's icon up arrow.*/
      this.updateOpenMenuArray(this.props)
    }
  }

  updateOpenMenuArray = (nextProps) => {
    let openMenus = this.state.openMenus
    if (
      this.props.sec_level.tech_only !== nextProps.sec_level.tech_only &&
      !openMenus.includes("customers")
    ) {
      if (nextProps.sec_level.tech_only) {
        openMenus.push("customers")
      } else {
        openMenus = openMenus.filter((e) => e !== "customers")
      }
    }
    if (
      this.props.sec_level.user_only !== nextProps.sec_level.user_only &&
      !openMenus.includes("users")
    ) {
      if (nextProps.sec_level.user_only) {
        openMenus.push("users")
      } else {
        openMenus = openMenus.filter((e) => e !== "users")
      }
    }

    this.setState({ openMenus: openMenus })
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    })

    document.body.classList.toggle("sidebar-hidden")
  }
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    })
  }
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    let menus = []
    let defaultManus = routes.filter((m) => m.access === "default")
    let userMenus = routes.filter((m) => m.access === "user")
    let dataMenus = routes.filter((m) => m.access === "data")
    let techMenus = routes.filter((m) => m.access === "tech")
    let _adminMenus = routes.filter((m) => m.access === "_draftco")
    let _pourChartMenus=routes.filter((m) => m.access === "pour_charts")
    let _kegChartMenus=routes.filter((m) => m.access === "keg_charts")
    let _glycolChartMenus=routes.filter((m) => m.access === "glycol_charts")
    let _lineChartMenus=routes.filter((m) => m.access === "line_charts")
    let _krChartMenus=routes.filter((m) => m.access === "kr_charts")
    let _techMaintenance=routes.filter((m)=>m.access==="tech_maintenance");
    let _kegManagement=routes.filter((m)=>m.access==="keg_management");
    let _techAlarms=routes.filter((m)=>m.access==="tech_alarms");
    let _subscriptions=routes.filter((m)=>m.access==="report_subscriptions");

    let security = this.props.sec_level
    if (!security) {
      security = security || {}
      security.data_only = true
      security.tech_only = true
      security.user_only = true
    }

    defaultManus = defaultManus.map((prop, key) => {
      return this.getNavItem(prop)
    })
    menus = [...menus, defaultManus]

    if (security && security.tech_only) {
      techMenus = techMenus.map((prop, key) => {
        return this.getNavItem(prop)
      })
      techMenus = this.getMenuGroupHeader(
        "Customer Setup",
        "customers",
        techMenus
      )

      menus = [...menus, techMenus]
    }

    if (security && (security.user_only || security.data_only)) {
      userMenus = userMenus.map((prop, key) => {
        if(security.data_only && !security.user_only){
          if(prop.name==="Responsibilities" || prop.name==="Security Level"){
            return  null
          }
        }
        return  this.getNavItem(prop)
      })
      // userMenus = (<UncontrolledCollapse toggler="#toggler">{userMenus}</UncontrolledCollapse>)

      userMenus = this.getMenuGroupHeader("Users Setup", "users", userMenus)
      menus = [...menus, userMenus]
    }

    if (security && security.data_only) {
      _kegChartMenus = _kegChartMenus.map((prop, key) => {
        return this.getNavItem(prop)
      })

      _pourChartMenus = _pourChartMenus.map((prop, key) => {
        return this.getNavItem(prop)
      })
      
      _glycolChartMenus = _glycolChartMenus.map((prop, key) => {
        return this.getNavItem(prop)
      })
      
      _lineChartMenus = _lineChartMenus.map((prop, key) => {
        return this.getNavItem(prop)
      })

      _krChartMenus = _krChartMenus.map((prop, key) => {
        return this.getNavItem(prop)
      })

      dataMenus = dataMenus.map((prop, key) => {
        return this.getNavItem(prop)
      })
      dataMenus = this.getMenuGroupHeader(
        "Data Analytics",
        "dataanalytics",
        dataMenus,[{title:"Kegs",menus:_kegChartMenus},{title:"Pours",menus:_pourChartMenus},{title:"Glycol",menus:_glycolChartMenus},{title:"Line",menus:_lineChartMenus},{title:"KegRoom",menus:_krChartMenus}]
      )
      menus = [...menus, dataMenus]
    }

    if (security && security.data_only) {
      _kegManagement = _kegManagement.map((prop, key) => {
        return this.getNavItem(prop)
      })
      _kegManagement = this.getMenuGroupHeader(
        "Keg Management",
        "keg_management",
        _kegManagement
      )
      menus = [...menus, _kegManagement]
    }

    if (this.props.userDetails.superuser) {
      _adminMenus = _adminMenus.map((prop, key) => {
        return this.getNavItem(prop)
      })
      _adminMenus = this.getMenuGroupHeader(
        "Draftco Setup",
        "draftcoadmin",
        _adminMenus
      )
      menus = [...menus, _adminMenus]
    }


    if(security){
      _techAlarms = _techAlarms.map((prop, key) => {
        return this.getNavItem(prop)
      })
      _techAlarms = this.getMenuGroupHeader(
        "Alarms",
        "tech_alarm",
        _techAlarms
      )
      menus = [...menus, _techAlarms]
    }

    if(security){
      _subscriptions = _subscriptions.map((prop, key) => {
        return this.getNavItem(prop)
      })
      _subscriptions = this.getMenuGroupHeader(
        "Subscriptions",
        "report_subscriptions",
        _subscriptions
      )
      menus = [...menus, _subscriptions]
    }

    if (security && security.tech_only) {
      _techMaintenance = _techMaintenance.map((prop, key) => {
        return this.getNavItem(prop)
      })
      _techMaintenance = this.getMenuGroupHeader(
        "Maintenance",
        "tech_maintenance",
        _techMaintenance
      )
     
     
      menus = [...menus, _techMaintenance]
    }

    return menus
  }

  getMenuGroupHeader = (title, uniqueStr, menus,submenus) => {
    submenus=submenus??[];
    const key = `${uniqueStr}-header`
    const id = `toggler-${uniqueStr}`
    var iconClass = " dft-primary-color fa"
    switch (uniqueStr) {
      case "customers":
        iconClass = iconClass + " fa-cogs"
        break
      case "tech_maintenance":
        iconClass = iconClass + " fa-wrench"
        break
      case "users":
      default:
        iconClass = iconClass + " fa-users-cog"
        break
      case "dataanalytics":
        iconClass = iconClass + " fa-chart-bar"
        break
      case "keg_management":
        iconClass = iconClass + " fa-database"
        break
      case "report_subscriptions":
        iconClass = iconClass + " fa-bell"
        break
    }

    const toggleUpIcon = "fa fa-caret-up fa-lg"
    const toggleDownIcon = "fa fa-caret-down fa-lg"

    const isMenuNameInOpenMenus = this.state.openMenus.find((menu) => {
      return menu === uniqueStr
    })

    return (
      <div key={key}>
        <NavItem key={key + "-nav"} id={id}>
          <NavLink>
            <React.Fragment>
              <div className={iconClass}></div>
            </React.Fragment>
            &nbsp;&nbsp;&nbsp;{title}
            &nbsp;&nbsp;&nbsp;
            {menus.length > 0 ? (
              isMenuNameInOpenMenus ? (
                <React.Fragment>
                  <div className={toggleUpIcon}></div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className={toggleDownIcon}></div>
                </React.Fragment>
              )
            ) : null}
          </NavLink>
        </NavItem>
        <UncontrolledCollapse
          key={key + "-grp"}
          className="dft-sidebar-submenus"
          toggler={"#" + id}
          defaultOpen={false}
          onEntering={() => this.onCollapseEntering(uniqueStr)}
          onExiting={() => this.onCollapseExiting(uniqueStr)}
        >
          {submenus.length===0?menus:submenus.map((item)=>{return(this.getSubNavItem(item)) })}
        </UncontrolledCollapse>
      </div>
    )
  }

  onCollapseEntering = (uniqueStr) => {
    let openMenus = this.state.openMenus
    openMenus.push(uniqueStr)
    this.setState(openMenus)
  }
  onCollapseExiting = (uniqueStr) => {
    let openMenus = this.state.openMenus.filter((e) => e !== uniqueStr)

    this.setState({ openMenus: openMenus })
  }

  scrollToComponent = (to) => {
    scroller.scrollTo(to, {
      smooth: true,
      offset: 50,
      duration: 1000,
      isDynamic:true,
      delay:2000
   })
  }

  getSubNavItem = (prop) => {
    return (
      <NavItem key={prop.title}>
        <NavLink key={prop.title + "-nav"} id={prop.title}>
            <React.Fragment>
              <div className="dft-primary-color fa fa-chart-bar"></div>
            </React.Fragment>
            &nbsp;&nbsp;&nbsp;{prop.title}
            &nbsp;&nbsp;&nbsp;
          </NavLink>
          <UncontrolledCollapse
            key={prop.title + "-grp"}
            className="dft-sidebar-submenus"
            toggler={"#" + prop.title}
            defaultOpen={false}
            onEntering={() => this.onCollapseEntering(prop.title)}
            onExiting={() => this.onCollapseExiting(prop.title)}
          >
            {prop.menus}
        </UncontrolledCollapse>
      </NavItem>
    )
    // return(
    //   <Link key={prop.path} activeClass="active" to={prop.layout + prop.path}  spy={true} smooth={true}>
    //     <i className={prop.icon} />
    //     {prop.name}
    //   </Link>
    // )
  }

  getNavItem = (prop) => {
    return (
      <NavItem key={prop.path}>
        <NavLink
          to={prop.layout + prop.path}
          tag={NavLinkRRD}
          activeClassName="active"
          onClick={() => this.scrollToComponent(prop.layout + prop.path)}
        >
          <i className={prop.icon} />
          {prop.name}
        </NavLink>
      </NavItem>
    )
    // return(
    //   <Link key={prop.path} activeClass="active" to={prop.layout + prop.path}  spy={true} smooth={true}>
    //     <i className={prop.icon} />
    //     {prop.name}
    //   </Link>
    // )
  }
  render() {
    const { routes, logo } = this.props
    const { collapseOpen } = this.state
    let logoUrl = `${process.env.REACT_APP_CDN_BASE_URL}/assets/logo/Black/${process.env.REACT_APP_LOGO_URL}`;
    //let logoUrl = `../assets/img/draftco-test-logo-black.png`
    let navbarBrandProps
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      }
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      }
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          <div>
            <div className="top-nav">
              {logo ? (
                <NavbarBrand className="pt-0 pb-0" {...navbarBrandProps}>
                  <img
                    alt={logo.imgAlt}
                    className="navbar-brand-img"
                    src={logoUrl}
                  />
                  <hr />
                  <hr />
                </NavbarBrand>
              ) : null}
              <button
                className="navbar-toggler"
                type="button"
                onClick={this.toggleCollapse}
              >
                {collapseOpen ? (
                  <i className="fas fa-align-right"></i>
                ) : (
                  <i className="fas fa-align-justify"></i>
                )}
              </button>
            </div>
            <div navbar="true" className="mt-5">
              <Nav navbar>{this.createLinks(routes)}</Nav>
            </div>
          </div>
        </Container>
      </Navbar>
    )
  }
}

Sidebar.defaultProps = {
  routes: [{}],
}

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    //imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
}

const mapStateToProps = (state) => {
  return {
    sec_level: state.userInfo.security.sec_level,
  }
}

export default connect(mapStateToProps, null)(Sidebar)
