import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
//import axios from 'axios';
import moment from 'moment';
import {convertValueByMetric} from '../../utility/units-conversion'
import * as kegRoomActions from '../kegroom/store/kegroom.actions'
import * as monitorActions from '../dashboard/store/dashboard-actions';
import {
    Card,
    CardHeader,
    Row,
    Col,
    Label,
    CardBody,
    Button
} from "reactstrap";
import Select from "react-select";
import axios from 'axios';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

class BeerVolumeTrend extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            lineChart:{
                series:[],
                options:{}
            },
            filterOptions:[{label:"Last 12 months",value:12},{label:"Last 9 months",value:9},{label:"Last 6 months",value:6},{label:"Last 3 months",value:3}],
            selFilterOption:{label:"Last 12 months",value:12},
            loading:false,
            kegRooms:[],
            selKegRoom:null,
            monthlyVolumePoured:[],
            rangeSlider:{
                min:0,
                max:0,
                value:0,
                toolTip:""
            },
            selBeer:null,
            kegBeers:[]
        };
        this.chartRef=React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
       if(props.kegRooms.length>0){
        return {
            kegRooms:props.kegRooms.map((item=>{
                return {
                    value:item.kr_id,
                    label:item.kr_name
                }
            })),
            selKegRoom: {
                value:props.kegRooms[0].kr_id,
                label:props.kegRooms[0].kr_name
            }
        }
       }
       return null;
    }

    async componentDidMount(){
        await this.props.getKegRooms(this.props.site_id);
        if(this.props.kegRooms.length>0){
            this.loadMonthlyVolumeReport();
        }
    }

    handleChangeSelection = (item,inputPropName) => {
        if(inputPropName==="selBeer"){
            // var newSeries=[this.state.lineChart.series[0],item.beerTrendLine]
            // this.chartRef.current.chart.updateSeries(newSeries)
            // this.setState({
            //     selBeer:item
            //  });
        }
        else{
            this.setState({ [inputPropName]: item }, () => {
            });
        }
       
    }


    setRangeValue=(val)=>{
        var rangeOption=this.state.kegBeers.filter(s=>s.value===val);
        if(rangeOption.length===0) return
        var newSeries=[this.state.lineChart.series[0],rangeOption[0].beerTrendLine]
        this.chartRef.current.chart.updateSeries(newSeries)
        debugger
        this.setState({
            ...this.state,
            rangeSlider:{
                ...this.state.rangeSlider,
                value:val,
                toolTip:rangeOption[0].label
            }
        })
    }

    loadMonthlyVolumeReport=()=>{
        const units=this.props.units;
        let newState=this;
        this.setState({loading:true});
        var duration=this.state.selFilterOption.value;
        var den=units==="metric"?1000:1;
        var fromDate=moment(new Date()).add(-duration,'month').startOf('month').format("YYYY-MM-DD");
        var toDate=moment(new Date()).endOf('month').format("YYYY-MM-DD");
        //await this.props.getMonthlyVolumePoured(this.state.selKegRoom.value,current_year);
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/VolumeYearlyReport`, { params: { 
            kr_id: this.state.selKegRoom.value,from_date:fromDate,to_date:toDate } }).then((response) => {
			if (response.data !== undefined) {
                response.data.sort((a, b) => new Date(a.x_date) - new Date(b.x_date));
                var _lineData=[];
                var groupByMonth=  response.data.reduce(function (r, a) {
                    r[a.x_date] = r[a.x_date] || [];
                    r[a.x_date].push(a);
                    return r;
                }, Object.create(null));
                for(var key in groupByMonth){
                    var total_pour_volume = groupByMonth[key].map(o => o.total_pour_volume).reduce((a, c) => { return a + c });
                    _lineData.push({volume_poured:total_pour_volume,pour_date:key});
                }
                var groupByBeer=  response.data.reduce(function (r, a) {
                    r[a.beer_name] = r[a.beer_name] || [];
                    r[a.beer_name].push(a);
                    return r;
                }, Object.create(null));
                let _beers=[],rangeCounter=1;
                var volumeLine={name:"Volume Poured",type:"line",data:[]};
                //var series=[volumeLine];
                var colors=["#018FFB"];
                //response.data.sort((a, b) => parseInt(a.local_line_number)-parseInt(b.local_line_number));
                for(var keyBeer in groupByBeer){
                    var _beerTrendLine={name:"Beer Trend",data:[],type:"line"};
                    for(var j=0;j<duration;j++){
                        var d_j=moment(new Date()).add(-j,'month').startOf('month').format("YYYY-MM-DD");
                        // eslint-disable-next-line no-loop-func
                        var item_beer=groupByBeer[keyBeer].filter(s=>moment(s.x_date).format("YYYY-MM-DD")===d_j);
                        if(item_beer.length>0){
                            _beerTrendLine.data.push({x:moment(d_j)._d,y:(convertValueByMetric(item_beer[0].total_pour_volume,units,"vol")/den).toFixed(2)})
                        }
                        else{
                            _beerTrendLine.data.push({x:moment(d_j)._d,y:0})
                        }
                    }
                    _beerTrendLine.data.sort((a, b) =>new Date(a.x) - new Date(b.x));
                    _beers.push({label:keyBeer,value:rangeCounter,beerTrendLine:_beerTrendLine,line_number:groupByBeer[keyBeer][0].local_line_number})
                    //series.push(_beerTrendLine);
                    colors.push("#58EAB9");
                    rangeCounter++
                }
                //_beers.sort((a, b) => a.label.localeCompare(b.label));
                _beers.sort((a, b) => parseInt(a.line_number)-parseInt(b.line_number));
                var range_line=1;
                var _beersByLine=[];
                _beers.forEach((item)=>{
                    _beersByLine.push({
                        label:item.label,
                        value:range_line,
                        beerTrendLine:item.beerTrendLine,
                        line_number:item.line_number
                    })
                    range_line++
                })
                debugger
                var _rangerSlider={
                    min:0,
                    max:Math.max(..._beersByLine.map(s=>s.value)),
                    value:_beersByLine[0].value,
                    toolTip:_beersByLine[0].label
                }
                debugger
                var xAxis=[];
                var lineData=[];
                _lineData.forEach((item)=>{
                    var dt1=moment(item.pour_date);
                    xAxis.push(dt1._d);
                    lineData.push({x:dt1._d,y:(convertValueByMetric(item.volume_poured,units,"vol")/den).toFixed(2)})
                })
                var max=0;
                for(var i=0;i<duration;i++){
                    var d=moment(new Date()).add(-i,'month').startOf('month').format("YYYY-MM-DD");
                    // eslint-disable-next-line no-loop-func
                    var item=lineData.filter(s=>moment(s.x).format("YYYY-MM-DD")===d);
                    if(item.length>0){
                        volumeLine.data.push({x:item[0].x,y:item[0].y})
                        max=max>parseFloat(item[0].y)?max:parseFloat(item[0].y);
                    }
                    else{
                        var dt1=moment(d);
                        volumeLine.data.push({x:dt1._d,y:0})
                    }
                }
                volumeLine.data.sort((a, b) => new Date(a.x) - new Date(b.x));
                debugger
                var chartData={
                    series:[volumeLine,_beers[0].beerTrendLine],
                    options: {
                        chart: {
                          height: 400,
                          id:'apexchartsfcp10ctwj',
                          //stacked: true,
                          type:'line',
                          //stackType: '100%',
                          zoom: {
                            enabled: false
                          }
                        },
                        // tooltip:{
                        //     custom: function({series, seriesIndex, dataPointIndex, w}) {
                        //         var data = w.globals.initialSeries.map((item)=>{
                        //             if(parseFloat(item.data[dataPointIndex].y)!==0){
                        //                 return {
                        //                     name:item.name,
                        //                     label:parseFloat(item.data[dataPointIndex].y)
                        //                 }
                        //             }else{
                        //                 return null
                        //             }
                                   
                        //         });

                        //         var d=moment(w.globals.initialSeries[seriesIndex].data[dataPointIndex].x).format("MMM");
                        //         var beer_info=data.length!==0?data.filter(i=>i!==null).map((item)=>{
                        //             return `<div class="apexcharts-tooltip-y-group">
                        //                         <span class="apexcharts-tooltip-text-y-label">${item.name}: </span>
                        //                         <span class="apexcharts-tooltip-text-y-value">${item.label.toFixed(2)}</span>
                        //                     </div>`
                        //         }):[`<div class="apexcharts-tooltip-y-group">
                        //         <span class="apexcharts-tooltip-text-y-label">Volume : </span>
                        //         <span class="apexcharts-tooltip-text-y-value">${data.y}</span>
                        //     </div>`]
                        //         return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${d}</div>
                        //         <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                        //         <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        //         ${beer_info.join(" ")}                                
                        //        </div></div>`
                        //     }
                        // },
                        toolbar:{
                            show:false
                        },
                        legend:{
                            show:true,
                            position:"right"
                        },
                        dataLabels: {
                          enabled: false
                        },
                        //colors:colors,
                        stroke: {
                          curve: 'straight',
                          width: [3],
                          //dashArray: [0, 0, 0,2,2]
                        },
                        title: {
                          text: `Volume Trend`,
                          align: 'left'
                        },
                        grid: {
                          row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                          },
                        },
                        xaxis: {
                          categories: xAxis,
                          type: "datetime",
                          tickAmount:12,
                          labels: {
                            datetimeUTC: false,
                            rotateAlways: false,
                            rotate: 0,
                            //tickPlacement: 'on',
                            formatter: function(val, timestamp) {
                                return moment(val).format("MMM");; // formats to hours:minutes
                            }  
                          }
                        },
                        yaxis: [
                            {
                              //max:(max+100),
                              seriesName: 'Volume Poured',
                              axisTicks: {
                                show: true
                              },
                              axisBorder: {
                                show: true,
                                color: "#018FFB"
                              },
                              labels: {
                                style: {
                                  colors: "#018FFB"
                                },
                                formatter: function (value) {
                                    return value.toFixed(0)//.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                }
                              },
                              title: {
                                text: units==="metric"?"Volume (L)":"Volume (ou)",
                                style: {
                                  color: "#018FFB"
                                }
                              }
                            },
                            {
                                seriesName: 'Beer Trend',
                                opposite: true,
                                min:0,
                                axisTicks: {
                                  show: true
                                },
                                axisBorder: {
                                  show: true,
                                  color: "#58EAB9"
                                },
                                labels: {
                                  style: {
                                    colors: "#58EAB9"
                                  },
                                  formatter: function (value) {
                                      return value.toFixed(0)//.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                  }
                                },
                                title: {
                                  text: units==="metric"?"Volume (L)":"Volume (ou)",
                                  style: {
                                    color: "#58EAB9"
                                  }
                                }
                              }
                          ]
                      }
                }
                newState.setState({
                    ...newState.state,
                    lineChart:chartData,
                    kegBeers:_beersByLine,
                    selBeer:_beersByLine[0],
                    loading:false,
                    rangeSlider:_rangerSlider
                });
			}
		});
    }

    render(){
        return(
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Monthly Volume Trend</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Select Range</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selFilterOption}
                                    options={this.state.filterOptions}
                                    onChange={(e) => this.handleChangeSelection(e, 'selFilterOption')}>
                                </Select>
                            </Col>
                            {/* <Col md="4" sm="4" lg="4">
                                <Label>Select Beer</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selBeer}
                                    options={this.state.kegBeers}
                                    onChange={(e) => this.handleChangeSelection(e, 'selBeer')}>
                                </Select>
                            </Col> */}
                        </Row>
                        <br /><br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.loadMonthlyVolumeReport} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                        {/* <Row >
                            <Col md="4" sm="4" lg="4">
                                <Label>Select Beer</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selBeer}
                                    options={this.state.kegBeers}
                                    onChange={(e) => this.handleChangeSelection(e, 'selBeer')}>
                                </Select>
                                <br /><br /><br />
                            </Col>
                        </Row> */}
                        <Row>
                            <Col md="10" sm="10" lg="10" >
                            <Slider
                                value={this.state.rangeSlider.value}
                                min={this.state.rangeSlider.min}
                                max={this.state.rangeSlider.max}
                                onChange={this.setRangeValue}
                                />
                            <Label>{this.state.rangeSlider.toolTip}</Label>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                            <Col md="12" sm="12" lg="12"  id='monthly_volume_chart'>
                                <ReactApexChart ref={this.chartRef} options={this.state.lineChart.options} type="line" series={this.state.lineChart.series} width="100%" height="400" />
                            </Col>
                           
                        </Row>
                       
                    </CardBody>
                </Card>
            </React.Fragment>)
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        kegs: state.monitor.kegs,
        last_pour_item: state.monitor.last_pour_item,
        kegRooms:state.kegRooms.kegRooms,
        monthlyVolumePoured:state.monitor.monthlyVolumePoured,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
      getMonthlyVolumePoured:(kr_id,current_year)=>dispatch(monitorActions.getMonthlyVolumePoured(kr_id,current_year))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeerVolumeTrend);