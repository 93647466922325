import {generatePdf} from '../pdfGenerator';

export const DownloadPdf=(columns,rows,title,companyLogo,profileLogo,reportName)=>{
    var tblColumns=columns.filter(col => col.hidden===undefined || !col.hidden).map(col=>col.text);
    var tblColumnsKeys=columns.filter(col => col.hidden===undefined || !col.hidden);
    var tblRows=[];
    var columnStyles={};
    var headerStyles={};
    tblColumnsKeys.forEach((item,index)=>{
        headerStyles[index]={halign:'center'}
        if(item.align){
            columnStyles[index]={halign:item.align}
        }
        else{
            columnStyles[index]={halign:'left'}
        }
    })
    rows.forEach((item,index)=>{
        var row=[];
        tblColumnsKeys.forEach((item1,index1)=>{
            row.push(item[item1.dataField]);
        })
        tblRows.push(row);
    })
    generatePdf(tblColumns,tblRows,title,companyLogo,profileLogo,reportName,columnStyles,headerStyles);
}