import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
//import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
//import { Link } from "react-router-dom";
import LoaderComponent from '../shared/loader';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AdvancedFilter from "../shared/advancedFilter";
import * as kegActions from '../keg/store/keg.actions';
import * as beersActions from '../beers/store/beers.actions';
import * as kegRoomActions from '../kegroom/store/kegroom.actions';
import { withRouter } from 'react-router-dom';

class KegList extends React.Component {
  constructor() {
    super();
    this.state = {
      kegs: [],
      filteredKegList: [],
      fields: [{ value: 'keg_id', label: 'Id', type: 'int' }, { value: 'draft_beer_name', label: 'Beer', type: 'text' }, { value: 'kr_name', label: 'Keg Room', type: 'text' }, { value: 'percent_remaining', label: '% Remaining', type: 'text' }],
      showAdvancedSearch: false,
      showList: false,
    };
  }
  
  async componentDidMount () {
    var selected_site_id = this.props.site_id;
    //await this.props.getBeers();
    await this.props.getKegRooms(selected_site_id);
    this.props.setSelKegRoom(Object.assign({}, this.props.kegRooms[0]));
    //await this.props.getKegs(this.props.kegRooms[0].kr_id);
    await this.props.getKegs(selected_site_id);
    this.props.setSelKeg(Object.assign({}, this.props.kegs[0]))
  }

  static getDerivedStateFromProps(props, state) {
    if (state.kegs.length === 0) {
      return {
        kegs:props.kegs,
        selKeg:props.selKeg,
        filteredKegList: props.kegs,
        showList: props.showList
      }
    }
    return null;
  }

  toggleAdvancedSearch = () => {
    let newState = Object.assign({}, this.state);
    newState.showAdvancedSearch = !newState.showAdvancedSearch;
    if (!newState.showAdvancedSearch) {
      newState.filteredKegList = newState.kegs;
    }
    this.setState(newState);
  }

  callBackFromAdvancedSearch = (data) => {
    let newState = Object.assign({}, this.state);
    newState.filteredKegList = data;
    this.setState(newState);
  }

  addKegroom = () => {
    this.props.clearSelKeg();
    if (this.state.kr_id && this.state.kr_id > 0)
      window.location = "#/admin/keg/0/kegroom/" + this.state.kr_id;
    else
      window.location = "#/admin/keg/0";
  };

  editKeg=(keg)=>{
    this.props.setSelKeg(Object.assign({}, keg));
    //window.location = `/admin/keg/${keg.keg_id}/kegroom/${keg.kr_id}`;
    this.props.history.push(`/admin/keg/${keg.keg_id}/kegroom/${keg.kr_id}`);
  }

  filterKegList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredKegList: [...this.state.kegs] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredKegs = this.state.kegs.filter(
        (com) =>
          com.kr_name.toLowerCase().indexOf(searchText) >= 0 ||
          com.draft_beer_name.toLowerCase().indexOf(searchText) >= 0
      );
      this.setState({ filteredKegList: [...filteredKegs] });
    }
  };

  render() {
    const options = {
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const columns = [
      {
        dataField: "keg_id",
        text: "Keg Id",
        hidden: true,
      },
      {
        dataField: "draft_beer_name",
        text: "Beer",
        sort: true,
      },
      {
        dataField: "kr_name",
        text: "Keg Room",
        sort: true,
      },
      {
        dataField: "volume",
        text: "Size (ml)",
        sort: true,
        align:'right',
        formatter: (cell, row) => {
          return `${cell}`
        }
      },
      {
        dataField: "percent_remaining",
        text: "% Remaining",
        sort: true,
        align:'right',
        formatter: (cell, row) => {
          return `${cell}`
        }
      },
      // {
      //   dataField: "kr_in_date",
      //   text: "Kr In Date",
      //   sort: true,
      // },
      // {
      //   dataField: "kr_out_date",
      //   text: "Kr Out Date",
      //   sort: true,
      // },
      {
        dataField: "volume_poured",
        text: "Volume Poured (ml)",
        sort: true,
        align:'right',
        formatter: (cell, row) => {
          return `${cell}`
        }
      },
      // {
      //   dataField: "connected_start_time",
      //   text: "Connected Start Time",
      //   sort: true,
      // },
      // {
      //   dataField: "connected_end_time",
      //   text: "Connected End Time",
      //   sort: true,
      // },
      {
        dataField: "connected",
        text: "Connected",
        sort: true,
        formatter: (cell, row) => {
          return cell ? 'Yes' : 'No'
        }
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
               <button
                className="btn btn-sm btn-info"
                onClick={() => this.editKeg(row)}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" style={{ width: '100%' }} id="/admin/kegs">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
              <h3 className="dft-primary-color">Kegs</h3>
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterKegList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              <Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addKegroom}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.kegs} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--4">
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="keg_id"
                    data={this.state.filteredKegList}
                    pagination={paginationFactory(options)}
                    columns={columns}
                  />
                ) : this.state.showList === false ? (
                  <LoaderComponent />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    kegRooms: state.kegRooms.kegRooms,
    kegs:state.kegs.kegs,
    showList:state.kegs.showList,
    selKeg:state.kegs.selKeg,
    currPage: state.kegs.currPage
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
    setSelKegRoom:(kr)=>dispatch(kegRoomActions.setSelKegRoom(kr)),
    getBeers: () => dispatch(beersActions.getBeers()),
    getKegs:(kr_id)=>dispatch(kegActions.getKegs(kr_id)),
    setCurrPage: (page) => dispatch(kegActions.setCurrPage(page)),
    setSelKeg:(keg)=>dispatch(kegActions.setSelKeg(keg)),
    clearSelKeg:()=>dispatch(kegActions.clearSelKeg()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(KegList));
