import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
//import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { formatNumbers } from "../../utility/common";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import LoaderComponent from '../shared/loader';
import { connect } from "react-redux";
import * as lineActions from './store/line.actions';

class LineList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //kr_id: this.props.kr_id === undefined ? 0 : this.props.kr_id,
      lines: [],
      filteredLinesList: [],
      //expanded: [],
      showList: false,
      selLine:null,
      //depthLevel: this.props.depth_level ? this.props.depth_level : 0,
    };
  }

  async componentDidMount () {
    //var selected_site_id = this.props.site_id;
    await this.props.getLines();
    this.props.setSelLine(Object.assign({}, this.props.lines[0]));
  }

  static getDerivedStateFromProps(props, state) {
    if (state.lines.length === 0) {
      return {
        lines:props.lines,
        selLine:props.selLine,
        filteredLinesList: props.lines,
        showList: props.showList
      }
    }
    return null;
  }

  // componentDidMount() {
  //   if (this.state.depthLevel === 0) {
  //     this.fetchLines();
  //   }
  // }

  // fetchLines = () => {
  //   if (this.state.kr_id) {
  //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/line`, { params: { kr_id: this.state.kr_id } }).then(response => {

  //       if (response.data) {
  //         this.setLineModel(response.data);
  //       }
  //     });
  //   } else {
  //     if (this.state.depthLevel === 0) {
  //       axios(`${process.env.REACT_APP_API_BASE_URL}/api/line`).then(response => {
  //         if (response.data) {
  //           this.setLineModel(response.data);
  //         }
  //       });
  //     }
  //     else {
  //       this.setLineModel([]);
  //     }
  //   }
  // }

  // setLineModel = (sites) => {
  //   //let newState = Object.assign({}, this.state);
  //   sites = sites.sort((a, b) => a.local_line_number - b.local_line_number);
  //   var linesData = [];
  //   sites.forEach((item) => {
  //     linesData.push({
  //       line_id: item.line_id,
  //       local_line_number: item.local_line_number,
  //       sensor_id: item.sensor_id,
  //       keg_id: item.keg_id,
  //       // connected: item.connected,
  //       // volume_poured: item.volume_poured,
  //       // line_last_clean_date: item.line_last_clean_date,
  //       sensor_serial_number: item.sensor.sensor_serial_number,
  //       draft_beer_name: item.keg.db_keg.name,
  //       // line_status: item.line_status,
  //       pressure: item.pressure,
  //       line_length: item.line_length
  //     });
  //   });

  //   linesData = linesData.sort((a, b) => a.local_line_number - b.local_line_number);
  //   this.setState({ lines: linesData, filteredLinesList: linesData, showList: true });
  // }

  // fetchLinesFromParent(krId) {
  //   this.setState({
  //     kr_id: krId
  //   });
  //   this.fetchLines();
  // }

  addLine = () => {
    var local_line_number = this.state.lines.length;
    window.location = `#/admin/line/0/kr/${this.props.selKegRoom.kr_id}?localline=${local_line_number}`;
  };

  filterLineList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredLineList: [...this.state.lines] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredLines = this.state.lines.filter(
        (com) =>
          com.sensor_serial_number.toLowerCase().indexOf(searchText) >= 0 ||
          com.draft_beer_name.toLowerCase().indexOf(searchText) >= 0
      );
      this.setState({ filteredLineList: [...filteredLines] });
    }
  };

  render() {

    const options = {
      //sizePerPageRenderer,
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const columns = [
      {
        dataField: "line_id",
        text: "Line Id",
        hidden: true,
      },
      {
        dataField: "local_line_number",
        text: "Line #",
        sort: true,
      },
      {
        dataField: "sensor_serial_number",
        text: "Sensor",
        sort: true,
      },
      {
        dataField: "draft_beer_name",
        text: "Beer",
        sort: true,
      },
      // {
      //   dataField: "connected",
      //   text: "Connected",
      //   sort: true,
      //   formatter: (cell, row) => {
      //     return cell ? 'Yes' : 'No'
      //   }
      // },
      // {
      //   dataField: "volume_poured",
      //   text: "Volume",
      //   sort: true,
      //   formatter: (cell, row) => {
      //     return `${cell} ml`
      //   }
      // },
      {
        dataField: "pressure",
        text: "Pressure (psi)",
        sort: true,
        align:'right',
        formatter: (cell, row) => {
          return `${formatNumbers(cell)}`
        }
      },
      // {
      //   dataField: "line_last_clean_date",
      //   text: "Last Clean Date",
      //   sort: true,
      // },
      {
        dataField: "line_length",
        text: "Length(mtr)",
        sort: true,
        align:'right',
        formatter: (cell, row) => {
          return `${formatNumbers(cell)}`
        }
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link
                className="btn btn-sm btn-info"
                to={`/admin/line/${row.line_id}/kr/${this.props.selKegRoom.kr_id}`}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => this.customDelete(row)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" id="/admin/lines">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
              <h3 className="dft-primary-color">Lines</h3>
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterLineList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="6" lg="6">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addLine}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--5">
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="line_id"
                    data={this.state.filteredLinesList}
                    pagination={paginationFactory(options)}
                    columns={columns}
                  />
                ) : this.state.showList === false ? (
                  <LoaderComponent />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    lines: state.lines.lines,
    selLine:state.lines.selLine,
    showList:state.lines.showList,
    currPage: state.lines.currPage,
    selKegRoom:state.kegRooms.selKegRoom
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrPage: (page) => dispatch(lineActions.setCurrPage(page)),
    getLines:()=>dispatch(lineActions.getLines()),
    setSelLine:(line)=>dispatch(lineActions.setSelLine(line))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LineList);
