import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
//import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import LoaderComponent from '../shared/loader';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AdvancedFilter from "../shared/advancedFilter";
// import McuList from "../mcu";
import LineList from "../line";
// import KegList from "../keg/kegList";
import { connect } from "react-redux";
import * as sitesActions from '../sites/store/site.actions';
import * as kegRoomActions from './store/kegroom.actions';
import * as customerActions from '../customer/store/customer.actions';
import * as personActions from '../persons/store/person.actions';
import KegList from "../keg/kegList";
import Mcu from "../mcu";
//import TowerList from "../towers/towerList";

class KegroomList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kegRooms: [],
      filteredKegRoomsList: [],
      fields: [{ value: 'kr_id', label: 'Id', type: 'int' }, { value: 'kr_name', label: 'Name', type: 'text' }, { value: 'site_name', label: 'Site', type: 'text' }],
      showAdvancedSearch: false,
      showList: false,
      selKegRoom: null,
      collapse: false
    };
  }

  async componentDidMount () {
    var selected_site_id = this.props.site_id;
    //await this.props.getPersons();
    //await this.props.getCustomers(selected_site_id);
    //await this.props.getSites(selected_site_id)
    //this.props.setSelSite(Object.assign({}, this.props.sites[0]));
    await this.props.getKegRooms(selected_site_id);
    this.props.setSelKegRoom(Object.assign({}, this.props.kegRooms[0]));
  }

  static getDerivedStateFromProps(props, state) {
    if (state.kegRooms.length === 0) {
      return {
        kegRooms:props.kegRooms,
        selKegRoom:props.selKegRoom,
        filteredKegRoomsList: props.kegRooms,
        showList: props.kegRooms.length>0
      }
    }
    return null;
  }


  toggleAdvancedSearch = () => {
    let newState = Object.assign({}, this.state);
    newState.showAdvancedSearch = !newState.showAdvancedSearch;
    if (!newState.showAdvancedSearch) {
      newState.filteredKegRoomsList = newState.kegRooms;
    }
    this.setState(newState);
  }

  callBackFromAdvancedSearch = (data) => {
    let newState = Object.assign({}, this.state);
    newState.filteredKegRoomsList = data;
    this.setState(newState);
  }

  // fetchKegroomsFromParent(siteId) {
  //   this.setState({
  //     site_id: siteId
  //   });
  //   this.fetchSites();
  // }

  // fetchSites = () => {
  //   // Load the list
  //   axios(`${process.env.REACT_APP_API_BASE_URL}/api/sites`).then((response) => {
  //     if (response.data !== undefined) {
  //       let sites = [];
  //       response.data.forEach((item) => {
  //         sites.push({
  //           site_id: item.site_id,
  //           name: item.site_name,
  //         });
  //       });
  //       this.setState({ sites: sites }, () => {
  //         this.fetchKegRooms();
  //       });
  //     }
  //   });
  // }

  // fetchKegRooms = () => {
  //   if (this.state.site_id) {
  //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {

  //       if (response.data !== undefined) {
  //         this.setKegroomModel(response.data);
  //       }
  //     });
  //   } else {
  //     if (this.state.depthLevel === 0) {
  //       axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom?site_id=${this.props.site_id}`).then((response) => {
  //         if (response.data !== undefined) {
  //           this.setKegroomModel(response.data);
  //         }
  //       });
  //     }
  //     else {
  //       this.setKegroomModel([]);
  //     }
  //   }
  // }

  // setKegroomModel = (kegrooms) => {

  //   //let newState = Object.assign({}, this.state);
  //   var kegroomsData = [];
  //   kegrooms.forEach((item) => {
  //     let siteData = this.state.sites.find(p => p.site_id === item.site_id);
  //     kegroomsData.push({
  //       kr_id: item.kr_id,
  //       kr_name: item.kr_name,
  //       number_kegs: item.number_kegs,
  //       site_id: item.site_id,
  //       site_name: siteData.name,
  //     });
  //   });

  //   kegroomsData = kegroomsData.sort((a, b) => a.kr_name.localeCompare(b.kr_name));

  //   if (this.state.depthLevel < 2 && kegroomsData.length) {
  //     this.loadChildComponents(kegroomsData[0].kr_id);
  //   }
  //   else {
  //     this.loadChildComponents(0);
  //   }

  //   this.setState({ kegrooms: kegroomsData, filteredKegroomList: kegroomsData, showList: true });
  // }

  addKegroom = () => {
    if (this.state.site_id && this.state.site_id > 0)
      window.location = "#/admin/kegroom/0/site/" + this.state.site_id;
    else
      window.location = "#/admin/kegroom/0";
  };

  filterKegRoomList = (event) => {
    let searchText = event.target.value;
    let filteredKegRoomsList = [];
    if (!searchText) {
      filteredKegRoomsList = this.state.kegRooms;
      //this.setState({ filteredKegroomList: [...this.state.kegrooms] });
    } else {
      searchText = searchText.toLowerCase();
      filteredKegRoomsList = this.state.kegRooms.filter(
        (com) =>
          com.kr_name.toLowerCase().indexOf(searchText) >= 0
      );
      //this.setState({ filteredKegroomList: [...filteredKegrooms] });
    }

    // if (this.state.depthLevel < 2) {
    //   if (filteredKegrooms.length) {
    //     this.loadChildComponents(filteredKegrooms[0].kr_id);
    //   }
    //   else {
    //     this.loadChildComponents(0);
    //   }
    // }

    this.setState({ filteredKegRoomsList: [...filteredKegRoomsList] });
  };

  // addSite = () => {
  //   if (this.state.customer_id && this.state.customer_id > 0)
  //     window.location = "#/admin/site/0/customer/" + this.state.customer_id;
  //   else
  //     window.location = "#/admin/site/0";
  // };

  // filteredSitesList = (event) => {
  //   let searchText = event.target.value;
  //   if (!searchText) {
  //     this.setState({ filteredSitesList: [...this.state.kegrooms] });
  //   } else {
  //     searchText = searchText.toLowerCase();
  //     const filteredSites = this.state.sites.filter(
  //       (com) =>
  //         com.name.toLowerCase().indexOf(searchText) >= 0 ||
  //         com.country.toLowerCase().indexOf(searchText) >= 0 ||
  //         com.address1.toLowerCase().indexOf(searchText) >= 0 ||
  //         com.address2.toLowerCase().indexOf(searchText) >= 0
  //     );
  //     this.setState({ filteredSitesList: [...filteredSites] });
  //   }
  // };

  // loadChildComponents = (siteId) => {
  //   this.selectedSiteId = siteId;
  //   if (this.state.depthLevel < 2 && this.refKegs.current!==null &&this.refMucs.current!==null && this.refLines.current!==null) {
  //     this.refKegs.current.fetchKegsFromParent(siteId);
  //     this.refMucs.current.fetchMcusFromParent(siteId);
  //     this.refLines.current.fetchLinesFromParent(siteId);
  //   }
  // }

  setRowSelected = (elem) => {
    for (let i = 0; i < elem.parentNode.getElementsByTagName('tr').length; i++) {
      elem.parentNode.getElementsByTagName('tr')[i].className = '';
    }
    elem.className = 'selected';
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  setKegRoom=(kr)=>{
    this.props.setSelKegRoom(Object.assign({}, kr));
  }

  render() {

    const options = {
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (this.state.depthLevel < 2) {
          //this.loadChildComponents(row.kr_id);
          this.setKegRoom(row)
          this.setRowSelected(e.currentTarget);
        }
      }
    };

    const rowClasses = (row, rowIndex) => {
      return rowIndex === 0 ? 'selected' : '';
    };

   

    const columns = [
      {
        dataField: "kr_id",
        text: "KR Id",
        hidden: true,
      },
      {
        dataField: "kr_name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "site_name",
        text: "Site",
        sort: true,
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link
                className="btn btn-sm btn-info"
                to={`/admin/kegroom/${row.kr_id}/site/${row.site_id}`}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" style={{ width: '100%' }} id="/admin/kegroom">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
              <h3 className="dft-primary-color">Kegrooms</h3>
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterKegRoomList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              <Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addKegroom}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.sites} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--4">
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                <div style={{ width: '100%' }}>
                  {this.state.showList === true ? (
                    <BootstrapTable
                      classes="custom-table-css dft-bg-primary-color"
                      striped
                      hover
                      condensed
                      keyField="kr_id"
                      data={this.state.filteredKegRoomsList}
                      pagination={paginationFactory(options)}
                      columns={columns}
                      rowEvents={rowEvents}
                      rowClasses={rowClasses}
                    />
                  ) : this.state.showList === false ? (
                    <LoaderComponent />
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {this.props.selKegRoom.kr_id!==0?
        <>
        <KegList />
        <Mcu />
        <LineList />
        {/* <TowerList /> */}
        </>
        :""}
        {/* {this.state.depthLevel < 2 ? (
          <Card style={{ width: '100%' }}>
            <CardBody className="px-lg-5 py-lg-5">
              <Row>
                <h3 className="dft-primary-color">Keg</h3>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <KegList kr_id={this.selectedKegroomId} ref={this.refKegs} depth_level={this.state.depthLevel + 1} />
                </Col>
              </Row>
              
              <Row>
                <Col sm="12" md="12" lg="12">
                  <McuList kr_id={this.selectedKegroomId} ref={this.refMucs} depth_level={this.state.depthLevel + 1} />
                </Col>
              </Row>
              <Row>
                <h3 className="dft-primary-color">Lines</h3>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <LineList kr_id={this.selectedKegroomId} ref={this.refLines} depth_level={this.state.depthLevel + 1} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        ) : ""} */}
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    kegRooms: state.kegRooms.kegRooms,
    selKegRoom:state.kegRooms.selKegRoom,
    currPage: state.kegRooms.currPage,
    sites:state.sites.sites
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPersons: () => dispatch(personActions.getPersons()),
    getSites:(site_id)=>dispatch(sitesActions.getSites(site_id)),
    setSelSite:(site)=>dispatch(sitesActions.setSelSite(site)),
    getCustomers: (site_id) => dispatch(customerActions.getCustomers(site_id)),
    setCurrPage: (page) => dispatch(kegRoomActions.setCurrPage(page)),
    getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
    setSelKegRoom:(kr)=>dispatch(kegRoomActions.setSelKegRoom(kr))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KegroomList);
