import React from "react";
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Label,
  Row,
  Col,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";
import * as ktsActions from './store/kr_temperature.actions';
import * as kegRoomActions from '../kegroom/store/kegroom.actions';
import * as sensorActions from '../sensor/store/sensor.actions';
import * as sensorHubActions from '../sensor_hub/store/sensor_hub.actions';
import * as mcuActions from '../mcu/store/mcu.actions';
import Select from "react-select";

class KrTemperatureSensorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     selKts: {
        kts_id:0,
        kr_id:0,
        kr_name:"",
        sensor_id:0,
        controller_sn:"",
        sensor_hub_sn:"",
        port:0,
        sensor_serial_number:""
      },
      kegRooms:[],
      sensors:[],
      mcus:[],
      sensorHubs:[],
      selKegRoom:null,
      selSensor:null,
      selMcu:null,
      selSensorHub:null,
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  async componentDidMount() {
    await this.props.getKegRooms(this.props.site_id)
    await this.props.getMcus(this.props.site_id)
    await this.props.getSensorHubs(this.props.site_id)
    let selKr=null,selMcu=null,selSh=null,_sensorHubs=[],_sensors=[],_selSensor=null;
    if(this.props.selKts.kts_id===0){
        selKr=this.state.kegRooms[0]
        selMcu=this.state.mcus[0]
        if(this.props.sensorHubs.length>0){
            _sensorHubs=this.props.sensorHubs.filter(s=>s.mcu_serial_number===selMcu.label && s.status==="Online")
                        .map((item)=>{
                            return {
                                label:item.serial_number,
                                value:item.id
                              }
                        });

            selSh=_sensorHubs.length>0?_sensorHubs[0]:null
            this.props.setSelSh(this.props.sensorHubs.find(sh=>sh.id===selSh.value))
            await this.props.getAvailableSensors(selSh.value);
        }
        if(this.props.freeSensors.length>0){
            _sensors=this.props.freeSensors.filter(s=>s.status==="Online")
            .map((item)=>{
                return {
                    label:item.serial_number,
                    value:item.id
                  }
            });
            _selSensor=_sensors.length>0?_sensors[0]:null
        }
    }
    else{
        selKr=this.state.kegRooms.find(k=>k.value===this.props.selKts.kr_id)
        selMcu=this.state.mcus.find(k=>k.label===this.props.selKts.controller_sn)
        if(this.props.sensorHubs.length>0){
            _sensorHubs=this.props.sensorHubs.filter(s=>s.mcu_serial_number===selMcu.label && s.status==="Online")
                        .map((item)=>{
                            return {
                                label:item.serial_number,
                                value:item.id
                              }
                        });
            selSh=_sensorHubs.length>0?(_sensorHubs.find(sh=>sh.label===this.props.selKts.sensor_hub_sn)??null):null;
            if(selSh!==null){
                this.props.setSelSh(this.props.sensorHubs.find(sh=>sh.id===selSh.value))
                await this.props.getAvailableSensors(selSh.value);
            }
        }
        if(this.props.freeSensors.length>0){
            _sensors=this.props.freeSensors.filter(s=>s.status==="Online")
            .map((item)=>{
                return {
                    label:item.serial_number,
                    value:item.id
                  }
            });
            //_selSensor=_sensors.length>0?_sensors.find(s=>s.value===this.props.selKts.sensor_id)??null:null;
            _selSensor={label:this.props.selKts.sensor_serial_number,value:this.props.selKts.sensor_id};
        }
        else{
          _selSensor={label:this.props.selKts.sensor_serial_number,value:this.props.selKts.sensor_id};
        }
    }
   
    this.setState({
        ...this.state,
        selKegRoom:selKr,
        selMcu:selMcu,
        selSensorHub:selSh,
        sensorHubs:_sensorHubs,
        sensors:_sensors,
        selSensor:_selSensor
    })
  }

  static getDerivedStateFromProps(props, state) {
    let _kr_rooms=[],_mcus=[];
    if(props.kegRooms.length>0){
        _kr_rooms =props.kegRooms.map((item)=>{
          return {
            label:item.kr_name,
            value:item.kr_id
          }
        })
    }
    if(props.mcus.length>0){
        _mcus =props.mcus.filter(m=>m.status==="Online").map((item)=>{
          return {
            label:item.serial_number,
            value:item.id
          }
        })
    }
    return {
        selKts:props.selKts,
        kegRooms:_kr_rooms,
        mcus:_mcus
      }
  }
  

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.selKts[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.selKts[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }

  handleChangeSelection = async(item, inputPropName) => {
    // const newState = Object.assign({}, this.state);
    // newState[inputPropName] = item;
    // this.setState(newState);
    let _sensorHubs=[],_sensors=[],_selSensor=null;
    if(inputPropName==="selMcu"){
        if(this.props.sensorHubs.length>0){
            _sensorHubs=this.props.sensorHubs.filter(s=>s.mcu_serial_number===item.label && s.status==="Online")
                        .map((item)=>{
                            return {
                                label:item.serial_number,
                                value:item.id
                              }
                        });
            if(_sensorHubs.length>0){
                this.props.setSelSh(this.props.sensorHubs.find(sh=>sh.id===_sensorHubs[0].value))
                await this.props.getAvailableSensors(_sensorHubs[0].value);
                if(this.props.freeSensors.length>0){
                    _sensors=this.props.freeSensors.filter(s=>s.status==="Online")
                    .map((item)=>{
                        return {
                            label:item.serial_number,
                            value:item.id
                          }
                    });
                    _selSensor=_sensors.length>0?_sensors[0]:null
                }
            }
        }
        
        this.setState({
            ...this.state,
            selMcu:item,
            sensorHubs:_sensorHubs,
            selSensor:_selSensor,
            sensors:_sensors,
            selSensorHub:_sensorHubs.length>0?_sensorHubs[0]:null
        })
    }
    else if(inputPropName==="selSensorHub"){
        this.props.setSelSh(this.props.sensorHubs.find(sh=>sh.id===item.value))
        await this.props.getAvailableSensors(item.value);
        if(this.props.freeSensors.length>0){
            _sensors=this.props.freeSensors.filter(s=>s.status==="Online")
            .map((item)=>{
                return {
                    label:item.serial_number,
                    value:item.id
                  }
            });
            _selSensor=_sensors.length>0?_sensors[0]:null
        }
        this.setState({
            ...this.state,
            selSensor:_selSensor,
            sensors:_sensors,
            selSensorHub:item
        })
    }
    else if(inputPropName==="selSensor"){
        this.setState({
            ...this.state,
            selSensor:item
        })
    }
  }

  saveKts = async () => {
    if (!this.validator.allValid()) {
        this.validator.showMessages();
        return;
      } 
    this.setState({ saveInProgress: true });
    const sensorInfo=this.props.freeSensors.find(s=>s.id===this.state.selSensor.value);
    const ktsInfo = {
        kts_id:this.state.selKts.kts_id,
        kr_id:this.state.selKegRoom.value,
        sensor_id:this.state.selSensor.value,
        port:sensorInfo.port,
        controller_sn:this.state.selMcu.label,
        sensor_hub_sn:this.state.selSensorHub.label,
        kr_name:this.state.selKegRoom.label,
        sensor_serial_number:this.state.selSensor.label
    }
    if (ktsInfo.kts_id === 0) {
      await this.props.addKts(ktsInfo);
    }
    else {
      await this.props.updateKts(ktsInfo);
    }
    this.setState({ saveInProgress: false });
    this.props.history.push(`/admin/kts`);
  }


  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.selKts.kts_id === 0 ? "Add Keg Room Temperature" : "Edit Keg Room Temperature"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
                <Col md="4" sm="4" lg="4">
                    <Label> Keg Room<span style={{color:'red'}}>*</span></Label>
                    <Select size="sm" value={this.state.selKegRoom}
                        options={this.state.kegRooms}
                        onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}></Select>
                    {this.validator.message('Select Keg Room', this.state.selKegRoom, 'required|selectValidator:""')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label> MCU<span style={{color:'red'}}>*</span></Label>
                    <Select size="sm" value={this.state.selMcu}
                        options={this.state.mcus}
                        onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}></Select>
                    {this.validator.message('Select Mcu', this.state.selMcu, 'required|selectValidator:""')}
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="4" lg="4">
                    <Label> Sensor Hubs<span style={{color:'red'}}>*</span></Label>
                    <Select size="sm" value={this.state.selSensorHub}
                        options={this.state.sensorHubs}
                        onChange={(e) => this.handleChangeSelection(e, 'selSensorHub')}></Select>
                    {this.validator.message('Select Sensor Hub', this.state.selSensorHub, 'required|selectValidator:""')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label> Sensors<span style={{color:'red'}}>*</span></Label>
                    <Select size="sm" value={this.state.selSensor}
                        options={this.state.sensors}
                        onChange={(e) => this.handleChangeSelection(e, 'selSensor')}></Select>
                    {this.validator.message('Select Sensor', this.state.selSensor, 'required|selectValidator:""')}
                </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveKts} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.selKts.kts_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    selKegSize:state.kegSizes.selKegSize,
    kegRooms:state.kegRooms.kegRooms,
    mcus:state.mcus.mcus,
    sensorHubs:state.sensorHubs.sensorHubs,
    sensors:state.sensors.sensors,
    selKts:state.krTempSensors.selKts,
    freeSensors:state.sensors.freeSensors
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
       getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
       getMcus:(site_id)=>dispatch(mcuActions.getMcus(site_id)),
       getSensorHubs:(site_id)=>dispatch(sensorHubActions.getSensorHubs(site_id)),
       getSensors:()=>dispatch(sensorActions.getSensors(true)),
       getAvailableSensors:(sh_id)=>dispatch(sensorActions.getAvailableSensors(sh_id)),
       setSelSh:(sh)=>dispatch(sensorHubActions.setSelSensorHub(sh)),
       addKts:(kts)=>dispatch(ktsActions.addKts(kts)),
       updateKts:(kts)=>dispatch(ktsActions.updateKts(kts))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(KrTemperatureSensorDetails);
