import React from "react"
import Select from "react-select"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import { confirmAlert } from "react-confirm-alert"
import { successToaster } from "../toaster/toaster"
import data from '../../constants/staticData';
import SimpleReactValidator from "simple-react-validator"
import { isObject } from "reactstrap/lib/utils"
import { connect } from "react-redux"

const { encrypt } = require("../../crypto")

class UserDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        id: parseInt(props.match.params.id),
        person_id:
          props.match.params.pid === undefined
            ? 0
            : parseInt(props.match.params.pid),
        username: "",
        password: "",
        selectedPerson: null,
        selectedResponsibility: null,
        selectedSite: null,
        siteRespMappings: [],
        showSiteRespMapping: false,
        disableSiteSelection: false,
        user_site_resp_id: 0,
        isSuperUser: false,
        isUsernameExists: false,
        units: '',
        isPasswordChange: parseInt(props.match.params.id) === 0
      },
      personDetail: null,
      sites: [],
      persons: [],
      responsibilities: [],
      saveInProgress: false,
    }
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: "Please select an option",
          rule: (val, params, validator) => {
            return isObject(val)
          },
          required: true, // optional
        },
        siteMappingValidator: {
          message: "Select atleast one site mapping",
          rule: (val, params, validator) => {
            return val.length > 0;
          },
          required: true, // optional
        },
        usernameValidator: {
          message: "Username already exists",
          rule: (val, params, validator) => {
            return !val;
          },
          required: true, // optional
        },
      },
    })

    this.userRespValidator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: "Please select an option",
          rule: (val, params, validator) => {
            return isObject(val)
          },
          required: true, // optional
        },
      },
    })
    this.units = data.units;
  }

  componentDidMount() {
    this.loadSites()
    this.loadPersons()
    this.loadResponsibilities()
    this.loadUserDetails(this.state.user.id)
  }

  loadPersonDetails = () => {
    if (this.state.personDetail == null) return ""
    return (
      <>
        <Row className="mb-3">
          <Col md="4" sm="4" lg="4">
            <Label> First Name</Label>
            <p>{this.state.personDetail.first_name}</p>
          </Col>
          <Col md="4" sm="4" lg="4">
            <Label> Middle Name</Label>
            <p>{this.state.personDetail.middle_name}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="4" sm="4" lg="4">
            <Label>Last Name</Label>
            <p>{this.state.personDetail.last_name}</p>
          </Col>
          <Col md="4" sm="4" lg="4">
            <Label> Gender</Label>
            <p>{this.state.personDetail.gender}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="4" sm="4" lg="4">
            <Label> Email</Label>
            <p>{this.state.personDetail.email}</p>
          </Col>
          <Col md="4" sm="4" lg="4">
            <Label>Phone</Label>
            <p>{this.state.personDetail.phone}</p>
          </Col>
        </Row>
      </>
    )
  }

  loadUserDetails = (userId) => {
    if (userId !== 0) {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}`,
        headers: {
          "Access-Control-Allow-Origin": "true",
        },
      }).then((response) => {
        if (response.data) {
          let newState = Object.assign({}, this.state)
          newState.user.username = response.data.username

          // if (response.data.password && response.data.password_salt) {
          //   let decryptPwd = decrypt({
          //     content: response.data.password,
          //     iv: response.data.password_salt,
          //   })
          //   newState.user.password = decryptPwd
          // }
          newState.user.isSuperUser = response.data.superuser;
          newState.user.selectedPerson = {
            value: response.data.person_id,
            label: response.data.person.first_name,
          }
          newState.user.selectedPerson = {
            value: response.data.person_id,
            label: response.data.person.first_name,
          }
          const site_id = this.props.site_id;
          const superUser = this.props.isSuperUser;
          response.data.user_site_resp.forEach(function (item) {
            if (superUser) {
              newState.user.siteRespMappings.push({
                id: item.user_site_resp_id,
                responsibility_id: item.usr_resp.responsibility_id,
                site_id: item.usr_site.site_id,
                siteName: item.usr_site.site_name,
                respName: item.usr_resp.responsibility_name,
              })
            }
            else if (site_id === item.site_id) {
              newState.user.siteRespMappings.push({
                id: item.user_site_resp_id,
                responsibility_id: item.usr_resp.responsibility_id,
                site_id: item.usr_site.site_id,
                siteName: item.usr_site.site_name,
                respName: item.usr_resp.responsibility_name,
              })
            }
          })

          if (response.data.units != null) {
            let units = this.units.find(u => u.value === response.data.units);
            newState.user.units = units;
          } else {
            newState.user.units = '';
          }
          //newState.personDetail=response.data.person;
          //newState.user.selectedResponsibility = { value: response.data.responsibility.responsibility_id, label: response.data.responsibility.responsibility_name };
          this.setState(newState)
        }
      })
    }
  }

  loadResponsibilities = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/responsibility/`,
      headers: {
        "Access-Control-Allow-Origin": "true",
      },
    }).then((response) => {
      if (response.data) {
        let newState = Object.assign({}, this.state)

        response.data.forEach(function (item) {
          newState.responsibilities.push({
            value: item.responsibility_id,
            label: item.responsibility_name,
          })
        })

        this.setState(newState)
      }
    })
  }

  loadSites = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/sites/`,
      headers: {
        "Access-Control-Allow-Origin": "true",
      },
    }).then((response) => {
      if (response.data) {
        var site_id = this.props.site_id;
        var isSuperUser = this.props.isSuperUser;
        let newState = Object.assign({}, this.state)
        let _selectedSite = null;
        response.data.forEach(function (item) {
          if (item.site_id === site_id) {
            _selectedSite = { value: item.site_id, label: item.site_name };
          }
          newState.sites.push({ value: item.site_id, label: item.site_name })
        })
        newState.user.selectedSite = _selectedSite;
        newState.user.disableSiteSelection = !isSuperUser;
        this.setState(newState)
      }
    })
  }

  loadPersons = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/persons/`,
      headers: {
        "Access-Control-Allow-Origin": "true",
      },
    }).then((response) => {
      if (response.data) {
        let newState = Object.assign({}, this.state)

        response.data.forEach(function (item) {
          newState.persons.push({
            value: item.person_id,
            label: item.first_name,
            moreInfo: item,
          })
          if (item.person_id === newState.user.person_id) {
            newState.user.selectedPerson = {
              value: item.person_id,
              label: item.first_name,
              moreInfo: item,
            }
            newState.personDetail = item
          }
        })
        this.setState(newState)
      }
    })
  }

  AddSiteRespMapping = () => {
    let newState = Object.assign({}, this.state)
    newState.user.showSiteRespMapping = !newState.user.showSiteRespMapping
    newState.user.selectedResponsibility = null
    //newState.user.selectedSite = null
    this.setState(newState)
  }

  updatePreview = (base64Data) => {
    let newState = Object.assign({}, this.state)
    newState.user.avatar = base64Data
    newState.user.profile = base64Data
    this.setState(newState)
  }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state)
    newState.user[propName] = value
    this.setState(newState)
  }

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state)
    if (event.target) {
      newState.user[inputPropName] = inputPropName === "isSuperUser" ? event.target.checked : event.target.value
    }
    this.setState(newState)
  }

  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state)
    newState.user[inputPropName] = item
    if (inputPropName === "selectedPerson") {
      newState.personDetail = item.moreInfo
    }
    this.setState(newState)
  }

  updateSiteRespMapping = () => {
    if (!this.userRespValidator.allValid()) {
      this.userRespValidator.showMessages();
      return;
    }
    const userInfo = this.state.user
    let _siteRespMappings = this.state.user.siteRespMappings;
    _siteRespMappings.push({
      responsibility_id: userInfo.selectedResponsibility.value,
      siteName: userInfo.selectedSite.label,
      respName: userInfo.selectedResponsibility.label,
      site_id: userInfo.selectedSite.value,
    })
    this.setState({ ...this.state, user: { ...this.state.user, siteRespMappings: _siteRespMappings } })
    // const userInfo = this.state.user
    // const formData = {responsibility_id:
    //   user_id: userInfo.id,
    //   responsibility_id: userInfo.selectedResponsibility.value,
    //   site_id: userInfo.selectedSite.value,
    // }
    // if (userInfo.user_site_resp_id === 0) {
    //   axios({
    //     method: "POST",
    //     url: `${process.env.REACT_APP_API_BASE_URL}/api/usermapping/`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "true",
    //     },
    //     data: formData,
    //   }).then((response) => {
    //     if (response.data) {
    //      let newState = Object.assign({}, this.state)
    //       const mapping = {
    //         id: response.data.user_site_resp_id,
    //         siteName: userInfo.selectedSite.label,
    //         respName: userInfo.selectedResponsibility.label,
    //       }
    //       newState.user.siteRespMappings.push(mapping);
    //       newState.user.selectedSite=null;
    //       newState.user.selectedResponsibility=null;
    //       this.setState(newState);
    //       alert('Site responsibility updated');
    //     }

    //   })
    // } else {
    //   axios({
    //     method: "PUT",
    //     url: `${process.env.REACT_APP_API_BASE_URL}/api/users/${userInfo.id}`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "true",
    //     },
    //     data: formData,
    //   }).then((response) => {
    //     alert("Updated succesfully")
    //   })
    // }
  }

  addUser = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    // if (this.state.user.password !== this.state.user.confirmPassword) {
    //   alert("Password and confirm password do not match")
    //   return
    // }
    this.setState({ saveInProgress: true })
    const userInfo = this.state.user

    const formData = {
      user_id: userInfo.id,
      username: userInfo.username,
      person_id: userInfo.selectedPerson.value,
      superuser: this.props.isSuperUser ? userInfo.isSuperUser : false,
      units: userInfo.units.value
      // responsibility_id:
      //   userInfo.id === 0 ? userInfo.selectedResponsibility.value : 0,
      // site_id: userInfo.id === 0 ? userInfo.selectedSite.value : 0,
      //siteRespMappings:userInfo.siteRespMappings
    }



    if (userInfo.id === 0) {
      if (userInfo.password) {
        let encryptedPwd = encrypt(userInfo.password)
        formData.password = encryptedPwd.content
        formData.password_salt = encryptedPwd.iv
      }
      formData.siteRespMappings = userInfo.siteRespMappings;
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_BASE_URL}/api/users/`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "true",
        },
        data: formData,
      })
        .then((response) => {
          successToaster("Added")
          this.setState({ saveInProgress: false })
          this.handleCancel()
        })
    } else {
      var _srMappings = userInfo.siteRespMappings.filter(m => m.id === undefined);
      formData.siteRespMappings = _srMappings;
      if (userInfo.password && userInfo.isPasswordChange) {
        let encryptedPwd = encrypt(userInfo.password)
        formData.password = encryptedPwd.content
        formData.password_salt = encryptedPwd.iv
      }
      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_BASE_URL}/api/users/${userInfo.id}`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "true",
        },
        data: formData,
      })
        .then((response) => {
          successToaster("Updated")
          this.setState({ saveInProgress: false })
          this.handleCancel()
        })
    }
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  getUsernames = (username) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/users/getUsersByUsername?username=${username}`,
    }).then((response) => {
      if (response.data !== undefined) {
        this.setState({ ...this.state, user: { ...this.state.user, isUsernameExists: response.data } });
      }
    })
  }

  changePassword = () => {
    this.setState({ ...this.state, user: { ...this.state.user, isPasswordChange: !this.state.user.isPasswordChange } });
  }

  deleteUserMapping = (row) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log(row.id === undefined);
            if (row.id === undefined) {
              let newState = Object.assign({}, this.state)
              let mappings = newState.user.siteRespMappings;
              const index = mappings.indexOf(row);
              mappings.splice(index, 1);
              newState.user.siteRespMappings = mappings;
              this.setState(newState)
            }
            else {
              axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_API_BASE_URL}/api/usermapping/${row.id}`,
              }).then((response) => {
                if (response.data !== undefined) {
                  alert("Deleted succesfully")
                  let newState = Object.assign({}, this.state)
                  const userMappings = newState.user.siteRespMappings.filter(
                    (com) => com.id !== row.id
                  )
                  newState.user.siteRespMappings = userMappings
                  this.setState(newState)
                }
              })
            }

          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    })
  }

  validateConfirmPassword = () => {
    if (this.state.user.password !== this.state.user.confirmPassword) {
      alert("Password and confirm password do not match")
    }
  }

  render() {
    const columns = [
      {
        dataField: "id",
        text: "User Id",
        hidden: true,
      },
      {
        dataField: "siteName",
        text: "Site",
        sort: true,
      },
      {
        dataField: "respName",
        text: "Responsibility",
        sort: true,
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          if(this.props.data_only) return ("")
          return (
            <div>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => this.deleteUserMapping(row)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          )
        },
      },
    ]

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">
            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3>{this.state.user.id === 0 ? "Add User" : "Update User"}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="6">
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label> Username<span style={{ color: 'red' }}>*</span></Label>
                    <form autoComplete="Off">
                      <Input
                        value={this.state.user.username}
                        bsSize="sm"
                        type="text"
                        disabled={this.state.user.id !== 0 ? "disabled" : ""}
                        onChange={(e) => this.handleInputChange(e, "username")}
                        autoComplete="new-name"
                        onBlur={() => this.getUsernames(this.state.user.username)}
                      />
                    </form>
                    {this.validator.message('username', this.state.user.username, 'required')}
                    {
                      this.state.user.id === 0 ?
                        <>{this.validator.message('username exists', this.state.user.isUsernameExists, 'usernameValidator:false')}</> : ""
                    }
                  </Col>
                </Row>
                {this.state.user.id !== 0 ? <Button size="sm" disabled={this.props.data_only?"disabled":""} onClick={this.changePassword}>Change Password</Button> : ""}
                {this.state.user.id === 0 || this.state.user.isPasswordChange ?
                  <Row className="mb-3">
                    <Col md="9" sm="9" lg="9">
                      <Label> Password<span style={{ color: 'red' }}>*</span></Label>
                      <form autoComplete="Off">
                        <Input
                          value={this.state.user.password}
                          bsSize="sm"
                          type="password"
                          onChange={(e) => this.handleInputChange(e, "password")}
                          autoComplete="new-name"
                        />
                      </form>
                      {this.validator.message('password', this.state.user.password, 'required')}
                    </Col>
                  </Row> : ""}
                {this.state.user.id === 0 ?
                  <Row className="mb-3">
                    <Col md="9" sm="9" lg="9">
                      <Label>Confirm Password<span style={{ color: 'red' }}>*</span></Label>
                      <form autoComplete="Off">
                        <Input
                          value={this.state.user.confirmPassword}
                          bsSize="sm"
                          type="password"
                          onChange={(e) =>
                            this.handleInputChange(e, "confirmPassword")
                          }
                          //onBlur={this.validateConfirmPassword}
                          autoComplete="new-name"
                        />
                      </form>
                      {this.validator.message('confirmPassword', this.state.user.confirmPassword, `required|in:${this.state.user.password}`, { messages: { in: 'Passwords need to match!' } })}
                    </Col>
                  </Row> : ""}
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label> Person<span style={{ color: 'red' }}>*</span></Label>&nbsp;&nbsp;
                    {this.props.data_only?"":
                    <Link className="btn btn-sm" to={"/admin/addperson/0"} isDisabled={this.props.data_only?"disabled":""}>
                      Add Person
                    </Link>
                    }
                    <Select
                      size="sm"
                      value={this.state.user.selectedPerson}
                      isDisabled={this.props.data_only}
                      options={this.state.persons}
                      onChange={(e) =>
                        this.handleChangeSelection(e, "selectedPerson")
                      }
                    ></Select>
                    {this.validator.message('person', this.state.user.selectedPerson, 'required|selectValidator:""')}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="9" sm="9" lg="9">
                    <Label> Units<span style={{ color: 'red' }}>*</span></Label>&nbsp;&nbsp;
                    <Select size="sm" value={this.state.user.units}
                      options={this.units}
                      onChange={(e) => this.handleChangeSelection(e, 'units')}
                    ></Select>
                    {this.validator.message('Select Unit', this.state.user.units, 'required|selectValidator:""')}
                  </Col>
                </Row>
                {
                  this.props.isSuperUser && !this.props.data_only ?
                    <Row>
                      <Col md="4" sm="4" lg="4">
                        <label>Draftco Admin</label>
                      </Col>
                      <Col md="6" sm="6" lg="6">
                        <Input
                          checked={this.state.user.isSuperUser}
                          style={{ padding: 0, margin: 0 }}
                          bsSize="sm"
                          type="checkbox"
                          onChange={(e) =>
                            this.handleInputChange(e, "isSuperUser")
                          }
                        />
                      </Col>
                    </Row> : ""
                }
                {/* {this.state.user.id === 0 ? (
                  <>
                    <Row className="mb-3">
                      <Col md="9" sm="9" lg="9">
                        <Label> Sites<span style={{color:'red'}}>*</span></Label>
                        <Select
                          size="sm"
                          value={this.state.user.selectedSite}
                          options={this.state.sites}
                          isDisabled={this.state.user.disableSiteSelection}
                          onChange={(e) =>
                            this.handleChangeSelection(e, "selectedSite")
                          }
                        ></Select>
                        {this.validator.message('site', this.state.user.selectedSite, 'required|selectValidator:""')}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="9" sm="9" lg="9">
                        <Label> Responsibility<span style={{color:'red'}}>*</span></Label>
                        <Select
                          size="sm"
                          value={this.state.user.selectedResponsibility}
                          options={this.state.responsibilities}
                          onChange={(e) =>
                            this.handleChangeSelection(
                              e,
                              "selectedResponsibility"
                            )
                          }
                        ></Select>
                        {this.validator.message('responsibility', this.state.user.selectedResponsibility, 'required|selectValidator:""')}
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )} */}
              </Col>
              <Col md="6" sm="6" lg="6">
                {this.loadPersonDetails()}
              </Col>
            </Row>
            <hr />
            {/* <Row>
              <Col md="3" lg="3">
                <Button
                  className="btn btn-primary btn-sm"
                  onClick={this.AddSiteRespMapping}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Add
                  Application Responsibility&nbsp;
                </Button>
              </Col>
            </Row> */}
            <br />
            {this.validator.message('Site Mapping', this.state.user.siteRespMappings, 'required|siteMappingValidator:""', { messages: { in: 'Please select atleast one site mapping for user' } })}
            <Row className="mb-3">
              <Col md="5" sm="5" lg="5">
                <Label> Sites<span style={{ color: 'red' }}>*</span></Label>
                <Select
                  size="sm"
                  value={this.state.user.selectedSite}
                  options={this.state.sites}
                  isDisabled={this.state.user.disableSiteSelection}
                  onChange={(e) =>
                    this.handleChangeSelection(e, "selectedSite")
                  }
                ></Select>
                {this.userRespValidator.message('site', this.state.user.selectedSite, 'required|selectValidator:""')}
              </Col>
              <Col md="5" sm="5" lg="5">
                <Label> Responsibility<span style={{ color: 'red' }}>*</span></Label>
                <Select
                  size="sm"
                  value={this.state.user.selectedResponsibility}
                  options={this.state.responsibilities}
                  onChange={(e) =>
                    this.handleChangeSelection(e, "selectedResponsibility")
                  }
                ></Select>
                {this.userRespValidator.message('Responsibility', this.state.user.selectedResponsibility, 'required|selectValidator:""')}
              </Col>
              <Col md="2" sm="2" lg="2">
                <p>&nbsp;</p>
                <button
                  className="btn btn-sm btn-success"
                  onClick={this.updateSiteRespMapping}
                  disabled={this.props.data_only?"disabled":""}
                >
                  Add Mapping
                </button>
              </Col>
            </Row>
            <BootstrapTable
              classes="custom-table-css dft-bg-primary-color"
              striped
              hover
              condensed
              keyField="id"
              data={this.state.user.siteRespMappings}
              columns={columns}
            />
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="2" lg="2">
                <div>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={this.addUser}
                    disabled={this.state.saveInProgress ? "disabled" : ""}
                  >
                    {this.state.user.id === 0 ? "Add" : "Update"}
                  </button>
                  <button
                    className="btn btn-sm btn-default"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    data_only:state.userInfo.security.sec_level.data_only && !state.userInfo.security.sec_level.user_only,
    isSuperUser: state.userInfo.userDetails ? state.userInfo.userDetails.superuser : false
  }
}

export default connect(mapStateToProps, null)(UserDetail);
