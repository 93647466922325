import Login from "../views/login"
import BeerList from "../views/beers/beerList"
import BeerDetail from "../views/beers/beerDetail"
import PersonList from "../views/persons/personList"
import PersonDetail from "../views/persons/personDetail"
import CustomerList from "../views/customer/customerList"
import CustomerDetail from "../views/customer/customerDetail"
import Monitor from "../views/dashboard/startup"
import SitesList from "../views/sites/sitesList"
import SiteDetail from "../views/sites/siteDetails"
import KegroomList from "../views/kegroom/kegroomList"
import KegRoomDetail from "../views/kegroom/kegroomdetail"
import KegDetail from "../views/keg/kegDetail"
import UserList from "../components/users/userList"
import UserDetail from "../views/users/userDetail"
import TowerDetail from "../views/towers/towerDetail"
//import TowerList from "../views/towers/towerList"
import SensorDetail from "../views/sensor/details"
import McuList from "../views/mcu"
import McuDetails from "../views/mcu/details"
import SensorHubList from "../views/sensor_hub"
import SensorHubDetails from "../views/sensor_hub/details"
import LineDetails from "../views/line/details"
import SecuritylevelList from "../views/securitylevel/securitylevelList"
import SecuritylevelDetail from "../views/securitylevel/securitylevelDetail"
import ResponsibilityList from "../views/responsibility/responsibilityList"
import ResponsibilityDetail from "../views/responsibility/responsibilityDetail"
import AuditLogsList from "../views/auditlogs/auditlogsList"
import InternalServerError from "../views/error/InternalServerError"
import PageNotFound from "../views/error/PageNotFound"
import BadRequest from "../views/error/BadRequest"
import StartUp from "../layouts/startup"
import ForgotPassword from "../views/password/forgot-password"
import ResetPassword from "../views/password/reset-password"
import KegDataView from "../views/kegdata/kegdataview"
import pourDataReport from "../views/reports/pour-data-report"
import pourConsolidatedReport from "../views/reports/pour-consolidated-report"
import kegReport from "../views/reports/keg-report"
import pourDistributionReport from "../views/reports/pour-distribution-report"
import volumeReport from "../views/reports/volume-report"
import pourVolumeReport from "../views/reports/pour-volume-report"
import pourWaterfallReport from "../views/reports/pour-waterfall-report"
import PourWaterfallChart from "../views/charts/pour-volume-waterfall"
import pourVolumeDateChart from "../views/charts/pour-volume-date-chart"
import pourVolumeChart from "../views/charts/pour-volume-chart"
import kegInventoryChart from "../views/charts/keg-inventory-chart"
import connectedKegsChart from "../views/charts/connected-kegs-chart"
import pourDetailChart from "../views/charts/pour-detail-chart"
import pourTotalChart from "../views/charts/pour-total-chart"
import glycolDataChart from "../views/charts/glycol-data-chart"
import lineDataChart from "../views/charts/line-data-chart"
import KegMerge from '../views/keg/keg_merge'
import pourHourlyDistribution from "../views/charts/pour-hourly-distribution"
import lineCleaning from "../views/line/line-cleaning"
import kegSizes from "../views/keg-sizes"
import KegSizeDetail from "../views/keg-sizes/details"
import kegVolumeChange from "../views/keg/keg-volume-change"
import GlycolSystem from "../views/glycol_system/index"
import GlycolSystemDetail from "../views/glycol_system/details"
import KrTemperatureSensor from "../views/kr_temperature/index"
import KrTemperatureSensorDetails from "../views/kr_temperature/details"
import KrTemperatureReport from "../views/charts/kr_temperature_report"
import kegBeerChange from "../views/keg/keg-beer-change"
import TotalPourChartByDistributors from '../views/charts/pour-total-chart-company'
import kegOrderReport from "../views/charts/keg-order-report";
import alarmDefinitionList from "../views/alarms/alarm-definition-list"
import alarmDefinitionDetails from "../views/alarms/alarm-definition-details"
import alarmInstances from "../views/alarms/alarm-instances"
import MonthlyVolumeReport from "../views/charts/monthly-volume-report"
import VolumeTrendByBeer from "../views/charts/beer-volume-trend"
import ReportSubscriptionList from '../views/subscriptions/reportSubscriptionList';
import KegPourVolumeChange from '../views/keg/keg-pour-volume-change';

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-tv-2 dft-primary-color",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-tv dft-primary-color",
    component: Monitor,
    layout: "/admin",
    routeType: "main",
    access: "default",
  },
  {
    path: "/alarmDefinitions",
    name: "Alarm Definitions",
    icon: "fa fa-bookmark dft-primary-color",
    component: alarmDefinitionList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/customer",
    name: "Customers",
    icon: "fa fa-bookmark dft-primary-color",
    component: CustomerList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/editcustomer/:id",
    name: "Edit Customer",
    icon: "fa fa-thumbtack dft-primary-color",
    component: CustomerDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Customers'
  },
  {
    path: "/editad",
    name: "Add / Update Alarm Definition",
    icon: "fa fa-thumbtack dft-primary-color",
    component: alarmDefinitionDetails,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Alarm Definitions'
  },
  {
    path: "/sites",
    name: "Sites",
    icon: "fa fa-thumbtack dft-primary-color",
    component: SitesList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/site/:id/customer/:cid",
    name: "Site",
    icon: "fa fa-igloo dft-primary-color",
    component: SiteDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Sites'
  },
  {
    path: "/site/:id",
    name: "Site",
    icon: "fa fa-igloo dft-primary-color",
    component: SiteDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Sites'
  },
  {
    path: "/kegroom",
    name: "Keg Room",
    icon: "fa fa-igloo dft-primary-color",
    component: KegroomList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/kegroom/:id/site/:sid",
    name: "Keg Room",
    icon: "fa fa-users dft-primary-color",
    component: KegRoomDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Keg Room'
  },
  {
    path: "/kegroom/:id",
    name: "Keg Room",
    icon: "fa fa-users dft-primary-color",
    component: KegRoomDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Keg Room'
  },
  {
    path: "/kegs",
    name: "Keg",
    icon: "fa fa-database dft-primary-color",
    component: KegroomList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/kegsizes",
    name: "Keg Sizes",
    icon: "fa fa-database dft-primary-color",
    component: kegSizes,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/editkegsize/:id",
    name: "Keg Sizes",
    icon: "fa fa-database dft-primary-color",
    component: KegSizeDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Keg Sizes'
  },
  {
    path: "/glycol",
    name: "Glycol System",
    icon: "fa fa-database dft-primary-color",
    component: GlycolSystem,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/editgs/:id",
    name: "Edit Glycol System",
    icon: "fa fa-database dft-primary-color",
    component: GlycolSystemDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Glycol System'
  },
  {
    path: "/kts",
    name: "Keg Room Temperature",
    icon: "fa fa-database dft-primary-color",
    component: KrTemperatureSensor,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/editkts/:id",
    name: "Edit Keg Room Temperature",
    icon: "fa fa-database dft-primary-color",
    component: KrTemperatureSensorDetails,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Keg Room Temperature'
  },
  {
    path: "/keg/:id/kegroom/:krid",
    name: "Keg",
    icon: "fa fa-users dft-primary-color",
    component: KegDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Keg'
  },
  {
    path: "/keg/:id",
    name: "Keg",
    icon: "fa fa-users dft-primary-color",
    component: KegDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Keg'
  },
  {
    path: "/sensor/:id/sh/:shid",
    name: "Sensor ",
    icon: "fa fa-users dft-primary-color",
    component: SensorDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Sensors'
  },
  {
    path: "/mcu",
    name: "M C U",
    icon: "fa fa-align-justify dft-primary-color",
    component: McuList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/mcu/:id/kr/:krid",
    name: "M C U",
    icon: "fa fa-users dft-primary-color",
    component: McuDetails,
    layout: "/admin",
    routeType: "sub",
    subAccess:'M C U'
  },
  {
    path: "/sensor_hub",
    name: "Sensor Hub",
    icon: "fa fa-satellite-dish dft-primary-color",
    component: SensorHubList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/sensor_hub/:id/mcu/:mcuid",
    name: "Sensor Hub",
    icon: "fa fa-users dft-primary-color",
    component: SensorHubDetails,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Sensor Hub'
  },
  {
    path: "/sensors",
    name: "Sensors",
    icon: "fa fa-thermometer-half dft-primary-color",
    component: SensorHubList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/lines",
    name: "Lines",
    icon: "fa fa-grip-lines-vertical dft-primary-color",
    component: KegroomList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/line/:id/kr/:kr_id",
    name: "Lines",
    icon: "fa fa-water dft-primary-color",
    component: LineDetails,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Lines'
  },
  {
    path: "/lineCleaning",
    name: "Line Cleaning",
    icon: "fa fa-water dft-primary-color",
    component: lineCleaning,
    layout: "/admin",
    routeType: "main",
    access: "tech_maintenance",
  },
  {
    path: "/alarmtriggers",
    name: "Alarm History",
    icon: "fa fa-water dft-primary-color",
    component: alarmInstances,
    layout: "/admin",
    routeType: "main",
    access: "tech_alarms",
  },
  // {
  //   path: "/tower",
  //   name: "Towers",
  //   icon: "fa fa-building dft-primary-color",
  //   component: TowerList,
  //   layout: "/admin",
  //   routeType: "main",
  //   access: "tech",
  // },
  {
    path: "/tower/:id/site/:sid",
    name: "Tower",
    icon: "fa fa-building dft-primary-color",
    component: TowerDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Towers'
  },
  {
    path: "/towers/:id",
    name: "Tower",
    icon: "fa fa-building dft-primary-color",
    component: TowerDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Towers'
  },
  {
    path: "/Beers",
    name: "Beers",
    icon: "fa fa-beer dft-primary-color",
    component: BeerList,
    layout: "/admin",
    routeType: "main",
    access: "tech",
  },
  {
    path: "/addbeer/:id",
    name: "Add Beer",
    icon: "fa fa-users dft-primary-color",
    component: BeerDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Beers'
  },
  {
    path: "/Persons",
    name: "Person",
    icon: "fa fa-user dft-primary-color",
    component: PersonList,
    layout: "/admin",
    routeType: "main",
    access: "user",
  },
  {
    path: "/addperson/:id",
    name: "Add Person",
    icon: "fa fa-users dft-primary-color",
    component: PersonDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Person'
  },
  {
    path: "/users",
    name: "Users",
    icon: "fa fa-users dft-primary-color",
    component: UserList,
    layout: "/admin",
    routeType: "main",
    access: "user",
  },
  {
    path: "/adduser/:id/person/:pid",
    name: "Add User",
    icon: "fa fa-users dft-primary-color",
    component: UserDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Users'
  },
  {
    path: "/securitylevel",
    name: "Security Level",
    icon: "fa fa-key dft-primary-color",
    component: SecuritylevelList,
    layout: "/admin",
    routeType: "main",
    access: "user",
  },
  {
    path: "/securitylevel/:id",
    name: "Security Level",
    icon: "fa fa-building dft-primary-color",
    component: SecuritylevelDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Security Level'
  },
  {
    path: "/responsibility",
    name: "Responsibilities",
    icon: "fa fa-user-lock dft-primary-color",
    component: ResponsibilityList,
    layout: "/admin",
    routeType: "main",
    access: "user",
  },
  {
    path: "/responsibility/:id",
    name: "Responsibility",
    icon: "fa fa-users dft-primary-color",
    component: ResponsibilityDetail,
    layout: "/admin",
    routeType: "sub",
    subAccess:'Responsibilities'
  },
  {
    path: "/audittrail",
    name: "Audit Logs",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: AuditLogsList,
    layout: "/admin",
    routeType: "main",
    access: "_draftco",
  },
  {
    path: "/kegmerge",
    name: "Keg Merge",
    icon: "fa fa-database dft-primary-color",
    //component: CustomerList,
    component: KegMerge,
    layout: "/admin",
    routeType: "main",
    access: "keg_management",
  },
  {
    path: "/kegvolumechange",
    name: "Change Keg Size",
    icon: "fa fa-database dft-primary-color",
    //component: CustomerList,
    component: kegVolumeChange,
    layout: "/admin",
    routeType: "main",
    access: "keg_management",
  },
  {
    path: "/kegpourrvolumechange",
    name: "Change Percentage Used of Keg",
    icon: "fa fa-database dft-primary-color",
    //component: CustomerList,
    component: KegPourVolumeChange,
    layout: "/admin",
    routeType: "main",
    access: "keg_management",
  },
  {
    path: "/kegbeerchange",
    name: "Keg Beer Update",
    icon: "fa fa-database dft-primary-color",
    //component: CustomerList,
    component: kegBeerChange,
    layout: "/admin",
    routeType: "main",
    access: "keg_management",
  },
  {
    path: "/kegdata",
    name: "Keg Inventory",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: KegDataView,
    layout: "/admin",
    routeType: "main",
    access: "data",
  },
  {
    path: "/admin/internalservererror",
    name: "Responsibility",
    icon: "fa fa-users dft-primary-color",
    component: InternalServerError,
    layout: "/admin",
    routeType: "sub",
  },
  {
    path: "/admin/pagenotfound",
    name: "Responsibility",
    icon: "fa fa-users dft-primary-color",
    component: PageNotFound,
    layout: "/admin",
    routeType: "sub",
  },
  {
    path: "/admin/badrequest",
    name: "Responsibility",
    icon: "fa fa-users dft-primary-color",
    component: BadRequest,
    layout: "/admin",
    routeType: "sub",
  },
  {
    path: "/admin/startup",
    name: "Welcome to Draftco",
    icon: "fa fa-users dft-primary-color",
    component: StartUp,
    layout: "/admin",
    routeType: "sub",
  },
  {
    path: "/forgotpassword",
    name: "Forgot Password",
    icon: "ni ni-tv-2 dft-primary-color",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/resetpassword",
    name: "Reset Password",
    icon: "ni ni-tv-2 dft-primary-color",
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/kegreport",
    name: "Connected Keg Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: kegReport,
    layout: "/admin",
    routeType: "main",
    access: "data",
  },
  {
    path: "/pourdata",
    name: "Detail Pour Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourDataReport,
    layout: "/admin",
    routeType: "main",
    access: "data",
  },
  {
    path: "/pourconsolidated",
    name: "Total Pour Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourConsolidatedReport,
    layout: "/admin",
    routeType: "main",
    access: "data",
  },
  {
    path: "/pourdistribution",
    name: "Pour Distribution Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourDistributionReport,
    layout: "/admin",
    routeType: "main",
    access: "data",
  },
  {
    path: "/volumereport",
    name: "Volume Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: volumeReport,
    layout: "/admin",
    routeType: "main",
    access: "data",
  },
  {
    path: "/pourvolume",
    name: "Pour Volume Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourVolumeReport,
    layout: "/admin",
    routeType: "main",
    access: "data",
  },
  {
    path: "/pourwaterfall",
    name: "Pour Waterfall",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourWaterfallReport,
    layout: "/admin",
    routeType: "main",
    access: "data",
  },
  // {
  //   path: "/chartspoc",
  //   name: "Connected keg Report By Pour Volume",
  //   icon: "fa fa-bookmark dft-primary-color",
  //   //component: CustomerList,
  //   component: chartsPoc,
  //   layout: "/admin",
  //   routeType: "main",
  //   access: "pour_charts",
  // },
  {
    path: "/totalpourchart",
    name: "Total Volume Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourTotalChart,
    layout: "/admin",
    routeType: "main",
    access: "pour_charts",
  },
  {
    path: "/totalpourchartbydistributors",
    name: "Total Volume Report By Distributors",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: TotalPourChartByDistributors,
    layout: "/admin",
    routeType: "main",
    access: "pour_charts",
  },
  {
    path: "/monthlyvolumereport",
    name: "Monthly Volume Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: MonthlyVolumeReport,
    layout: "/admin",
    routeType: "main",
    access: "pour_charts",
  },
  {
    path: "/volumetrendbybeer",
    name: "Volume Trend By Beer",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: VolumeTrendByBeer,
    layout: "/admin",
    routeType: "main",
    access: "pour_charts",
  },
  {
    path: "/hourlydistribution",
    name: "Time Of Day Distribution",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourHourlyDistribution,
    layout: "/admin",
    routeType: "main",
    access: "pour_charts",
  },
  {
    path: "/detailpourchart",
    name: "Detail Pour Chart By Beer",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourDetailChart,
    layout: "/admin",
    routeType: "main",
    access: "pour_charts",
  },
  {
    path: "/waterfallchart",
    name: "Individual Keg Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: PourWaterfallChart,
    layout: "/admin",
    routeType: "main",
    access: "keg_charts",
  },
  {
    path: "/pourvolumebydate",
    name: "Top Selling Beers",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourVolumeDateChart,
    layout: "/admin",
    routeType: "main",
    access: "pour_charts",
  },
  {
    path: "/pourvolumechart",
    name: "Pie Chart By Brand",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: pourVolumeChart,
    layout: "/admin",
    routeType: "main",
    access: "pour_charts",
  },
  {
    path: "/glycolreport",
    name: "Glycol Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: glycolDataChart,
    layout: "/admin",
    routeType: "main",
    access: "glycol_charts",
  },
  {
    path: "/linedatareport",
    name: "Line Report",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: lineDataChart,
    layout: "/admin",
    routeType: "main",
    access: "line_charts",
  },
  {
    path: "/KrTempPlot",
    name: "Temperature Report",
    icon: "fa fa-thermometer-half dft-primary-color",
    //component: CustomerList,
    component: KrTemperatureReport,
    layout: "/admin",
    routeType: "main",
    access: "kr_charts",
  },
  {
    path: "/keginventorychart",
    name: "Used Kegs",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: kegInventoryChart,
    layout: "/admin",
    routeType: "main",
    access: "keg_charts",
  },
  {
    path: "/connectedkegschart",
    name: "Kegs on Tap",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: connectedKegsChart,
    layout: "/admin",
    routeType: "main",
    access: "keg_charts",
  },
  {
    path: "/kegorderchart",
    name: "Kegs Order",
    icon: "fa fa-bookmark dft-primary-color",
    //component: CustomerList,
    component: kegOrderReport,
    layout: "/admin",
    routeType: "main",
    access: "keg_charts",
  },
  {
    path: "/reportSubscriptions",
    name: "Report Subscriptions",
    icon: "fa fa-bell dft-primary-color",
    //component: CustomerList,
    component: ReportSubscriptionList,
    layout: "/admin",
    routeType: "main",
    access: "report_subscriptions",
  }
]
export default routes
