import {SET_USER_SEC, SET_USER_DETAIL, SET_USER_SITES,SET_COMPANY_LOGO_BASE64,SET_USER_LOGO_BASE64} from './actionTypes'
import store from '../store';
import axios from 'axios';

// export const LOGIN_USER_UPDATE=(data)=>{
//     return (dispatch) => {
// 		dispatch(handleActionDispatch(LOGIN_USER_INFO, data));
// 	}   
// }


export const setUserSec=(data)=>{
    return (dispatch) => {
		dispatch(handleActionDispatch(SET_USER_SEC, data));
	}   
}

export const setCompanyLogo=(url)=>{
    const state = store.getState();
	return (dispatch) => {
		if(state.userInfo.companyLogoBase64!==null){
			return;
		}
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/reports/GetImageToBase64`, { params: { url: url } })
        .then(response => {
            if (response.data !== undefined) {
                dispatch(handleActionDispatch(SET_COMPANY_LOGO_BASE64, response.data));
            }
        });
	}
}

export const setUserLogo=(url)=>{
	//const state = store.getState();
	return(dispatch)=>{
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/reports/GetImageToBase64`, { params: { url: url } })
		.then(response => {
			if (response.data !== undefined) {
				dispatch(handleActionDispatch(SET_USER_LOGO_BASE64, response.data));
			}
		});
	}
}


export const setUserDetail=(data)=>{	
    return (dispatch) => {
		dispatch(handleActionDispatch(SET_USER_DETAIL, data));
	}   
}

export const setUserSites=(data)=>{
    return (dispatch) => {
		dispatch(handleActionDispatch(SET_USER_SITES, data));
	}   
}


export const handleActionDispatch = (actionName, data) => {
	return {
		type: actionName,
		payload: data
	}
}