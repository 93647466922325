import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import {convertValueByMetric} from '../../../utility/units-conversion'
import * as kegRoomActions from '../../kegroom/store/kegroom.actions'

class HourlyPourVolumeWidget extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            lineChart:{
                series:[],
                options:{}
            },
            last_pour_item:{
              last_pour_date:"",
              volume_poured:"",
              beer_name:"",
              pour_id:0,
              pour_volume:0,
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
      if (state.last_pour_item !== null) {
          return {
            last_pour_item:props.last_pour_item
          }
      }
      return null;
  }

    async componentDidMount(){
        await this.props.getKegRooms(this.props.site_id);
        this.getChartData()
    }

    componentDidUpdate(prevProps,prevState){
      console.log(prevProps.last_pour_item)
      console.log(this.state.last_pour_item)
      if(prevProps.last_pour_item===null &&  this.state.last_pour_item===null){

      }
      else if(prevProps.last_pour_item.pour_id!==this.state.last_pour_item.pour_id){
        this.updateHourlyDistributionChart()
      }
    }

    updateHourlyDistributionChart(){
      var pour_item=this.state.last_pour_item;
      var _lineChart=this.state.lineChart;
      var _seriesData=_lineChart.series[0].data;
      var hours=moment(pour_item.last_pour_date).hours();
      //var dt=moment(new Date()).format("YYYY-MM-DD");
      //var dt1=moment(dt+" "+hours+":00")
      var index=hours;
      var item=null;
      if(index!==-1){
        item=_seriesData[index];
        console.log(`Pour distribution updating for ${hours} with volume ${item.y}`);
        item.y= convertValueByMetric(parseFloat(item.y)+parseFloat(pour_item.pour_volume),this.props.units,"vol").toFixed(2);
        console.log(`Pour distribution updated for ${hours} with volume ${item.y}`);
        var beerInfo=item.moreInfo.filter(b=>b.beer_name===pour_item.beer_name);
        if(beerInfo.length>0){
          var updatedVolume=parseFloat(beerInfo[0].volume)+parseFloat(pour_item.pour_volume);
          beerInfo[0].volume= convertValueByMetric(updatedVolume,this.props.units,"vol").toFixed(2)//.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        else{
          item.moreInfo.push({
            beer_name:pour_item.beer_name,
            volume:convertValueByMetric(pour_item.pour_volume,this.props.units,"vol").toFixed(2)//.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          })
        }
      }
     
      _seriesData.splice(index, 1);
      _seriesData.splice( index, 0, item );
      console.log(item);
      const data = _seriesData.map( x => { return {...x}} )
      this.setState({
        ...this.state.lineChart,
        lineChart:{
          ...this.state.lineChart.options,
          series:[{
            ...this.state.lineChart.series[0].data,
            data:data
          }]
        }
      })
    }


    getChartData=()=>{
        var testDateUtc = moment.utc(new Date());
        var localDate = moment(testDateUtc).local();
        var x=localDate;
        const units=this.props.units;
        var localDateUtc = moment.utc(new Date());
        var localDate = moment(localDateUtc).local().add('days',0).format("YYYY-MM-DD");
        var params={
            kr_id:this.props.kegRooms[0].kr_id,
            from_date:localDate
        }
        let newState=this;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetCurrentHourlyDistribution`,{params:params})
        .then(function(response){
            if(response.data!==undefined){
                console.log(response)
                var _lineData=[];
                var groupByHour=  response.data.reduce(function (r, a) {
                    r[a.hours] = r[a.hours] || [];
                    r[a.hours].push(a);
                    return r;
                }, Object.create(null));
                for(var key in groupByHour){
                    var beer_volume = groupByHour[key].map(o => o.volume_poured).reduce((a, c) => { return a + c });
                    _lineData.push({volume_poured:beer_volume,hours:key,
                        moreInfo:groupByHour[key].map((item)=>{
                            return {
                                beer_name:item.beer_name,
                                volume:convertValueByMetric(item.volume_poured,units,"vol").toFixed(2)
                            }
                        })
                    })
                }

                var volumeLine={name:"Volume",data:[]};
                var xaxis=[];
                var lineData=[];
                _lineData.forEach((item)=>{
                    var dt=moment(new Date()).format("YYYY-MM-DD");
                    var dt1=moment(dt+" "+item.hours+":00")
                    xaxis.push(dt1._d);
                    lineData.push({x:dt1._d,y:convertValueByMetric(item.volume_poured,units,"vol").toFixed(2),moreInfo:item.moreInfo})
                })
                for(var i=0;i<24;i++){
                    // eslint-disable-next-line no-loop-func
                    var item=lineData.filter(s=>moment(s.x).hour()===i);
                    if(item.length>0){
                        volumeLine.data.push({x:item[0].x,y:item[0].y,moreInfo:item[0].moreInfo})
                    }
                    else{
                        var dt=moment(new Date()).format("YYYY-MM-DD");
                        var dt1=moment(dt+" "+i+":00")
                        volumeLine.data.push({x:dt1._d,y:0,moreInfo:[]})
                    }
                }
                var chartData={
                    series:[volumeLine],
                    options: {
                        chart: {
                          height: 400,
                          id:'apexchartsfcp10ctwj',
                          type: 'line',
                          zoom: {
                            enabled: true
                          }
                        },
                        tooltip:{
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                data.moreInfo.sort((a, b) => parseFloat(b.volume) - parseFloat(a.volume));
                                var beer_info=data.moreInfo.length!==0?data.moreInfo.map((item)=>{
                                    return `<div class="apexcharts-tooltip-y-group">
                                                <span class="apexcharts-tooltip-text-y-label">${item.beer_name}: </span>
                                                <span class="apexcharts-tooltip-text-y-value">${item.volume}</span>
                                            </div>`
                                }):[`<div class="apexcharts-tooltip-y-group">
                                <span class="apexcharts-tooltip-text-y-label">Volume : </span>
                                <span class="apexcharts-tooltip-text-y-value">${data.y}</span>
                            </div>`]
                                return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${moment(data.x).format("LT")} (${data.y})</div>
                                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                ${beer_info.join(" ")}                                
                               </div></div>`
                            }
                        },
                        dataLabels: {
                          enabled: false
                        },
                        stroke: {
                          curve: 'straight',
                          width: [1],
                          //dashArray: [0, 0, 0,2,2]
                        },
                        title: {
                          text: `Pour Hourly Distribution`,
                          align: 'left'
                        },
                        grid: {
                          row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                          },
                        },
                        xaxis: {
                          categories: xaxis,
                          type: "datetime",
                          tickAmount:6,
                          labels: {
                            datetimeUTC: false,
                            rotateAlways: false,
                            rotate: 0,
                            //tickPlacement: 'on',
                            formatter: function(val, timestamp) {
                                return moment(val).format("LT");; // formats to hours:minutes
                            }  
                          }
                        },
                        yaxis: [
                            {
                              seriesName: 'Volume',
                              axisTicks: {
                                show: true
                              },
                              axisBorder: {
                                show: true,
                                color: "#FF1654"
                              },
                              labels: {
                                style: {
                                  colors: "#FF1654"
                                },
                                formatter: function (value) {
                                    return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                }
                              },
                              title: {
                                text: units==="metric"?"Volume (ml)":"Volume (ou)",
                                style: {
                                  color: "#FF1654"
                                }
                              }
                            }
                          ]
                      }
                }
                newState.setState({
                    ...newState.state,
                    lineChart:chartData
                });

            }

        })
    }

    render(){
        return(
        <>
        <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} />
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        kegs: state.monitor.kegs,
        last_pour_item: state.monitor.last_pour_item,
        kegRooms:state.kegRooms.kegRooms
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(HourlyPourVolumeWidget);