import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";
import { Redirect, Link, NavLink } from 'react-router-dom'
import Select from 'react-select';
import { connect } from 'react-redux';
import * as UserInfoActions from "../redux/userDetail/action";
import axios from "axios";
import Form from 'reactstrap/lib/Form';

class StartUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = { proceedToSite: false,sites: [], selectedSite: null };
    }

    componentDidMount() {
        // let newState = Object.assign({}, this.state);
        // newState.userMappings.forEach((item) => {
        //     newState.sites.push({ label: item.usr_site.site_name, value: item.site_id, moreInfo: item.usr_resp.resp_security_level });
        // })

        // newState.selectedSite = newState.sites[0];
        // localStorage.setItem("site_id", newState.sites[0].value);
        // localStorage.setItem("_dft_user_security_level", JSON.stringify(newState.sites[0].moreInfo));
        // this.props.updateUserSec(newState.sites[0].moreInfo);
        // //this.props.updateUserInfo({ userDetail: null, security: newState.sites[0].moreInfo });
        // this.setState(newState);


        axios(`${process.env.REACT_APP_API_BASE_URL}/api/login/user`).then((response) => {
            var userMappings = response.data.userData.user_site_resp;
            var selected_site_id = userMappings[0].site_id
            var userSites = [];
            userMappings.forEach((item) => {
                userSites.push({
                    site_id: item.site_id,
                    units:item.usr_site.units,
                    value:item.site_id + '-' +item.responsibility_id,
                    label: item.usr_site.site_name +' - '+ item.usr_resp.responsibility_name,
                    sec_level: item.usr_resp.resp_security_level,
                    customerUrl:item.usr_site.site.image.indexOf('https')===-1? `https://cdndraftco.nyc3.digitaloceanspaces.com/assets/images/${item.usr_site.site.image}`:item.usr_site.site.image
                });
            });

            const selectedSite = userSites.find(a => a.site_id === selected_site_id);
            this.props.updateUserSites(userSites);
            this.props.updateUserSec(selectedSite);
            this.props.updateUserDetail(response.data.userData);
            this.props.setCompanyLogo("https://cdndraftco.nyc3.digitaloceanspaces.com/assets/logo/Black/draftco-logo-black.png")
            //var profile_Url=response.data.userData.person.profile.indexOf('https')===-1? `https://cdndraftco.nyc3.digitaloceanspaces.com/assets/images/${response.data.userData.person.profile}`:response.data.userData.person.profile;
            this.props.setUserLogo(selectedSite.customerUrl);
            localStorage.setItem("user_site_resp_selected",JSON.stringify(selectedSite));
            if(userSites.length===1){
                this.setState({proceedToSite:true});    
            }
        });
    }

    handleChangeSelection = (item, inputPropName) => {
        this.props.updateUserSec(item);
        localStorage.setItem("user_site_resp_selected",JSON.stringify(item));
        this.setState({proceedToSite:true});
    }

    render() {
        return (this.state.proceedToSite ? <Redirect to="/admin/dashboard" /> :
            <>
                <Col lg="5" md="7">
                    <Card className="shadow border-0 dft-bg-primary-color-lite text-center">
                        <CardHeader className="bg-transparent py-4">
                            <div className="text-muted text-center">
                                <h3 className="mb-0">Select your visiting Site</h3>
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <h4>Please select the site</h4>
                            <Form role="form" noValidate onSubmit={()=>window.location='#/admin/dashboard'}>
                            <Select size="sm" value={this.props.userSec} autoFocus
                                options={this.props.userSites}
                                onChange={(e) => this.handleChangeSelection(e, 'selectedSite')}>
                            </Select>
                            <br />
                            <Row>
                                <Col>
                                    <NavLink
                                        className="nav-link-icon"
                                        to="/admin/dashboard"
                                        tag={Link}
                                    >
                                        <span className="dft-primary-color nav-link-inner--text">Continue To Dashboard</span>
                                    </NavLink>
                                </Col>
                            </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserDetail: (userData) => dispatch(UserInfoActions.setUserDetail(userData)),
        updateUserSec: (userSec) => dispatch(UserInfoActions.setUserSec(userSec)),
        updateUserSites: (userSites) => dispatch(UserInfoActions.setUserSites(userSites)),
        setCompanyLogo:(url)=>dispatch(UserInfoActions.setCompanyLogo(url)),
        setUserLogo:(url)=>dispatch(UserInfoActions.setUserLogo(url))
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        userSec: state.userInfo.security
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartUp);