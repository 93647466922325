import React from "react";
import Select from "react-select";
import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import { successToaster } from '../toaster/toaster';
import SimpleReactValidator from "simple-react-validator"
import { isObject } from "reactstrap/lib/utils"

var DatePicker = require("reactstrap-date-picker");

class ResponsibilityDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsibility: {
        responsibility_id: parseInt(props.match.params.id),
        site_id: props.match.params.sid,
        responsibility_name: '',
        security_level_id: 0,
        selectedSecuritylevel: null,
      },
      securitylevels: [],
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: "Please select an option",
          rule: (val, params, validator) => {
            return isObject(val)
          },
          required: true, // optional
        },
      },
    })
  }

  componentDidMount() {
    this.fetchSecuritylevels();
  }

  fetchSecuritylevels = () => {
    // Load the list
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/securitylevel`).then((response) => {
      if (response.data !== undefined) {
        let securitylevelsData = [];
        response.data.forEach((item) => {
          securitylevelsData.push({
            value: item.security_level_id,
            label: item.sl_name,
          });
        });

        this.setState({ securitylevels: securitylevelsData }, () => {
          this.fetchResponsibility();
        });
      }
    });
  }

  fetchResponsibility = () => {
    const responsibility_id = this.state.responsibility.responsibility_id;
    if (responsibility_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/responsibility/${responsibility_id}`).then(response => {
        if (response.data) {
          let newState = Object.assign({}, this.state);
          newState.responsibility.responsibility_id = response.data.responsibility_id;
          newState.responsibility.responsibility_name = response.data.responsibility_name;

          newState.responsibility.security_level_id = response.data.security_level_id;
          newState.responsibility.expiry_date = response.data.expiry_date;

          if (response.data.security_level_id != null) {
            let securityLevel = this.state.securitylevels.find(p => p.value === response.data.security_level_id);
            if (securityLevel) {
              newState.responsibility.selectedSecuritylevel = { value: securityLevel.value, label: securityLevel.label };
            }
          } else {
            newState.responsibility.selectedSecuritylevel = {};
          }

          this.setState(newState);
        }
      });
    }
    else {
      let newState = Object.assign({}, this.state);
      if (this.state.responsibility.security_level_id != null) {
        let securityLevel = this.state.securitylevels.find(p => p.value === this.state.responsibility.site_id);
        if (securityLevel) {
          newState.responsibility.selectedSecuritylevel = { value: securityLevel.value, label: securityLevel.label };
        }
      } else {
        newState.responsibility.selectedSecuritylevel = {};
      }
      this.setState(newState);
    }
  }

  saveResponsibility = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    this.setState({ saveInProgress: true });

    const responsibilityInfo = this.state.responsibility;
    const formData = {
      responsibility_name: responsibilityInfo.responsibility_name,
      security_level_id: responsibilityInfo.selectedSecuritylevel.value,
      expiry_date: responsibilityInfo.expiry_date
    };

    if (responsibilityInfo.responsibility_id === 0) {

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/responsibility`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
        data: formData
      })
        .then(response => {
          successToaster("Added");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
    else {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/responsibility/${responsibilityInfo.responsibility_id}`,
        data: formData
      })
        .then(response => {
          successToaster("Updated");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
  }

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.responsibility[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }

  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.responsibility[inputPropName] = item;
    this.setState(newState);
  }

  handleDatepickerChange = (value, formattedValue, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (value) {
      newState.responsibility[inputPropName] = value;
    }
    this.setState(newState);
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.responsibility.responsibility_id === 0 ? "Add Responsibility" : "Edit Responsibility"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Name<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.responsibility.responsibility_name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'responsibility_name')} autoComplete="new-name" />
                {this.validator.message('Name', this.state.responsibility.responsibility_name, 'required')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Security Level<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.responsibility.selectedSecuritylevel}
                  options={this.state.securitylevels}
                  onChange={(e) => this.handleChangeSelection(e, 'selectedSecuritylevel')}></Select>
                  {this.validator.message('Security Level', this.state.responsibility.selectedSecuritylevel, 'required|selectValidator:""')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label>Expiry Date<span style={{color:'red'}}>*</span></Label>
                <DatePicker size="sm"
                  value={this.state.responsibility.expiry_date}
                  onChange={(v, f) => this.handleDatepickerChange(v, f, 'expiry_date')} />
                  {this.validator.message('Expiry date', this.state.responsibility.expiry_date, 'required')}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveResponsibility} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.responsibility.responsibility_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

export default ResponsibilityDetail;
