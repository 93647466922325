import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as beersActions from '../beers/store/beers.actions';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import WaterfallChart from "../charts/waterfall";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import {convertValueByMetric} from '../../utility/units-conversion'


class PourWaterfallChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selKegRoom: null,
            selKeg:null,
            kegRooms: [],
            kegs:[],
            pourData:[],
            waterFallData:[],
            hc_waterFallData:[],
            showHcChart:false,
            chartTitle:'',
            showChart:false,
            activeTab:"1",
            errorMessage:'',
            loading:false,
            beers:[],
            selBeer:null
        };
    }

    static getDerivedStateFromProps(props,state){
        let _beers=[];
        if(props.beers_kr.length>0){
            props.beers_kr.forEach((item)=>{
                _beers.push({value:item.id,label:item.name});
            })
        }
        if (_beers.length !== 0) {
            return {
              ...state,
              beers: _beers,
              selBeer:state.selBeer===null?_beers[0]:state.selBeer
            }
          }
          return null;
    }

    componentDidMount() {
        this.fetchKegRooms();
    }
  
    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]},()=>{
                   this.props.getBeersByKegRoom(kegRooms[0].value)
                   setTimeout(()=>{this.fetchKegs()},2000);
                });
            }
        });
    }

    fetchKegs=()=>{
        let beer_name=this.state.selBeer.label.replace(new RegExp("'", 'g'), "''");
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg/GetKegsByKegRoom`, { params: 
            { kr_id: this.state.selKegRoom.value,beer_name:beer_name} }).then(response => {
            if (response.data !== undefined) {
              let _kegs=[];
              response.data.forEach((item)=>{
                 let start=moment(item.connected_start_local).format("YYYY-MM-DD");
                 let end=moment(item.connected_end_local).format("YYYY-MM-DD");
                _kegs.push({
                    value:item.keg_id,
                    label:`Keg ${item.keg_id} (${start} - ${end})`
                })
              })
              this.setState({...this.state,kegs:_kegs,selKeg:_kegs[0]});
            }
          });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    getChartData=()=>{
        const units=this.props.units;
        this.setState({loading:true,showChart:false,showHcChart:false});
        let newState=this;
        var title=`${this.state.selKegRoom.label} ${this.state.selKeg.label} Keg Pour Volume History`;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/PourWaterfallChartData`,{params:{keg_id:this.state.selKeg.value,kr_id:this.state.selKegRoom.value}})
        .then(function(response){
            if(response.data!==undefined && response.data.length>0){
                response.data.map(function(item){
                    var datePart=item.end_date.substring(0,10)+ " 00:00:00";
                    var d=moment(datePart);
                    //var d1=moment(item.local_end_period);
                    item["end_date"]=moment(d._d).format("YYYY-MM-DD");
                    return item
                }) 
                response.data.sort((a, b) => new Date(a.end_date) - new Date(b.end_date));
                var minDate=Math.min(...response.data.map(s=>moment(s.end_date.substring(0,10))));
                var maxDate=Math.max(...response.data.map(s=>moment(s.end_date.substring(0,10))));
                var diffDays=moment(maxDate).diff(moment(minDate),'days');
                var days_bin=parseInt(diffDays/14);
                var datesBin=[];
                while(moment(minDate)<=moment(maxDate)){
                    var point={
                        x:moment(minDate).format("YYYY-MM-DD"),
                        fromDate:moment(minDate).format("YYYY-MM-DD"),
                        toDate:moment(minDate).add(days_bin,'days')>moment(maxDate)?moment(maxDate).format("YYYY-MM-DD"):moment(minDate).add(days_bin,'days').format("YYYY-MM-DD"),
                        y:0
                    }
                    datesBin.push(point);
                    minDate=moment(minDate).add(days_bin+1,'days');
                }
                var waterFallData=[];
                datesBin.forEach((item)=>{
                    var fromDate=moment(item.fromDate);
                    var toDate=moment(item.toDate);
                    var subData=response.data.filter(s=>moment(s.end_date)>=fromDate && moment(s.end_date)<=toDate)
                    if(subData.length!==0){
                        var vol_poured=subData.map(o => parseFloat(o.pour_volume)).reduce((a, c) => { return a + c });
                        item.y=parseFloat(convertValueByMetric(vol_poured,units,"vol")/(units==="metric"?1000:1));
                        waterFallData.push(item);
                    }
                })
                
                var groupByEndDate=  response.data.reduce(function (r, a) {
                    var end_date=moment(a.end_date.substring(0,10)).format("YYYY-MM-DD");
                    r[end_date] = r[end_date] || [];
                    r[end_date].push(a);
                    return r;
                }, Object.create(null));
               
                var pourData=[];
                for (var key in groupByEndDate){
                    var subData=groupByEndDate[key];
                    // eslint-disable-next-line no-loop-func
                    subData.forEach((item)=>{
                        pourData.push({
                            name:item.beer_name,
                            pour_volume:(convertValueByMetric(item.pour_volume,units,"vol")/(units==="metric"?1000:1)).toFixed(2),
                            number_pints:(item.pour_volume/item.mlperpint).toFixed(2),
                            pour_id:item.id,
                            keg_id:item.keg_id,
                            duration:item.duration_sec,
                            temperature:convertValueByMetric(item.temperature_c,units,"c").toFixed(2),
                            ambient:item.ambient_c===null?0:item.ambient_c.toFixed(2),
                            end_date:moment(item.end_date).format("YYYY-MM-DD"),
                            end_time:item.end_time

                        });
                        //counter++;
                    })
                  }
                  if(pourData.length>0){
                    pourData.push({
                        name:newState.state.selBeer.label,
                        pour_volume:pourData.map(o => parseFloat(o.pour_volume)).reduce((a, c) => { return a + c }).toFixed(2),
                        pour_id:0,
                        keg_id:0,
                        duration:pourData.map(o => parseFloat(o.duration)).reduce((a, c) => { return a + c }).toFixed(2),
                        temperature:(pourData.map(o => parseFloat(o.temperature)).reduce((a, c) => { return a + c })/pourData.length).toFixed(2),
                        ambient:(pourData.map(o => parseFloat(o.ambient)).reduce((a, c) => { return a + c })/pourData.length).toFixed(2),
                        end_date:"",
                        end_time:""

                    });
                  }
                  var totalPour_Volume=waterFallData.map(o => parseFloat(o.y)).reduce((a, c) => { return a + c });
                  waterFallData.push({x:'Total Volume',y:totalPour_Volume,"showFull":true})
                  newState.setState({
                      ...newState.state,
                      waterFallData:waterFallData,
                      pourData:pourData,
                      showChart:true,
                      chartTitle:title,
                      showHcChart:true,
                      loading:false,
                      errorMessage:''
                    })
            }
            else{
                newState.setState({
                    ...newState.state,
                    showHcChart:true,
                    loading:false,
                    errorMessage:'No data found'
                  })
            }
            
        })
    }

    

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
            if(inputPropName==="selBeer"){
                this.fetchKegs();
            }
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    render() {
        const columns = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "duration",
              text: "Duration (s)",
              sort: true,
              align:'right',
              headerAlign:'center'
            },
            {
              dataField: "pour_volume",
              text: this.props.units==="metric"?"Volume Poured (L)":"Volume Poured (OU)",
              sort: true,
              align: 'right',
              headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                align: 'right',
                headerAlign:'center'
            },
            {
                dataField: "end_date",
                text: "Date",
                sort: true,
                align:'center',
                headerAlign:'center'
             },
            {
              dataField: "end_time",
              text: "Time",
              sort: true,
              align:'center',
              headerAlign:'center'
            },
            {
              dataField: "temperature",
              text: this.props.units==="metric"?"Temp (C)":"Temp (F)",
              sort: true,
              align: 'right',
              headerAlign:'center'
            },
            // {
            //   dataField: "ambient",
            //   text: "Ambient (C)",
            //   sort: true,
            //   align: 'right',
            //   headerAlign:'center'
            // }
          ];
          
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Keg Pour Waterfall Plot</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Beer</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selBeer}
                                    options={this.state.beers}
                                    onChange={(e) => this.handleChangeSelection(e, 'selBeer')}>
                                </Select>
                            </Col>
                            <Col md="5" sm="5" lg="5">
                                <Label>keg</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKeg}
                                    options={this.state.kegs}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKeg')}>
                                </Select>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                {/* <Button  size="sm" onClick={this.getChartData}>Chart</Button> */}
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Report"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Row>
                        <Col lg="12" sm="12" md="12">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            { this.state.showChart?
                            <div >
                                    <WaterfallChart initialValue={0} data={this.state.waterFallData} yAxisTitle="Volume Poured (L)" title={this.state.chartTitle} />
                            </div>
                            :""}
                            <br />
                            
                        </TabPane>
                        <TabPane tabId="2">
                        {/* <Button onClick={()=>this.printPourVolume(columns)} size="sm">Print</Button>
                        <button onClick={this.generatePdf} className="btn btn-secondary btn-sm">Download Pdf</button> */}
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.pourData,this.state.chartTitle,this.props.companyLogo,this.props.userLogo,"Pour-Detail-Report.pdf")} 
                            className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_pour_volume_waterfall"
                                filename="Pour Data"
                                sheet="Pour Data"
                                buttonText="Download Excel"/>
                        </div>
                            <BootstrapTable id="tbl_pour_volume_waterfall"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="pour_id"
                                data={this.state.pourData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                    </TabContent>
                           
                        </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        beers_kr: state.beers.beers_kr,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBeersByKegRoom: (kr_id) => dispatch(beersActions.getBeersByKegRoom(kr_id)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(PourWaterfallChart);
