import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import * as chartActions from '../../charts/store/chart-actions';
import {convertValueByMetric} from '../../../utility/units-conversion'

class GlycolWidget extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            glycolData:[],
            loading:false,
            kegRooms:[],
            title:'',
            port:0,
            showChart:false,
            errorMessage:'',
            glycolDetails:[],
            volumeLineData:[],
            tempLineData:[],
            lineChart:{
                series:[],
                options:{}
            },
        };
    }

    componentDidMount(){
        this.props.getKegRooms(this.props.site_id);
        if(this.state.kegRooms.length>0){
          this.getChartData()
        }
    }

    static getDerivedStateFromProps(props, state) {
      return {
        kegRooms:props.kegRooms,
        port:props.port
      }
    }

    componentDidUpdate(prevProps,prevState){
      if(prevProps.kegRooms.length!==this.state.kegRooms.length){
        this.getChartData()
      }
      else if(prevProps.port!==this.state.port){
        this.getChartData()
      }
    }

    getChartData=()=>{
        if(this.state.kegRooms.length===0) return
        this.setState({showChart:false,loading:true});
        let newState=this;
        var title=`Glycol data for keg room`
        var params={
            kr_id:this.state.kegRooms[0].value,
            port:this.props.port
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetGlycolData`,{params:params})
        .then(function(response){
            if(response.data!==undefined){
                response.data.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
                var _glycolData=[];
                response.data.forEach((item)=>{
                    _glycolData.push({
                        gs_id:item.gs_id,
                        gs_name:item.gs_name,
                        temp_total:item.gs_temperature_total.toFixed(2),
                        temp_avg:item.gs_temperature_c_30_avg.toFixed(2),
                        temp_stdev:item.gs_temperature_30_stdev.toFixed(2),
                        flow_total:(item.gs_flow_total*3.6).toFixed(2),
                        flow_avg:(item.gs_flow_avg*3.6).toFixed(2),
                        flow_stdev:(item.gs_flow_stdev*3.6).toFixed(2),
                        vol_total:item.gs_volume_total.toFixed(2),
                        vol_avg:item.gs_volume_avg.toFixed(2),
                        vol_stdev:item.gs_volume_stdev.toFixed(2),
                        gs_count:item.gs_count
                    })
                })
                newState.setState({
                    ...newState.state,
                    glycolData:_glycolData,
                    showChart:true,
                    title:title,
                    loading:false,
                },()=>{newState.getGlycolDetails()});
            }

        })
    }

    getGlycolDetails=()=>{
        let newState=this;
        const units=this.props.units;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetGlycolDetails`,{
          params:{
            kr_id:this.state.kegRooms[0].value,
            port:this.props.port,
            fromDate: moment(new Date()).add('days',-7).format('MM/DD/YYYY'),
            toDate: moment(new Date()).format('MM/DD/YYYY'),
          }})
        .then(function(response){
            var lineChartData=[];
            var agg_data=newState.state.glycolData;

            var volumeLine={name:"Volume",data:[]};
            var tempLine={name:"Temperature",data:[]};
            var avg_temp_line={name:"Avg Temp",data:[]};
            var avg_temp_line1={name:"Avg + Std Dev Temp",data:[]};
            var avg_temp_line2={name:"Avg - Std Dev Temp",data:[]};
            var xaxis=[];
            if(response.data!==undefined && response.data.length>0){
                response.data.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
                var groupByDate=  response.data.reduce(function (r, a) {
                  r[a.start_time] = r[a.start_time] || [];
                  r[a.start_time].push(a);
                  return r;
                }, Object.create(null));
                for(var key in groupByDate){
                  var item=groupByDate[key][0];
                  item.volume=convertValueByMetric(item.volume,units,"vol");
                  item.temperature_c=convertValueByMetric(item.temperature_c,units,"c");
                  var temp_avg=convertValueByMetric(parseFloat(agg_data[0].temp_avg),units,"c");
                  var temp_stdev=convertValueByMetric(parseFloat(agg_data[0].temp_stdev),units,"std");
                    lineChartData.push({
                        id:item.id,
                        start_time:item.start_time,
                        pour:item.pour,
                        volume:item.volume,
                        temperature:item.temperature_c
                    })
                    xaxis.push(item.start_time);
                    volumeLine.data.push({x:item.start_time,y:item.volume.toFixed(2)})
                    tempLine.data.push({x:item.start_time,y:item.temperature_c.toFixed(2)})
                    if(agg_data.length>0){
                      avg_temp_line.data.push({x:item.start_time,y:temp_avg})
                      avg_temp_line1.data.push({x:item.start_time,y:(temp_avg+temp_stdev).toFixed(2)})
                      avg_temp_line2.data.push({x:item.start_time,y:(temp_avg-temp_stdev).toFixed(2)})
                    }
                    
                }

                var volume_min =(Math.min(...volumeLine.data.map(s=>s.y))-Math.min(...volumeLine.data.map(s=>s.y)))*6;
                var tempLine_min=Math.min(...tempLine.data.map(s=>s.y));
                var avg_temp_min =Math.min(...avg_temp_line.data.map(s=>s.y));
                var avg_temp_min1 =Math.min(...avg_temp_line1.data.map(s=>s.y));
                var avg_temp_min2 =Math.min(...avg_temp_line2.data.map(s=>s.y));
                var temp_min=Math.min(...[tempLine_min,avg_temp_min,avg_temp_min1,avg_temp_min2])-1;
                var chartData={
                    series:[volumeLine,tempLine,avg_temp_line,avg_temp_line1,avg_temp_line2],
                    options: {
                        chart: {
                          height: 250,
                          type: 'line',
                          zoom: {
                            enabled: false
                          },
                          toolbar: {
                            show: false
                          }
                        },
                        dataLabels: {
                          enabled: false
                        },
                        stroke: {
                          curve: 'straight',
                          width: [1,1,1, 2, 2],
                          dashArray: [0, 0, 0,4,4]
                        },
                        title: {
                          //text: `Glycol Chart with average temperature at ${parseFloat(agg_data[0]===undefined?"":convertValueByMetric(agg_data[0].temp_avg,units,"c")).toFixed(2)} ${units==="metric"?"C":"F"} and average flow at ${parseFloat(agg_data[0]===undefined?"":convertValueByMetric(agg_data[0].flow_avg*1000,units,"vol")/(units==="metric"?1000:1)).toFixed(2)} ${units==="metric"?"(L/HR)":"(OU/HR)"}`,
                          align: 'left'
                        },
                        legend: {
                            show: false,
                        },
                        grid: {
                          row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                          },
                        },
                        xaxis: {
                          categories: xaxis,
                          type: "datetime",
                          //min:volumeMin,
                          tickAmount:4,
                          labels: {
                            datetimeUTC: false,
                            rotateAlways: false,
                            rotate: 0,
                            tickPlacement: 'between',
                            formatter: function(val, timestamp) {
                                return moment(val).format("DD MMM hh:mm");; // formats to hours:minutes
                            }  
                          }
                        },
                        yaxis: [
                            {
                              seriesName: 'Volume',
                              //forceNiceScale: true,
                              //min:volume_min,
                              tickAmount:volume_min,
                              axisTicks: {
                                show: true
                              }, 
                              axisBorder: {
                                show: true,
                                color: "#0893FB"
                              },
                              labels: {
                                style: {
                                  colors: "#0893FB"
                                },formatter: function(val, timestamp) {
                                  return parseFloat(val).toFixed(2) // formats to hours:minutes
                                } 
                              },
                              title: {
                                text: units==="metric"?"Volume (ml per 6 Sec)":"Volume (ou per 6 Sec)",
                                style: {
                                  color: "#0893FB"
                                }
                              }
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              //min:units==="metric"?0:29,
                              min:temp_min,
                              tickAmount:10,
                              axisTicks: {
                                show: true
                              },
                              axisBorder: {
                                show: true,
                                color: "#00E396"
                              },
                              labels: {
                                style: {
                                  colors: "#00E396"
                                },
                                formatter: function(val, timestamp) {
                                  return parseFloat(val).toFixed(2) // formats to hours:minutes
                              }  
                              },
                              title: {
                                text: units==="metrics"?"Temperature (C)":"Temperature (F)",
                                style: {
                                  color: "#00E396"
                                }
                              }
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              min:temp_min,
                              show:false,
                              tickAmount:10,
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              min:temp_min,
                              show:false,
                              tickAmount:10,
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              show:false,
                              min:temp_min,
                              tickAmount:10,
                            }
                          ]
                      }
                }
                newState.setState({
                    ...newState.state,
                    glycolDetails:lineChartData,
                    lineChart:chartData
                });
                
            }
        });
    }

    render(){
        return(
        <>
        <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} />
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        kegRooms:state.charts.kegRooms,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        port:state.monitor.port
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getKegRooms: (site_id) => dispatch(chartActions.getKegRooms(site_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlycolWidget);