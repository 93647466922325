import React from "react";
import { connect } from 'react-redux';
import axios from "axios";
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import * as chartActions from '../../charts/store/chart-actions';
import * as krTempActions from '../../kr_temperature/store/kr_temperature.actions';
import {convertValueByMetric} from '../../../utility/units-conversion';

class KrTemperatureWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lineChart:{
                series:[],
                options:{}
            },
            kegRooms:[],
            last_pour_item:{
              last_pour_date:"",
              volume_poured:"",
              beer_name:"",
              pour_id:0,
              pour_volume:0,
              line:0
            }
        };
    }
    
    async componentDidMount(){
      await this.props.getKegRooms(this.props.site_id);
      if(this.props.kegRooms.length>0){
        this.getChartData(this.props.kegRooms[0].value);
      }
    }

    async componentDidUpdate(prevProps,prevState){
      if(prevProps.last_pour_item===null &&  this.state.last_pour_item===null){

      }
      else if(prevProps.last_pour_item.pour_id!==this.state.last_pour_item.pour_id){
        if(this.props.kegRooms.length>0){
          //await this.props.updateKrTemp();
          this.getChartData(this.props.kegRooms[0].value);
        }
      }
      if(prevProps.kegRooms.length!==this.state.kegRooms.length){
        debugger
        this.getChartData(this.state.kegRooms[0].value);
      }
    }

    static getDerivedStateFromProps(props, state) {
       return {
            last_pour_item:props.last_pour_item,
            kegRooms:props.kegRooms
        }
  }
     
    getChartData=(kr_id)=>{
        let newState=this;
        let units=this.props.units;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/KrTempSensor/GetKrTemperatureReadings`,{params:{
          kr_id:kr_id,
          fromDate: moment(new Date()).add('days',-7).format('MM/DD/YYYY'),
          toDate: moment(new Date()).format('MM/DD/YYYY'),
          filter:"Raw"
        }})
        .then(function(response){
            var lineChartData=[];
            var tempLine={name:"Temperature",data:[]};
            var avg_temp_line={name:"Avg Temp",data:[]};
            var avg_temp_line1={name:"Avg + Std Dev Temp",data:[]};
            var avg_temp_line2={name:"Avg - Std Dev Temp",data:[]};
            var xaxis=[];
            if(response.data!==undefined && response.data.length>0){
                response.data.sort((a, b) => new Date(a.local_time) - new Date(b.local_time));
                var groupByDate=  response.data.reduce(function (r, a) {
                  r[a.local_time] = r[a.local_time] || [];
                  r[a.local_time].push(a);
                  return r;
              }, Object.create(null));
             
            for(var key in groupByDate){
                var item=groupByDate[key][0];
                lineChartData.push({
                    id:item.kt_id,
                    start_time:item.local_time,
                    temperature:item.temperature_c
                })
                var temperature=convertValueByMetric(item.temperature_c,units,"c");
                var temperature_avg=convertValueByMetric(item.kr_temperature_c_30_avg,units,"c");
                var temperature_stdev=convertValueByMetric(item.kr_temperature_30_stdev,units,"std");
                xaxis.push(new Date(item.local_time));
                tempLine.data.push({x:item.local_time,y:temperature.toFixed(2)})
                avg_temp_line.data.push({x:item.local_time,y:temperature_avg.toFixed(2)})
                avg_temp_line1.data.push({x:item.local_time,y:(temperature_avg+temperature_stdev).toFixed(2)})
                avg_temp_line2.data.push({x:item.local_time,y:(temperature_avg-temperature_stdev).toFixed(2)})
            }
            var temp_tick =(Math.min(...tempLine.data.map(s=>s.y))-Math.min(...tempLine.data.map(s=>s.y)))*6;
                var chartData={
                    series:[tempLine,avg_temp_line,avg_temp_line1,avg_temp_line2],
                    options: {
                        chart: {
                          height: 350,
                          type: 'line',
                          zoom: {
                            enabled: true
                          }
                        },
                        dataLabels: {
                          enabled: false
                        },
                        stroke: {
                          curve: 'straight',
                          width: [1],
                          dashArray: [0.5,4,4,4]
                        },
                        title: {
                          text: `Keg Room Temperature Plot`,
                          align: 'left'
                        },
                        grid: {
                          row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                          },
                        },
                        xaxis: {
                          categories: xaxis,
                          type: "datetime",
                        
                          tickAmount:4,
                          labels: {
                            datetimeUTC: false,
                            rotateAlways: false,
                            rotate: 0,
                            tickPlacement: 'between',
                            formatter: function(val, timestamp) {
                              return moment(val).format("DD MMM hh:mm");; // formats to hours:minutes
                            }  
                          }
                        },
                        yaxis: [
                            {
                              seriesName: 'Temperature',
                              tickAmount:temp_tick,
                              //min:units==="metric"?0:29,
                              axisTicks: {
                                show: true
                              },
                              axisBorder: {
                                show: true,
                                color: "#FF1654"
                              },
                              labels: {
                                style: {
                                  colors: "#FF1654"
                                },
                                formatter: function (value) {
                                    return value.toFixed(2);
                                }
                              },
                              title: {
                                text: units==="metric"?"Temperature (C)":"Temperature (F)",
                                style: {
                                  color: "#FF1654"
                                }
                              }
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              show:false
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              show:false,
                            },
                            {
                              seriesName: 'Temperature',
                              opposite: true,
                              show:false,
                            }
                          ]
                      }
                }
                
                newState.setState({
                    ...newState.state,
                    lineChart:chartData
                });
            }
        });
    }

   
    render() {
        return (
            <React.Fragment>
             <div>
                <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} />
            </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        kegRooms:state.charts.kegRooms,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        last_pour_item: state.monitor.last_pour_item,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getKegRooms: (site_id) => dispatch(chartActions.getKegRooms(site_id)),
      updateKrTemp:()=>dispatch(krTempActions.updateKrTemp())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KrTemperatureWidget);
