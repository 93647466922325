import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import LoaderComponent from '../shared/loader';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AdvancedFilter from "../shared/advancedFilter";

class SecuritylevelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      securitylevels: [],
      filteredSecuritylevelList: [],
      fields: [{ value: 'security_level_id', label: 'Id', type: 'int' }, { value: 'sl_name', label: 'Name', type: 'text' }, { value: 'tech_only', label: 'Tech Only', type: 'text' }, { value: 'data_only', label: 'Data Only', type: 'text' }, { value: 'user_only', label: 'Super User', type: 'text' }],
      showAdvancedSearch: false,
      expanded: [],
      showList: false,
    };
  }

  componentDidMount() {
    this.fetchSecuritylevels();
  }

  toggleAdvancedSearch = () => {
    let newState = Object.assign({}, this.state);
    newState.showAdvancedSearch = !newState.showAdvancedSearch;
    if (!newState.showAdvancedSearch) {
      newState.filteredSecuritylevelList = newState.securitylevels;
    }
    this.setState(newState);
  }

  callBackFromAdvancedSearch = (data) => {
    let newState = Object.assign({}, this.state);
    newState.filteredSecuritylevelList = data;
    this.setState(newState);
  }

  fetchSecuritylevels = () => {
    if (this.state.kr_id) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/securitylevel`, { params: {} }).then(response => {

        if (response.data !== undefined) {
          this.setSecuritylevelData(response.data);
        }
      });
    } else {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/securitylevel`).then((response) => {
        if (response.data !== undefined) {
          this.setSecuritylevelData(response.data);
        }
      });
    }
  }

  setSecuritylevelData = (responseData) => {
    let securitylevelsData = [];
    responseData.forEach((item) => {
      securitylevelsData.push({
        security_level_id: item.security_level_id,
        sl_name: item.sl_name,
        tech_only: item.tech_only,
        user_only: item.user_only,
        data_only: item.data_only,
        draftco_only: item.draftco_only
      });
    });

    this.setState({ sites: securitylevelsData, filteredSecuritylevelList: securitylevelsData, showList: true });
  }

  addSecuritylevel = () => {
    // if(this.state.security_level_id && this.state.security_level_id > 0)
    // window.location = "#/admin/securitylevel/0/securitylevel/" + this.state.security_level_id;
    // else
    window.location = "#/admin/securitylevel/0";
  };

  filterSecuritylevelList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredSecuritylevelList: [...this.state.securitylevels] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredSecuritylevels = this.state.securitylevels.filter(
        (com) =>
          com.kr_name.toLowerCase().indexOf(searchText) >= 0
      );
      this.setState({ filteredSecuritylevelList: [...filteredSecuritylevels] });
    }
  };

  render() {
    const options = {
      //sizePerPageRenderer,
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const columns = [
      {
        dataField: "security_level_id",
        text: "Security Level Id",
        hidden: true,
      },
      {
        dataField: "sl_name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "tech_only",
        text: "Tech Only",
        sort: true,
        formatter: (cell, row) => {
          return cell ? 'Yes' : 'No'
        }
      },
      {
        dataField: "user_only",
        text: "User Only",
        sort: true,
        formatter: (cell, row) => {
          return cell ? 'Yes' : 'No'
        }
      },
      {
        dataField: "data_only",
        text: "Data Only",
        sort: true,
        formatter: (cell, row) => {
          return cell ? 'Yes' : 'No'
        }
      },
      {
        dataField: "draftco_only",
        text: "Draftco Only",
        sort: true,
        formatter: (cell, row) => {
          return cell ? 'Yes' : 'No'
        }
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link
                className="btn btn-sm btn-info"
                to={`/admin/securitylevel/${row.security_level_id}`}
                params={{ testvalue: "hello" }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
              {/* <button
                className="btn btn-sm btn-danger"
                onClick={() => this.customDelete(row)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button> */}
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" style={{ width: '100%' }}>
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterSecuritylevelList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              <Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addSecuritylevel}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.sites} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="security_level_id"
                    data={this.state.filteredSecuritylevelList}
                    pagination={paginationFactory(options)}
                    columns={columns}
                  />
                ) : this.state.showList === false ? (
                  <LoaderComponent />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default SecuritylevelList;
