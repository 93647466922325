import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./beers.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_BEERS):
            return setBeers(state, action);
        case (actionTypes.SET_BEERS_KR):
            return setBeers_Kr(state, action);
        case (actionTypes.SET_SEL_BEERS):
            return setSelBeers(state, action);
        case (actionTypes.SET_CURR_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.ADD_BEER):
            return addBeer(state, action);
        case (actionTypes.UPDATE_BEER):
            return updateBeer(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        case (actionTypes.UPDATE_BEERS_KR):
            return updateKrBeers(state, action);
        case (actionTypes.SET_BEER_DISTRIBUTORS):
            return setBeer_Distributors(state, action);
        default:
            return state;
    }

};

const setSelBeers = (state, action) => {
    return updateObject(state, {
        selBeer: action.data
    });
};

const setBeer_Distributors= (state, action) => {
    return updateObject(state, {
        distributors: action.data
    });
};

const setBeers = (state, action) => {
    return updateObject(state, {
        beers: action.data
    });
};

const setBeers_Kr= (state, action) => {
    return updateObject(state, {
        beers_kr: action.data
    });
};

const updateKrBeers=(state,action)=>{
    let newBeer={
        name:action.data.newBeer.label,
        id:action.data.newBeer.value
    }
    return {
        ...state,
        beers_kr: [...state.beers_kr.slice(0, action.data.oldBeerIndex),newBeer, ...state.beers_kr.slice(action.data.oldBeerIndex + 1)]
    };
}

const addBeer = (state, action) => {
    let beers = [...state.beers];
    beers.push(action.data);
    return updateObject(state, {
        beers: beers
    });
};

const updateBeer = (state, action) => {
    const index = state.beers.findIndex(b => b.id === action.data.id);
    return {
        ...state,
        beers: [...state.beers.slice(0, index), action.data, ...state.beers.slice(index + 1)]
    };
};



// set current page number of beers list page 
const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selBeer: initialState.selBeer,
        beers: initialState.beers,
        currPage: initialState.currPage
    });
};





export default reducer;