import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import WaterfallChart from "./waterfall";
import LoaderComponent from "../shared/loader";
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import {convertValueByMetric} from '../../utility/units-conversion'
import {sortByDataType} from '../../utility/common';

var DatePicker = require("reactstrap-date-picker");
//const pdfGenerator=require('../../utility/pdfGenerator')

class KegInventoryChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            expanded:[],
            kegData:[],
            data:[],
            waterFallData:[],
            pourData:[],
            showPourData:false,
            enableChartTab:false,
            activeTab:"2",
            loading:false,
            title:'',
            showChart:false,
            errorMessage:'',
        };
        console.log(moment().startOf('month').format('DD/MM/YYYY'));
    }

    
    
    componentDidMount() {
        this.fetchKegRooms();
    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]});
            }
        });
    }

    getChartData=()=>{
        const units=this.props.units;
        this.setState({kegData:[]});
        let newState=this;
        var title=`Total used kegs from ${moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD")} to ${moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD")}`
        var params={
            from_date:moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD"),
            kr_id:this.state.selKegRoom.value
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/KegInventoryChart`,{params:params})
        .then(function(response){
            if(response.data!==undefined && response.data.length>0){
                var kr_data=response.data;
                var groupByBeer=  response.data.reduce(function (r, a) {
                    r[a.beer_name] = r[a.beer_name] || [];
                    r[a.beer_name].push(a);
                    return r;
                }, Object.create(null));
                var _kegsData=[],counter=1;
                for(var key in groupByBeer){
                    var kegData=groupByBeer[key];
                    var volume_poured = kegData.map(o => o.volume_poured).reduce((a, c) => { return a + c });
                    var percent_remaining = kegData.map(o => o.percent_remaining).reduce((a, c) => { return a + c });
                    var avg_temp = kegData.map(o => convertValueByMetric(o.avg_temp,units,"c")).reduce((a, c) => { return a + c });
                    var size = kegData.map(o => o.size).reduce((a, c) => { return a + c });
                    var duration = kegData.map(o => o.on_service_duration_days).reduce((a, c) => { return a + c });
                    _kegsData.push({
                        keg_id:counter,
                        name:key,
                        count:kegData.length,
                        volume_poured:(convertValueByMetric(volume_poured,units,"vol")/(units==="metric"?1000:1)).toFixed(2),
                        avg:(convertValueByMetric(volume_poured,units,"vol")/((units==="metric"?1000:1)*kegData.length)).toFixed(2),
                        avg_percent_left:((percent_remaining*100)/kegData.length).toFixed(2),
                        avg_temp:(avg_temp/kegData.length).toFixed(2),
                        size:(convertValueByMetric(size,units,"vol")/((units==="metric"?1000:1)*kegData.length)).toFixed(2),
                        mlperpint:kegData[0].mlperpint,
                        number_pints:(convertValueByMetric(volume_poured,units,"vol")/convertValueByMetric(kegData[0].mlperpint,units,"vol")).toFixed(2),
                        duration:parseFloat((duration/kegData.length)).toFixed(2),
                    })
                    counter++;
                }
                
                newState.setState({
                    ...newState.state,
                    activeTab:"2",
                    kegData:_kegsData,
                    //showChart:true,
                    title:title,
                    data:kr_data,
                    //loading:false,
                });
            }

        })
    }

    onBeerExpand=(row,isExpand,rowIndex,e)=>{
        if (isExpand &&this.state.pourData.filter(a => a.keg_id === row.keg_id).length === 0 ) {
            this.showPourDetails(row);
        }
        else if(!isExpand){
            this.setState({...this.state,pourData:[],showPourData:true,expanded:[],enableChartTab:isExpand});
        }
    }

    onBeerRender=(row)=>{
        const columns_kegs = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
              headerAlign:'center'
            },
            {
                dataField: "end_date",
                text: "End Date",
                sort: true,
                headerAlign:'center'
            },
            {
              dataField: "volume_poured",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "temp",
                text: this.props.units==="metric"?"Temperature (C)":"Temperature (F)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "ambient",
                text: this.props.units==="metric"?"Ambient (C)":"Ambient (F)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "duration",
                text: "Pour Duration (Sec)",
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                sort: true,
                align:'right',
                headerAlign:'center'
            }
          ];
          var title=`${row.name} Pour Data for keg ${row.keg_id}`;
          if(this.state.showPourData){
            if(this.state.pourData.filter(a => a.keg_id === row.keg_id).length !== 0){
                return(
                    <>
                    <div style={{padding:'10px',float:'right'}}>
                        <button className="btn btn-sm btn-info" onClick={() => this.rowClicked(row)}>Chart</button>
                        <Button onClick={()=>printReport(columns_kegs,this.state.pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                        <button onClick={()=>DownloadPdf(columns_kegs,this.state.pourData,title,this.props.companyLogo,this.props.userLogo,"Keg-Inventory-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                        <ReactHTMLTableToExcel
                            className="btn btn-secondary btn-sm"
                            table="tbl_PourData"
                            filename="Keg Inventory"
                            sheet="Pour Data"
                            buttonText="Download Excel"/>
                    </div>
                        <BootstrapTable id="tbl_PourData"
                                    classes="custom-table-css dft-bg-primary-color tbl-inner"
                                    striped
                                    hover
                                    condensed
                                    keyField="pour_id"
                                    data={this.state.pourData}
                                    //pagination={paginationFactory(pagingOptions)}
                                    columns={columns_kegs}>
                                </BootstrapTable>
                    </>
                )
            }
            else{
                return(<><LoaderComponent /></>);
            }
          }else{
            if(this.state.pourData.filter(a => a.keg_id === row.keg_id).length === 0){
                return(<><LoaderComponent /></>);
            }
          }
    }

    showBeerDetails=(row,columns)=>{
        const expandBeerRow = {
            onlyOneExpanding: true,
            expanded:this.state.expanded,
            nonExpandable: [0],
            onExpand:this.onBeerExpand,
            parentClassName: 'selected',
            renderer: this.onBeerRender,
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              return(<div onClick={(e) => { e.stopPropagation() }}></div>);
            },
            expandColumnRenderer: ({ expanded }) => {
              if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
            }
          };
        var kegsByBeer=this.state.data.filter(p=>p.beer_name===row.name);
        var kegData=[];
        kegsByBeer.forEach((item)=>{
            kegData.push({
                name:row.name,
                keg_id:item.keg_id,
                line:item.local_line_number,
                connected_end:moment(item.connected_end_local.substring(0,10)).format("YYYY-MM-DD"),
                connected_start:moment(item.connected_start_local.substring(0,10)).format("YYYY-MM-DD"),
                volume_poured:(convertValueByMetric(item.volume_poured,this.props.units,"vol")/(this.props.units==="metric"?1000:1)).toFixed(2),
                volume_avg:(convertValueByMetric(item.volume_poured,this.props.units,"vol")/((this.props.units==="metric"?1000:1)*kegsByBeer.length)).toFixed(2),
                avg_percent_left:((item.percent_remaining*100)).toFixed(2),
                avg_temp:(convertValueByMetric(item.avg_temp,this.props.units,"c")).toFixed(2),
                size:(convertValueByMetric(item.size,this.props.units,"vol")/((this.props.units==="metric"?1000:1))).toFixed(2),
                number_pints:(convertValueByMetric(item.volume_poured,this.props.units,"vol")/convertValueByMetric(item.mlperpint,this.props.units,"vol")).toFixed(2),
                duration:parseFloat((item.on_service_duration_days)).toFixed(2),
            })
        })
        if(kegData.length>0){
            kegData.push({
                name:row.name,
                keg_id:0,
                line:"Summary",
                connected_end:"",
                connected_start:"",
                volume_poured:kegData.map(s=>parseFloat(s.volume_poured)).reduce((acc, item) => parseFloat(acc+item, 0)).toFixed(2),
                avg_percent_left:(kegData.map(s=>parseFloat(s.avg_percent_left)).reduce((acc, item) => parseFloat(acc+item, 0))/kegData.length).toFixed(2),
                avg_temp:(kegData.map(s=>parseFloat(s.avg_temp)).reduce((acc, item) => parseFloat(acc+item, 0))/kegData.length).toFixed(2) ,
                size:(kegData.map(s=>parseFloat(s.size)).reduce((acc, item) => parseFloat(acc+item, 0))/kegData.length).toFixed(2),
                duration:(kegData.map(s=>parseFloat(s.duration)).reduce((acc, item) => parseFloat(acc+item, 0))/kegData.length).toFixed(2),
            })
        }
        var title=`${row.name} kegs used from ${moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD")} to ${moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD")}`
        if(kegData.length!==0){
            return(
                <>
                <div style={{padding:'10px',float:'right'}}>
                    <Button onClick={()=>printReport(columns,kegData,"ifmcontentstoprint")} size="sm">Print</Button>
                    <button onClick={()=>DownloadPdf(columns,kegData,title,this.props.companyLogo,this.props.userLogo,"Keg-Inventory-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                    <ReactHTMLTableToExcel
                            className="btn btn-secondary btn-sm"
                            table="tbl_Kegs"
                            filename="Keg Inventory"
                            sheet="Kegs"
                            buttonText="Download Excel"/>
                </div>
                    <BootstrapTable id="tbl_Kegs"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="keg_id"
                                data={kegData}
                                expandRow={expandBeerRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    showPourDetails=(row,pourData)=>{
        var context=this;
        var mlpermint=this.state.data[0].mlperpint;
        const units=this.props.units;
        //context.setState({...context.state,showPourData:false,pourData:[]});
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetPourData`,{params:{keg_id:row.keg_id}})
        .then(function(response){
            var pourData=[];
            response.data.forEach((item)=>{
                pourData.push({
                    name:row.beer_name,
                    pour_id:item.id,
                    keg_id:item.keg_id,
                    end_date:moment(item.end_date.substring(0,10)).format("YYYY-MM-DD"),
                    volume_poured:(convertValueByMetric(item.pour_volume,units,"vol")).toFixed(2),
                    temp:convertValueByMetric(item.temperature_c,units,"c").toFixed(2),
                    number_pints:(convertValueByMetric(item.pour_volume,units,"vol")/convertValueByMetric(mlpermint,units,"vol")).toFixed(2),
                    duration:item.duration_sec,
                    ambient:item.ambient_c===null?"0":convertValueByMetric(item.ambient_c,units,"c").toFixed(2)
                })
            })
            if(response.data.length>0){
                pourData.push({
                    name:row.beer_name,
                    pour_id:0,
                    keg_id:0,
                    end_date:"Summary",
                    volume_poured:(response.data.map(s=>parseFloat(convertValueByMetric(s.pour_volume,units,"vol"))).reduce((acc, item) => parseFloat(acc+item, 0))/(units==="metric"?1000:1)).toFixed(2),
                    temp:(response.data.map(s=>parseFloat(convertValueByMetric(s.temperature_c,units,"c"))).reduce((acc, item) => parseFloat(acc+item, 0))/response.data.length).toFixed(2),
                    duration:(response.data.map(s=>parseFloat(s.duration_sec)).reduce((acc, item) => parseFloat(acc+item, 0))/response.data.length).toFixed(2),
                    ambient:(response.data.map(s=>parseFloat(convertValueByMetric(s.ambient_c,units,"c"))).reduce((acc, item) => parseFloat(acc+item, 0))/response.data.length).toFixed(2)
                })
            }
            context.setState({...context.state,pourData:pourData,showPourData:true,expanded:[row.keg_id],enableChartTab:true});
        })
        
    }

    rowClicked=(row)=>{
        var context=this;
        var pourData=this.state.pourData.filter(s=>s.end_date!=="Summary");
        var minDate=Math.min(...pourData.map(s=>moment(s.end_date)));
        var maxDate=Math.max(...pourData.map(s=>moment(s.end_date)));
        var diffDays=moment(maxDate).diff(moment(minDate),'days');
        var days_bin=parseInt(diffDays/14);
        var datesBin=[];
        while(moment(minDate)<=moment(maxDate)){
            var point={
                x:moment(minDate).format("YYYY-MM-DD"),
                fromDate:moment(minDate).format("YYYY-MM-DD"),
                toDate:moment(minDate).add(days_bin,'days')>moment(maxDate)?moment(maxDate).format("YYYY-MM-DD"):moment(minDate).add(days_bin,'days').format("YYYY-MM-DD"),
                y:0
            }
            datesBin.push(point);
            minDate=moment(minDate).add(days_bin+1,'days');
        }
        var _waterfall_data=[...[]];
        datesBin.forEach((item)=>{
            var fromDate=moment(item.fromDate);
            var toDate=moment(item.toDate);
            var subData=pourData.filter(s=>moment(s.end_date)>=fromDate && moment(s.end_date)<=toDate)
            if(subData.length!==0){
                var vol_poured=subData.map(o => parseFloat(o.volume_poured)).reduce((a, c) => { return a + c });
                item.y=parseFloat(vol_poured/(this.props.units==="metric"?1000:1));
                _waterfall_data.push(item);
            }
        })
        var totalPour_Volume=_waterfall_data.map(o => parseFloat(o.y)).reduce((a, c) => { return a + c });
        _waterfall_data.push({x:'Total Volume',y:totalPour_Volume,"showFull":true})
        var title=`${context.state.selKegRoom.label} ${row.name} Pour Volume Inventory`
        context.setState({
            ...context.state,
            activeTab:"1",
            title:title,
            waterFallData:_waterfall_data,
            showChart:true
        });
    }

    showWaterFallChart=()=>{
        if(this.state.waterFallData.length>0 && this.state.activeTab==="1"){
            return(<>
                <WaterfallChart initialValue={0} data={this.state.waterFallData} title={this.state.title} />
            </>)
        }
        return "";
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    onBeerColumnRender=(expanded)=>{
        this.setState({...this.state,enableChartTab:expanded})
        if (expanded) {
            return (
              <i className="fa fa-caret-down fa-md"></i>
            );
          }
          return (
            <i className="fa fa-caret-right fa-md"></i>
          );
    }


    render() {
        const {showBeerDetails}=this;
        const expandBeerRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return showBeerDetails(row,columns_kegs)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer:  ({ expanded }) => this.onBeerColumnRender(expanded)
          };

        const columns = [
            {
              dataField: "keg_id",
              text: "Keg Id",
              hidden: true,
              headerAlign:'center'
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "count",
              text: "Count",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
              headerAlign:'center'
            },
            {
              dataField: "volume_poured",
              text: this.props.units==="metric"?"Total Volume (l)":"Total Volume (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            // {
            //     dataField: "avg",
            //     text: this.props.units==="metric"?"Avg Volume (L)":"Avg Volume (ou)",
            //     sort: true,
            //     align:'right',
            //     headerAlign:'center'
            // },
            // {
            //     dataField: "avg_percent_left",
            //     text: "Avg % Left",
            //     sort: true,
            //     align:'right',
            //     headerAlign:'center'
            // },
            {
                dataField: "avg_temp",
                text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "size",
                text: this.props.units==="metric"?"Size (L)":"Size (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "duration",
                text: "Avg Duration (days)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            }
          ];

          const columns_kegs = [
            {
              dataField: "keg_id",
              text: "Keg Id",
              hidden: true,
              headerAlign:'center'
            },
            {
              dataField: "line",
              text: "Line #",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
              headerAlign:'center'
            },
            {
                dataField: "connected_start",
                text: "Connected Start",
                sort: true,
                headerAlign:'center'
            },
            {
                dataField: "connected_end",
                text: "Connected End",
                sort: true,
                headerAlign:'center'
            },
            {
              dataField: "volume_poured",
              text: this.props.units==="metric"?"Volume Poured (l)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            // {
            //     dataField: "volume_avg",
            //     text: this.props.units==="metric"?"Avg Volume (l)":"Avg Volume (ou)",
            //     sort: true,
            //     align:'right',
            //     headerAlign:'center'
            //   },
            {
                dataField: "avg_percent_left",
                text: "% Left",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "avg_temp",
                text: this.props.units==="metric"?"Temp (C)":"Temp (F)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "size",
                text: this.props.units==="metric"?"Size (L)":"Size (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "duration",
                text: "Avg Duration (days)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            }
          ];
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Used Kegs</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                           
                        </Row>
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Nav tabs>
                    <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                            {this.state.enableChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>:""
                            }
                           
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                        {this.showWaterFallChart()}
                        <br />
                        </TabPane>
                        <TabPane tabId="2">
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.kegData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.kegData,this.state.title,this.props.companyLogo,this.props.userLogo,"Keg-Inventory-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_Beers"
                                filename="Keg Inventory"
                                sheet="Beers"
                                buttonText="Download Excel"/>
                        </div>
                            <BootstrapTable id="tbl_Beers"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="keg_id"
                                data={this.state.kegData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}
                                expandRow={expandBeerRow}>
                            </BootstrapTable>
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

export default connect(mapStateToProps, null)(KegInventoryChart);
