import * as actionTypes from "../../../constants/actionTypes"
import store from "../../../redux/store"
import axios from "axios"

export const initialState = {
  auditLogs: [],
  currPage: 1,
}

export const getAuditLogs = () => {
  const state = store.getState()
  return (dispatch) => {
    if (state.auditlogs.auditLogs.length > 0) {
      return
    }
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/audit-trails`).then(
      (response) => {
        if (response.data !== undefined) {
          dispatch(
            handleActionDispatch(actionTypes.SET_AUDIT_LOGS, response.data)
          )
          //this.setState({ beers: beersData, filteredBeerList: beersData, showList: true });
        }
      }
    )
  }
}

export const setAuditLogs = (auditLogs) => {
  return (dispatch) => {
    dispatch(handleActionDispatch(actionTypes.SET_AUDIT_LOGS, auditLogs))
  }
}

export const setCurrPage = (page) => {
  return (dispatch) => {
    dispatch(handleActionDispatch(actionTypes.SET_CURR_PAGE, page))
  }
}

export const clearStates = () => {
  return (dispatch) => {
    dispatch(handleActionDispatch(actionTypes.CLEAR, initialState))
  }
}

export const handleActionDispatch = (actionName, data) => {
  return {
    type: actionName,
    data: data,
  }
}
