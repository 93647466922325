import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import LoaderComponent from '../shared/loader';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AdvancedFilter from "../shared/advancedFilter";

class ResponsibilityList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsibilities: [],
      filteredResponsibilityList: [],
      fields: [{ value: 'responsibility_id', label: 'Id', type: 'int' }, { value: 'responsibility_name', label: 'Name', type: 'text' }, { value: 'site_name', label: 'Site', type: 'text' }, { value: 'security_level_name', label: 'Security Level', type: 'text' }],
      showAdvancedSearch: false,
      expanded: [],
      showList: false,
      securityLevels: []
    };
  }


  componentDidMount() {
    this.fetchSecuritylevels();
  }

  fetchSecuritylevels = () => {
    // Load the list
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/securitylevel`).then((response) => {
      if (response.data !== undefined) {
        let securityLevels = [];
        response.data.forEach((item) => {
          securityLevels.push({
            security_level_id: item.security_level_id,
            name: item.sl_name,
          });
        });
        this.setState({ securityLevels: securityLevels }, () => {
          this.fetchResponsibilities();
        });
      }
    });
  }

  fetchResponsibilities = () => {
    if (this.state.site_id) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/responsibility`, { params: { site_id: this.state.site_id } }).then(response => {

        if (response.data !== undefined) {
          let responsibilitiesData = [];
          response.data.forEach((item) => {
            let slData = this.state.securityLevels.find(p => p.security_level_id === item.security_level_id);
            responsibilitiesData.push({
              responsibility_id: item.responsibility_id,
              responsibility_name: item.responsibility_name,
              security_level_id: item.security_level_id,
              security_level_name: slData.name,
              expiry_date: item.expiry_date,
            });
          });
          this.setState({ responsibilities: responsibilitiesData, filteredResponsibilityList: responsibilitiesData, showList: true });
        }
      });
    } else {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/responsibility`).then((response) => {

        if (response.data !== undefined) {
          let responsibilitiesData = [];
          response.data.forEach((item) => {
            let slData = this.state.securityLevels.find(p => p.security_level_id === item.security_level_id);
            responsibilitiesData.push({
              responsibility_id: item.responsibility_id,
              responsibility_name: item.responsibility_name,
              security_level_id: item.security_level_id,
              security_level_name: slData.name,
              expiry_date: item.expiry_date,
            });
          });

          this.setState({ responsibilities: responsibilitiesData, filteredResponsibilityList: responsibilitiesData, showList: true });
        }
      });
    }
  }

  addResponsibility = () => {
    // if(this.state.site_id && this.state.site_id > 0)
    // window.location = "#/admin/responsibility/0/site/" + this.state.site_id;
    // else
    window.location = "#/admin/responsibility/0";
  };

  filterResponsibilityList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredResponsibilityList: [...this.state.responsibilities] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredResponsibilitys = this.state.responsibilities.filter(
        (com) =>
          com.responsibility_name.toLowerCase().indexOf(searchText) >= 0
      );
      this.setState({ filteredResponsibilityList: [...filteredResponsibilitys] });
    }
  };

  toggleAdvancedSearch = () => {
    let newState = Object.assign({}, this.state);
    newState.showAdvancedSearch = !newState.showAdvancedSearch;
    if (!newState.showAdvancedSearch) {
      newState.filteredResponsibilityList = newState.responsibilities;
    }
    this.setState(newState);
  }

  callBackFromAdvancedSearch = (data) => {
    let newState = Object.assign({}, this.state);
    newState.filteredResponsibilityList = data;
    this.setState(newState);
  }

  render() {
    const options = {
      //sizePerPageRenderer,
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const columns = [
      {
        dataField: "responsibility_id",
        text: "Responsibility Id",
        hidden: true,
      },
      {
        dataField: "responsibility_name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "security_level_name",
        text: "Security Level",
        sort: true,
      },
      {
        dataField: "expiry_date",
        text: "Expiry Date",
        sort: true,
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link
                className="btn btn-sm btn-info"
                to={`/admin/responsibility/${row.responsibility_id}`}
                params={{ testvalue: "hello" }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
              {/* <button
                className="btn btn-sm btn-danger"
                onClick={() => this.customDelete(row)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button> */}
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" style={{ width: '100%' }}>
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filteredResponsibilityList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              <Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addResponsibility}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.responsibilities} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="responsibility_id"
                    data={this.state.filteredResponsibilityList}
                    pagination={paginationFactory(options)}
                    columns={columns}
                  />
                ) : this.state.showList === false ? (
                  <LoaderComponent />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default ResponsibilityList;
