import {SET_KEG_ROOMS} from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from './chart-actions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (SET_KEG_ROOMS):
            return setKegRooms(state, action);
        default:
            return state;
    }
};

const setKegRooms = (state, action) => {
    return updateObject(state, {
        kegRooms: action.data
    });
};

export default reducer;