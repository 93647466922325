import React from "react";
import Select from "react-select";
import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import { successToaster } from '../toaster/toaster';
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";

class SensorHubDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sh: {
        id: parseInt(props.match.params.id),
        mcu_id: props.match.params.mcuid === undefined ? 0 : parseInt(props.match.params.mcuid),
        serial_number: "",
        status: {label:"Online",value:"Online"},
        statusOptions:[{label:"Online",value:"Online"},{label:"Offline",value:"Offline"}],
        last_reboot_date: moment(new Date()).format('YYYY-MM-DD'),
        selectedMcu: {},
        sensor1_deployment: moment(new Date()).format('YYYY-MM-DD'),
      },
      mcus: [],
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  componentDidMount() {
    this.fetchMcus();
    this.fetchShDetails();
  }

  fetchMcus = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/mcu?site_id=${this.props.site_id}`).then(response => {
      if (response.data) {
        let newState = Object.assign({}, this.state);
        let _mcus = [];
        response.data.forEach((item) => {
          _mcus.push({
            label: item.mcu_serial_number,
            value: item.mcu_id
          });
        });
        if (this.state.sh.mcu_id !== 0) {
          let _autoMcu = _mcus.find(p => p.value === this.state.sh.mcu_id);
          newState.sh.selectedMcu = { value: _autoMcu.value, label: _autoMcu.label };
        }
        newState.mcus = _mcus;
        newState.sh.selectedMcu=_mcus[0];
        this.setState(newState);
      }
    });
  }

  fetchShDetails = () => {
    const sh_id = this.state.sh.id;
    if (sh_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensor_hub/${sh_id}`).then(response => {
        if (response.data) {
          let newState = Object.assign({}, this.state);
          newState.sh.serial_number = response.data.sh_serial_number;
          const statusOption=newState.sh.statusOptions.filter(s=>s.value===response.data.sh_status)
          newState.sh.status = statusOption.length===0?null:statusOption[0];//response.data.sh_status;
          newState.sh.last_reboot_date = response.data.sh_last_reboot_date;
          newState.sh.sensor1_deployment = response.data.sh_sensor_deployment;

          if (response.data.mcu_id != null) {
            let _mcu = this.state.mcus.find(p => p.value === response.data.mcu_id);

            if (_mcu) {
              newState.sh.selectedMcu = { value: _mcu.value, label: _mcu.label };
            }
          }

          this.setState(newState);
        }
      });
    }
  }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.sh[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.sh[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }


  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.sh[inputPropName] = item;
    this.setState(newState);
  }

  saveSensorHub = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    } 
    this.setState({ saveInProgress: true });
    const shInfo = this.state.sh;
    const formData = {
      sh_serial_number: shInfo.serial_number,
      sh_status: shInfo.status.value,
      sh_last_reboot_date: shInfo.last_reboot_date,
      mcu_id: shInfo.selectedMcu.value,
      sh_sensor_deployment: shInfo.sensor1_deployment,
    };

    let newState = Object.assign({}, this.state);

    if (shInfo.id === 0) {

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/sensor_hub`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
        data: formData
      })
        .then(response => {
          shInfo.id = response.sh_id;
          newState.sh = shInfo;
          this.setState(newState);
          successToaster("Added");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
    else {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/sensor_hub/${shInfo.id}`,
        data: formData
      })
        .then(response => {
          successToaster("Updated");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.sh.id === 0 ? "Add Sensor Hub" : "Edit Sensor Hub"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Serial Number<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.sh.serial_number} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'serial_number')} autoComplete="new-name" />
                {this.validator.message('Serial Number', this.state.sh.serial_number, 'required')}
              </Col>
              <Col md="4" sm="6" lg="4">
              <Label> Status<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.sh.status}
                  options={this.state.sh.statusOptions}
                  onChange={(e) => this.handleChangeSelection(e, 'status')}
                ></Select>
                {this.validator.message('Select Status', this.state.sh.status, 'required|selectValidator:""')}
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Last Reboot Date<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.sh.last_reboot_date} bsSize="sm" type="date" onChange={(e) => this.handleInputChange(e, 'last_reboot_date')} autoComplete="new-name" />
                {this.validator.message('Last reboot date', this.state.sh.last_reboot_date, 'required')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> MCU<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.sh.selectedMcu}
                  options={this.state.mcus}
                  onChange={(e) => this.handleChangeSelection(e, 'selectedMcu')}
                ></Select>
                {this.validator.message('Select MCU', this.state.sh.selectedMcu, 'required|selectValidator:""')}
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Deployment Date<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.sh.sensor1_deployment} bsSize="sm" type="date" onChange={(e) => this.handleInputChange(e, 'sensor1_deployment')} autoComplete="new-name" />
                {this.validator.message('Sensor deployment', this.state.sh.sensor1_deployment, 'required')}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="2" lg="2" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveSensorHub} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.sh.id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id
  }
}

export default connect(mapStateToProps, null)(SensorHubDetails);
