import React from "react";
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";
import * as kegSizeActions from './store/keg-sizes.actions';

class KegSizeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selKegSize: {
        ks_id:0,
        size_name:"",
        nickname: "",
        capacity_oz: 0,
        capacity_gal: 0,
        capacity_l: 0,
        height_in: 0,
        height_cm: 0,
        width_in: 0,
        width_cm: 0,
        weight_kg: 0,
        weight_lbs: 0
      },
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  componentDidMount() {
   
  }

  static getDerivedStateFromProps(props, state) {
    return {
        selKegSize:props.selKegSize
      }
  }
  

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.person[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.selKegSize[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }

  handleCheckboxChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.selKegSize[inputPropName] = event.target.checked;
    }
    this.setState(newState);
  }

  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.selKegSize[inputPropName] = item;
    this.setState(newState);
  }

  saveKegSize = async () => {
    if (!this.validator.allValid()) {
        this.validator.showMessages();
        return;
      } 
    this.setState({ saveInProgress: true });
    const ksInfo = this.state.selKegSize;
    if (ksInfo.ks_id === 0) {
      await this.props.addKegSize(ksInfo);
    }
    else {
      await this.props.updateKegSize(ksInfo);
    }
    this.setState({ saveInProgress: false });
    this.props.history.push(`/admin/kegsizes`);
  }


  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.selKegSize.ks_id === 0 ? "Add Keg Size" : "Edit Keg Size"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
                <Col md="4" sm="4" lg="4">
                    <Label> Size Name<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.size_name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'size_name')} autoComplete="new-name" />
                    {this.validator.message('Size Name', this.state.selKegSize.size_name, 'required')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label> Nick Name<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.nickname} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'nickname')} autoComplete="new-name" />
                    {this.validator.message('Nick Name', this.state.selKegSize.nickname, 'required')}
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="4" lg="4">
                    <Label> Capacity (L)<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.capacity_l} bsSize="sm" type="number" onChange={(e) => this.handleInputChange(e, 'capacity_l')} autoComplete="new-name" />
                    {this.validator.message('capacity_l', this.state.selKegSize.capacity_l, 'required')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label> Capacity (OZ)<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.capacity_oz} bsSize="sm" type="number" onChange={(e) => this.handleInputChange(e, 'capacity_oz')} autoComplete="new-name" />
                    {this.validator.message('capacity_oz', this.state.selKegSize.capacity_oz, 'required')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label> Capacity (GAL)<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.capacity_gal} bsSize="sm" type="number" onChange={(e) => this.handleInputChange(e, 'capacity_gal')} autoComplete="new-name" />
                    {this.validator.message('capacity_gal', this.state.selKegSize.capacity_gal, 'required')}
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="4" lg="4">
                    <Label> Width (IN)<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.width_in} bsSize="sm" type="number" onChange={(e) => this.handleInputChange(e, 'width_in')} autoComplete="new-name" />
                    {this.validator.message('width_in', this.state.selKegSize.width_in, 'required')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label> Width (CM)<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.width_cm} bsSize="sm" type="number" onChange={(e) => this.handleInputChange(e, 'width_cm')} autoComplete="new-name" />
                    {this.validator.message('width_cm', this.state.selKegSize.width_cm, 'required')}
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="4" lg="4">
                    <Label> Height (IN)<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.height_in} bsSize="sm" type="number" onChange={(e) => this.handleInputChange(e, 'height_in')} autoComplete="new-name" />
                    {this.validator.message('height_in', this.state.selKegSize.height_in, 'required')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label> Height (CM)<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.height_cm} bsSize="sm" type="number" onChange={(e) => this.handleInputChange(e, 'height_cm')} autoComplete="new-name" />
                    {this.validator.message('height_cm', this.state.selKegSize.height_cm, 'required')}
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="4" lg="4">
                    <Label> Weight (LBS)<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.weight_lbs} bsSize="sm" type="number" onChange={(e) => this.handleInputChange(e, 'weight_lbs')} autoComplete="new-name" />
                    {this.validator.message('weight_lbs', this.state.selKegSize.weight_lbs, 'required')}
                </Col>
                <Col md="4" sm="4" lg="4">
                    <Label> Weight (KG)<span style={{color:'red'}}>*</span></Label>
                    <Input value={this.state.selKegSize.weight_kg} bsSize="sm" type="number" onChange={(e) => this.handleInputChange(e, 'weight_kg')} autoComplete="new-name" />
                    {this.validator.message('weight_kg', this.state.selKegSize.weight_kg, 'required')}
                </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveKegSize} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.selKegSize.ks_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    selKegSize:state.kegSizes.selKegSize
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addKegSize: (kegSize) => dispatch(kegSizeActions.addKegSize(kegSize)),
        updateKegSize: (kegSize) => dispatch(kegSizeActions.updateKegSize(kegSize))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(KegSizeDetail);
