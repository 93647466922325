import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./sensor_hub.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SELECTED_SENSOR_HUB):
            return setSelSensorHub(state, action);
        case (actionTypes.SET_SENSOR_HUB):
            return setSensorHubs(state, action);
        case (actionTypes.SET_CURR_SENSOR_HUB_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        default:
            return state;
    }

};

const setSelSensorHub = (state, action) => {
    return updateObject(state, {
        selSensorHub: action.data
    });
};

const setSensorHubs = (state, action) => {
    return updateObject(state, {
        sensorHubs: action.data
    });
};

// set current page number of beers list page 
const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selSensorHub: initialState.selSensorHub,
        sensorHubs: initialState.sensorHubs,
        currPage: initialState.currPage
    });
};

export default reducer;