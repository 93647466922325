import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
//import axios from 'axios';
import moment from 'moment';
import {convertValueByMetric} from '../../utility/units-conversion'
import * as kegRoomActions from '../kegroom/store/kegroom.actions'
import * as monitorActions from '../dashboard/store/dashboard-actions';
import {
    Card,
    CardHeader,
    Row,
    Col,
    Label,
    CardBody,
    Button
} from "reactstrap";
import Select from "react-select";
import axios from 'axios';

class MonthlyVolumePouredReport extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            lineChart:{
                series:[],
                options:{}
            },
            columnChart:{
                series:[],
                options:{}
            },
            filterOptions:[{label:"Last 12 months",value:12},{label:"Last 9 months",value:9},{label:"Last 6 months",value:6},{label:"Last 3 months",value:3}],
            selFilterOption:{label:"Last 12 months",value:12},
            loading:false,
            kegRooms:[],
            selKegRoom:null,
            monthlyVolumePoured:[]
        };
        this.chartRef=React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
       if(props.kegRooms.length>0){
        return {
            kegRooms:props.kegRooms.map((item=>{
                return {
                    value:item.kr_id,
                    label:item.kr_name
                }
            })),
            selKegRoom: {
                value:props.kegRooms[0].kr_id,
                label:props.kegRooms[0].kr_name
            }
        }
       }
       return null;
    }

    async componentDidMount(){
        await this.props.getKegRooms(this.props.site_id);
        
    }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    loadVolumePouredByMonth=(beerData,month)=>{
        debugger
        let newState=this;
        const units=this.props.units;
        var cats=[];
        var seriesData=[];
        var den=units==="metric"?1000:1;
        beerData.forEach((item)=>{
            cats.push(item.short_name);
            seriesData.push((convertValueByMetric(item.total_pour_volume,units,"vol")/den).toFixed(2));
        })
        var chartData={
            series: [{
                name:'Volume',
                data: seriesData
              }],
              options: {
                chart: {
                  type: 'bar',
                  height: 350
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                    distributed: true,
                  },
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
                },
                xaxis: {
                  categories: cats,
                  labels: {
                    rotate: -45,
                    formatter: function(val, opts) {
                        return val;
                    }
                  },
                },
                legend: {
                    show: false,
                },
                yaxis: {
                  title: {
                    text: `Volume Poured (${units==="metric"?"L":"OU"})`,
                  },
                  labels: {
                    formatter: function(val, opts) {
                      return  parseFloat(val).toFixed(0)
                    }
                  }
                },
                fill: {
                  opacity: 1
                },
                title: {
                    text: `Volume Poured for ${month}`,
                    offsetX: 15
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return parseFloat(val).toFixed(2)
                    }
                  }
                }
              }
        }
        newState.setState({
            ...newState.state,
            columnChart:chartData,
            loading:false
        });
    }

    loadMonthlyVolumeReport=()=>{
        const units=this.props.units;
        let newState=this;
        this.setState({loading:true});
        var duration=this.state.selFilterOption.value;
        var fromDate=moment(new Date()).add(-duration,'month').startOf('month').format("YYYY-MM-DD");
        var toDate=moment(new Date()).endOf('month').format("YYYY-MM-DD");
        //await this.props.getMonthlyVolumePoured(this.state.selKegRoom.value,current_year);
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/VolumeYearlyReport`, { params: { 
            kr_id: this.state.selKegRoom.value,from_date:fromDate,to_date:toDate } }).then((response) => {
			if (response.data !== undefined) {
                response.data.sort((a, b) => new Date(a.x_date) - new Date(b.x_date));
                var _lineData=[];
                var groupByMonth=  response.data.reduce(function (r, a) {
                    r[a.x_date] = r[a.x_date] || [];
                    r[a.x_date].push(a);
                    return r;
                }, Object.create(null));
                for(var key in groupByMonth){
                    var total_pour_volume = groupByMonth[key].map(o => o.total_pour_volume).reduce((a, c) => { return a + c });
                    _lineData.push({volume_poured:total_pour_volume,pour_date:key});
                }

                var volumeLine={name:"Volume Poured",data:[]};
                var xAxis=[];
                var lineData=[];
                _lineData.forEach((item)=>{
                    //var dt1=moment(item.pour_date);
                    var den=units==="metric"?1000:1;
                    xAxis.push(moment(item.pour_date).format("MMM YYYY"));
                    lineData.push({y:moment(item.pour_date).format("MMM YYYY"),x:(convertValueByMetric(item.volume_poured,units,"vol")/den).toFixed(2)})
                })
                for(var i=0;i<duration;i++){
                    var d=moment(new Date()).add(-i,'month').startOf('month').format("MMM YYYY");
                     // eslint-disable-next-line no-loop-func
                    var item=lineData.filter(s=>s.y===d);
                    if(item.length>0){
                        volumeLine.data.push({x:item[0].x,y:item[0].y})
                    }
                    else{
                        //var dt1=moment(d);
                        volumeLine.data.push({x:"0",y:d})
                    }
                }
                //volumeLine.data.sort((a, b) => new Date(a.x) - new Date(b.x));
                var chartData={
                    series:[{name:'Volume Poured',data:volumeLine.data.map(s=>s.x)}],
                    options: {
                        chart: {
                            type: 'bar',
                           
                            height: 350,
                            events: {
                                dataPointSelection: function (e, chart, opts) {
                                    var yPoint=opts.w.globals.labels[opts.dataPointIndex];
                                    var beer_data=response.data.filter(s=>moment(s.x_date).format("MMM YYYY")===yPoint);
                                    beer_data.sort((a, b) => parseInt(a.local_line_number) - parseInt(b.local_line_number));
                                    newState.loadVolumePouredByMonth(beer_data,yPoint);
                                }
                            }
                          },
                          plotOptions: {
                            bar: {
                              borderRadius: 4,
                              horizontal: true,
                            }
                          },
                          dataLabels: {
                            enabled: false
                          },
                          xaxis: {
                            categories: volumeLine.data.map(s=>s.y),
                            title:{
                                text: `Volume Poured (${units==="metric"?"L":"OU"})`,
                            }
                          },
                          title: {
                            text: 'Monthly Volume Poured',
                            offsetX: 15
                          },
                          subtitle: {
                            text: '(Click on bar to see details)',
                            offsetX: 15
                          },
                    }
                }
                newState.setState({
                    ...newState.state,
                    lineChart:chartData,
                    columnChart:{
                        series:[],
                        options:{}
                    },
                    loading:false
                },()=>{
                    this.chartRef.current.chart.toggleDataPointSelection(0,0)
                });
			}
		});
    }

    render(){
        return(
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Monthly Volume Report</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Select Range</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selFilterOption}
                                    options={this.state.filterOptions}
                                    onChange={(e) => this.handleChangeSelection(e, 'selFilterOption')}>
                                </Select>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.loadMonthlyVolumeReport} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                        <Row>
                            <Col md="3" sm="3" lg="3"  id='monthly_volume_chart'>
                                <ReactApexChart ref={this.chartRef} options={this.state.lineChart.options} type="bar" series={this.state.lineChart.series} width="400" height="400" />
                            </Col>
                            <Col md="1" sm="1" lg="1">
                            </Col>
                            <Col md="8" sm="8" lg="8">
                                <ReactApexChart options={this.state.columnChart.options} type="bar" series={this.state.columnChart.series} width="700" height="400" />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>)
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        kegs: state.monitor.kegs,
        last_pour_item: state.monitor.last_pour_item,
        kegRooms:state.kegRooms.kegRooms,
        monthlyVolumePoured:state.monitor.monthlyVolumePoured,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
      getMonthlyVolumePoured:(kr_id,current_year)=>dispatch(monitorActions.getMonthlyVolumePoured(kr_id,current_year))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyVolumePouredReport);