import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./tower.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SELECTED_TOWER):
            return setSelTower(state, action);
        case (actionTypes.SET_TOWERS):
            return setTowers(state, action);
        case (actionTypes.SET_CURR_TOWER_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        case (actionTypes.SET_AVAILABLE_LINES):
            return setAvailableLines(state,action);
        case (actionTypes.SET_TOWER_BEERS):
            return setTowerBeers(state,action);
        default:
            return state;
    }
};

const setAvailableLines = (state, action) => {
    return updateObject(state, {
        availableLines: action.data
    });
};

const setTowerBeers = (state, action) => {
    return updateObject(state, {
        towerBeers: action.data
    });
};


const setSelTower = (state, action) => {
    return updateObject(state, {
        selTower: action.data
    });
};

const setTowers = (state, action) => {
    return updateObject(state, {
        towers: action.data
    });
};

const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selTower: initialState.selTower,
        towers: initialState.towers,
        currPage: initialState.currPage,
        availableLines:initialState.availableLines
    });
};

export default reducer;