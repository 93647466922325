import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from 'react-bootstrap-table2-filter';
import "react-filter-box/lib/react-filter-box.css"
import {
    Card,
    CardBody,
    Row,
    Col,
} from "reactstrap";
import * as auditLogsActions from './store/auditlogs.actions';

class AuditLogsList extends React.Component {
    constructor() {
        super();
        this.state = {
            auditLogs: [],
            showList: false,

        };
    }

    componentDidMount() {
        this.props.getAuditLogs();
    }


    static getDerivedStateFromProps(props, state) {
        if (state.auditLogs.length === 0) {
            return {
                auditLogs: props.auditLogs,
                showList: true
            }
        }
        return null;
    }

    componentWillUnmount() {
        this.props.clearStates();
    }

    render() {
        const pagingOptions = {
            sizePerPage: 10,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true,
            page: this.props.currPage,
            onPageChange: (page) => {
                this.props.setCurrPage(page)
            }
        };

        const columns = [
            {
                dataField: "id",
                text: "Id",
                hidden: true,
            },
            {
                dataField: "user_id",
                text: "User",
                sort: true,
                headerStyle: {
                    backgroundColor: '#c8e6c9'
                }
            },
            {
                dataField: "table_changed",
                text: "Table",
                sort: true
            },
            {
                dataField: "change_type",
                text: "Type",
                sort: true
            },
            {
                dataField: "record",
                text: 'Record',
                sort: true,
                formatter: (value) => JSON.stringify(value)
            }
        ];

        return (
            <React.Fragment>
                <Card className="bg-secondary shadow border-0">

                    <CardBody >
                        <Row>
                            <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                                {this.state.showList === true ? (
                                    <BootstrapTable
                                        classes="custom-table-css dft-bg-primary-color "
                                        striped
                                        hover
                                        condensed
                                        keyField="db_audit_trail_id"
                                        data={this.state.auditLogs}
                                        filter={filterFactory()}
                                        pagination={paginationFactory(pagingOptions)}
                                        filterPosition="top"
                                        columns={columns}
                                    >
                                    </BootstrapTable>
                                ) : this.state.showList === false ? (
                                    ""
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currPage: state.auditlogs.currPage,
        auditLogs: state.auditlogs.auditLogs
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAuditLogs: () => dispatch(auditLogsActions.getAuditLogs()),
        clearStates: () => dispatch(auditLogsActions.clearStates())
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogsList);
