import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import ReactDOMServer from 'react-dom/server';
import "react-filter-box/lib/react-filter-box.css"
import {
  Input,
  Nav, NavItem, NavLink,Button,
  Row,
  Col,
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import { connect } from "react-redux";
import * as monitorActions from './store/dashboard-actions';
import LoaderComponent from "../shared/loader";
import moment from 'moment';
import * as kegRoomActions from '../kegroom/store/kegroom.actions';

function temperatureFormatter(column, colIndex) {
  return (
    <strong>{ column.text } (&#8451;)</strong>
  );
}

function printComponent(columns,data) {

  return(<>
<table cellSpacing="0" cellPadding="2" style={{width:'100%',border:'1px solid #ccc',borderCollapse:'collapse'}} border="1">
  <thead>
    <tr>
      {columns.filter(col => col.hidden===undefined || !col.hidden).map(col=><th key={col.dataField} style={{padding:'5px',border:'1px solid #ccc',textAlign:'left'}}>{col.text}</th>)}
    </tr>
  </thead>
  <tbody>
  {data.map((d,index)=>(
    <tr>
       {columns.filter(col => col.hidden===undefined || !col.hidden).map((col,index1)=>(
          <td style={{padding:'5px',border:'1px solid #ccc'}}>
          {(data[index][col.dataField]).toString().indexOf('http')!==-1
          ?
          <img src={data[index][col.dataField]} alt={data[index][col.text]} style={{width:'50px'}} />
          :<span>{data[index][col.dataField]}</span>}
          </td>
       ))}
    </tr>
  ))}
  </tbody>
</table>
  </>)

}

function percentFormatter(cell, row) {
  if (parseInt(row.percent_remaining)<-5) {
    return (
      <span>
        <strong style={ { color: 'red' } }>{cell}</strong>
      </span>
    );
  }
  if (parseInt(row.percent_remaining)<25) {
    return (
      <span>
        <strong style={ { color: 'orange' } }>{cell}</strong>
      </span>
    );
  }

  return (
    <span>{cell}</span>
  );
}


function noWrapFormatter(cell, row) {
  return (
    <span class='nowrap'>{cell}</span>
  );
}


class KegsInventory extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      kegs: this.props.kegs,
      kegsHistory:this.props.kegsHistory,
      filteredKegs:[],
      connected:true,
      activeTab:"1",
      showKegList:false,
      showKegHistoryList:false
    };
    this.eventSource=new EventSource(`https://dev-events.draftco.ca/events?site_id=${this.props.site_id}`);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.kegs.length === 0 || state.kegsHistory.length===0) {
      return {
        kegs: props.kegs,
        kegsHistory:props.kegsHistory,
        filteredKegs:props.kegs.filter(m=>m.connected),
        showKegList: props.showKegList,
        showKegHistoryList:props.showKegHistoryList
      }
    }
    return null;
  }

  async componentDidMount(){
    await this.props.getKegRooms(this.props.site_id);
    this.props.getKegs(this.props.kegRooms[0].kr_id);
    this.props.getKegsHistory(this.props.site_id);

    this.eventSource.onmessage=(e)=>{
      if(e.type==="message"){
        console.log(e.data);
        var result = JSON.parse(e.data)
        if(result.length===0) return;
        var selectedKeg=this.state.filteredKegs.filter(s=>s.keg_id===result[0].keg_id);
        selectedKeg=selectedKeg[0];
        result=result[0];
        if(selectedKeg===undefined) return;
        var logo = result.logo.includes("https://")
						? result.logo
						: `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${result.logo}`;
					var updatedItem={
						beerName: result.beer_name,
						connected_start_local: moment(result.connected_date.substring(0,10) + ' '+result.connected_time).format('MM/DD/yyyy hh:mm'),
						volume: result.size.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
						percent_remaining: (result.percent_remaining * 100).toFixed(2),
						volume_poured: result.volume_poured ? result.volume_poured.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0,
						number_pours: result.number_pours,
						temp_running_total: result.avg_temp.toFixed(2),
						keg_id: result.keg_id,
						kr_id: result.kr_id,
						logo: logo,
						connected: result.connected,
						line:result.line_number,
					};

          var _kegs=[...this.state.filteredKegs];
          var index=_kegs.indexOf(selectedKeg)
          if (index !== -1) {
            _kegs.splice(index, 1);
            this.setState({...this.state,filteredKegs: [updatedItem].concat(_kegs)});
            var elem=document.getElementById('tblKegsInventory');
            elem.childNodes[1].childNodes[0].className='live-selected';
            setTimeout(() => {
              elem.childNodes[1].childNodes[0].className='';
            }, 5000);
          }
      }
    }

    this.eventSource.onerror=(e)=>{
      console.log(e)
      //this.eventSource.close();
      //this.eventSource=new EventSource(`https://dev-events.draftco.ca/events?site_id=${this.props.site_id}`);
    }
  }

  componentWillUnmount() {
    if (!window.location.pathname.includes('/dashboard/')) {
      this.props.clearStates();
    }
    if(this.eventSource){
      this.eventSource.close();
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        ...this.state,
        activeTab: tab
      });
    }
  }

  toggleAdvancedSearch = () => {
    let newState = {
      showAdvancedSearch: !this.state.showAdvancedSearch
    }
    if (!newState.showAdvancedSearch) {
      newState.filteredBeerList = this.state.beers;
    }
    this.setState({
      ...this.state,
      ...newState
    });
  }

  handleCheckboxChange=(e)=>{
    let _filteredKegs=[];
    if(e.currentTarget.checked){
      _filteredKegs=this.state.kegs.filter(s=>s.connected);
    }
    else{
      _filteredKegs=this.state.kegs.filter(s=>!s.connected && s.kr_out_date===null);
    }
    this.setState({
        ...this.state,
        connected:e.currentTarget.checked,
        filteredKegs:_filteredKegs
    })    
  }

  setRowSelected = () => {
    //tblKegsInventory
    var elem=document.getElementById('tblKegsInventory');
    for (let i = 0; i < elem.parentNode.getElementsByTagName('tr').length; i++) {
      elem.parentNode.getElementsByTagName('tr')[i].className = '';
    }

    elem.className = 'selected';
  }

  

  printKegInventory=(columns)=>{
    var content = ReactDOMServer.renderToStaticMarkup(printComponent(columns,this.state.filteredKegs));
    //let component=<printComponent columns={columns} data={this.state.filteredKegs} />;
    //var content = ReactDOMServer.renderToString(<printComponent />);
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  printKegHistory=(columns)=>{
    var content = ReactDOMServer.renderToStaticMarkup(printComponent(columns,this.state.kegsHistory));
    //let component=<printComponent columns={columns} data={this.state.filteredKegs} />;
    //var content = ReactDOMServer.renderToString(<printComponent />);
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  render() {
    // const pagingOptions = {
    //   sizePerPage: 10,
    //   hideSizePerPage: true,
    //   hidePageListOnlyOnePage: true,
    //   page: this.props.currPage
    // };

    const columns = [
      {
        dataField: "logo",
        text: "Logo",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <img src={row.logo} style={{ width: 50 + "px" }} alt="" />
            </div>
          );
        },
      },
      {
        dataField: "kr_id",
        text: "Kr Id",
        hidden: true,
      },
      {
        dataField: "line",
        text: "Line",
        sort: true
      },
      {
        dataField: "beerName",
        text: "Name",
        sort: true,
        headerStyle: {
          backgroundColor: '#c8e6c9'
        },
        formatter:noWrapFormatter
      },
      {
        dataField: "connected_start_local",
        text: "Connected Date",
        sort: true,
        formatter:noWrapFormatter
      },
      {
        dataField: "volume",
        text: "Size (ml)",
        sort: true,
        align: 'right',
        // formatter:(cell,row)=>{
        //   return `${formatNumbers(cell)}`
        // }
      },
      {
        dataField: "percent_remaining",
        text: "Percent Remaining (%)",
        sort: true,
        align: 'right',
        formatter:percentFormatter
      },
      {
        dataField: "volume_poured",
        text: "Volume Poured (ml)",
        sort: true,
        align: 'right',
        // formatter:(cell,row)=>{
        //   return `${formatNumbers(cell)}`
        // }
      },
      {
        dataField: "number_pours",
        text: "Number Pours",
        sort: true,
        align: 'right'
      },
      {
        dataField: "temp_running_total",
        text: "Average Temperature",
        sort: true,
        headerFormatter:temperatureFormatter,
        align: 'right'
      }
    ];

    const history_columns = [
        
        {
          dataField: "keg_id",
          text: "Keg Id",
          hidden:true
        },
        {
          dataField: "local_line_number",
          text: "Line",
        },
        {
          dataField: "beerName",
          text: "Name",
          sort: true,
          headerStyle: {
            backgroundColor: '#c8e6c9'
          },
          formatter:noWrapFormatter
        },
        {
          dataField: "connected_start_local",
          text: "Connected Start Date",
          sort: true,
          formatter:noWrapFormatter
        },
        {
          dataField: "connected_end_local",
          text: "Connected End Date",
          sort: true,
          formatter:noWrapFormatter
        },
        {
          dataField: "on_service_duration",
          text: "On Service Duration",
          sort: true,
          formatter:noWrapFormatter
        },
        {
          dataField: "volume_poured",
          text: "Volume Poured (ml)",
          sort: true,
          align: 'right'
        },
        {
          dataField: "percent_remaining",
          text: "Percent Remaining (%)",
          sort: true,
          align: 'right'
        },
        {
          dataField: "avg_temp",
          text: "Average Temperature",
          sort: true,
          headerFormatter:temperatureFormatter,
          align: 'right'
        }
      ];

    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Kegs
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}            >
              History
            </NavLink>
          </NavItem>
        </Nav>
        <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
    <TabContent id="keg_inventory_d" activeTab={this.state.activeTab} style={{'overflow-x':'auto','overflow-y':'auto','height':'300px !important'}}>
          <TabPane tabId="1">
                <Row>
                    <Col lg="11">
                        <div>
                        <label>Connected</label> &nbsp;&nbsp;
                        <label class="switch">
                            <Input type="checkbox" checked={this.state.connected} bsSize="sm" 
                            onChange={(e) => this.handleCheckboxChange(e)} />
                            <span class="slider round"></span>
                        </label>
                        </div>
                    </Col>
                    <Col lg="1">
                    <Button onClick={()=>this.printKegInventory(columns)} size="sm">Print</Button>
                    </Col>
                </Row>
                
                <Row>
                    <Col lg="12" m="12" >
                    {this.state.showKegList?<BootstrapTable id="tblKegsInventory"
                            classes="custom-table-css dft-bg-primary-color "
                            striped
                            hover
                            condensed
                            keyField="keg_id"
                            data={this.state.filteredKegs}
                            //pagination={paginationFactory(pagingOptions)}
                            columns={columns}>
                        </BootstrapTable>: <LoaderComponent />}
                    </Col>
                    </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
                    <Col lg="11">
                       
                    </Col>
                    <Col lg="1">
                    <Button onClick={()=>this.printKegHistory(history_columns)} size="sm">Print</Button>
                    </Col>
                </Row>
          <Row>
                    {this.state.showKegHistoryList?<BootstrapTable id="tblKegsHistory"
                            classes="custom-table-css dft-bg-primary-color "
                            striped
                            hover
                            condensed
                            keyField="keg_id"
                            data={this.state.kegsHistory}
                            //pagination={paginationFactory(pagingOptions)}
                            columns={history_columns}>
                        </BootstrapTable>: <LoaderComponent />}
                    </Row>

          </TabPane>
        </TabContent>
      </React.Fragment>
    );
    }    
}


const mapStateToProps = (state) => {
  return {
    currPage: state.monitor.currPage,
    site_id: state.userInfo.security.site_id,
    kegs: state.monitor.kegs,
    kegsHistory:state.monitor.kegsHistory,
    showKegList:state.monitor.showKegList,
    showKegHistoryList:state.monitor.showKegHistoryList,
    kegRooms:state.kegRooms.kegRooms
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrPage: (page) => dispatch(monitorActions.setCurrPage(page)),
    clearStates: () => dispatch(monitorActions.clearStates()),
    getKegs: (kr_id) => dispatch(monitorActions.getKegs(kr_id)),
    getKegsHistory:(site_id)=> dispatch(monitorActions.getKegsHistory(site_id)),
    getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KegsInventory);
