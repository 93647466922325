import React from 'react';
import dashboard from '../../assets/img/dashboard-v2.svg'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Modal
} from "reactstrap";
import KegsInventory from './kegsInventory';
import DashboardMonitor from './dashboardMonitor';
import DashboardUpdated from './dashboardUpdated';
import * as monitorActions from '../dashboard/store/dashboard-actions';
import { connect } from 'react-redux';
import * as lineActions from '../line/store/line.actions';
import * as kegRoomActions from '../kegroom/store/kegroom.actions';
import YearlyPourVolumeWidget from './widgets/volume_poured_yearly_widget';
import LoaderComponent from '../shared/loader';

class Monitor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currPage: 1,
            previewModal: false,
            linesForGlycol:[],
            port:0,
            kegRooms:[],
            selLine:null,
            selKegRoom:null,
            widgetKey:"pour",
            dashboardMonitorModal: false,
            showList: false
        }
    }

    async componentDidMount(){
        await this.props.getKegRooms(this.props.site_id);
       
    }
  
    static getDerivedStateFromProps(props, state) {
        return {
            kegRooms:props.kegRooms,
            selKegRoom:props.kegRooms.length>0?props.kegRooms[0]:null,
            linesForGlycol:props.linesForGlycol,
            port:props.port,
            highAlarms:props.highAlarms
          }
      }
  
    componentDidUpdate(prevProps,prevState){
        if(prevProps.kegRooms.length!==this.state.kegRooms.length){
            this.props.getLinesForGlycol(this.state.kegRooms[0].kr_id)
        }
      }
    
    redirectToAlarmHistory(){
        window.location = "#/admin/alarmtriggers";
        //this.props.history.push(`/admin/alarmtriggers`);
    }

    showDetails = (key) => {
        let _port=null,_line=null;
        if(key==="glycol"){
            if(this.state.selLine===null || this.state.selLine===this.state.linesForGlycol.length-1){
                console.log(this.state.linesForGlycol)
                _port=this.props.linesForGlycol[0].value;
                _line=0;
            }
            else{
                _line=this.state.selLine+1;
                _port=this.state.linesForGlycol[_line].value;
                _port=this.state.linesForGlycol[_line].value;
            }
            this.props.setPort(_port)
        }
        else if(key==="kegs"){
            if(this.props.kegs_by_line===""){
                this.props.setKegByLine("volume")
            }
            else if(this.props.kegs_by_line==="volume"){
                this.props.setKegByLine("%left")
            }
            else if(this.props.kegs_by_line==="%left"){
                this.props.setKegByLine("%days_left")
            }
            else if(this.props.kegs_by_line==="%days_left"){
                this.props.setKegByLine("volume")
            }
        }
        this.props.setWidget(key)
        this.setState({
            ...this.state,
            widgetKey:key,
            selLine:_line,
            port:_port
        })
       
    }

    showKegsDashboard=()=>{
        this.toggleModal("dashboardMonitorModal")
    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    stopLineCleaning=async()=>{
        await this.props.stopLineCleaning(this.props.selKegRoom.kr_id);
        await this.props.calculateLineCleaning(this.props.selKegRoom.kr_id);
    }

    render() {
        return (
            <React.Fragment>

                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <Row className="mb-4">
                            <Col lg="4" md="4" sm="4" style={{"padding":"0"}}>
                                <Row>
                                    <Col lg="8" md="8">
                                        <h4>Keg Room</h4>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col lg="4" md="4">
                                        <label>Status</label>
                                    </Col>
                                    <Col lg="4" md="4">
                                        <p style={{ color: 'green' }}>Online</p>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col lg="6" md="6">
                                        <label style={{fontSize:'14px'}}>Last Updated Date</label>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <p style={{fontSize:'14px'}}>{this.props.kegs.length===0 ?"":this.props.kegs[0].last_pour_date}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="8" md="8">
                                        <label style={{fontSize:'14px'}}>Alarms in the last 24 hours</label>
                                    </Col>
                                    <Col lg="2" md="2">
                                        <Button size="sm" className="float-right" onClick={this.redirectToAlarmHistory}>More Info</Button>
                                    </Col>
                                </Row>
                                {
                                    this.props.lineCleaningStatus?
                                    <>
                                    <Row>
                                        <Col lg="4" md="4">
                                            <label>Line Cleaning</label>
                                        </Col>
                                        <Col lg="6" md="6">
                                    
                                        <Button className="btn btn-outline-default btn-sm" onClick={this.stopLineCleaning}>
                                            <i className="fa fa-stop" aria-hidden="true"></i>
                                            <span>Stop Cleaning</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    </>
                                    :""
                                }
                                <Row>
                                    <Col lg="10" md="10">
                                    <ListGroup>
                                        {this.props.highAlarms.map((item, index) => {
                                            return  <ListGroupItem className="justify-content-between" key={index}>
                                                        {item.view_display_name}
                                                        <span class="badge badge-primary float-right">{item.high_alarm_count}</span>
                                                    
                                                    </ListGroupItem>
                                        })}
                                    </ListGroup>
                                    </Col>
                                </Row>
                                
                            </Col>
                            <Col lg="4" md="4" sm="4" style={{"padding":"0","margin":"25px 0 0 0"}}>
                            <YearlyPourVolumeWidget />
                            </Col>
                            <Col md="4" lg="4">
                                {
                                    this.props.kegRooms.length>0?
                                    <>
                                        <img src={dashboard} alt='Monitor' useMap='#monitorMap' disabled="true" />
                                        <map name="monitorMap">
                                            {/* <area shape="rect" coords="163,131,250,227" alt="Keg" onClick={() => this.showDetails("nothing")} /> */}
                                            <area style={{cursor:'pointer'}} alt="line cleaning" title="line cleaning" coords="221,88,288,107" shape="rect" onClick={() => this.showDetails("lc")} />
                                            <area style={{cursor:'pointer'}} alt="Pour" title="Pour" coords="323,119,386,177" shape="rect" onClick={() => this.showDetails("pour")} />
                                            <area style={{cursor:'pointer'}} alt="glycol" title="glycol" coords="210,154,278,231" shape="rect" onClick={() => this.showDetails("glycol")} />
                                            <area style={{cursor:'pointer'}} alt="Kegs" title="Kegs" coords="65,157,146,223" shape="rect" onClick={() => this.showDetails("kegs")} />
                                            <area style={{cursor:'pointer'}} alt="Keg room temperature" title="Keg room temperature" coords="163,86,182,120" shape="rect" onClick={() => this.showDetails("kr_temp")} />
                                        </map>
                                    </>
                                    :<LoaderComponent />
                                }
                                
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-2 py-lg-2 mt--5">
                        <Row>
                            <Col lg="12" md="12">
                                <hr />
                                <DashboardUpdated kegsDashboard={this.showKegsDashboard}    />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.previewModal} size="lg" fullscreen>
                    <div className="modal-header">
                        <h4>Kegs Inventory</h4>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("previewModal")}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body" >
                        <Row className="justify-content-md-left">
                            <Col lg="12" md="12">
                                <Row>
                                    <Col lg="12" md="12" sm="12">
                                        <KegsInventory kegs={this.props.kegs} kegsHistory={this.props.kegsHistory} />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                    </div>
                    <div className="modal-footer" style={{ justifyContent: 'flex-start' }}>
                        <Button onClick={() => this.toggleModal("previewModal")}>Close</Button>
                    </div>

                </Modal>

                <Modal isOpen={this.state.dashboardMonitorModal} size="lg" fullscreen>
                    <div className="modal-header">
                        
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("dashboardMonitorModal")}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body" >
                        <Row className="justify-content-md-left">
                            <Col lg="12" md="12">
                                <Row>
                                    <Col lg="12" md="12" sm="12">
                                        <DashboardMonitor kegs={this.props.kegs} kegsHistory={this.props.kegsHistory} />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                    </div>
                </Modal>
            </React.Fragment>
        )
    }

}



const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        kegs: state.monitor.kegs,
        kegRooms:state.kegRooms.kegRooms,
        linesForGlycol:state.monitor.linesForGlycol,
        port:state.monitor.port,
        kegs_by_line:state.monitor.kegs_by_line,
        selKegRoom:state.kegRooms.selKegRoom,
        lineCleaningStatus:state.lines.lineCleaningStatus && state.userInfo.security.sec_level.tech_only,
        highAlarms:state.monitor.highAlarms
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKegRooms: (site_id) => dispatch(kegRoomActions.getKegRooms(site_id)),
        getLinesForGlycol: (kr_id) => dispatch(monitorActions.getLinesForGlycol(kr_id)),
        setPort:(port)=>dispatch(monitorActions.setPort(port)),
        setKegByLine:(key)=>dispatch(monitorActions.setKegByLine(key)),
        stopLineCleaning:(kr_id)=>dispatch(lineActions.stopLineCleaning(kr_id)),
        calculateLineCleaning:(kr_id)=>dispatch(lineActions.calculateLineCleaning(kr_id)),
        setWidget:(key)=>dispatch(monitorActions.setWidget(key)),
        setHighAlarms:(kr_id,fromDate,toDate)=>dispatch(monitorActions.getHighAlarms(kr_id,fromDate,toDate)),
    }
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(Monitor);