import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
//import WaterfallChart from "./waterfall";
//import LoaderComponent from "../shared/loader";
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import {convertValueByMetric} from '../../utility/units-conversion'
import * as kegRoomActions from '../kegroom/store/kegroom.actions'
import * as kegSizeActions from '../keg-sizes/store/keg-sizes.actions';
import {sortByDataType} from '../../utility/common';


var DatePicker = require("reactstrap-date-picker");
//const pdfGenerator=require('../../utility/pdfGenerator')

class KegOrderChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            expanded:[],
            kegData:[],
            data:[],
            waterFallData:[],
            pourData:[],
            kegSizes:[],
            showPourData:false,
            enableChartTab:false,
            activeTab:"2",
            loading:false,
            title:'',
            showChart:false,
            errorMessage:'',
        };
    }

    async componentDidMount() {
        //this.fetchKegRooms();
        await this.props.getKegSizes();
        await this.props.getKegRooms(this.props.site_id);
    }

    static getDerivedStateFromProps(props,state){

        let _kegRooms=[];
        if(props.kegRooms.length>0){
            props.kegRooms.forEach((item)=>{
                _kegRooms.push({value:item.kr_id,label:item.kr_name});
            })
        }
        
        if (_kegRooms.length !== 0) {
            return {
              ...state,
              kegSizes:props.kegSizes,
              kegRooms:_kegRooms,
              selKegRoom:_kegRooms.length>0?_kegRooms[0]:null,
            }
          }
          return null;
    }

    getChartData=()=>{
        const units=this.props.units;
        this.setState({kegData:[]});
        let newState=this;
        var params={
            from_date:moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD"),
            kr_id:this.state.selKegRoom.value
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/KegOrderChart`,{params:params})
        .then(function(response){
            if(response.data!==undefined && response.data.length>0){
                var kr_data=response.data;
                var _kegsData=[],counter=1;
                response.data.forEach((item)=>{
                    _kegsData.push({
                        keg_id:counter,
                        beer_name:item.beer_name,
                        days_to_empty:item.days_to_empty.toFixed(2),
                        connected_date:moment(item.connected_date).format("YYYY-MM-DD"),
                        avg_percent_remaining:(item.avg_percent_remaining*100).toFixed(2),
                        percent_remaining:((item.percent_remaining)*100).toFixed(2),
                        size:convertValueByMetric(item.size,units,"vol").toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        total_kegs:item.total_kegs,
                        total_volume:convertValueByMetric(item.total_volume,units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        volume_poured:convertValueByMetric(item.volume_poured,units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    })
                    counter++
                })
                newState.setState({
                    ...newState.state,
                    activeTab:"2",
                    kegData:_kegsData,
                    data:kr_data,
                });
            }

        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   


    render() {
        const columns = [
            {
              dataField: "keg_id",
              text: "Keg Id",
              hidden: true,
              headerAlign:'center'
            },
            {
              dataField: "beer_name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "total_kegs",
              text: "Number of Kegs Used",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
              align:'center',
              headerAlign:'center'
            },
            {
                dataField: "percent_remaining",
                text: "Current Keg % Left",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "volume_poured",
                text: `Current Volume Used ${this.props.units==="metric"?"(ML)":"(OU)"}`,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "connected_date",
                text: "Connected Date",
                sort: true,
                hidden: true,
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "days_to_empty",
                text: "days to empty",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center'
            },
            {
                dataField: "size",
                text: this.props.units==="metric"?"size (ml)":"size (ou)",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center'
            }
          ];
          
          const kegSize_columns = [{
            dataField: 'nickname',
            text: 'Name'
          }, {
            dataField: 'capacity_oz',
            text: 'Capacity (OU)'
          }, {
            dataField: 'capacity_l',
            text: 'Capacity (L)'
          }];
       
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Kegs Order</h5>
                        <Row>
                            <Col  md="7" sm="7" lg="7">
                                <Row>
                                    <Col md="4" sm="4" lg="4">
                                        <Label>keg Room</Label>
                                        <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                            options={this.state.kegRooms}
                                            onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                        </Select>
                                    </Col>
                                    <Col md="4" sm="4" lg="4">
                                        <Label>From Date</Label>
                                        <DatePicker size="sm"
                                            value={this.state.from_date}
                                            onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                                    </Col>
                                    <Col md="4" sm="4" lg="4">
                                        <Label>To Date</Label>
                                        <DatePicker size="sm"
                                            value={this.state.to_date}
                                            onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                                    </Col>
                                </Row>
                                <div style={{height:"1rem"}}></div>
                                <Row>
                                    <Col md="3" sm="3" lg="3">
                                        <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                                    </Col>
                                </Row>
                                <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                            </Col>
                            <Col  md="5" sm="5" lg="5">
                                <BootstrapTable  
                                id="tbl_kegSizes"
                                striped
                                hover
                                condensed
                                keyField='ks_id' 
                                data={this.state.kegSizes} 
                                columns={ kegSize_columns } />
                            </Col>
                        </Row>
                        <br />
                       
                    </CardHeader>
                    <CardBody >
                    <Nav tabs>
                    <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                            {/* {this.state.enableChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>:""
                            } */}
                           
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        {/* <TabPane tabId="1">
                        {this.showWaterFallChart()}
                        <br />
                        </TabPane> */}
                        <TabPane tabId="2">
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.kegData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.kegData,this.state.title,this.props.companyLogo,this.props.userLogo,"Keg-Inventory-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_Beers"
                                filename="Keg Inventory"
                                sheet="Beers"
                                buttonText="Download Excel"/>
                        </div>
                            <BootstrapTable id="tbl_Beers"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="keg_id"
                                data={this.state.kegData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}
                                //</TabPane>expandRow={expandBeerRow}
                                >
                            </BootstrapTable>
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        kegSizes:state.kegSizes.kegSizes,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        kegRooms:state.kegRooms.kegRooms,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
        getKegSizes:()=>dispatch(kegSizeActions.getKegSizes()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KegOrderChart);
