import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import ReactApexChart from 'react-apexcharts';
import * as chartActions from '../charts/store/chart-actions';
import {convertValueByMetric} from '../../utility/units-conversion';
import {sortByDataType} from '../../utility/common';

var DatePicker = require("reactstrap-date-picker");

class KrTemperatureReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            loading:false,
            filterOptions:[{label:'Raw',value:'Raw'},{label:'Daily average',value:'Daily'},{label:'30 day moving average',value:'30 Day'}],
            selFilter:{label:'Daily average',value:'Daily'},
            activeTab:"1",
            title:'',
            showChart:false,
            masterData:[],
            tempData:[],
            errorMessage:'',
            tempLineData:[],
            loadBrushChart:false,
            //tempLineData:[],
            //linesForGlycol:[],
            lineChart:{
                series:[],
                options:{ 
                  chart: {
                  id: 'apexcharts74sj34ie'
                }
              }
            },
            areaChart:{
              series:[],
              options:{
                chart:{
                  id:'apexchartsomux1p9b'
                }
              }
            }
        };
        this.brushChartRef=React.createRef();
    }
    
    async componentDidMount(){
      await this.props.getKegRooms(this.props.site_id);
    }

    static getDerivedStateFromProps(props, state) {
      return {
        kegRooms:props.kegRooms,
        selKegRoom:props.kegRooms.length>0?props.kegRooms[0]:null
      }
    }

    
    getChartData=()=>{
        let newState=this;
        let units=this.props.units;
        var _filter=newState.state.selFilter.value;
        this.setState({
          ...this.state,
          loading:true,
        });
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/KrTempSensor/GetKrTemperatureReadings`,{params:{
          kr_id:this.state.selKegRoom.value,
          fromDate:moment(this.state.from_date).format("MM/DD/YYYY"),
          toDate:moment(this.state.to_date).format("MM/DD/YYYY"),
          filter:_filter
        }})
        .then(function(response){
          debugger
            var lineChartData=[];
            var tempLine={name:"Temperature",data:[]};
            var avg_temp_line={name:"Avg Temp",data:[]};
            var avg_temp_line1={name:"Avg + Std Dev Temp",data:[]};
            var avg_temp_line2={name:"Avg - Std Dev Temp",data:[]};
            var xaxis=[];
            if(response.data!==undefined && response.data.length>0){
                //response.data.sort((a, b) => new Date(a.local_time) - new Date(b.local_time));
                var _tempDetails=[];
                let counter=1,total_temp=0;
                var groupByDateOnly=  response.data.reduce(function (r, a) {
                  var date=moment(a.local_time).format("MM/DD/YYYY")
                  r[date] = r[date] || [];
                  r[date].push(a);
                  return r;
               }, Object.create(null));
               for(var key1 in groupByDateOnly){
                    var temp_by_date=groupByDateOnly[key1].map(o =>  convertValueByMetric(o.temperature_c,units,"c")).reduce((a, c) => { return a + c });
                    _tempDetails.push({
                    id:counter,
                    temp:(temp_by_date/groupByDateOnly[key1].length).toFixed(2),
                    temp_date:moment(key1).format("MM/DD/YYYY")
                    })
                    total_temp+=(temp_by_date/groupByDateOnly[key1].length);
                    counter++
                }
                _tempDetails.push({
                    id:0,
                    temp:(total_temp/Object.keys(groupByDateOnly).length).toFixed(2),
                    temp_date:"Avg Temp"
                })

                var groupByDate=  response.data.reduce(function (r, a) {
                    r[a.local_time] = r[a.local_time] || [];
                    r[a.local_time].push(a);
                    return r;
                }, Object.create(null));
                var kt_counter=1;
                for(var key in groupByDate){
                    var item=groupByDate[key][0];
                    var temperature=convertValueByMetric(item.temperature_c??0,units,"c");
                    var temperature_avg=convertValueByMetric(item.kr_temperature_c_30_avg,units,"c");
                    var temperature_stdev=convertValueByMetric(item.kr_temperature_30_stdev,units,"std");
                    lineChartData.push({
                        id:kt_counter,
                        start_time:item.local_time,
                        temperature:item.temperature_c
                    })
                    xaxis.push(new Date(item.local_time).getTime());
                    tempLine.data.push({x:item.local_time,y:temperature.toFixed(2)})
                    avg_temp_line.data.push({x:item.local_time,y:temperature_avg.toFixed(2)})
                    avg_temp_line1.data.push({x:item.local_time,y:(temperature_avg+temperature_stdev).toFixed(2)})
                    avg_temp_line2.data.push({x:item.local_time,y:(temperature_avg-temperature_stdev).toFixed(2)})
                    kt_counter++;
                }
                var chartData={
                    series:[tempLine,avg_temp_line,avg_temp_line1,avg_temp_line2],
                    options: {
                        chart: {
                          id: 'apexcharts74sj34ie',
                          height: 350,
                          type: 'line',
                          toolbar: {
                            autoSelected: 'pan',
                            show: false
                          },
                          zoom: {
                            enabled: false
                          }
                        },
                        markers:{
                          size:_filter==="Raw"?[0]:[4,0,0,0]
                        },
                        dataLabels: {
                          enabled: false
                        },
                        stroke: {
                          curve: 'straight',
                          width: [1,2,2,2],
                          dashArray: [0,6,6,6]
                        },
                        title: {
                          text: `Keg Room Temperature Plot`,
                          align: 'left'
                        },
                        grid: {
                          row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                          },
                        },
                        xaxis: {
                          categories: xaxis,
                          type: "datetime",
                          tickAmount:10,
                          labels: {
                            datetimeUTC: false,
                            rotateAlways: false,
                            rotate: 0,
                            //tickPlacement: 'between',
                            formatter: function(val, timestamp) {
                              return moment(val).format("DD MMM hh:mm");; // formats to hours:minutes
                            }  
                          }
                        },
                        yaxis: [
                            {
                              seriesName: 'Temperature',
                              //min:units==="metric"?0:29,
                              tickAmount:15,
                              min:units==="metric"?0:32,
                              axisTicks: {
                                show: true
                              },
                              axisBorder: {
                                show: true,
                                color: "#FF1654"
                              },
                              labels: {
                                style: {
                                  colors: "#FF1654"
                                },
                                formatter: function (value) {
                                    return value.toFixed(2);
                                }
                              },
                              title: {
                                text: units==="metric"?"Temperature (C)":"Temperature (F)",
                                style: {
                                  color: "#FF1654"
                                }
                              }
                            },
                            // {
                            //   seriesName: 'Temperature',
                            //   opposite: true,
                            //   show:false
                            // },
                            // {
                            //   seriesName: 'Temperature',
                            //   opposite: true,
                            //   show:false,
                            // },
                            // {
                            //   seriesName: 'Temperature',
                            //   opposite: true,
                            //   show:false,
                            // }
                          ]
                      },
                }
                var areaChart={
                  series: [tempLine],
                      options: {
                        chart: {
                          height: 180,
                          type: 'area',
                          brush:{
                            target: 'apexcharts74sj34ie',
                            enabled: true
                          },
                          toolbar:{
                            show:true,
                            autoSelected:'pan',
                            tools: {
                              download: false,
                              selection: true,
                              zoom: false,
                              zoomin: false,
                              zoomout: false,
                              pan: false,
                              reset: true 
                            },
                          },
                          selection: {
                            enabled: true,
                            type:'x'
                          },
                        },
                        dataLabels: {
                          enabled: false
                        },
                        colors: ['#008FFB'],
                        fill: {
                          type: 'gradient',
                          gradient: {
                            opacityFrom: 0.91,
                            opacityTo: 0.1,
                          }
                        },
                        xaxis: {
                            categories:xaxis,
                            type: "datetime",
                            tooltip: {
                              enabled: false
                            },
                            tickAmount: 10,
                            labels: {
                              datetimeUTC: false,
                              rotateAlways: false,
                              rotate: 0,
                              formatter: function(val, timestamp) {
                                return moment(val).format("DD MMM hh:mm");; // formats to hours:minutes
                              }  
                            }
                        },
                        yaxis: {
                          tickAmount: 1
                        }
                      },
                }
               newState.setState({
                    ...newState.state,
                    title:`${newState.props.kegRooms[0].label} Temperature Report`,
                    tempData:_tempDetails,
                    masterData:response.data,
                    tempLineData:lineChartData,
                    lineChart:chartData,
                    loading:false,
                    loadBrushChart:false,//newState.state.selFilter.label==="Raw",
                    areaChart:areaChart
                },()=>{
                  if(newState.state.loadBrushChart){
                    // setTimeout(()=>{
                    //   var selectedEle=document.getElementById(newState.brushChartRef.current.chartRef.current.childNodes[0].id).getElementsByClassName('apexcharts-selection-icon');
                    //   selectedEle[0].click();
                    // },4000);
                  }
                });
            }
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    showTempDetailsByDate=(row)=>{
        const columns = [
          {
            dataField: "id",
            text: "Id",
            hidden: true,
            headerAlign:'center'
          },
          {
            dataField: "temp_time",
            text: "Time",
            sort: true,
            align:'left',
            headerAlign:'center'
          },
          {
            dataField: "temp",
            text: this.props.units==="metric"?"Temperature (C)":"Temperature (F)",
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
            align:'right',
            headerAlign:'center'
          }
        ];
        var tempDataByTime=this.state.masterData.filter(p=>moment(p.local_time).format("MM/DD/YYYY")===row.temp_date);
        var childData=[],total_temp=0;
        tempDataByTime.forEach((item)=>{
          var temp_by_date=convertValueByMetric(item.temperature_c,this.props.units,"c")
          childData.push({
            id:item.id,
            temp:(temp_by_date).toFixed(2),
            temp_time:moment(item.local_time).format("LT")
          })
          total_temp+=temp_by_date;
      })
      if(childData.length>0){
        childData.push({
            id:0,
            temp:(total_temp/tempDataByTime.length).toFixed(2),
            temp_time:"Avg Temp"
        })
      }
        if(childData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,childData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,childData,`${this.props.kegRooms[0].label} Temperature Report for ${row.temp_date}`,this.props.companyLogo,this.props.userLogo,"Keg-Room-Temperature-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_Glycol_ByTime"
                                filename="Keg Room Temperature Report"
                                sheet="Temperature"
                                buttonText="Download Excel"/>
                        </div>
                    <BootstrapTable id="tbl_Glycol_ByTime"
                                classes="custom-table-css dft-bg-primary-color tbl-inner"
                                striped
                                hover
                                condensed
                                keyField="id"
                                data={childData}
                                columns={columns}>
                            </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    render() {
        const expandBeerRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return this.showTempDetailsByDate(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
              if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
            }
          };
        const columns = [
            {
              dataField: "id",
              text: "Id",
              hidden: true,
              headerAlign:'center'
            },
            {
              dataField: "temp_date",
              text: "Date",
              sort: true,
              align:'left',
              headerAlign:'center'
            },
            {
              dataField: "temp",
              text:  this.props.units==="metric"?"Temperature (C)":"Temperature (F)",
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              sort: true,
              align:'right',
              headerAlign:'center'
            }
          
          ];
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/KrTempPlot">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Keg room temperature plot</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Select Filter</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selFilter}
                                    options={this.state.filterOptions}
                                    onChange={(e) => this.handleChangeSelection(e, 'selFilter')}>
                                </Select>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Nav tabs>
                        <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                         
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <br/> <br/> <br/>
                            <div>
                                <div id="chart-line">
                                <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} /> 
                                </div>
                                {
                                  this.state.loadBrushChart?
                                  <div id="chart-area">
                                  <ReactApexChart ref={this.brushChartRef}  options={this.state.areaChart.options} series={this.state.areaChart.series} type="area" height={180} /> 
                                  </div>:""
                                }
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                      
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.tempData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.tempData,this.state.title,this.props.companyLogo,this.props.userLogo,"Keg-Room-Temperature-Report.pdf")} 
                            className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_kr_temp"
                                filename="Keg Room Temperature Report"
                                sheet="Temperature"
                                buttonText="Download Excel"/>
                            </div>
                            <BootstrapTable id="tbl_kr_temp"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="id"
                                expandRow={expandBeerRow}
                                data={this.state.tempData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        kegRooms:state.charts.kegRooms,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getKegRooms: (site_id) => dispatch(chartActions.getKegRooms(site_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KrTemperatureReport);
