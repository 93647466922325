import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import routes from "../routes/index";
import Sidebar from "../components/sidebar/sidebar";
import AdminHeader from "../components/header/adminHeader";
import PageNotFound from "../views/error/PageNotFound";
import Unauthorized from "../views/error/Unathorized";
import BadRequest from "../views/error/BadRequest";
import InternalServerError from "../views/error/InternalServerError";
import * as BreadCrumActions from "../redux/breadcrum/actions";
import * as loaderActions from '../redux/loader/action';
import { connect } from "react-redux";
import axios from "axios";
import * as UserInfoActions from "../redux/userDetail/action";
import AxiosLoader from "../views/shared/loader-new";
class Admin extends React.Component {
  constructor() {
    super();
    this.collapsibleSideBar = this.collapsibleSideBar.bind(this);
  }

  componentDidMount() {
    if (this.props.userDetails.user_id!==0) {
      return;
    }
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/login/user`).then((response) => {
      var userMappings = response.data.userData.user_site_resp;
      var selected_site_id = userMappings[0].site_id
      var userSites = [];
      userMappings.forEach((item) => {
        userSites.push({
          site_id: item.site_id,
          value: item.site_id,
          label: item.usr_site.site_name + ' - ' + item.usr_resp.responsibility_name,
          sec_level: item.usr_resp.resp_security_level,
          customerUrl:item.usr_site.site.image.indexOf('https')===-1? `https://cdndraftco.nyc3.digitaloceanspaces.com/assets/images/${item.usr_site.site.image}`:item.usr_site.site.image
        });
      });
      this.props.updateUserSites(userSites);
      if (localStorage.getItem("user_site_resp_selected") === undefined) {
        const selectedSite = userSites.find(a => a.site_id === selected_site_id);
        this.props.updateUserSec(selectedSite);
        this.props.setUserLogo(selectedSite.customerUrl);
      }
      else {
        var storedSite=JSON.parse(localStorage.getItem("user_site_resp_selected"));
        this.props.updateUserSec(storedSite);
        this.props.setUserLogo(storedSite.customerUrl);
      }
      this.props.updateUserDetail(response.data.userData);
      this.props.setCompanyLogo("https://cdndraftco.nyc3.digitaloceanspaces.com/assets/logo/Black/draftco-logo-black.png")
      //var profile_Url=response.data.userData.person.profile.indexOf('https')===-1? `https://cdndraftco.nyc3.digitaloceanspaces.com/assets/images/${response.data.userData.person.profile}`:response.data.userData.person.profile;
      
    });
  }

  getRoutes = (routes) => {
    let menus = []
    let defaultManus = routes.filter((m) => m.access === "default")
    let _dfMenus = routes.filter((m) => m.access === "_draftco")
    let userMenus = routes.filter((m) => m.access === "user")
    let dataMenus = routes.filter((m) => m.access === "data")
    let techMenus = routes.filter((m) => m.access === "tech")
    let pourChartMenus = routes.filter((m) => m.access === "pour_charts" || m.access==="keg_charts" || m.access==="glycol_charts" || m.access==="line_charts"|| m.access==="kr_charts")
    let _techMaintenance=routes.filter((m)=>m.access==="tech_maintenance");
    let _techAlarms=routes.filter((m)=>m.access==="tech_alarms");
    let _kegManagement=routes.filter((m)=>m.access==="keg_management");
    let _subscriptions=routes.filter((m)=>m.access==="report_subscriptions");

    let security = this.props.sec_level
    if (!security) {
      security = security || {}
      security.data_only = true
      security.tech_only = true
      security.user_only = true
    }

    menus = defaultManus;
    menus=menus.concat(_techAlarms)
    if (security && security.tech_only) {
      menus = menus.concat(techMenus)
      menus=menus.concat(_techMaintenance)
     
    }

    if(security){
      menus=menus.concat(_techAlarms)
      menus=menus.concat(_subscriptions)
    }

    if (security && security.user_only) {
      menus = menus.concat(userMenus)
    }

    if (security && security.data_only) {
      menus = menus.concat(dataMenus)
      menus = menus.concat(userMenus.filter(s=>s.name==="Users" || s.name==="Person"))
      menus = menus.concat(pourChartMenus)
      menus=menus.concat(_kegManagement)
    }
    

    let subMenus = [];
    menus.forEach((item, key) => {
      if (this.props.userDetails && this.props.userDetails.superuser && (item.name === "Customers")) {
        var _childs = routes.filter(m => m.subAccess === item.name);
        subMenus = subMenus.concat(_childs);
      }
      else if (item.name !== "Customers") {
        var _Childs = routes.filter(m => m.subAccess === item.name);
        subMenus = subMenus.concat(_Childs);
      }
    })

    menus = menus.concat(subMenus);

    if(security && security.data_only && security.tech_only && security.user_only){
      menus = menus.concat(_dfMenus)
    }
    return menus.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            exact path={prop.layout + prop.path}
            component={prop.component}
            //render={props => <prop.component {...this.props}/>}
            key={key}

          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = (path) => {
    //const _props=this.props;
    for (let i = 0; i < routes.length; i++) {
      //let pathname = routes[i].layout + routes[i].path;
      //let urls=pathname.split('/');
      //let parsedUrl='/'+urls[1]+'/'+urls[2];
      // if (path.includes(parsedUrl)) {
      //   if(routes[i].routeType==="main"){
      //     _props.setNavLink(path)
      //   }
      //   else{
      //     _props.setInternalLink(path)
      //   }
      //   return routes[i].name;
      // }
    }
    return "Draftco";
  };

  collapsibleSideBar = () => {
  };

  render() {
    return (this.props.userDetails.user_id!==0 ?
      <div>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/dashboard",
            //imgSrc: require("../assets/img/brand/aims_main.png"),
            imgAlt: "...",
          }}
          collapsibleSideBar={this.collapsibleSideBar}
        />
        <div className="main-content">
          <AdminHeader
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}

          />
          <div className="header bg-primary py-1 py-lg-5 dft-header-bg">

            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center ">
                  <Col lg="5" md="6"></Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container fluid className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="12" md="12">
                <Switch>
                  {this.getRoutes(routes)}
                  <Route component={PageNotFound} exact path="/admin/pagenotfound" key="pagenotfound" />
                  <Route component={Unauthorized} exact path="/admin/unauthorized" key="unauthorized" />
                  <Route component={BadRequest} exact path="/admin/badrequest" key="badrequest" />
                  <Route component={InternalServerError} exact path="/admin/internalservererror" key="internalservererror" />
                </Switch>
              </Col>
            </Row>

          </Container>

        </div>
      </div> : <AxiosLoader />
    );
  }
}



const mapDispatchToProps = (dispatch) => {
  return {
    setInternalLink: (linkUrl) => dispatch(BreadCrumActions.INTERNAL_NAV_CLICK(linkUrl)),
    setNavLink: (linkUrl) => dispatch(BreadCrumActions.SIDE_NAV_CLICK(linkUrl)),
    loader: (isLoading) => dispatch(loaderActions.UPDATE_LOADER(isLoading)),
    updateUserDetail: (userData) => dispatch(UserInfoActions.setUserDetail(userData)),
    updateUserSec: (userSec) => dispatch(UserInfoActions.setUserSec(userSec)),
    updateUserSites: (userSites) => dispatch(UserInfoActions.setUserSites(userSites)),
    setCompanyLogo:(url)=>dispatch(UserInfoActions.setCompanyLogo(url)),
    setUserLogo:(url)=>dispatch(UserInfoActions.setUserLogo(url))
  }
}

const mapStateToProps = (state) => {
  return {
    security: state.userInfo.security,
    axiosLoading: state.loader.showLoader,
    userDetails: state.userInfo.userDetails,
    sec_level: state.userInfo.security.sec_level,

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);