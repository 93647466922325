import React from "react";
import Select from "react-select";
import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";

import KegroomList from '../kegroom/kegroomList';
//import TowerList from '../towers/towerList';
import data from '../../constants/staticData';
import { successToaster } from '../toaster/toaster';
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { getGeocodeByPlaceId } from '../../utility/googleLocation';
import TimePicker from 'react-time-picker';
import * as siteAction from './store/site.actions';

class SiteDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: {
        site_id: parseInt(props.match.params.id),
        name: "",
        address1: "",
        address2: "",
        city: "",
        region: "",
        country: "",
        selectedCustomer: null,
        // selectedCountry: { value: "Austria", label: "Austria" },
        postalCode: "",
        selectedManager: null,
        selectedSalesRep: null,
        selLocation: null,
        selCity:null,
        selectedCountry:null,
        blackout_start: new Date(),
        blackout_end: new Date()
      },
      customer_id: parseInt(props.match.params.cid),
      persons: [],
      countries: [],
      cities:[],
      customers: [],
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
    this.units = data.units;
    this.dateFormats = data.dateFormats;
  }

  componentDidMount() {
    this.fetchPersons();
    this.props.getCountries();
  }

  static getDerivedStateFromProps(props, state) {
    var _countries=props.countries.map((item)=>{
      return {
        value:item.country_name,
        label:item.country_name
      }
    });
    var _cities=props.cities.map((item)=>{
      return {
        value:item.name,
        label:item.name,
        timezone:item.timezone
      }
    })
    return {
      countries:_countries,
      cities:_cities
    }
  }

  fetchPersons = () => {
    // Load the list
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/persons`).then((response) => {
      if (response.data !== undefined) {
        let personsData = [];
        response.data.forEach((item) => {
          personsData.push({
            value: item.person_id,
            label: item.first_name + ' ' + item.last_name,
          });
        });
        this.setState({ persons: personsData, site: { ...this.state.site, selectedManager: personsData[0], selectedSalesRep: personsData[0] } }, () => {
          this.fetchCustomer();
        });
      }
    });
  }

  fetchSiteContacts = () => {
    // Load the list
    const site_id = this.state.site.site_id;
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/sites/${site_id}/contacts`).then((response) => {
      if (response.data !== undefined) {
        let personsData = [];
        response.data.forEach((item) => {
          personsData.push({
            value: item.person_id,
            label: item.first_name + ' ' + item.last_name,
          });
        });
        this.setState({ persons: personsData }, () => {
          this.fetchCustomer();
        });
      }
    });
  }

  fetchCustomer = () => {
    // Load the list
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/customers`).then((response) => {
      if (response.data !== undefined) {
        let customersData = [];
        response.data.forEach((item) => {
          customersData.push({
            value: item.customer_id,
            label: item.name,
          });
        });
        this.setState({ customers: customersData, site: { ...this.state.site, selectedCustomer: customersData[0] } }, () => {
          this.fetchSites();
        });
      }
    });
  }

  fetchSites = async() => {
    const site_id = this.state.site.site_id;
    if (site_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/sites/${site_id}`).then(response => {
        if (response.data) {
          let newState = Object.assign({}, this.state);
          
          newState.site.name = response.data.site_name;
          newState.site.address1 = response.data.address_1;
          newState.site.address2 = response.data.address_2;
          newState.site.city = response.data.city;
          newState.site.region = response.data.region;
          newState.site.postalCode = response.data.postal_code;
          newState.site.selectedCountry ={label:response.data.country,value:response.data.country} ;

          
          newState.site.blackout_end = response.data.blackout_end;
          newState.site.blackout_start = response.data.blackout_start;


          if (response.data.customer_id != null) {
            let customer = this.state.customers.find(p => p.value === response.data.customer_id);
            newState.site.selectedCustomer = { value: customer.value, label: customer.label };
          } else {
            newState.site.selectedCustomer = {};
          }

          if (response.data.manager_id != null) {
            let manager = this.state.persons.find(p => p.value === response.data.manager_id);
            newState.site.selectedManager = { value: manager.value, label: manager.label };
          } else {
            newState.site.selectedManager = {};
          }

          if (response.data.sales_rep != null) {
            let sales_Rep = this.state.persons.find(p => p.value === response.data.sales_rep);
            newState.site.selectedSalesRep = { value: sales_Rep.value, label: sales_Rep.label };
          } else {
            newState.site.selectedSalesRep = {};
          }

          if (response.data.units != null) {
            let units = this.units.find(u => u.value === response.data.units);
            newState.site.units = units;
          } else {
            newState.site.units = {};
          }

          if (response.data.date_format != null) {
            let dateFormats = this.dateFormats.find(dt => dt.value === response.data.date_format)
            newState.site.dateFormat = dateFormats;
          } else {
            newState.site.dateFormat = {};
          }

          this.setState(newState,async()=>{
            await this.loadCities();
          });
        }
      });
    }
    else {
      let newState = Object.assign({}, this.state);
      if (this.state.customer_id) {
        let customer = this.state.customers.find(p => p.value === this.state.customer_id);
        newState.site.selectedCustomer = { value: customer.value, label: customer.label };
      } else {
        //newState.site.selectedCustomer = {};
      }
      this.setState(newState);
    }
  }

  loadCities=async()=>{
    await this.props.getCities(this.state.site.selectedCountry.label);
    var city=this.props.cities.filter(s=>s.name===this.state.site.city);
    let newState = Object.assign({}, this.state);
    newState.site.selCity={
      value:city[0].name,
      label:city[0].name,
      timezone:city[0].timezone
    };
    this.setState(newState)
  }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    //newState.person[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.site[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }


  handleChangeSelection = async(item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.site[inputPropName] = item;
    if(inputPropName==="selectedCountry"){
      await this.props.getCities(item.label);
      newState.site.selCity=null;
    }
    this.setState(newState);
  }

  saveSite = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    this.setState({ saveInProgress: true });

    const siteInfo = this.state.site;
    const formData = {
      site_id: siteInfo.site_id,
      site_name: siteInfo.name,
      customer_id: siteInfo.selectedCustomer.value,
      address_1: siteInfo.address1,
      address_2: siteInfo.address2,
      city: siteInfo.selCity.label,
      region: siteInfo.region,
      country: siteInfo.selectedCountry.label,
      postal_code: siteInfo.postalCode,
      manager_id: siteInfo.selectedManager.value,
      sales_rep: siteInfo.selectedSalesRep.value,
      units: siteInfo.units.value,
      date_format: siteInfo.dateFormat.value,
      blackout_start: siteInfo.blackout_start,
      blackout_end: siteInfo.blackout_end,
      timezone:siteInfo.selCity.timezone
    };

    if (this.state.site.selLocation) {
      formData.long = this.state.site.selLocation.geometry.location.lng();
      formData.lat = this.state.site.selLocation.geometry.location.lat();
    }

    if (siteInfo.site_id === 0) {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/sites`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
        data: formData
      })
        .then(response => {
          siteInfo.site_id = response.site_id;
          this.setState({ site: { ...siteInfo } });
          successToaster("Added");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
    else {

      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/sites/${siteInfo.site_id}`,
        data: formData
      })
        .then(response => {
          successToaster("Updated");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  onSelLocation = async (loc) => {
    const place_id = loc.value.place_id;

    const { postcode, city, state, country, address1, results } = await getGeocodeByPlaceId(place_id);
    let units = this.units.find((un) => un.value === 'metric');
    if (country === 'United States') {
      units = this.units.find((un) => un.value === 'imperial');
    }

    this.setState({
      ...this.state,
      site: {
        ...this.state.site,
        address1: address1,
        city: city,
        region: state,
        postalCode: postcode,
        country: country,
        selLocation: results,
        units: units
      }
    });
  }

  handleDatepickerChange = (value, formattedValue, inputPropName) => {
    this.setState({
      ...this.state,
      site: {
        ...this.state.site,
        [inputPropName]: value
      }

    }, () => {
    });
  }

  handleTimepickerChange = (value, inputPropName) => {
    console.log(value)
    this.setState({
      ...this.state,
      site: {
        ...this.state.site,
        [inputPropName]: value
      }

    }, () => {
    });
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.site.site_id === 0 ? "Add Site" : "Update Site"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="9">
                <Row className="mb-3">
                  <Col md="6" sm="6" lg="6">
                    <Label> Name<span style={{ color: 'red' }}>*</span></Label>
                    <Input value={this.state.site.name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'name')} autoComplete="new-name" />
                    {this.validator.message('Name', this.state.site.name, 'required')}
                  </Col>
                  <Col md="6" sm="6" lg="6">
                    <Label>Customer</Label>
                    <Select size="sm"
                      value={this.state.site.selectedCustomer}
                      options={this.state.customers}
                      isDisabled={!this.props.isSuperUser && this.state.site.site_id !== 0}
                      onChange={(e) => this.handleChangeSelection(e, 'selectedCustomer')}></Select>
                    {this.validator.message('Select customer', this.state.site.selectedCustomer, 'required|selectValidator:""')}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6" sm="6" lg="6">
                    <Label>Manager</Label>
                    <Select size="sm" value={this.state.site.selectedManager}
                      options={this.state.persons}
                      onChange={(e) => this.handleChangeSelection(e, 'selectedManager')}
                    ></Select>
                    {this.validator.message('Select Manager', this.state.site.selectedManager, 'required|selectValidator:""')}
                  </Col>
                  <Col md="6" sm="6" lg="6">
                    <Label>Sales Rep</Label>
                    <Select size="sm" value={this.state.site.selectedSalesRep}
                      options={this.state.persons}
                      onChange={(e) => this.handleChangeSelection(e, 'selectedSalesRep')}
                    ></Select>
                    {this.validator.message('Select Sales rep', this.state.site.selectedSalesRep, 'required|selectValidator:""')}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Label>Search Location</Label>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyBnzbLFA0RGLJzhG6bImIAi-BNMm35kkt8"
                      selectProps={{
                        value: this.state.selLocation,
                        onChange: this.onSelLocation,
                        placeholder: 'Search location'
                      }}
                    />
                  </Col>

                </Row>

                <Row className="mb-3">
                  <Col md="6" sm="6" lg="6">
                    <Label>Address 1<span style={{ color: 'red' }}>*</span></Label>
                    <Input value={this.state.site.address1} bsSize="sm" type="textarea" onChange={(e) => this.handleInputChange(e, 'address1')} autoComplete="new-name" />
                    {this.validator.message('Address 1', this.state.site.address1, 'required')}
                  </Col>
                  <Col md="6" sm="6" lg="6">
                    <Label>Address 2</Label>
                    <Input value={this.state.site.address2} bsSize="sm" type="textarea" onChange={(e) => this.handleInputChange(e, 'address2')} autoComplete="new-name" />
                  </Col>
                </Row>

                <Row className="mb-3">
                 <Col md="6" sm="6" lg="6">
                    {/* <Label>Country<span style={{ color: 'red' }}>*</span></Label>
                    <Input value={this.state.site.country} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'country')} autoComplete="new-name" />
                    {this.validator.message('Country', this.state.site.country, 'required')} */}
                    <Label>Country<span style={{ color: 'red' }}>*</span></Label>
                    <Select size="sm"
                      value={this.state.site.selectedCountry}
                      options={this.state.countries}
                      onChange={(e) => this.handleChangeSelection(e, 'selectedCountry')}></Select>
                    {this.validator.message('Select Country', this.state.site.selectedCountry, 'required|selectValidator:""')}
                  </Col>
                  <Col md="6" sm="6" lg="6">
                    <Label>City<span style={{ color: 'red' }}>*</span></Label>
                    <Select size="sm"
                      value={this.state.site.selCity}
                      options={this.state.cities}
                      onChange={(e) => this.handleChangeSelection(e, 'selCity')}></Select>
                    {this.validator.message('Select City', this.state.site.selCity, 'required|selectValidator:""')}
                  </Col>
                </Row>
                <Row className="mb-3">
                <Col md="6" sm="6" lg="6">
                    <Label> Region<span style={{ color: 'red' }}>*</span></Label>
                    <Input value={this.state.site.region} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'region')} autoComplete="new-name" />
                    {this.validator.message('Region', this.state.site.region, 'required')}
                  </Col>
                  <Col md="6" sm="6" lg="6">
                    <Label> Postal code<span style={{ color: 'red' }}>*</span></Label>
                    <Input value={this.state.site.postalCode} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'postalCode')} autoComplete="new-name" />
                    {this.validator.message('Postal code', this.state.site.postalCode, 'required')}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6" sm="6" lg="6">
                    <Label>Select Unit<span style={{ color: 'red' }}>*</span></Label>
                    <Select size="sm" value={this.state.site.units}
                      options={this.units}
                      onChange={(e) => this.handleChangeSelection(e, 'units')}
                    ></Select>
                    {this.validator.message('Select Unit', this.state.site.units, 'required|selectValidator:""')}
                  </Col>
                  <Col md="6" sm="6" lg="6">
                    <Label>Select Date format</Label>
                    <Select size="sm" value={this.state.site.dateFormat}
                      options={this.dateFormats}
                      onChange={(e) => this.handleChangeSelection(e, 'dateFormat')}
                    ></Select>
                    {this.validator.message('Select Date format', this.state.site.dateFormat, 'required|selectValidator:""')}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6" sm="6" lg="6">
                    <Label>Backout Start<span style={{ color: 'red' }}>*</span></Label>
                    <TimePicker
                      format='h:m a'
                      onChange={(val) => this.handleTimepickerChange(val, 'blackout_start')}
                      value={this.state.site.blackout_start}
                    />
                    {/* <DatePicker size="sm"
                      value={this.state.site.blackout_start}
                      onChange={(v, f) => this.handleDatepickerChange(v, f, 'blackout_start')} /> */}
                    {/* {this.validator.message('Select Date', this.state.site.blackout_start, 'required|selectValidator:""')} */}
                  </Col>
                  <Col md="6" sm="6" lg="6">
                    <Label>Backout End</Label>
                    {/* <DatePicker size="sm"
                      value={this.state.site.blackout_end}
                      onChange={(v, f) => this.handleDatepickerChange(v, f, 'blackout_end')} /> */}
                    <TimePicker
                      format='h:m a'
                      onChange={(val) => this.handleTimepickerChange(val, 'blackout_end')}
                      value={this.state.site.blackout_end}
                    />
                    {/* {this.validator.message('Select Date', this.state.site.blackout_end, 'required|selectValidator:""')} */}
                  </Col>
                </Row>
                { this.state.site.selCity!==null?
                  <Row>
                    <Col md="6" sm="6" lg="6">
                        <b>TimeZone : {this.state.site.selCity.timezone}</b>
                    </Col>
                </Row>:""
                }
                
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveSite} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.site.site_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
        {/* {this.state.site.site_id === 0 ? "" : (
          <React.Fragment>
            <Card>
              <CardBody>
                <Row>
                  <h3 className="dft-primary-color">Keg Rooms</h3>
                </Row>
                <Row>
                  <KegroomList site_id={this.state.site.site_id} />
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>

                <Row>
                  <TowerList site_id={this.state.site.site_id} />
                </Row>
              </CardBody>
            </Card>
          </React.Fragment>
        )} */}
        {this.props.site_id!==0 ? (
         <KegroomList />
        ) : ""}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser: state.userInfo.userDetails ? state.userInfo.userDetails.superuser : false,
    countries:state.sites.countries,
    cities:state.sites.cities
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCities: (country) => dispatch(siteAction.getCities(country)),
    getCountries: () => dispatch(siteAction.getCountries())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetail);