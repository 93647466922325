import jsPDF from "jspdf"; 
import "jspdf-autotable";


export const generatePdf=(tblColumns,tblRows,title,companyLogo,profileLogo,reportName,columnStyles)=>{
    const doc = new jsPDF('p', 'pt', 'a4');
        // define the columns we want and their titles
        const tableColumn =tblColumns;
        // define an empty array of rows
        const tableRows = tblRows;
        // startY is basically margin-top
        doc.autoTable(tableColumn, tableRows, {
            //startY: 50,
            margin: { top: 90, left: 10, bottom: 10 },
            styles: { overflow: "linebreak" },
            bodyStyles: { valign: "top" },
            columnStyles: columnStyles,
            headStyles:{
              valign: 'middle',
              halign : 'center'
            },
            theme: "grid",
            showHead: "everyPage",
            didDrawPage: function (data) {
              // Header
              doc.setFontSize(15);
              doc.setTextColor(40);
              var pageSize = doc.internal.pageSize;
              if (companyLogo) {
                doc.addImage(companyLogo, 'PNG', data.settings.margin.left, 15, 70, 35);
                
                //doc.addImage(state.logoBase64, 'PNG', data.settings.margin.right, 15, 100, 50);
              }
              if(profileLogo){
                doc.addImage(profileLogo, 'PNG',pageSize.width-80, 15, 70, 35);
              }
              var splitTitle = doc.splitTextToSize(title, 500);
              if(splitTitle.length>1){
                doc.text(data.settings.margin.left,65,splitTitle);
              }
              else{
                doc.text(title, data.settings.margin.left, 80);
              }
              // Footer
              var str = "Page " + doc.internal.getNumberOfPages();
          
              doc.setFontSize(10);
          
              // jsPDF 1.4+ uses getWidth, <1.4 uses .width
              
              var pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight();
              doc.text(str, data.settings.margin.left, pageHeight - 10);
            }
          });
        // we define the name of our PDF file.
        doc.save(`${reportName}.pdf`);
}

export const generatePdfForMultipleTables=(tblColumns,multipleTables,tableSummaryColumn,summaryRow,title,companyLogo,profileLogo,reportName,columnStyles,summaryColumnStyles)=>{
  const doc = new jsPDF('p', 'pt', 'a4');
      // define the columns we want and their titles
      const tableColumn =tblColumns;
      doc.autoTable(tableSummaryColumn, summaryRow, {
        //startY: 50,
        margin: { top: 90, left: 10, bottom: 10 },
        styles: { overflow: "linebreak" },
        bodyStyles: { valign: "top" },
        columnStyles: summaryColumnStyles,
        theme: "grid",
        showHead: "everyPage",
        headStyles:{
          valign: 'middle',
          halign : 'center'
        },
        didDrawPage: function (data) {
          // Header
          doc.setFontSize(15);
          doc.setTextColor(40);
          var pageSize = doc.internal.pageSize;
          if (companyLogo) {
            doc.addImage(companyLogo, 'PNG', data.settings.margin.left, 15, 70, 35);
          }
          if(profileLogo){
            doc.addImage(profileLogo, 'PNG',pageSize.width-80, 15, 40, 30);
          }
          
          doc.text(title, data.settings.margin.left, 80);
      
          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();
      
          doc.setFontSize(10);
      
          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
        }
      });
      doc.addPage();


      var counter=0;
      // define an empty array of rows
      multipleTables.forEach(item => {
        var tableRows = item.rows;
        // startY is basically margin-top
        doc.autoTable(tableColumn, tableRows, {
            //startY: 50,
            margin: { top: 90, left: 10, bottom: 10 },
            styles: { overflow: "linebreak" },
            bodyStyles: { valign: "top" },
            columnStyles: columnStyles,
            theme: "grid",
            showHead: "everyPage",
            didDrawPage: function (data) {
              // Header
              doc.setFontSize(15);
              doc.setTextColor(40);
              var pageSize = doc.internal.pageSize;
              if (companyLogo) {
                doc.addImage(companyLogo, 'PNG', data.settings.margin.left, 15, 70, 35);
                
                //doc.addImage(state.logoBase64, 'PNG', data.settings.margin.right, 15, 100, 50);
              }
              if(profileLogo){
                doc.addImage(profileLogo, 'PNG',pageSize.width-80, 15, 40, 30);
              }
              
              doc.text(item.title, data.settings.margin.left, 80);
          
              // Footer
              var str = "Page " + doc.internal.getNumberOfPages();
          
              doc.setFontSize(10);
          
              // jsPDF 1.4+ uses getWidth, <1.4 uses .width
              
              var pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight();
              doc.text(str, data.settings.margin.left, pageHeight - 10);
            }
          });
          if(counter!==multipleTables.length-1){
            doc.addPage();
          }
          counter++;
      });
      
      // we define the name of our PDF file.
      doc.save(`${reportName}.pdf`);
}