import React from "react";
import Select from "react-select";
import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import McuList from "../mcu";
import LineList from "../line";
import KegList from "../keg/kegList";
import { successToaster } from '../toaster/toaster';
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";

// import SitesList from '../sites/sitesList'


class KegRoomDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kegRoom: {
        kr_id: parseInt(props.match.params.id),
        site_id: props.match.params.sid,
        kr_name: '',
        number_kegs: 0,
        selectedSite: null,
      },
      sites: [],
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  componentDidMount() {
    this.fetchsites();
  }

  componentDidUpdate(prevProps,prevState){
    if(this.props.site_id!==prevProps.site_id && this.state.sites.length>0){
      this.setState({...this.state,kegRoom:{...this.state.kegRoom,selectedSite:this.state.sites.filter(s=>s.value===this.props.site_id)}});
    }
  }

  fetchsites = () => {
    // Load the list
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/sites`).then((response) => {
      if (response.data !== undefined) {
        let sitesData = [];
        let _selectedSiteId= parseInt(this.props.site_id);
        let _selectedSite=null;
        response.data.forEach((item) => {
          if(item.site_id===_selectedSiteId){
            _selectedSite={
              value: item.site_id,
            label: item.site_name,
            }
          }
          sitesData.push({
            value: item.site_id,
            label: item.site_name,
          });
        });
        this.setState({ sites: sitesData,kegRoom:{...this.state.kegRoom,selectedSite:_selectedSite}}, () => {
          this.fetchKegRoom();
        });
      }
    });
  }

  fetchKegRoom = () => {
    const kr_id = this.state.kegRoom.kr_id;
    if (kr_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom/${kr_id}`).then(response => {
        if (response.data) {
          let newState = Object.assign({}, this.state);
          newState.kegRoom.kr_id = response.data.kr_id;
          newState.kegRoom.kr_name = response.data.kr_name;
          newState.kegRoom.site_id = response.data.site_id;
          newState.kegRoom.number_kegs = response.data.number_kegs;

          if (response.data.site_id != null) {
            let site = this.state.sites.find(p => p.value === response.data.site_id);
            if (site) {
              newState.kegRoom.selectedSite = { value: site.value, label: site.label };
            }
          } else {
            //newState.kegRoom.selectedSite = {};
          }
          this.setState(newState);
        }
      });
    }
    else {
      let newState = Object.assign({}, this.state);
      if (this.state.kegRoom.site_id != null) {
        let site = this.state.sites.find(p => p.value === this.state.kegRoom.site_id);
        if (site) {
          newState.kegRoom.selectedSite = { value: site.value, label: site.label };
        }
      } else {
        //newState.kegRoom.selectedSite = {};
      }
      this.setState(newState);
    }
  }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.person[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.kegRoom[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }


  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.kegRoom[inputPropName] = item;
    this.setState(newState);
  }

  savekegRoom = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    } 
    this.setState({ saveInProgress: true });

    const kegRoomInfo = this.state.kegRoom;
    const formData = {
      kr_id: kegRoomInfo.kr_id,
      kr_name: kegRoomInfo.kr_name,
      site_id: kegRoomInfo.selectedSite.value,
      number_kegs: kegRoomInfo.number_kegs,
    };
    let newState = Object.assign({}, this.state);
    if (kegRoomInfo.kr_id === 0) {

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/kegroom`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
        data: formData
      })
        .then(response => {
          newState.kegRoom.kr_id = response.kr_id;
          this.setState(newState);
          successToaster("Added");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
    else {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/kegroom/${kegRoomInfo.kr_id}`,
        data: formData
      })
        .then(response => {
          successToaster("Updated");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.kegRoom.kr_id === 0 ? "Add Keg Room" : "Edit Keg Room"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Name<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.kegRoom.kr_name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'kr_name')} autoComplete="new-name" />
                {this.validator.message('Name', this.state.kegRoom.kr_name, 'required')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Site<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.kegRoom.selectedSite}
                  options={this.state.sites}
                  isDisabled={!this.props.isSuperUser}
                  onChange={(e) => this.handleChangeSelection(e, 'selectedSite')}></Select>
                  {this.validator.message('Select Site', this.state.kegRoom.selectedSite, 'required|selectValidator:""')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label>Number of Kegs<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.kegRoom.number_kegs} bsSize="sm" type="number" min="1" max="1000" step="1" onChange={(e) => this.handleInputChange(e, 'number_kegs')} autoComplete="new-name" />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.savekegRoom} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.kegRoom.kr_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
        {/* <Card>
          <CardBody>
            <Row>
              <h3 className="dft-primary-color">Kegs</h3>
            </Row>
            <Row>
              <SitesList sites={this.state.sites} kegRoom_id={this.state.kegRoom.kr_id} />
            </Row>
          </CardBody>
        </Card> */}
        {/* {this.state.kegRoom.kr_id === 0 ? "" : (
          <div>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Row>
                  <h3 className="dft-primary-color">Keg</h3>
                </Row>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <KegList kr_id={this.state.kegRoom.kr_id} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Row>
                  <h3 className="dft-primary-color">MCU</h3>
                </Row>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <McuList kr_id={this.state.kegRoom.kr_id} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Row>
                  <h3 className="dft-primary-color">Lines</h3>
                </Row>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <LineList kr_id={this.state.kegRoom.kr_id} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        )} */}
        {
          this.props.selKegRoom.kr_id!==0?
          <>
            <KegList />
            <McuList />
            <LineList />
          </>
          :""
        }
      </>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    selKegRoom:state.kegRooms.selKegRoom
  }
}

export default connect(mapStateToProps, null)(KegRoomDetail);
