import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
//import axios from 'axios';
import moment from 'moment';
import {convertValueByMetric} from '../../../utility/units-conversion'
import * as kegRoomActions from '../../kegroom/store/kegroom.actions'
import * as monitorActions from '../../dashboard/store/dashboard-actions';

class YearlyPourVolumeWidget extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            lineChart:{
                series:[],
                options:{}
            },
            kegRooms:[],
            monthlyVolumePoured:[],
            last_pour_item:{
              last_pour_date:"",
              volume_poured:"",
              beer_name:"",
              pour_id:0,
              pour_volume:0,
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            kegRooms:props.kegRooms,
            monthlyVolumePoured:props.monthlyVolumePoured,
            last_pour_item:props.last_pour_item
        }
    }

    updateHourlyDistributionChart(){
      
      var pour_item=this.state.last_pour_item;
      var _lineChart=this.state.lineChart;
      var _seriesData=_lineChart.series[0].data;
      if(this.props.site_id!==pour_item.site_id) return;
      var today=new Date();
      var index=moment(today).month()+1;
      //var year=moment(today).year();
      //var dt1=moment(year+"-"+month+"-01");
      var item=null;
      if(index!==-1){
        item=_seriesData[index-1];
        console.log(`Pour distribution updating for  with volume ${item.y}`);
        var old_pour=item.y*1000;
        var den=this.props.units==="metric"?1000:1;
        item.y= (convertValueByMetric(parseFloat(old_pour)+parseFloat(pour_item.pour_volume),this.props.units,"vol")/den).toFixed(2);
        console.log(`Pour distribution updated for  with volume ${item.y}`);
      }
      debugger
      _seriesData.splice(index-1, 1);
      _seriesData.splice( index-1, 0, item );
      const data = _seriesData.map( x => { return {...x}} )
      this.setState({
        ...this.state.lineChart,
        lineChart:{
          ...this.state.lineChart.options,
          series:[{
            ...this.state.lineChart.series[0].data,
            data:data
          }]
        }
      })
    }


    async componentDidMount(){
        await this.props.getKegRooms(this.props.site_id);
        var localDateUtc = moment.utc(new Date());
        var current_year = moment(localDateUtc).local().format("YYYY");
        await this.props.getMonthlyVolumePoured(this.props.kegRooms[0].kr_id,current_year)
        this.loadMonthlyVolumeReport()
        
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.monthlyVolumePoured.length!==this.state.monthlyVolumePoured.length){
            this.loadMonthlyVolumeReport()
        }
        if(prevProps.last_pour_item===null &&  this.state.last_pour_item===null){

        }
        else if(prevProps.last_pour_item.pour_id!==this.state.last_pour_item.pour_id){
          this.updateHourlyDistributionChart()
        }
    }

 
    loadMonthlyVolumeReport=()=>{
        const units=this.props.units;
        let newState=this;
        var _lineData=[];
        var groupByMonth=  this.props.monthlyVolumePoured.reduce(function (r, a) {
            r[a.x_date] = r[a.x_date] || [];
            r[a.x_date].push(a);
            return r;
        }, Object.create(null));
        for(var key in groupByMonth){
            var total_pour_volume = groupByMonth[key].map(o => o.total_pour_volume).reduce((a, c) => { return a + c });
            _lineData.push({volume_poured:total_pour_volume,pour_date:key});
        }

        var volumeLine={name:"Volume Poured",data:[]};
        var xAxis=[];
        var lineData=[];
        _lineData.forEach((item)=>{
            var dt1=moment(item.pour_date);
            xAxis.push(dt1._d);
            var den=units==="metric"?1000:1;
            lineData.push({x:dt1._d,y:(convertValueByMetric(item.volume_poured,units,"vol")/den).toFixed(2)})
        })
        for(var i=0;i<12;i++){
            var d=moment(new Date()).add(-i,'month').startOf('month').format("YYYY-MM-DD");
            // eslint-disable-next-line no-loop-func
            var item=lineData.filter(s=>moment(s.x).format("YYYY-MM-DD")===d);
            if(item.length>0){
                volumeLine.data.push({x:item[0].x,y:item[0].y})
            }
            else{
                var dt1=moment(d);
                volumeLine.data.push({x:dt1._d,y:0})
            }
        }
        volumeLine.data.sort((a, b) => new Date(a.x) - new Date(b.x));
        var chartData={
            series:[volumeLine],
            options: {
                chart: {
                  height: 400,
                  id:'apexchartsfcp10ctwj',
                  type: 'line',
                  zoom: {
                    enabled: false
                  }
                },
                toolbar:{
                    show:false
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  curve: 'straight',
                  width: [1],
                  //dashArray: [0, 0, 0,2,2]
                },
                title: {
                  text: `Monthly Volume Poured`,
                  align: 'left'
                },
                grid: {
                  row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                  },
                },
                xaxis: {
                  categories: xAxis,
                  type: "datetime",
                  tickAmount:12,
                  labels: {
                    datetimeUTC: false,
                    rotateAlways: false,
                    rotate: 0,
                    //tickPlacement: 'on',
                    formatter: function(val, timestamp) {
                        return moment(val).format("MMM");; // formats to hours:minutes
                    }  
                  }
                },
                yaxis: [
                    {
                      seriesName: 'Volume',
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                        color: "#FF1654"
                      },
                      labels: {
                        style: {
                          colors: "#FF1654"
                        },
                        formatter: function (value) {
                            return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                      },
                      title: {
                        text: units==="metric"?"Volume (L)":"Volume (ou)",
                        style: {
                          color: "#FF1654"
                        }
                      }
                    }
                  ]
              }
        }
        newState.setState({
            ...newState.state,
            lineChart:chartData
        });
    }

    render(){
        return(
        <>
        <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} width="400" height="300" />
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        kegs: state.monitor.kegs,
        last_pour_item: state.monitor.last_pour_item,
        kegRooms:state.kegRooms.kegRooms,
        monthlyVolumePoured:state.monitor.monthlyVolumePoured,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
      getMonthlyVolumePoured:(kr_id,current_year)=>dispatch(monitorActions.getMonthlyVolumePoured(kr_id,current_year))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(YearlyPourVolumeWidget);