import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selKts: {
        kts_id:0,
        kr_id:0,
        kr_name:"",
        sensor_id:0,
        controller_sn:"",
        sensor_hub_sn:"",
        port:0,
        sensor_serial_number:""
    },
    krTempSensors: [],
    currPage: 1,
    showList:false
};

export const getKrTempSensors = (site_id) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.krTempSensors.krTempSensors.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/KrTempSensor`,{params:{site_id:site_id}}).then((response) => {
                if (response.data !== undefined) {
                    let _krTempSensor = [];
                    response.data.forEach((item) => {
                        _krTempSensor.push({
                           kts_id:item.kts_id,
                           kr_id:item.kr_id,
                           kr_name:item.kr_name,
                           controller_sn:item.controller_sn,
                           sensor_hub_sn:item.sensor_hub_sn,
                           port:item.port,
                           sensor_id:item.sensor_id,
                           sensor_serial_number:item.sensor_serial_number
                       })
                    });
                    //_krTempSensor = _krTempSensor.sort((a, b) => a.gs_name.localeCompare(b.gs_name));
                    dispatch(handleActionDispatch(actionTypes.SET_KR_TEMP_SENSOR, _krTempSensor));
                    resolve(_krTempSensor);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const addKts = (item) => {

	const formData = {
        kr_id:item.kr_id,
        controller_sn:item.controller_sn,
        sensor_hub_sn:item.sensor_hub_sn,
        port:item.port,
        kr_name:item.kr_name,
        sensor_id:item.sensor_id,
        sensor_serial_number:item.sensor_serial_number
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/KrTempSensor`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			}).then(response => {
                const _ktsItem = {
                    ...item,
                    kts_id:response.data.kts_id
                };
                dispatch(handleActionDispatch(actionTypes.ADD_KR_TEMP_SENSOR, _ktsItem));
                successToaster("Added");
                resolve();
            });
		});

	}
}

export const updateKts = (item) => {
	const formData = {
        kts_id:item.kts_id,
        kr_id:item.kr_id,
        controller_sn:item.controller_sn,
        sensor_hub_sn:item.sensor_hub_sn,
        port:item.port,
        kr_name:item.kr_name,
        sensor_id:item.sensor_id,
        sensor_serial_number:item.sensor_serial_number
	};
	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/KrTempSensor/${item.kts_id}`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			dispatch(handleActionDispatch(actionTypes.UPDATE_KR_TEMP_SENSOR, item));
			successToaster("Updated");
			resolve();
		})
	}
}

export const updateKrTemp = () => {
    return (dispatch) => {  
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/updateKrTemp`).then((response) => {
                resolve(true);
            },
                (error) => {
                    reject(error);
                });
        })
    }
}


export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_KR_TEMP_SENSOR_PAGE, page));
    }
}

export const setSelKts = (gs) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SEL_KR_TEMP_SENSOR, gs));
    }
}

export const clearSelKts = () => {
    return setSelKts({
        kts_id:0,
        kr_id:0,
        kr_name:"",
        sensor_id:0,
        controller_sn:"",
        sensor_hub_sn:"",
        port:0,
        sensor_serial_number:""
    });
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}