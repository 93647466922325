import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import {
    Card,
    CardHeader,
    Row,
    Col,
    Label,
    CardBody,
    Button
} from "reactstrap";
import * as beersActions from '../beers/store/beers.actions';
import {convertValueByMetric} from '../../utility/units-conversion'
var DatePicker = require("reactstrap-date-picker");

class KegMerge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        selKegRoom: null,
        kegRooms: [],
        beers:[],
        kegs:[],
        selBeer:null,
        isLoading:false,
        isSplitLoading:false,
        isMergeLoading:false,
        selectedKegs:[],
        disabledRows:[],
        enableSplit:false,
        enableMerge:false,
        from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
        to_date: new Date(moment().endOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
    };
  }

  componentDidMount() {
    this.fetchKegRooms();
  }

    static getDerivedStateFromProps(props,state){
        let _beers=[];
        if(props.beers_kr.length>0){
           // _beers.push({value:0,label:"All Beers"})
            props.beers_kr.forEach((item)=>{
                _beers.push({value:item.id,label:item.name});
            })
        }
        if (_beers.length !== 0) {
            return {
            ...state,
            beers: _beers,
            selBeer:state.selBeer===null?{value:_beers[0].value,label:_beers[0].label}:state.selBeer
            }
        }
        return null;
    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]},()=>{this.props.getBeersByKegRoom(kegRooms[0].value)});
            }
        });
    }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        });
    }   

    getKegs=()=>{
        let newState=this;
        let beer_name=this.state.selBeer.label.replace(new RegExp("'", 'g'), "''");
        var units=this.props.units;
        this.setState({...this.state,isLoading:true});
        var params={
            kr_id:this.state.selKegRoom.value,
            beer_name:beer_name,
            from_date:moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD")
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/KegsForMerge`,{params:params})
        .then(function(response){
              console.log(response)
              var _kegs=[];
              response.data.forEach((item)=>{
                _kegs.push({
                    name:item.beer_name,
                    keg_id:item.keg_id,
                    line:item.local_line_number,
                    connected_end:moment(item.connected_end_local.substring(0,10)).format("YYYY-MM-DD"),
                    connected_start:moment(item.connected_start_local.substring(0,10)).format("YYYY-MM-DD"),
                    volume_poured:(convertValueByMetric(item.volume_poured,units,"vol")/(units==="metric"?1000:1)).toFixed(2),
                    //volume_avg:(convertValueByMetric(item.volume_poured,units,"vol")/((units==="metric"?1000:1)*response.data.length)).toFixed(2),
                    avg_percent_left:((item.percent_remaining*100)).toFixed(2),
                    avg_temp:(convertValueByMetric(item.avg_temp,units,"c")).toFixed(2),
                    size:(convertValueByMetric(item.size,units,"vol")/((units==="metric"?1000:1))).toFixed(2),
                    number_pints:(convertValueByMetric(item.volume_poured,units,"vol")/convertValueByMetric(item.mlperpint,units,"vol")).toFixed(2),
                    duration:parseFloat((item.on_service_duration_days)).toFixed(2),
                })
            })
            newState.setState({
                ...newState.state,
                isLoading:false,
                kegs:_kegs,
                disabledRows:[],
                selectedKegs:[],
                enableSplit:false,
                enableMerge:false
            });
        })
    }

    splitKegs=()=>{
        let newState=this;
        this.setState({...this.state,isSplitLoading:true});
        var params={
            keg_id:this.state.selectedKegs[0]
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/SplitKeg`,{params:params})
        .then(function(response){
            alert(response.data.message)
            newState.setState({
                ...newState.state,
                disabledRows:[],
                selectedKegs:[],
                enableSplit:false,
                enableMerge:false,
                isSplitLoading:false
            },()=>{newState.getKegs()})
        })
        
    }

    mergeKegs=()=>{
        let newState=this;
        this.setState({...this.state,isMergeLoading:true});
        var params={
            keg_id1:this.state.selectedKegs[0],
            keg_id2:this.state.selectedKegs[1]
        }
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/MergeKegs`,{params:params})
        .then(function(response){
             alert(response.data.message)
             newState.setState({
                ...newState.state,
                disabledRows:[],
                selectedKegs:[],
                enableSplit:false,
                enableMerge:false,
                isMergeLoading:false
            },()=>{newState.getKegs()})
        })
       
    }

    onRowSelect=(isSelected,kegId)=>{
        var selectedKegs=this.state.selectedKegs;
        var disabledKegs=[];
        if(isSelected){
            selectedKegs.push(kegId);
        }else{
            const index = selectedKegs.indexOf(kegId);
            if (index > -1) {
                selectedKegs.splice(index, 1);
            }
        }
        if(selectedKegs.length===2){
            disabledKegs=this.state.kegs.map((item) => {
                const index = selectedKegs.indexOf(item.keg_id);
               return index===-1?item.keg_id:null;
            });
        }
        this.setState({
            ...this.state,
            disabledRows:disabledKegs,
            selectedKegs:selectedKegs,
            enableSplit:selectedKegs.length===1,
            enableMerge:selectedKegs.length===2
        })
    }

  
  render() {
    const columns = [
        {
          dataField: "keg_id",
          text: "Keg Id",
          hidden: true,
          headerAlign:'center'
        },
        {
          dataField: "line",
          text: "Line #",
          sort: true,
          headerAlign:'center'
        },
        {
            dataField: "connected_start",
            text: "Connected Start",
            sort: true,
            headerAlign:'center'
        },
        {
            dataField: "connected_end",
            text: "Connected End",
            sort: true,
            headerAlign:'center'
        },
        {
          dataField: "volume_poured",
          text: this.props.units==="metric"?"Volume Poured (l)":"Volume Poured (ou)",
          sort: true,
          align:'right',
          headerAlign:'center'
        },
        {
            dataField: "avg_percent_left",
            text: "% Left",
            sort: true,
            align:'right',
            headerAlign:'center'
        },
        {
            dataField: "avg_temp",
            text: this.props.units==="metric"?"Temp (C)":"Temp (F)",
            sort: true,
            align:'right',
            headerAlign:'center'
        },
        {
            dataField: "size",
            text: this.props.units==="metric"?"Size (L)":"Size (ou)",
            sort: true,
            align:'right',
            headerAlign:'center'
        },
        {
            dataField: "number_pints",
            text: "#pints",
            sort: true,
            align:'right',
            headerAlign:'center'
        },
        {
            dataField: "duration",
            text: "Avg Duration (days)",
            sort: true,
            align:'right',
            headerAlign:'center'
        }
      ];
      const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        selected:this.state.selectedKegs,
        onSelect: (row, isSelect, rowIndex, e) => {
           this.onRowSelect(isSelect,row.keg_id);
        },
        nonSelectable:this.state.disabledRows
      };

    return (
      <React.Fragment>
            <Card className="bg-secondary shadow border-0" id="/admin/customer">
                <CardHeader className="bg-transparent py-3 border-0">
                    <h5>Kegs Merge</h5>
                    <Row>
                        <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                        </Col>
                        <Col md="3" sm="3" lg="3">
                            <Label>Beers</Label>
                            <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selBeer}
                                options={this.state.beers}
                                onChange={(e) => this.handleChangeSelection(e, 'selBeer')}>
                            </Select>
                        </Col>
                        <Col md="3" sm="3" lg="3">
                            <Label>From Date</Label>
                            <DatePicker size="sm"
                                value={this.state.from_date}
                                onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                        </Col>
                        <Col md="3" sm="3" lg="3">
                            <Label>To Date</Label>
                            <DatePicker size="sm"
                                value={this.state.to_date}
                                onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                        </Col>
                    </Row>
                        <br /><br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button className="btn btn-sm btn-secondary" onClick={this.getKegs} disabled={this.state.isLoading ? 'disabled' : ''}>{this.state.isLoading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                        {
                            this.state.kegs.length===0?"":
                            <>
                            <Button className="btn btn-secondary btn-sm" onClick={this.splitKegs} disabled={!this.state.enableSplit ? 'disabled' : ''}>{this.state.isSplitLoading?"Please wait...":"Split"}</Button>&nbsp;&nbsp;
                            <Button className="btn btn-secondary btn-sm" onClick={this.mergeKegs} disabled={!this.state.enableMerge ? 'disabled' : ''}>{this.state.isMergeLoading?"Please wait...":"Merge"}</Button>
                            </>
                        }
                        <div style={{marginTop:'10px'}}></div>
                        <BootstrapTable id="tbl_kegs"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="keg_id"
                                data={this.state.kegs}
                                selectRow={selectRow}
                                columns={columns}>
                        </BootstrapTable>
                    </CardBody>
            </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        beers_kr: state.beers.beers_kr,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBeersByKegRoom: (kr_id) => dispatch(beersActions.getBeersByKegRoom(kr_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KegMerge);
