import toast from 'react-hot-toast';

export const successToaster = (msg) => {
    toast.success(`Successfully ${msg}!`, {
        duration: 5000,
        position: 'top-right'
    });

}

export const infoToaster = (msg) => {
    toast.success(`${msg}!`, {
        duration: 5000,
        position: 'top-right'
    });

}

export const failureToaster = (msg) => {
    toast.error(`Error :${msg}`, {
        duration: 10000,
        position: 'top-right'
    });

}
