import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import {
    Card,
    CardHeader,
    Row,
    Col,
    Label,
    CardBody,
    Button
} from "reactstrap";
import WaterfallChart from "../charts/waterfall";





class PourWaterfallReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('DD/MM/YYYY')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            selKeg:null,
            kegRooms: [],
            kegs:[],
            beers:[],
            waterFallData:[],
            hc_waterFallData:[],
            showHcChart:false,
            chartTitle:'',
            showChart:false,
            selBeer:null,
            kegRepData: [],
            report_url:'',
            showReport:false,
            errorMessage:'',
            fileUrls:[]
        };
    }

    componentDidMount() {
        this.fetchKegRooms();
    }

  
    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]},()=>{this.fetchKegs()});
            }
        });
    }

    fetchKegs=()=>{
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg/GetKegsByKegRoom`, { params: { kr_id: this.state.selKegRoom.value} }).then(response => {
            if (response.data !== undefined) {
              let _kegs=[];
              response.data.forEach((item)=>{
                _kegs.push({
                    value:item.keg_id,
                    label:'Keg '+item.keg_id+' '+item.beer_name
                })
              })
              this.setState({...this.state,kegs:_kegs,selKeg:_kegs[0]});
            }
          });
    }

    getChartData=()=>{
        this.setState({showChart:false,showHcChart:false});
        let newState=this;
        var title=`${this.state.selKegRoom.label} ${this.state.selKeg.label} Pour Volume History`;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/PourWaterfallChartData?keg_id=${this.state.selKeg.value}`)
        .then(function(response){
            var waterFallData=[];
            var hcChartData=[];
            for (var key in response.data){
                var subData=response.data[key];
                let sum = subData.map(o => o.pour_volume).reduce((a, c) => { return a + c });
                //waterFallData.push({x:moment(key).format("YYYY-MM-DD"),y:parseFloat((sum/1000).toFixed(2))});
                hcChartData.push({name:moment(key).format("YYYY-MM-DD"),y:parseFloat((sum/1000).toFixed(2))});
              }
              var totalPour_Volume=waterFallData.map(o => parseFloat(o.y)).reduce((a, c) => { return a + c });
              waterFallData.push({x:'Total Volume',y:totalPour_Volume,"showFull":true})
              hcChartData.push({isSum:true,name:'Total Volume'})
              console.log(waterFallData);
              //var _ChartOptions=newState.initWaterFallChart(hcChartData,title);
              newState.setState({...newState.state,waterFallData:waterFallData,showChart:true,chartTitle:title,showHcChart:true})
        })
    }

   

    getReprtData = () => {
        this.setState({showReport:true});
        const formData = {
            customer_email:this.props.userDetails.person.email,
            kr_id: this.state.selKeg.value,
            //beer_in:this.state.selBeer==null?null:this.state.selBeer.value,
            //start_date: moment(this.state.from_date).format('YYYY-MM-DD'),
            //end_date: moment(this.state.to_date).format('YYYY-MM-DD')
        };
        axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/reports/PourWaterfallReport`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
            data: formData
        })
            .then((response) => {
                var urls=response.data.response;
                if(!response.data.error){
                    urls.forEach((url)=>{
                        // const link = document.createElement('a');
                        // link.href = url;
                        // link.target="_blank";
                        // link.setAttribute(
                        // 'download',
                        // `Report.pdf`,
                        // );
                        // // Append to html link element page
                        // document.body.appendChild(link);
                        // // Start download
                        // link.click();
                        // // Clean up and remove the link
                        // link.parentNode.removeChild(link);
                        let newState=this;
                        axios(`${process.env.REACT_APP_API_BASE_URL}/api/reports/GetFileContent`,{params:{url:url}}).then(function(response){
                            newState.setState({...newState.state,chartHtml:response.data,showReport:false,errorMessage:""},()=>{newState.getChartData()})
                        })
                    })
                    //this.setState({...this.state,showReport:false})
                    // if(Array.isArray(urls)){
                    //     this.setState({...this.state,fileUrls:urls,showReport:false})
                    // }
                    // else{
                    //     this.setState({...this.state,fileUrls:[urls],showReport:false})
                    // }
                }
                else{
                    this.setState({...this.state,errorMessage:response.data.message,showReport:false})
                }
            });
    }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    render() {
        return (
            <React.Fragment>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Keg Pour Waterfall Plot</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKeg}
                                    options={this.state.kegs}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKeg')}>
                                </Select>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                {/* <Button  size="sm" onClick={this.getChartData}>Chart</Button> */}
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.showReport ? 'disabled' : ''}>{this.state.showReport?"Please wait...":"Report"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Row>
                        <Col lg="12" sm="12" md="12">
                            
                            
                           { this.state.showChart?
                           <div >
                                <WaterfallChart initialValue={0} data={this.state.waterFallData} title={this.state.chartTitle} />
                           </div>
                           :""}
                           <br />
                        <iframe title="new" style={{width:'100%',height:'500px'}} srcDoc={this.state.chartHtml} ref={this.chartRef}></iframe>
                        </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails
    }
}


export default connect(mapStateToProps, null)(PourWaterfallReport);
