import { SET_KEG_ROOMS } from '../../../constants/actionTypes';
import store from '../../../redux/store';
import axios from "axios";

export const initialState = {
	kegRooms:[]
};

export const getKegRooms = (site_id) => {
	const state = store.getState();
	return (dispatch) => {
		if (state.charts.kegRooms.length > 0) {
			return;
		}
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: site_id } }).then((response) => {
			if (response.data !== undefined) {
				const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
				kegRooms.sort((a, b) => a.label.localeCompare(b.label));
				dispatch(handleActionDispatch(SET_KEG_ROOMS, kegRooms));
			}
		});
	}
}


export const handleActionDispatch = (actionName, data) => {
	return {
		type: actionName,
		data: data
	}
}
