import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import LoaderComponent from "../shared/loader";
import WaterfallChart from "./waterfall";
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
import {sortByDataType} from '../../utility/common';
import {convertValueByMetric} from '../../utility/units-conversion'

class ConnectedKegsChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('DD/MM/YYYY')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            expanded:[0],
            rowIndex:null,
            kegData:[],
            pourData:[],
            showPourData:false,
            enableChartTab:false,
            waterFallData:[],
            activeTab:"2",
            loading:false,
            title:'',
            showChart:false,
            errorMessage:'',
            isProcessed:false,
        };
    }
    
    componentDidMount() {
        this.fetchKegRooms();
        console.log(this.props)
    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]});
            }
        });
    }


    getChartData=()=>{
        this.setState({showChart:false,loading:true});
        let newState=this;
        var title=`Connected Kegs for ${this.state.selKegRoom.label}`
        var params={
            kr_id:this.state.selKegRoom.value
        }
        var units=this.props.units;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/ConnectedKegsChart`,{params:params})
        .then(function(response){
            if(response.data!==undefined){
                var _kegsData=[];
                response.data.forEach((item)=>{
                    _kegsData.push({
                        days_to_empty:item.days_to_empty.toFixed(2),
                        keg_id:item.keg_id,
                        mlperpint:item.mlperpint,
                        name:item.beer_name,
                        line_number:item.line_number,
                        size:(convertValueByMetric((item.size),units,"vol")/(units==="metric"?1000:1)).toFixed(0),
                        percent_remaining:(item.percent_remaining*100).toFixed(2),
                        volume_poured:(convertValueByMetric((item.volume_poured),units,"vol")/(units==="metric"?1000:1)).toFixed(2),
                        volume_avg:item.volume_poured===0?0:(convertValueByMetric(((item.volume_poured/item.number_pours)),units,"vol")/(units==="metric"?1000:1)).toFixed(2),
                        connected_date:item.connected_date==null?"":moment(item.connected_date.substring(0,10) + " 00:00:00").format("YYYY-MM-DD"),
                        avg_temp:convertValueByMetric(item.avg_temp,units,"c").toFixed(2),
                        number_pints:(convertValueByMetric(item.volume_poured,units,"vol")/convertValueByMetric(item.mlperpint,units,"vol")).toFixed(2),
                        number_pours:item.number_pours
                    })
                })
                _kegsData.sort((a,b)=> parseFloat(a.line_number)-parseFloat(b.line_number));
                newState.setState({
                    ...newState.state,
                    kegData:_kegsData,
                    showChart:true,
                    title:title,
                    loading:false,
                });
            }

        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    onBeerExpand=(row,isExpand,rowIndex,e)=>{
        if (isExpand &&this.state.pourData.filter(a => a.keg_id === row.keg_id).length === 0 ) {
            this.setState({...this.state,isProcessed:false});
            this.showPourDetails(row);
        }
        else if(!isExpand){
            this.setState({...this.state,showPourData:false,expanded:[],isProcessed:false,pourData:[]});
        }
    }

    showPourDetails=(row)=>{
        var context=this;
        var mlperpint=this.state.kegData[0].mlperpint;
        var units=this.props.units;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetPourDataForConnectedKegs`,{params:{keg_id:row.keg_id}})
        .then(function(response){
            var pourData=[];
            response.data.forEach((item)=>{
                pourData.push({
                    name:row.name,
                    pour_id:item.id,
                    keg_id:item.keg_id,
                    end_date:moment(item.end_date.substring(0,10)+ " 00:00:00").format("YYYY-MM-DD"),
                    volume_poured:(convertValueByMetric((item.volume),units,"vol")).toFixed(2),
                    number_pints:(convertValueByMetric(item.volume,units,"vol")/convertValueByMetric(mlperpint,units,"vol")).toFixed(2),
                    temp:convertValueByMetric(item.temperature_c,units,"c").toFixed(2),
                    duration:item.duration_sec,
                    ambient:item.ambient_c===null?"0":convertValueByMetric(item.ambient_c,units,"c").toFixed(2)
                })
            })
            if(pourData.length>0){
                pourData.push({
                    name:"",
                    pour_id:0,
                    keg_id:0,
                    end_date:"Summary",
                    volume_poured:response.data.map(s=>parseFloat(convertValueByMetric(s.volume,units,"vol"))).reduce((acc, item) => parseFloat(acc+item, 0)).toFixed(2),
                    temp:(response.data.map(s=>parseFloat(convertValueByMetric(s.temperature_c,units,"c"))).reduce((acc, item) => parseFloat(acc+item, 0))/pourData.length).toFixed(2),
                    duration:(response.data.map(s=>parseFloat(s.duration_sec)).reduce((acc, item) => parseFloat(acc+item, 0))/pourData.length).toFixed(2),
                    ambient:(response.data.map(s=>parseFloat(convertValueByMetric(s.ambient_c,units,"c"))).reduce((acc, item) => parseFloat(acc+item, 0))/pourData.length).toFixed(2)
                })
            }
            context.setState({...context.state,pourData:pourData,showPourData:true,expanded:[row.keg_id],isProcessed:true});
        })
        
    }


    onBeerRender=(row)=>{
        const columns_kegs = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
              headerAlign:'center'
            },
            {
                dataField: "end_date",
                text: "End Date",
                sort: true,
                headerAlign:'center'
            },
            {
              dataField: "volume_poured",
              text: this.props.units==="metric"?"Volume Poured (ml)":"Volume Poured (ou)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align:'right',
              headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "temp",
                text: this.props.units==="metric"?"Temperature (C)":"Temperature (F)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "ambient",
                text: this.props.units==="metric"?"Ambient (C)":"Ambient (F)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
                dataField: "duration",
                text: "Duration (Sec)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            }
          ];
          var title=`Pour data for ${row.name}`
          if(this.state.showPourData){
            if(this.state.pourData.filter(a => a.keg_id === row.keg_id).length !== 0 || this.state.isProcessed){
                return(
                    <>
                    <div style={{padding:'10px',float:'right'}}>
                        <button className="btn btn-sm btn-info" onClick={() => this.rowClicked(row)}>Chart</button>
                        <Button onClick={()=>printReport(columns_kegs,this.state.pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                        <button onClick={()=>DownloadPdf(columns_kegs,this.state.pourData,title,this.props.companyLogo,this.props.userLogo,"Connected-Kegs-Report.pdf")} 
                        className="btn btn-secondary btn-sm">Download Pdf</button>
                        <ReactHTMLTableToExcel
                            className="btn btn-secondary btn-sm"
                            table="tbl_PourData"
                            filename="Connected kegs"
                            sheet="Pour Data"
                            buttonText="Download Excel"/>
                    </div>
                        <BootstrapTable id="tbl_PourData"
                                    classes="custom-table-css dft-bg-primary-color tbl-inner"
                                    striped
                                    hover
                                    condensed
                                    keyField="pour_id"
                                    data={this.state.pourData}
                                    //pagination={paginationFactory(pagingOptions)}
                                    columns={columns_kegs}>
                                </BootstrapTable>
                    </>
                )
            }
            else{
                return(<><LoaderComponent /></>);
            }
          }else{
            if(this.state.pourData.filter(a => a.keg_id === row.keg_id).length === 0){
                return(<><LoaderComponent /></>);
            }
          }
        }

        rowClicked=(row)=>{
            var context=this;
            var pourData=this.state.pourData.filter(s=>s.end_date!=="Summary");
            var minDate=Math.min(...pourData.map(s=>moment(s.end_date)));
            var maxDate=Math.max(...pourData.map(s=>moment(s.end_date)));
            var diffDays=moment(maxDate).diff(moment(minDate),'days');
            var days_bin=parseInt(diffDays/14);
            var datesBin=[];
            while(moment(minDate)<=moment(maxDate)){
                var point={
                    x:moment(minDate).format("YYYY-MM-DD"),
                    fromDate:moment(minDate).format("YYYY-MM-DD"),
                    toDate:moment(minDate).add(days_bin,'days')>moment(maxDate)?moment(maxDate).format("YYYY-MM-DD"):moment(minDate).add(days_bin,'days').format("YYYY-MM-DD"),
                    y:0
                }
                datesBin.push(point);
                minDate=moment(minDate).add(days_bin+1,'days');
            }
            var _waterfall_data=[...[]];
            datesBin.forEach((item)=>{
                var fromDate=moment(item.fromDate);
                var toDate=moment(item.toDate);
                var subData=pourData.filter(s=>moment(s.end_date)>=fromDate && moment(s.end_date)<=toDate)
                if(subData.length!==0){
                    var vol_poured=subData.map(o => parseFloat(o.volume_poured)).reduce((a, c) => { return a + c });
                    item.y=parseFloat(vol_poured/(this.props.units==="metric"?1000:1));
                    _waterfall_data.push(item);
                }
            })
            var totalPour_Volume=_waterfall_data.map(o => parseFloat(o.y)).reduce((a, c) => { return a + c });
            _waterfall_data.push({x:'Total Volume',y:totalPour_Volume,"showFull":true})
            var title=`${context.state.selKegRoom.label} ${row.name} Pour Volume Inventory`
            //_waterfall_data=[..._waterfall_data];
            context.setState({
                ...context.state,
                activeTab:"1",
                title:title,
                waterFallData:_waterfall_data,
                //pourData:this,
                showChart:true
            });
            
        }
    
        showWaterFallChart=()=>{
            if(this.state.waterFallData.length>0 && this.state.activeTab==="1"){
                return(<>
                    <WaterfallChart initialValue={0} data={this.state.waterFallData} title={this.state.title} />
                </>)
            }
            return "";
        }

        onBeerColumnRender=(expanded)=>{
            this.setState({...this.state,enableChartTab:expanded})
            if (expanded) {
                return (
                  <i className="fa fa-caret-down fa-md"></i>
                );
              }
              return (
                <i className="fa fa-caret-right fa-md"></i>
              );
        }

    render() {
        const expandBeerRow = {
            onlyOneExpanding: true,
            expanded:this.state.expanded,
            onExpand:this.onBeerExpand,
            parentClassName: 'selected',
            renderer: this.onBeerRender,
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              return(<div onClick={(e) => { e.stopPropagation() }}></div>);
            },
            expandColumnRenderer: ({ expanded }) => this.onBeerColumnRender(expanded)
          };
        const columns = [
            {
              dataField: "keg_id",
              text: "Keg Id",
              hidden: true,
              headerAlign:'center'
            },
            {
              dataField: "line_number",
              text: "Line #",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
              headerAlign:'center'
            },
            {
              dataField: "name",
              text: "Beer",
              sort: true,
              headerAlign:'center'
            },
            {
              dataField: "days_to_empty",
              text: "Days to empty",
              sort: true,
              align:'right',
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              headerAlign:'center'
            },
            {
                dataField: "percent_remaining",
                text: "% Left",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center'
            },
            {
                dataField: "volume_poured",
                text: this.props.units==="metric"?"Volume Poured (L)":"Volume Poured (OU)",
                sort: true,
                //formatter:(cell)=>{convertValueByMetric(cell,this.props.units,"vol")},
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center'
            },
            {
                dataField: "volume_avg",
                text: this.props.units==="metric"?"Avg Volume (L)":"Avg Volume (OU)",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center'
            },
            {
                dataField: "number_pints",
                text: "#pints",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center'
            },
            {
                dataField: "connected_date",
                text: "Connected Date",
                sort: true,
                align:'center',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'date')},
                headerAlign:'center'
            },
            {
                dataField: "avg_temp",
                text: this.props.units==="metric"?"Avg Temp  (C)":"Avg Temp  (F)",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center'
            },
            {
                dataField: "number_pours",
                text: "No.of Pours",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
                headerAlign:'center'
            },
            {
                dataField: "size",
                text: this.props.units==="metric"?"Size (L)":"Size (OU)",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'int')},
                headerAlign:'center'
              },
          ];
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Kegs on Tap</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Nav tabs>
                            
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                            {this.state.enableChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>:""
                            }
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                        {this.showWaterFallChart()}
                        </TabPane>
                        <TabPane tabId="2">
                        {/* <Button onClick={()=>this.printPourVolumeByDate(columns)} size="sm">Print</Button>
                        <button onClick={this.generatePdf} className="btn btn-secondary btn-sm">Download Pdf</button> */}
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.kegData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.kegData,this.state.title,this.props.companyLogo,this.props.userLogo,"Connected-Kegs-Report.pdf")} 
                            className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_Kegs"
                                filename="Connected kegs"
                                sheet="Kegs"
                                buttonText="Download Excel"/>
                        </div>
                            <BootstrapTable id="tbl_Kegs"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="keg_id"
                                expandRow={expandBeerRow}
                                data={this.state.kegData}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                    </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        units:state.userInfo.userDetails.units??state.userInfo.security.units
    }
}

export default connect(mapStateToProps, null)(ConnectedKegsChart);
