import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import LoaderComponent from '../shared/loader';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AdvancedFilter from "../shared/advancedFilter";
import * as gsActions from './store/glycol_system.actions';


class GlycolSystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      glycolSystems: [],
      filteredGlycolSystems: [],
      fields: [{ value: 'gs_id', label: 'Id', type: 'int' }, { value: 'gs_name', label: 'Name', type: 'text' }],
      showAdvancedSearch: false,
      showList: false,
    };
  }
  
  async componentDidMount () {
    await this.props.getGlycolSystems(this.props.site_id);
  }

  static getDerivedStateFromProps(props, state) {
    return {
        glycolSystems:props.glycolSystems,
        showList:props.showList,
        filteredGlycolSystems:props.glycolSystems
      }
  }

  toggleAdvancedSearch = () => {
    let newState = Object.assign({}, this.state);
    newState.showAdvancedSearch = !newState.showAdvancedSearch;
    if (!newState.showAdvancedSearch) {
      newState.filteredGlycolSystems = newState.kegglycolSystemsSizes;
    }
    this.setState(newState);
  }

  callBackFromAdvancedSearch = (data) => {
    let newState = Object.assign({}, this.state);
    newState.filteredGlycolSystems = data;
    this.setState(newState);
  }

  addGs = () => {
    this.props.clearSelGs();
    this.props.history.push(`/admin/editgs/0`);
  };

  filterGlycolSystemList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredGlycolSystems: [...this.state.glycolSystems] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredGs = this.state.glycolSystems.filter(
        (com) =>
          com.gs_name.toLowerCase().indexOf(searchText) >= 0 
      );
      this.setState({ filteredGlycolSystems: [...filteredGs] });
    }
  };

  onEdit = (gs) => {
    this.props.setSelGs(Object.assign({}, gs));
    this.props.history.push(`/admin/editgs/${gs.gs_id}`);
  }

  render() {
    const options = {
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const columns = [
      {
        dataField: "gs_id",
        text: "Id",
        hidden: true,
      },
      {
        dataField: "kr_name",
        text: "keg Room",
        sort: true,
      },
      {
        dataField: "gs_name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "controller_sn",
        text: "MCU",
        sort: false,
      },
      {
        dataField: "sensor_hub_sn",
        text: "Sensor Hub",
        sort: false,
      },
      {
        dataField: "sensor_serial_number",
        text: "Sensor",
        sort: false,
      },
      {
        dataField: "port",
        text: "Port",
        sort: false,
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          if(!this.props.isSuperUser){
            return(<div></div>)
          }
          return (
            <div>
                <button
                className="btn btn-sm btn-info"
                onClick={() => this.onEdit(row)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </div>
          );
        },
      }
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" style={{ width: '100%' }} id="/admin/kegs">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
              <h3 className="dft-primary-color">Glycol Systems</h3>
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterGlycolSystemList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              <Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addGs}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.glycolSystems} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--4">
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="ks_id"
                    data={this.state.filteredGlycolSystems}
                    pagination={paginationFactory(options)}
                    columns={columns}
                  />
                ) : this.state.showList === false ? (
                  <LoaderComponent />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    glycolSystems:state.glycolSystems.glycolSystems,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    showList:state.glycolSystems.showList,
    site_id: state.userInfo.security.site_id,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGlycolSystems:(site_id)=>dispatch(gsActions.getGlycolSystems(site_id)),
    clearSelGs:()=>dispatch(gsActions.clearSelGs()),
    setSelGs:(gs)=>dispatch(gsActions.setSelGs(gs))
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GlycolSystem);
