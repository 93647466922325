import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
//import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selSite: {
        site_id:0,
        name:'',
        address1:'',
        address2:'',
        city:'',
        region:'',
        postalCode:'',
        country:'',
        customer_id:0,
        customer_name:'',
        manager_name:'',
        sales_rep_name:'',
        manager_id:0,
        sales_rep:0
    },
    sites: [],
    countries:[],
    cities:[],
    currPage: 1
};


export const getSites = (site_id) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.sites.sites.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={
                site_id:site_id
            }
            if(state.customers.selCustomer.customer_id!==0){
                siteParams.customer_id=state.customers.selCustomer.customer_id;
            }
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/sites`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                   
                    let _sites = [];
                    response.data.forEach((item) => {
                        let site = {};
                        site.site_id = item.site_id;
                        site.name = item.site_name;
                        site.address1 = item.address_1;
                        site.address2 = item.address_2;
                        site.city = item.city;
                        site.region = item.region;
                        site.postalCode = item.postal_code;
                        site.country = item.country;
                        site.customer_id = item.customer_id;
                        site.manager_id=item.manager_id;
                        site.sales_rep=item.sales_rep;
                        if (item.customer_id != null) {
                          //let customer = state.customers.customers.find(p => p.customer_id === item.customer_id);
                          site.customer_name = item.customer_name;
                          site.manager_name = item.contact;
                          site.sales_rep_name = item.sales_rep;
                        }
                        _sites.push(site);
                    });
                    _sites = _sites.sort((a, b) => a.name.localeCompare(b.name));
                    dispatch(handleActionDispatch(actionTypes.SET_SITES, _sites));
                    resolve(_sites);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const getCountries = () => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.sites.countries.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/sites/getCountries`).then((response) => {
                console.log(response)
                if (response.data !== undefined) {
                     let _countries=response.data.map((item) => {
                        return{
                            country_name:item.country_name,
                            country_code:item.country_code
                        }
                    });
                    dispatch(handleActionDispatch(actionTypes.SET_SITE_COUNTRIES, _countries));
                    resolve(_countries);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const getCities = (country) => {
    //const state = store.getState();
    return (dispatch) => {  
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/sites/getCities`,{params:{country:country}}).then((response) => {
                console.log(response)
                if (response.data !== undefined) {
                     let _cities=response.data.map((item) => {
                        return{
                            name:item.name,
                            timezone:item.timezone
                        }
                    });
                    dispatch(handleActionDispatch(actionTypes.SET_SITE_CITIES, _cities));
                    resolve(_cities);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}


export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_SITE_PAGE, page));
    }
}

export const setSelSite = (site) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SELECTED_SITE, site));
    }
}

export const clearSelSite = () => {
    return setSelSite(initialState.selSite);
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}