module.exports = {
    units: [{
        label: 'METRIC',
        value: 'metric'
    }, {
        label: 'IMPERIAL',
        value: 'imperial'
    }],
    dateFormats: [{
        label: 'DD/MM/YYYY',
        value: 'DD/MM/YYYY'
    },{
        label: 'MM/DD/YYYY',
        value: 'MM/DD/YYYY'
    },{
        label: 'YYYY/MM/DD',
        value: 'YYYY/MM/DD'
    }]
};