import axios from 'axios';
import { ADD_PERSON, UPDATE_PERSON, SET_PERSONS, SET_SEL_PERSON, SET_CURR_PAGE, CLEAR } from '../../../constants/actionTypes';
import store from '../../../redux/store';
import { successToaster } from '../../toaster/toaster';

export const initialState = {
	selPerson: {
		id: 0,
		first_name: "",
		middle_name: "",
		last_name: "",
		gender: "",
		email: "",
		phone: "",
		avatar: '',
		profile: "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-51g0co/4851.png?position=1",
	},
	currPage: 1,
	persons: []
}

export const getPersons = () => {
	const state = store.getState();
	return (dispatch) => {
		if (state.persons.persons.length > 0) {
			return;
		}
		return new Promise(function (resolve, reject) {
			axios(`${process.env.REACT_APP_API_BASE_URL}/api/persons`).then((response) => {
				if (response.data !== undefined) {
					let personsData = [];
					response.data.forEach((item) => {
						var profile = item.profile.includes("https://")
							? item.profile
							: `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${item.profile}`;
						personsData.push({
							id: item.person_id,
							first_name: item.first_name,
							last_name: item.last_name,
							middle_name: item.middle_name,
							gender: item.gender,
							email: item.email,
							phone: item.phone,
							profile: profile,
						});
					});

					personsData.sort((a, b) => a.first_name.localeCompare(b.first_name));
					dispatch(handleActionDispatch(SET_PERSONS, personsData));
					resolve(personsData);
					//this.setState({ persons: personsData, filteredPersonList: personsData,showList:true,person_id:personsData.length===0?0:personsData[0].id  });
				}
			},
				(error) => {
					reject(error);
				});
		})
	}
}

export const getPerson = (personId) => {
    //const state = store.getState();
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            axios({
				method: 'GET',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/persons/${personId}`,
				headers: {
				  'Access-Control-Allow-Origin': 'true'
				}
			  }).then(response => {
				if (response.data) {
				  let person=response.data;
				  person.selectedGender = { value: person.gender, label: person.gender };
				  person.profile = person.profile.includes("https://") ? person.profile : `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${person.profile}`;
				  person.avatar = person.profile.includes("https://") ? person.profile : `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${person.profile}`;
				  dispatch(handleActionDispatch(SET_SEL_PERSON, person));
				  resolve(person);
				}
            },
                (error) => {
                    reject(error);
			  });
        })
    }
}

export const setPersons = (persons) => {
	return (dispatch) => {
		dispatch(handleActionDispatch(SET_PERSONS, persons));
	}
}

export const addPerson = (person) => {
	const formData = {
		person_id: person.id,
		first_name: person.first_name,
		middle_name: person.middle_name,
		last_name: person.last_name,
		gender: person.gender.value,
		email: person.email,
		phone: person.phone,
		profile: person.profile,
	};

	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/persons/`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			const personData = {
				...person,
			};
			dispatch(handleActionDispatch(ADD_PERSON, personData));
			successToaster("Added");
			resolve();
		});
	}
}

export const updatePerson = (person) => {
	const formData = {
		person_id: person.person_id,
		first_name: person.first_name,
		middle_name: person.middle_name,
		last_name: person.last_name,
		gender: person.gender.value,
		email: person.email,
		phone: person.phone,
		profile: person.profile,
	};

	return (dispatch) => {
		return new Promise(async function (resolve, reject) {
			await axios({
				method: 'PUT',
				url: `${process.env.REACT_APP_API_BASE_URL}/api/persons/${person.person_id}`,
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true'
				},
				data: formData
			});
			const personData = {
				...person,
				gender: person.gender.value
			};
			dispatch(handleActionDispatch(UPDATE_PERSON, personData));
			successToaster("Updated");
			resolve();
		});
	}
}

export const setCurrPage = (page) => {
	return (dispatch) => {
		dispatch(handleActionDispatch(SET_CURR_PAGE, page));
	}
}

export const setSelPerson = (person) => {
	person.gender = person.gender ? { label: person.gender, value: person.gender } : "";
	return (dispatch) => {
		dispatch(handleActionDispatch(SET_SEL_PERSON, person));
	}
}

export const clearSelPerson = () => {
	return setSelPerson(initialState.selPerson);
}

export const clearStates = () => {
	return (dispatch) => {
		dispatch(handleActionDispatch(CLEAR, initialState));
	}
}

export const handleActionDispatch = (actionName, data) => {
	return {
		type: actionName,
		data: data
	}
}