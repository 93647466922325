import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import {
    Card,
    CardHeader,
    Row,
    Col,
    Label,
    CardBody,
    Button
} from "reactstrap";
var DatePicker = require("reactstrap-date-picker");

class VolumeReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('DD/MM/YYYY')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            kegRepData: [],
            report_url:'',
            chart:'',
            chartHtml:'',
            showReport:false,
            errorMessage:'',
            fileUrls:[]
        };
    }

    componentDidMount() {
        this.fetchKegRooms();
        // axios({
        //     method: 'GET',
        //     url: "https://nyc3.digitaloceanspaces.com/cdndraftco/assets/reports/Industria%20Burlington/Industria%20Burlington%20Total%20Volume%20Pie%20Chart%201645187957.html?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ELTLEN4XNP3YKMHUEPT5%2F20220218%2Fnyc3%2Fs3%2Faws4_request&X-Amz-Date=20220218T123917Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=5b0d644d6dff025c86c42cb4bceb5951dc9fc2211a96512cf931e0a58707a92c",
        //     headers: {
        //       'Content-Type': 'application/json',
        //       'Access-Control-Allow-Origin': '*',
        //       "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        //     }
        //     })
        //         .then((response) => {
        //             console.log(response);
        //         })
    }

  
    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]});
            }
        });
    }

    getReprtData = () => {
        this.setState({showReport:true});
        const formData = {
            customer_email:this.props.userDetails.person.email,
            kr_id: this.state.selKegRoom.value,
            //beer_in:this.state.selBeer==null?null:this.state.selBeer.value,
            start_date: moment(this.state.from_date).format('YYYY-MM-DD'),
            end_date: moment(this.state.to_date).format('YYYY-MM-DD')
        };
        axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/reports/VolumeReport`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
            data: formData
        })
            .then((response) => {
                var urls=response.data.response;
                if(!response.data.error){
                    urls.forEach((url)=>{
                        let newState=this;
                        axios(`${process.env.REACT_APP_API_BASE_URL}/api/reports/GetFileContent`,{params:{url:url}}).then(function(response){
                            newState.setState({...newState.state,chartHtml:response.data,showReport:false})
                        })
                        // const link = document.createElement('a');
                        // link.href = url;
                        // link.target="_blank";
                        // link.setAttribute(
                        // 'download',
                        // `Report.pdf`,
                        // );
                        // // Append to html link element page
                        // document.body.appendChild(link);
                        // // Start download
                        // link.click();
                        // // Clean up and remove the link
                        // link.parentNode.removeChild(link);
                    })
                    //this.setState({...this.state,showReport:false})
                    // if(Array.isArray(urls)){
                    //     this.setState({...this.state,fileUrls:urls,showReport:false,chartHtml:response.data.html})
                    // }
                    // else{
                    //     this.setState({...this.state,fileUrls:[urls],showReport:false,chartHtml:response.data.html})
                       
                    // }
                }
                else{
                    this.setState({...this.state,errorMessage:response.data.message,showReport:false,chartHtml:""})
                }
            });
    }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    render() {
        return (
            <React.Fragment>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Volume By Date Chart</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                           
                        </Row>
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button onClick={this.getReprtData} disabled={this.state.showReport ? 'disabled' : ''}>{this.state.showReport?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Row>
                            <Col lg="12" sm="12" md="12">
                            <iframe title="new" style={{width:'100%',height:'700px'}} srcDoc={this.state.chartHtml} ref={this.chartRef}></iframe>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails
    }
}



export default connect(mapStateToProps, null)(VolumeReport);
