import {SIDE_LINK_CLICK,INTERNAL_LINK_CLICK} from './actionTypes'

export const SIDE_NAV_CLICK=(linkUrl)=>{
    return (dispatch) => {
		dispatch(handleActionDispatch(SIDE_LINK_CLICK, linkUrl));
	}   
}

export const INTERNAL_NAV_CLICK=(linkUrl)=>{
    return (dispatch) => {
		dispatch(handleActionDispatch(INTERNAL_LINK_CLICK, linkUrl));
	}   
}

export const handleActionDispatch = (actionName, data) => {
	return {
		type: actionName,
		payload: data
	}
}