export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};

export const formatNumbers = (num) => {
    return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const secondsToDaysHours = (second) => {
    const days = Math.floor(second / 86400);
    const hours = Math.floor((second % 86400) / 3600);
    const daysStr = days > 1 ? 'days' : 'day';
    const hrsStr = hours > 1 ? 'hours' : 'hour';
    //const minutes = Math.floor(((second % 86400) % 3600) / 60);
    //const seconds = Math.floor(((second % 86400) % 3600) % 60);

    return `${days} ${daysStr} ${hours} ${hrsStr}`;
    // const forMatDate = {
    //     days: days,
    //     hours: hours,
    //     minutes: minutes,
    //     seconds: seconds
    // };
    // return forMatDate;
}

export const sortByDataType=(a,b,order,dataType)=>{
    var firsValue=a;
    var secondValue=b;
    if(dataType==="int"){
        firsValue=parseInt(a)
        secondValue=parseInt(b)
    }
    else if(dataType==="float"){
        firsValue=parseFloat(a.replace(/,/g, ''))
        secondValue=parseFloat(b.replace(/,/g, ''))
    }
    else if(dataType==="date"){
        firsValue=new Date(a)
        secondValue=new Date(b)
    }

    if (order === 'asc') {
        return secondValue - firsValue;
      }
      return firsValue - secondValue;
}