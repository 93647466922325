import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import LoaderComponent from '../shared/loader';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AdvancedFilter from "../shared/advancedFilter";
import { connect } from 'react-redux';
import * as customerActions from '../customer/store/customer.actions';
import * as personActions from '../persons/store/person.actions';
import * as sitesActions from './store/site.actions';
import KegroomList from "../kegroom/kegroomList";

class SitesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      filteredSitesList: [],
      fields: [{ value: 'site_id', label: 'Id', type: 'int' }, { value: 'name', label: 'Name', type: 'text' }, { value: 'city', label: 'City', type: 'text' },
      { value: 'region', label: 'Region', type: 'text' }, { value: 'country', label: 'Country', type: 'text' }, { value: 'postalcode', label: 'Postal Code', type: 'text' },
      { value: 'customer_name', label: 'Customer', type: 'text' }, { value: 'manager_name', label: 'Manager', type: 'text' }, { value: 'sales_rep_name', label: 'Sales Rep', type: 'text' }],
      showAdvancedSearch: false,
      showList: false,
      selSite: null,
      collapse: true
    };
  }

  async componentDidMount () {
    var selected_site_id = this.props.site_id;
    //await this.props.getPersons();
    //await this.props.getCustomers(selected_site_id);
    //this.props.setSelCustomer(Object.assign({}, this.props.customers[0]));
    await this.props.getSites(selected_site_id)
    this.props.setSelSite(Object.assign({}, this.props.sites[0]));
  }

  static getDerivedStateFromProps(props, state) {
    if (state.sites.length === 0) {
      return {
        sites:props.sites,
        selSite:props.selSite,
        filteredSitesList: props.sites,
        showList: props.sites.length>0
      }
    }
    return null;
  }

  addSite = () => {
    if (this.state.customer_id && this.state.customer_id > 0)
      window.location = "#/admin/site/0/customer/" + this.state.customer_id;
    else
      window.location = "#/admin/site/0";
  };

  filteredSitesList = (event) => {
    let searchText = event.target.value;
    let filteredSites = [];
    if (!searchText) {
      filteredSites = this.state.sites;
    } else {
      searchText = searchText.toLowerCase();
      filteredSites = this.state.sites.filter(
        (com) =>
          com.name.toLowerCase().indexOf(searchText) >= 0 ||
          com.country.toLowerCase().indexOf(searchText) >= 0 ||
          com.address1.toLowerCase().indexOf(searchText) >= 0 ||
          com.address2.toLowerCase().indexOf(searchText) >= 0
      );
    }
    this.setState({ filteredSitesList: [...filteredSites] });
  };

  setRowSelected = (elem) => {
    for (let i = 0; i < elem.parentNode.getElementsByTagName('tr').length; i++) {
      elem.parentNode.getElementsByTagName('tr')[i].className = '';
    }
    elem.className = 'selected';
  }

  toggleAdvancedSearch = () => {
    let newState = Object.assign({}, this.state);
    newState.showAdvancedSearch = !newState.showAdvancedSearch;
    if (!newState.showAdvancedSearch) {
      newState.filteredSitesList = newState.sites;
    }
    this.setState(newState);
  }

  callBackFromAdvancedSearch = (data) => {
    let newState = Object.assign({}, this.state);
    newState.filteredSitesList = data;
    this.setState(newState);
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  setSite=(site)=>{
    this.props.setSelSite(Object.assign({}, site));
  }

  render() {

    const options = {
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const columns = [
      {
        dataField: "site_id",
        text: "site_id",
        hidden: true,
        style: { 'whiteSpace': 'nowrap' }
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
        style: { 'whiteSpace': 'nowrap', 'width': '20px' },
      },
      {
        dataField: "city",
        text: "City",
        sort: true,
        style: { 'whiteSpace': 'nowrap' }
      },
      {
        dataField: "region",
        text: "Region",
        sort: true,
        style: { 'whiteSpace': 'nowrap' }
      },
      {
        dataField: "country",
        text: "Country",
        sort: true,
        style: { 'whiteSpace': 'nowrap' }
      },
      {
        dataField: "postalCode",
        text: "Postal Code",
        sort: true,
        style: { 'whiteSpace': 'nowrap' }
      },
      {
        dataField: "customer_name",
        text: "Customer",
        sort: true,
        style: { 'whiteSpace': 'nowrap' }
      },
      {
        dataField: "manager_name",
        text: "Manager",
        sort: true,
        style: { 'whiteSpace': 'nowrap' }
      },
      {
        dataField: "sales_rep_name",
        text: "Sales Rep",
        sort: true,
        style: { 'whiteSpace': 'nowrap' }
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link
                className="btn btn-sm btn-info"
                to={`/admin/site/${row.site_id}/customer/${row.customer_id}`}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </div>
          );
        },
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (this.state.depthLevel < 2) {
          //this.loadChildComponents(row.site_id);
          this.setSite(row)
          this.setRowSelected(e.currentTarget);
        }
      }
    };

    const rowClasses = (row, rowIndex) => {
      return rowIndex === 0 ? 'selected' : '';
    };

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" id="/admin/sites">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row>
              <Col lg="6" md="6" sm="12">
              <h3 className="dft-primary-color">Sites</h3>
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filteredSitesList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              {this.props.isSuperUser?<Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addSite}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>:""}
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.sites} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--4">
            <Row>
              <Col lg="12" m="12">
                <div style={{ width: '100%' }} className="dft-horizontal-scroll-bar">
                  {this.state.showList === true ? (
                    <BootstrapTable
                      classes="custom-table-css dft-bg-primary-color"
                      striped
                      hover
                      condensed
                      keyField="site_id"
                      data={this.state.filteredSitesList}
                      pagination={paginationFactory(options)}
                      columns={columns}
                      rowEvents={rowEvents}
                      rowClasses={rowClasses}
                    />
                  ) : this.state.showList === false ? (
                    <LoaderComponent />
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {this.props.site_id!==0 ? (
         <KegroomList />
        ) : ""}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    customers:state.customers.customers,
    sites: state.sites.sites,
    selSite:state.sites.selSite,
    currPage: state.customers.currPage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPersons: () => dispatch(personActions.getPersons()),
    getCustomers: (site_id) => dispatch(customerActions.getCustomers(site_id)),
    setCurrPage: (page) => dispatch(sitesActions.setCurrPage(page)),
    getSites:(site_id)=>dispatch(sitesActions.getSites(site_id)),
    setSelCustomer:(customer)=>dispatch(customerActions.setSelCustomer(customer)),
    setSelSite:(site)=>dispatch(sitesActions.setSelSite(site))
  }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SitesList);

