import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./subscriptions.action";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_REPORT_SUBSCRIPTIONS):
            return setSubscriptions(state, action);
        case (actionTypes.SET_AVAILABLE_REPORTS):
            return setAvailableReports(state, action);
        case (actionTypes.ADD_REPORT_SUBSCRIPTION):
            return addReportSubscription(state, action);
        case (actionTypes.UPDATE_REPORT_SUBSCRIPTION):
            return updateReportSubscription(state, action);
        case (actionTypes.REMOVE_REPORT_SUBSCRIPTION):
            return removeReportSubscription(state, action);
        case (actionTypes.EDIT_REPORT_SUBSCRIPTION):
            return editReportSubscription(state, action);
        case(actionTypes.Update_PARAMS_REPORT_SUBSCRIPTION):
            return updateSubscriptionParams(state,action);
        case(actionTypes.CANCEL_EDIT_REPORT_SUBSCRIPTION):
            return cancelEdit(state,action);
        default:
            return state;
    }

};

const cancelEdit=(state,action)=>{
    return {
        ...state,
        rowsExpanded:[]
    }
}

const updateSubscriptionParams=(state,action)=>{
    if(action.data.param==="selEditReport"){
        return {
            ...state,
            selEditReport:action.data.value
        }
    }
    if(action.data.param==="selEditFrequency"){
        return {
            ...state,
            selEditFrequency:action.data.value
        }
    }
}

const editReportSubscription=(state,action)=>{
    var selReport=state.availableReports.filter(s=>s.value===action.data.report_id);
    return {
        ...state,
        rowsExpanded:[action.data.report_subscription_id],
        selEditReport:selReport.length>0?selReport[0]:null,
        selEditFrequency:{label:action.data.run_frequency,value:action.data.run_frequency}
    };
}

const setSubscriptions = (state, action) => {
    return updateObject(state, {
        reportSubscriptions: action.data,
        showList:true
    });
};

const setAvailableReports = (state, action) => {
    return updateObject(state, {
        availableReports: action.data
    });
};


const addReportSubscription = (state, action) => {
    let rs = [...state.reportSubscriptions];
    rs.push(action.data);
    return updateObject(state, {
        reportSubscriptions: rs
    });
};

const updateReportSubscription = (state, action) => {
    const index = state.reportSubscriptions.findIndex(b => b.report_subscription_id===action.data.report_subscription_id);
    return {
        ...state,
        rowsExpanded:[],
        reportSubscriptions: [...state.reportSubscriptions.slice(0, index), action.data, ...state.reportSubscriptions.slice(index + 1)]
    };
};

const removeReportSubscription = (state, action) => {
    debugger
    const items = state.reportSubscriptions.filter((item)=>item.report_subscription_id!==action.data.report_subscription_id);
    return {
        ...state,
        reportSubscriptions: items
    };
};

export default reducer;