import React from "react";
import { connect } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from 'react-bootstrap-table2-filter';
import "react-filter-box/lib/react-filter-box.css"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AdvancedFilter from "../shared/advancedFilter";
import * as beersActions from './store/beers.actions';

class BeerList extends React.Component {
  constructor() {

    super();
    this.state = {
      beers: [],
      filteredBeerList: [],
      //expanded: [],
      fields: [{ value: 'id', label: 'Id', type: 'int' }, { value: 'keg_Coupler', label: 'Keg Coupler', type: 'text' }, { value: 'name', label: 'Name', type: 'text' }
        , { value: 'company', label: 'Company', type: 'text' }, { value: 'type', label: 'Type', type: 'text' }
        , { value: 'country', label: 'Country', type: 'text' }, { value: 'category', label: 'Category', type: 'text' }, { value: 'abv', label: 'ABV', type: 'int' }],
      showAdvancedSearch: false,
      showList: false,

    };
    // this.options = [
    //   {
    //     columnField: "name",
    //     type: "text"
    //   },
    //   {
    //     columnField: "company",
    //     type: "text"
    //   },
    //   {
    //     columnField: "type",
    //     type: "text"
    //   },
    //   {
    //     columnField: "keg_Coupler",
    //     type: "text"
    //   }
    // ];
  }

  componentDidMount() {
    // Load the list
    // axios(`${process.env.REACT_APP_API_BASE_URL}/api/beers`).then((response) => {
    //   if (response.data !== undefined) {
    //     let beersData = [];
    //     response.data.map((item) => {
    //       var logo = item.logo.includes("https://")
    //         ? item.logo
    //         : `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${item.logo}`;
    //       beersData.push({
    //         id: item.draft_beer_id,
    //         name: item.name,
    //         type: item.type,
    //         company: item.company,
    //         keg_Coupler: item.keg_coupler,
    //         logo: logo,
    //         country: item.countryoforigin,
    //         category: item.category,
    //         abv: item.ABV
    //       });
    //     });
    //     beersData.sort((a, b) => a.name.localeCompare(b.name));
    //     this.setState({ beers: beersData, filteredBeerList: beersData, showList: true });
    //   }
    // });
    this.props.getBeers();
  }


  static getDerivedStateFromProps(props, state) {
    if (state.beers.length === 0) {
      return {
        beers: props.beers,
        filteredBeerList: props.beers,
        showList: true
      }
    }
    return null;
  }

  componentWillUnmount() {
    // clear the curr page number from redux if user routes to other page then beer detail    
    if (!this.props.history.location.pathname.includes('/addbeer/')) {
      this.props.clearStates();
    }
  }

  addBeer = () => {
    this.props.clearSelBeer();
    // let newState = Object.assign({}, this.state);
    // let beers = newState.beers.filter(c => c.id === 0);
    // if (beers.length != 0) {
    //     newState.beers.splice(beers[0], 1);
    // }
    //newState.beers.splice(0, 0, { id: 0, name: 'ACE HILL VIENNA LAGER', companyName: 'ACE HILL BEER COMPANY INC.',keg_Coupler: 'D', kegCouplers: this.state.kegCouplers, beerTypes:this.state.beerTypes,type:'LAGER',logo:'https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-51g0co/4851.png?position=1' });
    //newState.expanded = [0];
    //this.setState(newState);
    this.props.history.push(`/admin/addbeer/0`);
    //window.location = "#/admin/addbeer/0";
  };

  onEdit = (beer) => {
    this.props.setSelBeer(Object.assign({}, beer));
    this.props.history.push(`/admin/addbeer/${beer.id}`);
  }

  // cancelDetail = (beerData, command) => {
  //   let newState = Object.assign({}, this.state);
  //   newState.expanded = [];
  //   if (beerData) {
  //     if (command === "update" || command === "cancel") {
  //       let updateBeer = newState.beers.filter((c) => c.id === beerData.id);
  //       if (updateBeer.length !== 0) {
  //         updateBeer[0].id = beerData.id;
  //         updateBeer[0].name = beerData.name;
  //         updateBeer[0].companyName = beerData.companyName;
  //       }
  //     } else {
  //       //newState.stories.splice(0, 0, storyData);
  //       newState.beers.push(beerData);
  //     }
  //   }
  //   let beers = newState.beers.filter((c) => c.id === 0);
  //   if (beers.length !== 0) {
  //     newState.beers.splice(beers[0], 1);
  //   }
  //   this.setState(newState);
  // };

  toggleAdvancedSearch = () => {

    //let newState = Object.assign({}, this.state);
    //newState.showAdvancedSearch = !newState.showAdvancedSearch;
    let newState = {
      showAdvancedSearch: !this.state.showAdvancedSearch
    }
    if (!newState.showAdvancedSearch) {
      newState.filteredBeerList = this.state.beers;
    }
    this.setState({
      ...this.state,
      ...newState
    });
  }

  callBackFromAdvancedSearch = (data) => {
    // let newState = Object.assign({}, this.state);
    // newState.filteredBeerList = data;
    // this.setState(newState);
    this.setState({
      ...this.state,
      filteredBeerList: data
    });
  }

  filterBeerList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredBeerList: [...this.state.beers] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredBeers = this.state.beers.filter(
        (com) =>
          com.name.toLowerCase().indexOf(searchText) >= 0 ||
          com.type.toLowerCase().indexOf(searchText) >= 0
      );
      this.setState({ filteredBeerList: [...filteredBeers] });
    }
  };



  render() {
    const pagingOptions = {
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      page: this.props.currPage,
      onPageChange: (page) => {
        this.props.setCurrPage(page)
      }
    };

    const columns = [
      {
        dataField: "logo",
        text: "Logo",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <img src={row.logo} style={{ width: 85 + "px" }} alt="" />
            </div>
          );
        },
      },
      {
        dataField: "id",
        text: "BeerId",
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
        headerStyle: {
          backgroundColor: '#c8e6c9'
        }
      },
      {
        dataField: "company",
        text: "Company",
        sort: true
      },
      {
        dataField: "distributor",
        text: "Distributor",
        sort: true
      },
      {
        dataField: "type",
        text: "Type",
        sort: true
      },
      {
        dataField: "keg_Coupler",
        text: "Keg Coupler",
        sort: true
      },
      {
        dataField: "country",
        text: "Country",
        sort: true
      },
      {
        dataField: "category",
        text: "Category",
        sort: true
      },
      {
        dataField: "abv",
        text: "ABV",
        sort: true
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              {/* <Link
                className="btn btn-sm btn-info"
                to={`/admin/addbeer/${row.id}`}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link> */}
              <button
                className="btn btn-sm btn-info"
                onClick={() => this.onEdit(row)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
              {/* <button
                className="btn btn-sm btn-danger"
                onClick={() => this.customDelete(row)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button> */}
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row>
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="12" md="12">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterBeerList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              <Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addBeer}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.filteredBeerList} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody >
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar" style={{"padding":0}}>
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color "
                    striped
                    hover
                    condensed
                    keyField="id"
                    data={this.state.filteredBeerList}
                    filter={filterFactory()}
                    pagination={paginationFactory(pagingOptions)}
                    filterPosition="top"
                    columns={columns}
                  >
                  </BootstrapTable>
                ) : this.state.showList === false ? (
                  ""
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currPage: state.beers.currPage,
    beers: state.beers.beers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBeers: (beers) => dispatch(beersActions.setBeers(beers)),
    setSelBeer: (beer) => dispatch(beersActions.setSelBeer(beer)),
    setCurrPage: (page) => dispatch(beersActions.setCurrPage(page)),
    clearSelBeer: () => dispatch(beersActions.clearSelBeer()),
    clearStates: () => dispatch(beersActions.clearStates()),
    getBeers: () => dispatch(beersActions.getBeers())
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(BeerList);
