import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import moment from "moment";
//import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selSensorHub: {
        id:0,
        serial_number:'',
        status:'',
        last_reboot_date:'',
        mcu_serial_number:false,
    },
    sensorHubs: [],
    currPage: 1
};

export const getSensorHubs = (site_id,forceReload) => {
  
    const state = store.getState();
    return (dispatch) => {  
        if (state.sensorHubs.sensorHubs.length > 0 && !forceReload) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={
                site_id:site_id
            }
            if(state.mcus.selMcu.id!==0){
                siteParams.mcu_id=state.mcus.selMcu.id
            }
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensor_hub`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    let _sensorHubs = [];
                    response.data.forEach((item) => {
                        _sensorHubs.push({
                            id: item.sh_id,
                            serial_number: item.sh_serial_number,
                            status: item.sh_status,
                            last_reboot_date: moment(item.sh_last_reboot_date).format('YYYY-MM-DD'),
                            mcu_serial_number: item.mcu_serial_number
                       })
                    });
                    _sensorHubs = _sensorHubs.sort((a, b) => a.serial_number.localeCompare(b.serial_number));
                    dispatch(handleActionDispatch(actionTypes.SET_SENSOR_HUB, _sensorHubs));
                    resolve(_sensorHubs);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_SENSOR_HUB_PAGE, page));
    }
}

export const setSelSensorHub = (sh) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SELECTED_SENSOR_HUB, sh));
    }
}

export const clearSelKeg = () => {
    return setSelSensorHub(initialState.selSensorHub);
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}