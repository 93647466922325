import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
//import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { formatNumbers } from "../../utility/common";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import LoaderComponent from '../shared/loader';
import { connect } from "react-redux";
import * as sensorActions from './store/sensor.actions';

class SensorList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //sh_id: this.props.sh_id === undefined ? 0 : this.props.sh_id,
      sensors: [],
      filteredSensorsList: [],
      selSensor:null,
      //expanded: [],
      showList: false,
      //depthLevel: this.props.depth_level ? this.props.depth_level : 0,
    };
  }

  async componentDidMount () {
    //var selected_site_id = this.props.site_id;
    await this.props.getSensors(true)
    this.props.setSelSensor(Object.assign({}, this.props.sensors[0]));
  }

  static getDerivedStateFromProps(props, state) {
    return {
      sensors:props.sensors,
      selSensor:props.selSensor,
      filteredSensorsList: props.sensors,
      showList: props.sensors.length>0
    }
  }
  // fetchSensors = () => {
  //   if (this.state.sh_id) {
  //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensors`, { params: { sh_id: this.state.sh_id } }).then(response => {

  //       if (response.data) {
  //         this.setSensorModel(response.data);
  //       }
  //     });
  //   } else {
  //     if (this.state.depthLevel === 0) {
  //       axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensors`).then(response => {
  //         if (response.data) {
  //           this.setSensorModel(response.data);
  //         }
  //       });
  //     }
  //     else {
  //       this.setSensorModel([]);
  //     }
  //   }
  // }

  // setSensorModel = (sites) => {
  //   //let newState = Object.assign({}, this.state);
  //   this.setState({showList: false});
  //   var sensorsData = [];
  //   sites.forEach((item) => {
  //     sensorsData.push({
  //       id: item.sensor_id,
  //       serial_number: item.sensor_serial_number,
  //       status: item.sensor_status,
  //       deployed_date: item.sensor_deployed_date,
  //       volume_measured: item.sensor_volume_measured,
  //       port: item.port
  //     });
  //   });
  //   sensorsData = sensorsData.sort((a, b) => a.serial_number.localeCompare(b.serial_number));
  //   setTimeout(() => {
  //     this.setState({ sensors: sensorsData, filteredSensorsList: sensorsData, showList: true });
  //   }, 500)
  // }

  // fetchSensorsFromParent(shId) {
  //   this.setState({
  //     sh_id: shId
  //   },()=>{this.fetchSensors()});
    
  // }

  addSensor = () => {
    window.location = `#/admin/sensor/0/sh/${this.props.selSensorHub.id}`;
  };

  filterSensorList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filteredSensorsList: [...this.state.sensors] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredSensors = this.state.sensors.filter(
        (com) =>
          com.serial_number.toLowerCase().indexOf(searchText) >= 0 ||
          com.status.toLowerCase().indexOf(searchText) >= 0
      );
      this.setState({ filteredSensorsList: [...filteredSensors] });
    }
  };

  render() {
    const options = {
      //sizePerPageRenderer,
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const columns = [
      {
        dataField: "id",
        text: "Sensor Id",
        hidden: true,
      },
      {
        dataField: "serial_number",
        text: "Serial Number",
        sort: true,
      },
      {
        dataField: "port",
        text: "Port",
        sort: true,
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
      },
      {
        dataField: "deployed_date",
        text: "Deployed Date",
        sort: true,
      },
      {
        dataField: "volume_measured",
        text: "Volume Measured (ml)",
        sort: true,
        align:'right',
        formatter: (cell, row) => {
          return `${formatNumbers(cell)}`
        }
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link
                className="btn btn-sm btn-info"
                to={`/admin/sensor/${row.id}/sh/${this.props.selSensorHub.id}`}
                params={{ testvalue: "hello" }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
              {/* <button
                className="btn btn-sm btn-danger"
                onClick={() => this.customDelete(row)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button> */}
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" id="/admin/sensors">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
              <h3 className="dft-primary-color">Sensors</h3>
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterSensorList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="6" lg="6">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addSensor}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--5">
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="id"
                    data={this.state.filteredSensorsList}
                    pagination={paginationFactory(options)}
                    columns={columns}
                  />
                ) : this.state.showList === false ? (
                  <LoaderComponent />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    currPage: state.sensors.currPage,
    sensors:state.sensors.sensors,
    selSensor:state.sensors.selSensor,
    selSensorHub:state.sensorHubs.selSensorHub
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrPage: (page) => dispatch(sensorActions.setCurrPage(page)),
    getSensors:(forceReload)=>dispatch(sensorActions.getSensors(forceReload)),
    setSelSensor:(sensor)=>dispatch(sensorActions.setSelSensor(sensor))
  }
}

export default  connect(mapStateToProps,mapDispatchToProps)(SensorList);
