import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./customer.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_CUSTOMERS):
            return setCustomers(state, action);
        case (actionTypes.SET_SEL_CUSTOMER):
            return setSelCustomer(state, action);
        case (actionTypes.SET_CURR_CUSTOMER_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.ADD_CUSTOMER):
            return addCustomer(state, action);
        case (actionTypes.UPDATE_CUSTOMER):
            return updateCustomer(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        default:
            return state;
    }

};

const setSelCustomer = (state, action) => {
    return updateObject(state, {
        selCustomer: action.data
    });
};

const setCustomers = (state, action) => {
    return updateObject(state, {
        customers: action.data
    });
};

const addCustomer = (state, action) => {
    let customers = [...state.customers];
    customers.push(action.data);
    return updateObject(state, {
        customers: customers
    });
};

const updateCustomer = (state, action) => {
    const index = state.customers.findIndex(b => b.id === action.data.id);
    return {
        ...state,
        customers: [...state.customers.slice(0, index), action.data, ...state.customers.slice(index + 1)]
    };
};



// set current page number of beers list page 
const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selBeer: initialState.selBeer,
        beers: initialState.beers,
        currPage: initialState.currPage
    });
};

export default reducer;