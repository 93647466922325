import React from 'react';
import LoaderGif from '../../assets/img/loader.gif'

function AxiosLoader() {
    return (
        <div className="loading-container">
            <div className='loader'>
                <img src={LoaderGif} alt='' />
            </div>
        </div>
    )
}

export default AxiosLoader
