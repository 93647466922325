import React from "react";
import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import { successToaster } from '../toaster/toaster';
import SimpleReactValidator from "simple-react-validator"
import { isObject } from "reactstrap/lib/utils"

class SecuritylevelDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      securitylevel: {
        security_level_id: parseInt(props.match.params.id),
        sl_name: '',
        tech_only: false,
        user_only: false,
        data_only: false,
        draftco_only:false
      },
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: "Please select an option",
          rule: (val, params, validator) => {
            return isObject(val)
          },
          required: true, // optional
        },
      },
    })
  }

  componentDidMount() {
    this.fetchSecuritylevel();
  }

  fetchSecuritylevel = () => {
    const security_level_id = this.state.securitylevel.security_level_id;

    if (security_level_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/securitylevel/${security_level_id}`).then(response => {

        if (response.data) {
          let newState = Object.assign({}, this.state);
          newState.securitylevel.security_level_id = response.data.security_level_id;
          newState.securitylevel.sl_name = response.data.sl_name;
          newState.securitylevel.tech_only = response.data.tech_only;
          newState.securitylevel.user_only = response.data.user_only;
          newState.securitylevel.data_only = response.data.data_only;
          newState.securitylevel.draftco_only = response.data.draftco_only;
          this.setState(newState);
        }
      });
    }
  }

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.securitylevel[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }

  handleCheckboxChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.securitylevel[inputPropName] = event.target.checked;
    }
    this.setState(newState);
  }

  savesecuritylevel = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    this.setState({ saveInProgress: true });

    const securitylevelInfo = this.state.securitylevel;
    const formData = {
      sl_name: securitylevelInfo.sl_name,
      tech_only: securitylevelInfo.tech_only,
      user_only: securitylevelInfo.user_only,
      data_only: securitylevelInfo.data_only,
      draftco_only:securitylevelInfo.draftco_only
    };
    let newState = Object.assign({}, this.state);
    if (securitylevelInfo.security_level_id === 0) {

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/securitylevel`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
        data: formData
      })
        .then(response => {
          newState.securitylevel.kr_id = response.kr_id;
          this.setState(newState);
          successToaster("Added");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
    else {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/securitylevel/${securitylevelInfo.security_level_id}`,
        data: formData
      })
        .then(response => {
          successToaster("Updated");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.securitylevel.securitylevel_id === 0 ? "Add Security Level" : "Edit Security Level"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="3" sm="6" lg="3">
                <Label> Name of the Security Level<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.securitylevel.sl_name} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'sl_name')} autoComplete="new-name" />
                {this.validator.message('Name', this.state.securitylevel.sl_name, 'required')}
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="3" sm="6" lg="3">
                <Label> Tech Only</Label>
                <div>
                  <label className="switch">
                    <Input type="checkbox" checked={this.state.securitylevel.tech_only} bsSize="sm" onChange={(e) => this.handleCheckboxChange(e, 'tech_only')} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </Col>
              <Col md="3" sm="6" lg="3">
                <Label> User Only</Label>
                <div>
                  <label className="switch">
                    <Input type="checkbox" checked={this.state.securitylevel.user_only} bsSize="sm" onChange={(e) => this.handleCheckboxChange(e, 'user_only')} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </Col>
              <Col md="3" sm="6" lg="3">
                <Label> Data Only</Label>
                <div>
                  <label className="switch">
                    <Input type="checkbox" checked={this.state.securitylevel.data_only} bsSize="sm" onChange={(e) => this.handleCheckboxChange(e, 'data_only')} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </Col>
              <Col md="3" sm="6" lg="3">
                <Label> Draftco Only</Label>
                <div>
                  <label className="switch">
                    <Input type="checkbox" checked={this.state.securitylevel.draftco_only} bsSize="sm" onChange={(e) => this.handleCheckboxChange(e, 'draftco_only')} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.savesecuritylevel} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.securitylevel.security_level_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

export default SecuritylevelDetail;
