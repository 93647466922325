import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
//import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selKegRoom: {
        kr_id:0,
        kr_name:'',
        number_kegs:0,
        site_id:0,
        site_name:''
    },
    kegRooms: [],
    currPage: 1
};

export const getKegRooms = (site_id) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.kegRooms.kegRooms.length > 0) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={
                site_id:site_id
            }
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    //const site=state.sites.sites.find(p=>p.site_id===site_id);
                    let _kegRooms = [];
                    response.data.forEach((item) => {
                       _kegRooms.push({
                        kr_id:item.kr_id,
                        kr_name:item.kr_name,
                        number_kegs:item.number_kegs,
                        site_id:item.site_id,
                        site_name:item.site_name
                       })
                    });
                    _kegRooms = _kegRooms.sort((a, b) => a.kr_name.localeCompare(b.kr_name));
                    dispatch(handleActionDispatch(actionTypes.SET_KEGROOMS, _kegRooms));
                    resolve(_kegRooms);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_KEGROOM_PAGE, page));
    }
}

export const setSelKegRoom = (kegRoom) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SELECTED_KEGROOM, kegRoom));
    }
}

export const clearSelKegRoom = () => {
    return setSelKegRoom(initialState.selKegRoom);
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}