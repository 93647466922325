import { geocodeByPlaceId } from 'react-google-places-autocomplete';
export const getGeocodeByPlaceId = async (place_id) => {
    let postcode, city, state, country, address1;
    const res = await geocodeByPlaceId(place_id)
    //.then(results => {
    const results = res[0];
    for (const component of results.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
            case "street_number": {
                address1 = `${component.long_name} ${address1}`;
                break;
            }

            case "route": {
                address1 += component.short_name;
                break;
            }

            case "postal_code": {
                postcode = `${component.long_name}`;
                break;
            }

            case "postal_code_suffix": {
                postcode = `${postcode}-${component.long_name}`;
                break;
            }
            case "administrative_area_level_2":
                city = component.long_name;
                break;
            case "administrative_area_level_1": {
                state = component.long_name;
                break;
            }
            case "country":
                country = component.long_name;
                break;
            default:
                break;
        }
    }

    return {
        postcode, city, state, country, address1, results
    }


    // })
    // .catch(error => console.error(error));
};


// export const getTimeZoneByLocation = async (address) => {
//     const long = address.geometry.location.lng();
//     const lat = address.geometry.location.lat();

//     const params = {
//         location: `${lat},${long}`,
//         timestamp: moment().unix(),
//         key: 'AIzaSyBnzbLFA0RGLJzhG6bImIAi-BNMm35kkt8'
//     };

//     const res = await axios(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=${moment().unix()}&key=AIzaSyBnzbLFA0RGLJzhG6bImIAi-BNMm35kkt8`);
//     console.log(res);
//     return res;
// }