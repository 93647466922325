import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import LoaderComponent from '../shared/loader';
import { connect } from 'react-redux';
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AdvancedFilter from "../shared/advancedFilter";
import * as reportSubscriptionActions from './store/subscriptions.action';


class ReportSubscriptionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportSubscriptions: [],
      filteredReportSubscriptions: [],
      fields: [{ value: 'report_name', label: 'Report name', type: 'text' }],
      selReport:null,
      runFrequencies:[{label:'Daily',value:"Daily"},{label:'Weekly',value:"Weekly"},{label:'Monthly',value:"Monthly"}],
      selFrequency:{label:'Daily',value:"Daily"},
      showAdvancedSearch: false,
      showList: true,
      selEditReport:null,
      selEditFrequency:null,
      availableReports:[],
      rowsExpanded:[]
    };
  }
  
  async componentDidMount () {
    await this.props.getAvailableReports();
    await this.props.getReportSubscriptions(this.props.site_id,this.props.user_id);
    this.setState({
      ...this.state,
      selReport:this.props.availableReports.length>0?this.props.availableReports[0]:null
    })
  }

  static getDerivedStateFromProps(props, state) {
    return {
        reportSubscriptions:props.reportSubscriptions,
        availableReports:props.availableReports,
        showList:props.showList,
        filteredReportSubscriptions:props.reportSubscriptions,
        rowsExpanded:props.rowsExpanded,
        selEditReport:props.selEditReport,
        selEditFrequency:props.selEditFrequency,
      }
  }

  toggleAdvancedSearch = () => {
    let newState = Object.assign({}, this.state);
    newState.showAdvancedSearch = !newState.showAdvancedSearch;
    if (!newState.showAdvancedSearch) {
      newState.filteredReportSubscriptions = newState.reportSubscriptions;
    }
    this.setState(newState);
  }

  callBackFromAdvancedSearch = (data) => {
    let newState = Object.assign({}, this.state);
    newState.filteredReportSubscriptions = data;
    this.setState(newState);
  }

  addRs = async() => {
    var item=this.state.reportSubscriptions.filter(s=>s.user_id===this.props.user_id && 
      s.site_id===this.props.site_id && s.run_frequency===this.state.selFrequency.label && s.report_id===this.state.selReport.value);
    if(item.length>0){
        alert('Report schedule already exists')
        return;
    }
    await this.props.addReportSubscription({
          report_subscription_id:this.state.reportSubscriptions.length===0?1:this.state.reportSubscriptions[this.state.reportSubscriptions.length-1].report_subscription_id+1,
          first_name:this.props.userDetails.first_name,
          last_name:this.props.userDetails.last_name,
          report_id:this.state.selReport.value,
          report_name:this.state.selReport.label,
          email:this.props.userDetails.email,
          run_frequency:this.state.selFrequency.label,
          user_id:this.props.user_id,
          site_id:this.props.site_id
        })
  };

  updateRs = async(row) => {
    if(row.report_id===this.state.selEditReport.value && row.run_frequency===this.state.selEditFrequency.value){
      alert('No changes to update');
      return;
    }
    var item=this.state.reportSubscriptions.filter(s=>s.user_id===this.props.user_id && 
      s.site_id===this.props.site_id && s.run_frequency===this.state.selEditFrequency.label && s.report_id===this.state.selEditReport.value);
    if(item.length>0){
        alert('Report schedule already exists')
        return;
    }
    await this.props.updateReportSubscription({
          report_subscription_id:row.report_subscription_id,
          first_name:this.props.userDetails.first_name,
          last_name:this.props.userDetails.last_name,
          report_id:this.state.selEditReport.value,
          report_name:this.state.selEditReport.label,
          email:this.props.userDetails.email,
          run_frequency:this.state.selEditFrequency.label,
          user_id:this.props.user_id,
          site_id:this.props.site_id
        })
  };

  removeRs=async(row)=>{
    var confirmDelete=window.confirm('Are you sure to remove the subscription?');
    if(!confirmDelete) return;
    await this.props.removeReportSubscription({
      report_subscription_id:row.report_subscription_id,
      first_name:this.props.userDetails.first_name,
      last_name:this.props.userDetails.last_name,
      report_id:row.report_id,
      report_name:row.report_name,
      email:this.props.userDetails.email,
      run_frequency:row.run_frequency,
      user_id:this.props.user_id,
      site_id:this.props.site_id
    })
  }


  filterReportSubscriptionsList = (event) => {
    let searchText = event.target.value;
    if (!searchText) {
      this.setState({ filterReportSubscriptionsList: [...this.state.reportSubscriptions] });
    } else {
      searchText = searchText.toLowerCase();
      const filteredAd = this.state.reportSubscriptions.filter(
        (com) =>
          com.gs_name.toLowerCase().indexOf(searchText) >= 0 
      );
      this.setState({ filterReportSubscriptionsList: [...filteredAd] });
    }
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState[inputPropName] = event.target.value;
    }
    else{
      newState[inputPropName]=event;
    }
    this.setState(newState);
  }

  handleEditInputChange = (event, inputPropName) => {
   this.props.updateSubscriptionParams({param:inputPropName,value:event});
  }


  updateStateOnEdit=(row, isExpand, rowIndex, e)=>{
    this.props.editReportSubscription(row);
  }

  cancelEit=()=>{
    this.props.cancelEdit();
  }

  renderEditReportSubscription=(row)=>{
    return(<>
        <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Select Report</Label>
                <Select size="sm" value={this.state.selEditReport}
                  options={this.state.availableReports}
                  onChange={(e) => this.handleEditInputChange(e, 'selEditReport')}></Select>
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Select Frequency</Label>
                <Select size="sm" value={this.state.selEditFrequency}
                  options={this.state.runFrequencies}
                  onChange={(e) => this.handleEditInputChange(e, 'selEditFrequency')}></Select>
              </Col>
              <Col md="4" lg="4">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                   <div style={{height:'0.4in'}}></div>
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={()=>this.updateRs(row)}
                      >
                      Update Report Subscription&nbsp;
                    </Button>
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={()=>this.cancelEit()}
                      >
                      Cancel&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
          </Row>
    </>)
  }

  render() {
    const options = {
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const expandRow = {
      onlyOneExpanding: true,
      parentClassName: 'selected',
      expandByColumnOnly: true,
      renderer: (row) =>{
        debugger
          return this.renderEditReportSubscription(row);
      },
      onExpand:this.updateStateOnEdit,
      expanded:this.state.rowsExpanded,
      showExpandColumn: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          //return <i className="fa fa-caret-down fa-md"></i>;
        }
        //return <i className="fa fa-caret-right fa-md"></i>;
      },
      expandColumnRenderer: ({ expanded }) => {
        
        if (expanded) {
          return (
            <i className="fa fa-edit fa-md"></i>
          );
        }
        return (
          <i className="fa fa-edit fa-md"></i>
        );
      }
    };

    const columns = [
      {
        dataField: "report_subscription_id",
        text: "Id",
        hidden: true,
      },
      {
        dataField: "report_name",
        text: "Report Name",
        sort: true,
      },
      {
        dataField: "run_frequency",
        text: "Frequency",
        sort: true,
      },
      {
        dataField: "first_name",
        text: "First Name",
        sort: false,
      },
      {
        dataField: "last_name",
        text: "Last Name",
        sort: false,
      },
      {
        dataField: "email",
        text: "Email",
        sort: false,
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <button className="btn btn-sm btn-danger" onClick={() => this.removeRs(row)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" style={{ width: '100%' }} id="/admin/reportsubscriptions">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
              <h3 className="dft-primary-color">Report Subscriptions</h3>
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterReportSubscriptionsList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
             
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.reportSubscriptions} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--4">
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Select Report</Label>
                <Select size="sm" value={this.state.selReport}
                  options={this.state.availableReports}
                  onChange={(e) => this.handleInputChange(e, 'selReport')}></Select>
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Select Frequency</Label>
                <Select size="sm" value={this.state.selFrequency}
                  options={this.state.runFrequencies}
                  onChange={(e) => this.handleInputChange(e, 'selFrequency')}></Select>
              </Col>
              <Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                   <div style={{height:'0.4in'}}></div>
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addRs}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add Report Subscription&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="report_subscription_id"
                    data={this.state.filteredReportSubscriptions}
                    pagination={paginationFactory(options)}
                    columns={columns}
                    expandRow={expandRow}
                  />
                ) : this.state.showList === false ? (
                  <LoaderComponent />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    site_id: state.userInfo.security.site_id,
    user_id:state.userInfo.userDetails.user_id,
    availableReports:state.reportSubscriptions.availableReports,
    rowsExpanded:state.reportSubscriptions.rowsExpanded,
    reportSubscriptions:state.reportSubscriptions.reportSubscriptions,
    showList:state.reportSubscriptions.showList,
    userDetails:state.userInfo.userDetails.person,
    selEditReport:state.reportSubscriptions.selEditReport,
    selEditFrequency:state.reportSubscriptions.selEditFrequency,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAvailableReports:()=>dispatch(reportSubscriptionActions.getAvailableReports()),
    getReportSubscriptions:(site_id,user_id)=>dispatch(reportSubscriptionActions.getReportSubscriptions(site_id,user_id)),
    addReportSubscription:(reportSubscription)=>dispatch(reportSubscriptionActions.addReportSubscription(reportSubscription)),
    updateReportSubscription:(reportSubscription)=>dispatch(reportSubscriptionActions.updateReportSubscription(reportSubscription)),
    editReportSubscription:(reportSubscription)=>dispatch(reportSubscriptionActions.editReportSubscription(reportSubscription)),
    updateSubscriptionParams:(paramObject)=>dispatch(reportSubscriptionActions.updateSubscriptionParams(paramObject)),
    removeReportSubscription:(reportSubscription)=>dispatch(reportSubscriptionActions.removeReportSubscription(reportSubscription)),
    cancelEdit:()=>dispatch(reportSubscriptionActions.cancelEdit())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSubscriptionList);
