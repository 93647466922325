import React from "react";
import Select from "react-select";
import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import SensorHubList from "../sensor_hub";
import { successToaster } from '../toaster/toaster';
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";

class McuDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mcu: {
        id: parseInt(props.match.params.id),
        kr_id: props.match.params.krid === undefined ? 0 : parseInt(props.match.params.krid),
        serial_number: "",
        status: {label:"Online",value:"Online"},
        statusOptions:[{label:"Online",value:"Online"},{label:"Offline",value:"Offline"}],
        connectivity: "",
        last_reboot_date: moment(new Date()).format('YYYY-MM-DD'),
        selectedKegRoom: {},
      },
      kegRooms: [],
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  componentDidMount() {
    this.fetchKegRooms();
  }

  fetchKegRooms = () => {
    //let _siteId=parseInt(localStorage.getItem('site_id'))
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom?site_id=${this.props.site_id}`).then(response => {
      if (response.data) {
        let newState = Object.assign({}, this.state);
        let _kegRooms = [];
        response.data.forEach((item) => {
          _kegRooms.push({
            label: item.kr_name,
            value: item.kr_id
          });
        });
        if (this.state.mcu.kr_id !== 0) {
          let _autoKr = _kegRooms.find(p => p.value === this.state.mcu.kr_id);
          newState.mcu.selectedKegRoom = { value: _autoKr.value, label: _autoKr.label };
        }
        _kegRooms = _kegRooms.sort((a, b) => a.label.localeCompare(b.label));
        newState.kegRooms = _kegRooms;
        newState.mcu.selectedKegRoom=_kegRooms[0];
        this.setState(newState, () => { this.fetchMcuDetails() });
      }
    });
  }


  fetchMcuDetails = () => {
    const mcu_id = this.state.mcu.id;
    if (mcu_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/mcu/${mcu_id}`).then(response => {
        if (response.data) {
          let newState = Object.assign({}, this.state);
          newState.mcu.serial_number = response.data.mcu_serial_number;
          const statusOption=newState.mcu.statusOptions.filter(s=>s.value===response.data.mcu_status)
          newState.mcu.status = statusOption.length===0?null:statusOption[0];//response.data.mcu_status;
          newState.mcu.last_reboot_date = response.data.mcu_last_reboot_date;
          newState.mcu.connectivity = response.data.mcu_connectivity;
          if (response.data.kr_id != null) {
            let kegRoom = this.state.kegRooms.find(p => p.value === response.data.kr_id);
            newState.mcu.selectedKegRoom = { value: kegRoom.value, label: kegRoom.label };
          }
          this.setState(newState);
        }
      });
    }
  }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.mcu[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.mcu[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }


  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.mcu[inputPropName] = item;
    this.setState(newState);
  }

  saveMcu = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    } 
    this.setState({ saveInProgress: true });

    const mcuInfo = this.state.mcu;

    const formData = {
      //sensor_id: sensorInfo.id,
      mcu_serial_number: mcuInfo.serial_number,
      mcu_status: mcuInfo.status.value,
      mcu_last_reboot_date: mcuInfo.last_reboot_date,
      mcu_connectivity: mcuInfo.connectivity,
      kr_id: mcuInfo.selectedKegRoom.value
    };

    let newState = Object.assign({}, this.state);

    if (mcuInfo.id === 0) {

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/mcu`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
        data: formData
      })
        .then(response => {
          mcuInfo.id = response.mcu_id;
          newState.mcu = mcuInfo;
          this.setState(newState);
          successToaster("Added");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
    else {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/mcu/${mcuInfo.id}`,
        data: formData
      })
        .then(response => {
          successToaster("Updated");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.mcu.id === 0 ? "Add MCU" : "Edit MCU"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Serial Number<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.mcu.serial_number} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'serial_number')} autoComplete="new-name" />
                {this.validator.message('Serial number', this.state.mcu.serial_number, 'required')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Status<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.mcu.status}
                  options={this.state.mcu.statusOptions}
                  onChange={(e) => this.handleChangeSelection(e, 'status')}
                ></Select>
                {this.validator.message('Select Status', this.state.mcu.status, 'required|selectValidator:""')}
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Last Reboot Date<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.mcu.last_reboot_date} bsSize="sm" type="date" onChange={(e) => this.handleInputChange(e, 'last_reboot_date')} autoComplete="new-name" />
                {this.validator.message('Last Reboot Date', this.state.mcu.last_reboot_date, 'required')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Connectivity</Label>
                <Input value={this.state.mcu.connectivity} bsSize="sm" type="text"
                  onChange={(e) => this.handleInputChange(e, 'connectivity')} autoComplete="new-name" />
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Keg rooms<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.mcu.selectedKegRoom}
                  options={this.state.kegRooms}
                  onChange={(e) => this.handleChangeSelection(e, 'selectedKegRoom')}
                ></Select>
                {this.validator.message('Select Kegroom', this.state.mcu.selectedKegRoom, 'required|selectValidator:""')}
              </Col>
            </Row>

          </CardBody>
          <CardFooter>
            <Row>
              <Col md="2" lg="2" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveMcu} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.mcu.id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
        {/* {this.state.mcu.id === 0 ? "" : (
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Row>
                <h3 className="dft-primary-color">Sensor Hubs</h3>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <SensorHubList mcu_id={this.state.mcu.id} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )} */}
        {
          this.props.selMcu.id!==0?
          <>
            <SensorHubList />
          </>
          :""
        }

      </>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    selMcu:state.mcus.selMcu
  }
}

export default connect(mapStateToProps, null)(McuDetails);
