import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import moment from "moment";
//import { successToaster } from '../../toaster/toaster';

export const initialState = {
    selSensor: {
        id:0,
        serial_number:'',
        status:'',
        deployed_date:'',
        volume_measured:'',
        port:0
    },
    sensors: [],
    freeSensors:[],
    currPage: 1
};


export const getSensors = (forceReload) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.sensors.sensors.length > 0 && !forceReload) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={};       
            if(state.sensorHubs.selSensorHub.id!==0){
                siteParams.sh_id=state.sensorHubs.selSensorHub.id
            }
          
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensors`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    let _sensors = [];
                    response.data.forEach((item) => {
                        _sensors.push({
                            id: item.sensor_id,
                            serial_number: item.sensor_serial_number,
                            status: item.sensor_status,
                            deployed_date: moment(item.sensor_deployed_date).format("YYYY-MM-DD"),
                            volume_measured: item.sensor_volume_measured,
                            port: item.port
                       })
                    });
                    _sensors = _sensors.sort((a, b) => a.serial_number.toString().localeCompare(b.serial_number.toString(), 'en', { numeric: true }));
                    dispatch(handleActionDispatch(actionTypes.SET_SENSOR, _sensors));
                    resolve(_sensors);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const getAvailableSensors = (sh_id) => {
    //const state = store.getState();
    return (dispatch) => {  
        return new Promise(function (resolve, reject) {
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensors/findAvailableSensors`,{params:{sh_id:sh_id}}).then((response) => {
                if (response.data !== undefined) {
                    let _sensors = [];
                    response.data.forEach((item) => {
                        _sensors.push({
                            id: item.sensor_id,
                            serial_number: item.sensor_serial_number,
                            status: item.sensor_status,
                            deployed_date: moment(item.sensor_deployed_date).format("YYYY-MM-DD"),
                            volume_measured: item.sensor_volume_measured,
                            port: item.port
                       })
                    });
                    _sensors = _sensors.sort((a, b) => a.serial_number.toString().localeCompare(b.serial_number.toString(), 'en', { numeric: true }));
                    dispatch(handleActionDispatch(actionTypes.SET_FREE_SENSOR, _sensors));
                    resolve(_sensors);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}


export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_SENSOR_PAGE, page));
    }
}

export const setSelSensor = (sensor) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SELECTED_SENSOR, sensor));
    }
}

export const clearSelKeg = () => {
    return setSelSensor(initialState.selSensor);
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}