import React from 'react'
import { Link } from 'react-router-dom';
import routes from '../../routes/index';

function CustomBreadCrumb({ links }) {

    const dashboardRoute = routes.find(r => r.name === "Dashboard")
    return (
        <div>
            <Link to={dashboardRoute.layout + dashboardRoute.path} key="dashboard">{dashboardRoute.name}</Link>
            {
                links.map(l => {
                    let urls = l.split('/');
                    let parsedUrl = urls.length < 4 ? ('/' + urls[1] + '/' + urls[2]) : ('/' + urls[1] + '/' + urls[2] + '/');
                    const route = routes.find(r => (r.layout + r.path).includes(parsedUrl))
                    return route.name !== "Dashboard" && <React.Fragment key={route.path}>
                        &nbsp;/&nbsp;
                        <Link to={l}>{route.name}</Link>
                    </React.Fragment>
                })
            }
        </div>
    )
}

export default CustomBreadCrumb
