import React from "react";
import Select from "react-select";
import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import { successToaster } from '../toaster/toaster';
import queryString from 'query-string'
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";
import * as lineActions from './store/line.actions';

class LineDetails extends React.Component {
  constructor(props) {
    super(props);
    const qs = queryString.parse(this.props.location.search);
    this.state = {
      line: {
        id: parseInt(props.match.params.id),
        kr_id: props.match.params.kr_id === undefined ? 0 : parseInt(props.match.params.kr_id),
        selected_keg: {},
        selected_sensor: {},
        connected: false,
        volume_poured: 0.00,
        line_last_clean_date: "",
        line_coupler: "",
        line_status: "",
        pressure: 0,
        line_length: 0,
        local_line_number: parseInt(qs.localline) + 1
      },
      kegs: [],
      sensors: [],
      allSensors: [],
      site_id: this.props.site_id,
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            console.log(val);
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  componentDidMount() {
    this.fetchSensors();
  }

  fetchSensors = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/sensors/getSensorsBySite?site_id=${this.props.site_id}`).then(response => {
      if (response.data) {
        let newState = Object.assign({}, this.state);
        let _sensors = [];
        let _allSensors = [];
        response.data.forEach((item) => {
          _allSensors.push({
            label: item.sensor_id +'-' +item.sensor_serial_number,
            value: item.sensor_id
          });
          _sensors.push({
            label: item.sensor_id +'-' +item.sensor_serial_number,
            value: item.sensor_id
          });
          // const existingSensorLine = item.sensorlines.find(s => s.site_id === parseInt(newState.site_id));
          // if (item.sensorlines.length === 0 || existingSensorLine === undefined) {
           
          // }
        });
        newState.sensors = _sensors.sort((a, b) => a.label.localeCompare(b.label));
        newState.allSensors = _allSensors.sort((a, b) => a.label.localeCompare(b.label));
        newState.line.selected_sensor=_sensors[0];
        this.setState(newState, () => { this.fetchKegs() });
      }
    });
  }

  fetchKegs = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg/findAvailableKegs?kr_id=${this.state.line.kr_id}`).then(response => {
      if (response.data) {
        let newState = Object.assign({}, this.state);
        let _kegs = [];
        response.data.forEach((item) => {
          _kegs.push({
            label: item.name + "(" + item.volume + ")",
            value: item.keg_id
          });
        });
        newState.kegs = _kegs.sort((a, b) => a.label.localeCompare(b.label));
        newState.line.selected_keg=_kegs[0];
        this.setState(newState, () => { this.fetchLineDetails() });
      }
    });
  }

  fetchLineDetails = () => {
    const line_id = this.state.line.id;
    if (line_id !== 0) {
      axios(`${process.env.REACT_APP_API_BASE_URL}/api/line/${line_id}`).then(response => {
        if (response.data) {
          let newState = Object.assign({}, this.state);
          newState.line.line_coupler = response.data.line_coupler;
          newState.line.line_status = response.data.line_status;
          newState.line.connected = response.data.connected;
          newState.line.line_last_clean_date = response.data.line_last_clean_date??"";
          newState.line.volume_poured = response.data.volume_poured;
          newState.line.pressure = response.data.pressure;
          newState.line.line_length = response.data.line_length;
          newState.line.local_line_number = response.data.local_line_number ? response.data.local_line_number : this.state.line.local_line_number;
          if (response.data.sensor_id != null) {
            let _sensor = this.state.allSensors.find(p => p.value === response.data.sensor_id);
            newState.line.selected_sensor = _sensor===undefined?{value: response.data.sensor.sensor_id, label: response.data.sensor.sensor_id+'-'+response.data.sensor.sensor_serial_number}:{ value: _sensor.value, label: _sensor.label };
          }
          if (response.data.keg_id != null) {
            //let _keg = this.state.kegs.find(p => p.value === response.data.keg_id);
            let _keg = response.data.keg;
            //if(_keg!==undefined)
            newState.line.selected_keg = { value: _keg.keg_id, label: _keg.db_keg.name + "(" + _keg.volume + ")" };
          }
          this.setState(newState);
        }
      });
    }
  }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.line[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.line[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }


  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    newState.line[inputPropName] = item;
    this.setState(newState);
  }

  saveLine = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    } 
    this.setState({ saveInProgress: true });

    const lineInfo = this.state.line;
    const formData = {
      keg_id: lineInfo.selected_keg.value,
      sensor_id: lineInfo.selected_sensor.value,
      connected: false,
      volume_poured: 0,
      line_last_clean_date: new Date().toUTCString(),
      line_coupler: lineInfo.line_coupler,
      line_status: 'connected',
      site_id: this.state.site_id,
      pressure: lineInfo.pressure,
      local_line_number: lineInfo.local_line_number,
      line_length: lineInfo.line_length
    };

    if (lineInfo.id === 0) {

      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/line`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
        data: formData
      })
        .then(response => {
          successToaster("Added");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
    else {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/line/${lineInfo.id}`,
        data: formData
      })
        .then(response => {
          successToaster("Updated");
          this.setState({ saveInProgress: false });
          this.handleCancel();
        });
    }
  }

  handleCancel = async() => {
    await this.props.getLines();
    this.props.setSelLine(Object.assign({}, this.props.lines[0]));
    this.props.history.goBack();
  }

  handleCheckboxChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.line[inputPropName] = event.target.checked;
    }
    this.setState(newState);
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">
            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.line.id === 0 ? "Add Line : " + this.state.line.local_line_number : "Edit Line : " + this.state.line.local_line_number}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="4" sm="4" lg="4">
                <Label> Line Coupler<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.line.line_coupler} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'line_coupler')} autoComplete="new-name" />
                {this.validator.message('Line coupler', this.state.line.line_coupler, 'required')}
              </Col>
              <Col md="4" sm="4" lg="4">
                <Label> Line Pressure (psi)<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.line.pressure} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'pressure')} autoComplete="new-name" />
                {this.validator.message('Pressure', this.state.line.pressure, 'required')}
              </Col>
              <Col md="4" sm="4" lg="4">
                <Label> Line Length (meters)<span style={{color:'red'}}>*</span></Label>
                <Input value={this.state.line.line_length} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'line_length')} autoComplete="new-name" />
                {this.validator.message('Line length', this.state.line.line_length, 'required')}
              </Col>
            </Row>
            {/* <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Last Clean Date</Label>
                <Input value={this.state.line.line_last_clean_date} size="sm" type="date" onChange={(e) => this.handleInputChange(e, 'line_last_clean_date')} autoComplete="new-name" />
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Volume Poured (ml)</Label>
                <Input value={this.state.line.volume_poured} size="sm" type="number"
                  min="0" max="10" step="0.25"
                  onChange={(e) => this.handleInputChange(e, 'volume_poured')} autoComplete="new-name" />
              </Col>
            </Row> */}
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label>Sensor<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.line.selected_sensor}
                  options={this.state.sensors}
                  onChange={(e) => this.handleChangeSelection(e, 'selected_sensor')}
                ></Select>
                {this.validator.message('Select Sensor', this.state.line.selected_sensor, 'required|selectValidator:""')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label>Keg<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.line.selected_keg}
                  options={this.state.kegs}
                  onChange={(e) => this.handleChangeSelection(e, 'selected_keg')}
                ></Select>
                {this.validator.message('Select Keg', this.state.line.selected_keg, 'required|selectValidator:""')}
              </Col>
              {/* <Col md="4" sm="6" lg="4">
                <Label>Connected</Label>
                <div>
                  <label class="switch">
                    <Input type="checkbox" checked={this.state.line.connected} size="sm" type="checkbox" onChange={(e) => this.handleCheckboxChange(e, 'connected')} />
                    <span class="slider round"></span>
                  </label>
                </div>
              </Col> */}
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="2" lg="2" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveLine} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.line.id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    lines: state.lines.lines,
    selLine:state.lines.selLine,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrPage: (page) => dispatch(lineActions.setCurrPage(page)),
    getLines:()=>dispatch(lineActions.getLines()),
    setSelLine:(line)=>dispatch(lineActions.setSelLine(line))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineDetails);
