import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
//import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import LoaderComponent from '../shared/loader';
import AdvancedFilter from "../shared/advancedFilter";
import * as kegRoomActions from '../kegroom/store/kegroom.actions';
import * as sitesActions from '../sites/store/site.actions';
import * as mcuActions from './store/mcu.actions';
import SensorHubList from "../sensor_hub";
import * as shActions from '../sensor_hub/store/sensor_hub.actions';

class McuList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mcus: [],
      //kr_id: this.props.kr_id === undefined ? 0 : this.props.kr_id,
      filteredMcusList: [],
      fields: [{ value: 'id', label: 'Id', type: 'int' }, { value: 'serial_number', label: 'Serial Number', type: 'text' }, { value: 'connectivity', label: 'Connectivity', type: 'text' }, { value: 'kr_name', label: 'Keg Room', type: 'text' }],
      showAdvancedSearch: false,
      //expanded: [],
      showList: false,
      //depthLevel: this.props.depth_level ? this.props.depth_level : 0,
      selMcu: null,
      collapse: false
    };

    // Create the child instance using react createRef
    //this.refSensorHubs = React.createRef();
  }

  
  async componentDidMount () {
    var selected_site_id = this.props.site_id;
    //await this.props.getPersons();
    //await this.props.getCustomers(selected_site_id);
    //await this.props.getSites(selected_site_id)
    //this.props.setSelSite(Object.assign({}, this.props.sites[0]));
    //await this.props.getKegRooms(selected_site_id);
    //this.props.setSelKegRoom(Object.assign({}, this.props.kegRooms[0]));
    await this.props.getMcus(selected_site_id,true);
    if(this.props.selMcu.id===0){
      this.props.setSelMcu(Object.assign({}, this.props.mcus[0]));
    }
  }

  static getDerivedStateFromProps(props, state) {
   
    if (state.mcus.length === 0) {
      return {
        mcus:props.mcus,
        selMcu:props.selMcu,
        filteredMcusList: props.mcus,
        showList: props.mcus.length>0
      }
    }
    return null;
  }


  toggleAdvancedSearch = () => {
    let newState = Object.assign({}, this.state);
    newState.showAdvancedSearch = !newState.showAdvancedSearch;
    if (!newState.showAdvancedSearch) {
      newState.filteredMcusList = newState.mcus;
    }
    this.setState(newState);
  }

  callBackFromAdvancedSearch = (data) => {
    let newState = Object.assign({}, this.state);
    newState.filteredMcusList = data;
    this.setState(newState);
  }

  // fetchMcus = () => {
  //   //var selected_site_id = parseInt(localStorage.getItem("site_id"));
  //   var selected_site_id = this.props.site_id;

  //   if (this.state.kr_id) {
  //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/mcu`, { params: { kr_id: this.state.kr_id, site_id: selected_site_id } }).then(response => {

  //       if (response.data) {
  //         this.setMcuModel(response.data);
  //       }
  //     });
  //   } else {
  //     if (this.state.depthLevel === 0) {
  //       axios(`${process.env.REACT_APP_API_BASE_URL}/api/mcu`, { params: { site_id: selected_site_id } }).then(response => {
  //         if (response.data) {
  //           this.setMcuModel(response.data);
  //         }
  //       });
  //     }
  //     else {
  //       this.setMcuModel([]);
  //     }
  //   }
  // }

  // setMcuModel = (mcus) => {
  //   //let newState = Object.assign({}, this.state);
  //   var mcusData = [];
  //   mcus.forEach((item) => {
  //     mcusData.push({
  //       id: item.mcu_id,
  //       serial_number: item.mcu_serial_number,
  //       status: item.mcu_status,
  //       last_reboot_date: item.mcu_last_reboot_date,
  //       connectivity: item.mcu_connectivity,
  //       kr_name: item.kegroom !== undefined ? item.kegroom.kr_name : ""
  //     });
  //   });

  //   mcusData = mcusData.sort((a, b) => a.serial_number.toString().localeCompare(b.serial_number.toString(), 'en', { numeric: true }));

  //   if (this.state.depthLevel < 2 && mcusData.length) {
  //     this.loadChildComponents(mcusData[0].id);
  //   }
  //   else {
  //     this.loadChildComponents(0);
  //   }

  //   this.setState({ mcus: mcusData, filteredMcusList: mcusData, showList: true });
  // }

  // fetchMcusFromParent(krId) {
  //   this.setState({
  //     kr_id: krId
  //   });
  //   this.fetchMcus();
  // }

  addMcu = () => {
    window.location = `#/admin/mcu/0/kr/${this.props.selKegRoom.kr_id}`;
  };

  filterMcusList = (event) => {
    let searchText = event.target.value;
    let filteredmcus = [];

    if (!searchText) {
      filteredmcus = this.state.mcus;
      //this.setState({ filteredMcusList: [...this.state.mcus] });
    } else {
      searchText = searchText.toLowerCase();
      filteredmcus = this.state.mcus.filter(
        (com) =>
          com.serial_number.toLowerCase().indexOf(searchText) >= 0 ||
          com.connectivity.toLowerCase().indexOf(searchText) >= 0 ||
          com.status.toLowerCase().indexOf(searchText) >= 0
      );
      //this.setState({ filteredMcusList: [...filteredmcus] });
    }

    // if (this.state.depthLevel < 2 && filteredmcus.length) {
    //   this.loadChildComponents(filteredmcus[0].id);
    // }
    // else {
    //   this.loadChildComponents(0);
    // }

    this.setState({ filteredMcusList: [...filteredmcus] });
  };

  // loadChildComponents = (mcuId) => {
  //   let newState = Object.assign({}, this.state);
  //   newState.selectedMCUId = mcuId;
  //   this.setState(newState);
  //   if (this.state.depthLevel < 2 && this.refSensorHubs.current !== null) {
  //     this.refSensorHubs.current.fetchSensorHubsFromParent(mcuId);
  //   }
  // }

  setRowSelected = (elem) => {
    for (let i = 0; i < elem.parentNode.getElementsByTagName('tr').length; i++) {
      elem.parentNode.getElementsByTagName('tr')[i].className = '';
    }
    elem.className = 'selected';
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  setMcu=async(mcu)=>{
    this.props.setSelMcu(Object.assign({},mcu));
    await this.props.getSensorHubs(this.props.site_id,true)
    this.props.setSelSensorHub(Object.assign({}, this.props.sensorHubs[0]))
  }

  render() {

    const options = {
      //sizePerPageRenderer,
      sizePerPage: 10,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    };

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setMcu(row)
        this.setRowSelected(e.currentTarget);
      }
    };

    const rowClasses = (row, rowIndex) => {
      return row.id===this.props.selMcu.id?'selected':''
    };

    const columns = [
      {
        dataField: "id",
        text: "Mcu Id",
        hidden: true,
      },
      {
        dataField: "serial_number",
        text: "Serial Number",
        sort: true,
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
      },
      {
        dataField: "last_reboot_date",
        text: "Last Reboot Date",
        sort: true,
      },
      {
        dataField: "connectivity",
        text: "Connectivity",
        sort: true,
      },
      {
        dataField: "kr_name",
        text: "Keg Room Name",
        sort: true,
      },
      {
        dataField: "manage",
        text: "Manage",
        sort: false,
        isDummyField: true,
        editable: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link
                className="btn btn-sm btn-info"
                to={`/admin/mcu/${row.id}/kr/${this.props.selKegRoom.kr_id}`}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Card className="bg-secondary shadow border-0" id="/admin/mcu">
          <CardHeader className="bg-transparent py-3 border-0">
            <Row className="mb-4">
              <Col lg="6" md="6" sm="12">
              <h3 className="dft-primary-color">MCU</h3>
                <Row>
                  <Col lg="8" md="8">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="Search"
                          type="text"
                          onChange={this.filterMcusList}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3" lg="3">
                <Button className="btn btn-primary btn-sm" onClick={this.toggleAdvancedSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i> &nbsp;
                  Advanced Search&nbsp;
                </Button>
              </Col>
              <Col md="3" lg="3">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mr-5">
                    <Button
                      className="btn btn-outline-default btn-sm"
                      onClick={this.addMcu}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                      Add&nbsp;
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {
              this.state.showAdvancedSearch ?
                <Row >
                  <Col lg="12" md="12" sm="12">
                    <AdvancedFilter data={this.state.mcus} datacallback={this.callBackFromAdvancedSearch}
                      datafields={this.state.fields} />
                  </Col>
                </Row>
                :
                ""
            }
          </CardHeader>
          <CardBody className="px-lg-2 py-lg-2 mt--5">
            <Row>
              <Col lg="12" m="12" className="dft-horizontal-scroll-bar">
                {this.state.showList === true ? (
                  <BootstrapTable
                    classes="custom-table-css dft-bg-primary-color"
                    striped
                    hover
                    condensed
                    keyField="id"
                    data={this.state.filteredMcusList}
                    pagination={paginationFactory(options)}
                    columns={columns}
                    rowEvents={rowEvents}
                    rowClasses={rowClasses}
                  />
                ) : this.state.showList === false ? (
                  <LoaderComponent />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>

        {
          this.props.selMcu.id!==0?
          <>
            <SensorHubList />
          </>
          :""
        }

        {/* {this.state.depthLevel < 2 ? (
          <Card style={{ width: '100%' }}>
            <CardBody className="px-lg-5 py-lg-5">
            
              <Row>
                <Col sm="12" md="12" lg="12">
                  <SensorHubList mcu_id={this.state.selectedMCUId} ref={this.refSensorHubs} depth_level={this.state.depthLevel + 1} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        ) : ""} */}
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    isSuperUser:state.userInfo.userDetails?state.userInfo.userDetails.superuser:false,
    mcus: state.mcus.mcus,
    selMcu:state.mcus.selMcu,
    currPage: state.mcus.currPage,
    selKegRoom:state.kegRooms.selKegRoom,
    sensorHubs:state.sensorHubs.sensorHubs
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSites:(site_id,forceReload)=>dispatch(sitesActions.getSites(site_id,forceReload)),
    setCurrPage: (page) => dispatch(mcuActions.setCurrPage(page)),
    getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
    setSelKegRoom:(kr)=>dispatch(kegRoomActions.setSelKegRoom(kr)),
    getMcus:(site_id)=>dispatch(mcuActions.getMcus(site_id)),
    setSelMcu:(mcu)=>dispatch(mcuActions.setSelMcu(mcu)),
    getSensorHubs:(site_id,forceReload)=>dispatch(shActions.getSensorHubs(site_id,forceReload)),
    setSelSensorHub:(sh)=>dispatch(shActions.setSelSensorHub(sh))
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(McuList);
