import { KEG_BY_LINE,UPDATE_CONFIG_TREE,SHOW_KEG_HISTORY, SHOW_SITE_KEGS, SET_CURR_PAGE, CLEAR,SET_LINES_GLYCOL,SET_PORT,SET_LAST_POUR_ITEM, SET_WIDGET, SET_HIGH_ALARMS, SET_MONTHLY_VOLUME_POURED} from '../../../constants/actionTypes';
import store from '../../../redux/store';
import axios from "axios";
import moment from 'moment';

export const initialState = {
	kegs: [],
	selKeg: null,
	kegsHistory: [],
	highAlarms:[],
	showKegList:false,
	linesForGlycol:[],
	showKegHistoryList:false,
	widgetKey:"pour",
	port:0,
	kegs_by_line:"",
	monthlyVolumePoured:[],
	last_pour_item:{
		last_pour_date:"",
		volume_poured:"",
		beer_name:"",
		pour_volume:0,
		pour_id:0,
		line:0
	  }
};

export const setPort=(port)=>{
	return (dispatch) => {
		dispatch(handleActionDispatch(SET_PORT, port));
	}
}

export const setKegByLine=(key)=>{
	return (dispatch) => {
		dispatch(handleActionDispatch(KEG_BY_LINE, key));
	}
}

export const setWidget=(key)=>{
	return (dispatch) => {
		dispatch(handleActionDispatch(SET_WIDGET, key));
	}
}

export const setLastPourItem=(item)=>{
	return (dispatch) => {
		dispatch(handleActionDispatch(SET_LAST_POUR_ITEM, item));
	}
}

export const getKegs = (kr_id) => {
	const state = store.getState();
	return (dispatch) => {
		if (state.monitor.kegs.length > 0) {
			return;
		}
		//axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg`, { params: { site_id: localStorage.getItem("site_id") } }).then((response) => {
		//var selected_site_id = state.userInfo.security.site_id;
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg/KrInventory`, { params: { kr_id: kr_id } }).then((response) => {
			
			if (response.data !== undefined) {
				let _kegs = [];
				response.data.forEach((item) => {
					var logo = item.logo.includes("https://")
						? item.logo
						: `${process.env.REACT_APP_CDN_BASE_URL}/assets/images/${item.logo}`;
					//var _pour_date=item.local_end_period===null?"":item.local_end_period.substring(0,10)+" "+item.local_end_period.substring(11,19);
					_kegs.push({
						beerName: item.short_name==null?item.beer_name.substring(0,15):item.short_name,
						connected_start_local:item.connected_date===null?"":moment(item.connected_date + ' '+item.connected_time).format('MM/DD/yyyy hh:mm'),
						//volume: item.size.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
						volume:(item.size/1000).toFixed(0),
						percent_remaining: (item.percent_remaining * 100).toFixed(2),
						volume_poured: item.volume_poured ? (item.volume_poured/1000).toFixed(2) : 0,
						number_pours: item.number_pours,
						temp_running_total: item.avg_temp.toFixed(2),
						keg_id: item.keg_id,
						kr_id: item.kr_id,
						number_pints:(item.volume_poured/item.mlperpint).toFixed(2),
						days_to_empty:item.days_to_empty>1000?0:item.days_to_empty,
						last_pour_date:(item.local_end_date===null || item.local_end_time===null)?"":moment(item.local_end_date+" "+item.local_end_time).format("MM/DD/yyyy HH:mm"),
						logo: logo,
						connected: item.connected,
						line:item.line_number,
						//kr_out_date: item.kr_out_date
					})
				});
				_kegs.sort((a, b) => {
					if(a.last_pour_date===""){
						return 1;
					}
					else if(b.last_pour_date===""){
						return -1;
					}
					return new Date(b.last_pour_date) - new Date(a.last_pour_date);
				})
				//_kegs.sort((a, b) => a.line > b.line ? 1 : -1)
				dispatch(handleActionDispatch(SHOW_SITE_KEGS, _kegs));
			}
		});
	}
}

export const getLinesForGlycol = (kr_id) => {
	const state = store.getState();
	return (dispatch) => {
		if (state.monitor.linesForGlycol.length > 0) {
			return;
		}
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/GetLinesForGlycol`, { params: { kr_id: kr_id } }).then((response) => {
			if (response.data !== undefined) {
				let _lines = [];
				var counter=1;
				response.data.forEach((item) => {
					_lines.push({
						label:"Line - "+counter,
						value:item.port
					})
					counter++;
				})
				dispatch(handleActionDispatch(SET_LINES_GLYCOL, _lines));
			}
		});
	}
}


export const getKegsHistory = (site_id) => {
	const state = store.getState();
	return (dispatch) => {
		if (state.monitor.kegsHistory.length > 0) {
			return;
		}
		if(!state.userInfo.security.site_id){
			return;
		}
		//axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg/KrInventoryHistory`, { params: { site_id: localStorage.getItem("site_id") } }).then((response) => {
		//var selected_site_id = state.userInfo.security.site_id;
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg/KrInventoryHistory`, { params: { site_id: site_id } }).then((response) => {
			if (response.data !== undefined) {
				let _kegsHistory = [];
				response.data.forEach((item) => {
					var _service_duration='';
					if(item.on_service_duration.months){
						_service_duration+=` ${item.on_service_duration.months} months`;
					}
					if(item.on_service_duration.days){
						_service_duration+=` ${item.on_service_duration.days} days`;
					}
					if(item.on_service_duration.hours){
						_service_duration+=` ${item.on_service_duration.hours} hours`;
					}
					if(item.on_service_duration.minutes){
						_service_duration+=` ${item.on_service_duration.minutes} minutes`;
					}
					_kegsHistory.push({
						beerName: item.beer_name,
						connected_start_local: moment(item.connected_start_local).format('MM/DD/yyyy hh:mm'),
						connected_end_local: moment(item.connected_end_local).format('MM/DD/yyyy hh:mm'),
						on_service_duration: _service_duration,
						//${item.on_service_duration.seconds===undefined?'0':item.on_service_duration.seconds} seconds`,
						percent_remaining: (item.percent_remaining * 100).toFixed(2),
						volume_poured: item.volume_poured.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
						avg_temp: item.avg_temp.toFixed(2),
						keg_id: item.keg_id,
						kr_id: item.kr_id,
						line_id: item.line_id,
						local_line_number: item.local_line_number
					})
				});
				//_kegsHistory.sort((a, b) => a.local_line_number > b.local_line_number ? 1 : -1)
				_kegsHistory.sort((a, b) => a.beerName.localeCompare(b.beerName) || new Date(a.connected_start_local) - new Date(b.connected_start_local)  || new Date(a.connected_end_local) - new Date(b.connected_end_local));
				dispatch(handleActionDispatch(SHOW_KEG_HISTORY, _kegsHistory));
			}
		});
	}
}

export const getHighAlarms = (kr_id,fromDate,toDate) => {
	const state = store.getState();
	return (dispatch) => {
		if (state.monitor.highAlarms.length > 0) {
			return;
		}
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/alarm/getHighAlarms`, { params: { kr_id: kr_id,from_date:fromDate,to_date:toDate } }).then((response) => {
			if (response.data !== undefined) {
				let _highAlarms = [],counter=1;
				response.data.forEach((item) => {
					_highAlarms.push({
						view_display_name:item.view_display_name,
						high_alarm_count:item.high_alarm_count,
						id:counter
					})
					counter++;
				});
				dispatch(handleActionDispatch(SET_HIGH_ALARMS, _highAlarms));
			}
		});
	}
}

export const getMonthlyVolumePoured = (kr_id,current_year) => {
	const state = store.getState();
	return (dispatch) => {
		if (state.monitor.monthlyVolumePoured.length > 0) {
			return;
		}
		var fromDate=moment(new Date()).add(-12,'month').startOf('month').format("YYYY-MM-DD");
        var toDate=moment(new Date()).endOf('month').format("YYYY-MM-DD");
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/VolumeYearlyReport`, { params: { kr_id: kr_id,from_date:fromDate,to_date:toDate } }).then((response) => {
			if (response.data !== undefined) {
				dispatch(handleActionDispatch(SET_MONTHLY_VOLUME_POURED, response.data));
			}
		});
	}
}

export const updateConfigTree = () => {
	return (dispatch) => {
		axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/RefreshConfigTree`).then((response) => {
			if (response.data !== undefined) {
				dispatch(handleActionDispatch(UPDATE_CONFIG_TREE, {}));
			}
		});
	}
}

export const setCurrPage = (page) => {
	return (dispatch) => {
		dispatch(handleActionDispatch(SET_CURR_PAGE, page));
	}
}

export const updateKegs = (kegs) => {
	return (dispatch) => {
		dispatch(handleActionDispatch(SHOW_SITE_KEGS, kegs));
	}
}

export const clearStates = () => {
	return (dispatch) => {
		dispatch(handleActionDispatch(CLEAR, initialState));
	}
}

export const handleActionDispatch = (actionName, data) => {
	return {
		type: actionName,
		data: data
	}
}
