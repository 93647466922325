import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./site.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SELECTED_SITE):
            return setSelSite(state, action);
        case (actionTypes.SET_SITES):
            return setSites(state, action);
        case (actionTypes.SET_CURR_CUSTOMER_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        case (actionTypes.SET_SITE_COUNTRIES):
            return setCountries(state, action);
        case (actionTypes.SET_SITE_CITIES):
            return setCities(state, action);
        default:
            return state;
    }

};

const setCountries = (state, action) => {
    return updateObject(state, {
        countries: action.data
    });
};

const setCities = (state, action) => {
    return updateObject(state, {
        cities: action.data
    });
};

const setSelSite = (state, action) => {
    return updateObject(state, {
        selSite: action.data
    });
};

const setSites = (state, action) => {
    return updateObject(state, {
        sites: action.data
    });
};

// set current page number of beers list page 
const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selSite: initialState.selSite,
        sites: initialState.sites,
        currPage: initialState.currPage
    });
};

export default reducer;