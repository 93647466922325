import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
import axios from 'axios';
//import moment from 'moment';
import {convertValueByMetric} from '../../../utility/units-conversion'
import * as kegRoomActions from '../../kegroom/store/kegroom.actions';
import moment from 'moment';

class LineCleaningWidget extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            lineChart:{
                series:[],
                options:{}
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
      return null;
  }

   async componentDidMount(){
      var selected_site_id = this.props.site_id;
      await this.props.getKegRooms(selected_site_id);
      this.props.setSelKegRoom(Object.assign({}, this.props.kegRooms[0]));
        this.getChartData()
    }

    getChartData=()=>{
        const units=this.props.units;
        var params={
            kr_id:this.props.selKegRoom.kr_id,
            //line_cleaning_status:this.props.lineCleaningStatus
        }
        let newState=this;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/line/getLineCleaningData`,{params:params})
        .then(function(response){
            if(response.data!==undefined){
              var series=[];
              var groupByCleanDate=  response.data.reduce(function (r, a) {
                  var cleanDate=moment(a.cleaning_start_time).format("MM/DD/YYYY")
                  r[cleanDate] = r[cleanDate] || [];
                  r[cleanDate].push(a);
                  return r;
              }, Object.create(null));

              var lines=[];
              response.data.forEach((item)=>{
                if(lines.indexOf(item.line_number)===-1){
                  lines.push(item.line_number)
                }
              })
              var categories=[],initial_clean_date="";
              initial_clean_date=moment(Object.keys(groupByCleanDate)[0]).add('days',-3).format("MM/DD/YYYY");
            
              var keys=Object.keys(groupByCleanDate);
              keys.sort(function(a,b){
                return new Date(a) - new Date(b);
              });
              console.log(keys);
              lines.forEach((item=>{
                var s={name:'Line Cleaning',color:"#007FFF",data:[]}
                s.data.push({x:initial_clean_date, y:0});
                for(var i=0;i<keys.length;i++){
                  var cleanDate=keys[i];
                  // eslint-disable-next-line no-loop-func
                  var data=response.data.find(s=>s.line_number===item && moment(s.cleaning_start_time).format("MM/DD/YYYY")===cleanDate);
                  s.data.push({
                    x:cleanDate, 
                    y:item,
                    fillColor:data===undefined||data.cleaning_volume===null?"pink":"#007FFF",
                    moreInfo:{
                      beer_name:data===undefined?"":data.beer_name,
                      volume:data===undefined||data.cleaning_volume===null?0:`${convertValueByMetric(data.cleaning_volume,units,"vol").toFixed(2)} ${units==="metric"?"ML":"OU"}`,
                      temp:data===undefined||data.cleaning_temperature===null?0:`${convertValueByMetric(data.cleaning_temperature,units,"c").toFixed(2)} ${units==="metric"?"C":"F"}`
                    }
                  });
                }
                series.push(s)
              }))
                var chartData={
                  series: series,
                    options: {
                      //colors: ["#49C0F2", "#28C76F"],
                      chart: {
                        type: 'scatter',
                        height: 350,
                        zoom: {
                          enabled: false,
                          //type: "xy"
                        },
                        toolbar: {
                          show: false
                        }
                      },
                      title: {
                        text: `Line Cleaning`,
                        align: 'left'
                      },
                      tooltip:{
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                          if(data.moreInfo===undefined) return ""
                          var line_clean_info=`<div class="apexcharts-tooltip-y-group">
                            <span class="apexcharts-tooltip-text-y-value">Beer:</span>&nbsp;
                            <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.beer_name}</span>
                          </div>
                          <div class="apexcharts-tooltip-y-group">
                              <span class="apexcharts-tooltip-text-y-value">Volume:</span>&nbsp;
                              <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.volume}</span>
                            </div>
                            <div class="apexcharts-tooltip-y-group">
                              <span class="apexcharts-tooltip-text-y-value">Temperature:</span>&nbsp;
                              <span class="apexcharts-tooltip-text-y-value">${data.moreInfo.temp}</span>
                            </div>`
                            return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">Line - ${data.y} (${moment(data.x).format("ll")})</div>
                            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                            ${line_clean_info}                                
                           </div></div>`
                        }
                    },
                      xaxis: {
                        type: "datetime",
                        categories: categories,
                        //categories: [last_clean_date],
                        labels: {
                          formatter: function(value, timestamp, index) {
                            return value
                          },
                          style: {
                            fontSize: "10px"
                          }
                        },
                        //tickAmount: 10,
                        //tickPlacement: 'between'
                      },
                      yaxis: {
                        tickAmount: 7
                      },
                      markers: {
                        strokeWidth: 0,
                      },
                      legend: {
                        height: 30,
                        show:false,
                      }
                    }
                }
                newState.setState({
                    ...newState.state,
                    lineChart:chartData
                });

            }

        })
    }

    render(){
        return(
        <>
        <ReactApexChart options={this.state.lineChart.options} series={this.state.lineChart.series} type="scatter" />
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        site_id: state.userInfo.security.site_id,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        lineCleaningStatus:state.lines.lineCleaningStatus,
        kegRooms:state.kegRooms.kegRooms,
        selKegRoom:state.kegRooms.selKegRoom,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
      setSelKegRoom:(kr)=>dispatch(kegRoomActions.setSelKegRoom(kr)),
      //setLastPour:(item)=>dispatch(monitorActions.setLastPourItem(item))
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(LineCleaningWidget);