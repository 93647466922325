import React, { useState } from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  NavItem,
  NavLink,
  Nav,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label
} from "reactstrap";
import { Link,} from 'react-router-dom'
import validator from 'validator';
import axios from "axios";
import StartUp from '../layouts/startup';
import { connect } from "react-redux";
import * as UserInfoActions from "../redux/userDetail/action";

const Login = (props) => {

  const [state, setState] = useState({ name: '', email: '', password: '', errorMsg: '', emailError: false, passwordError: false, loggedIn:localStorage.getItem("user_logged_in")===null?false:true , userMappings: [],isSiteSelected:localStorage.getItem("user_site_resp_selected")===null?false:true });
  if(state.isSiteSelected && state.loggedIn){
    window.location='#/admin/dashboard'
  }
  
  const handleChange = (e) => {
    const { id, value } = e.target

    setState((prevState) => ({
      ...prevState,
      [id]: value,
      error: false,
    }))
    if (id === "email") {
      setState((prevState) => ({
        ...prevState,
        emailError: false,
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        passwordError: false,
      }))
    }
  }

  const validateLogin = (e) => {
    e.preventDefault()
    if (!validator.isEmail(state.email)) {
      setState((prevState) => ({
        ...prevState,
        emailError: true,
      }))
    }
    if (validator.isEmpty(state.password)) {
      setState((prevState) => ({
        ...prevState,
        passwordError: true,
      }))
    } else {
      let logindata = {
        Email: state.email,
        Password: state.password,
      }
      submitLogin(logindata)
    }
  }

  const submitLogin = (logindata) => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/api/login/validateuser?username=${logindata.Email}&&password=${logindata.Password}`).then((response) => {
      console.log(response)

      if (response.data.success) {
        localStorage.setItem("user_logged_in",true)
        setState(prevState => ({
          ...prevState,
          loggedIn: true,
          errorMsg: null
        }))
        // const userData = response.data.userData;
        // const accessToken = response.data.accessToken;
        // const userDetails = {
        //   user_id: userData.user_id,
        //   name: userData.person.last_name + " ," + userData.person.first_name,
        //   email: userData.person.email,
        //   phone: userData.person.phone,
        //   profile: userData.person.profile
        // };
        // const _userMappings = userData.user_site_resp;
        // localStorage.setItem('userId', userDetails.user_id);
        // localStorage.setItem('userDetails', JSON.stringify(userDetails));
        // localStorage.setItem('_dft_userMappings', JSON.stringify(_userMappings));

        // localStorage.setItem('accessToken', accessToken);
        // localStorage.setItem('refreshToken', accessToken);
        // if (_userMappings.length < 2) {
        //   localStorage.setItem("site_id", _userMappings[0].site_id);
        //   localStorage.setItem("_dft_user_security_level", JSON.stringify(_userMappings[0].usr_resp.resp_security_level));
        //   window.location = '#/admin/dashboard';
        // }
        // else {
        
        // }
      } else {
        setState(prevState => ({
          ...prevState,
          loggedIn: false,
          errorMsg: response.data.message
        }))
      }
      // if (response.data !== undefined && response.data.length !== 0) {
      //   if (response.data[0].password && response.data[0].password_salt) {
      //     let decryptPwd = decrypt({ content: response.data[0].password, iv: response.data[0].password_salt });
      //     if (decryptPwd === logindata.Password) {
      //       const userDetails = {
      //         user_id: response.data[0].user_id,
      //         name: response.data[0].person.last_name + " ," + response.data[0].person.first_name,
      //         email: response.data[0].person.email,
      //         phone: response.data[0].person.phone,
      //         profile: response.data[0].person.profile
      //       };
      //       const _userMappings = response.data[0].user_site_resp;
      // localStorage.setItem('userId', userDetails.user_id)
      // localStorage.setItem('userDetails', JSON.stringify(userDetails))
      // localStorage.setItem('_dft_userMappings', JSON.stringify(_userMappings))
      // axios(`${process.env.REACT_APP_API_BASE_URL}/api/login/createToken`, { username: logindata.Email }).then((response) => {
      //   if (response.data && response.data.accessToken) {
      //     localStorage.setItem('accessToken', response.data.accessToken);
      //     localStorage.setItem('refreshToken', response.data.accessToken);
      //     if(_userMappings.length<2){
      //       localStorage.setItem("site_id", _userMappings[0].site_id);
      //       localStorage.setItem("_dft_user_security_level", JSON.stringify(_userMappings[0].usr_resp.resp_security_level));
      //       window.location = '#/admin/dashboard';
      //     }
      //     else{
      //       setState(prevState => ({
      //         ...prevState,
      //         loggedIn: true,
      //         userMappings: _userMappings,
      //         errorMsg: "",
      //         name:userDetails.name
      //       }))
      //     }
      //   }
      //   else {
      //     setState(prevState => ({
      //       ...prevState,
      //       loggedIn: false,
      //       errorMsg: "Login failed"
      //     }))
      //   }
      // });
      //       }
      //       else {
      //         setState(prevState => ({
      //           ...prevState,
      //           loggedIn: false,
      //           errorMsg: "Password is invalid"
      //         }))
      //       }
      //     }
      //     else {
      //       setState(prevState => ({
      //         ...prevState,
      //         loggedIn: false,
      //         errorMsg: "Login failed"
      //       }))
      //     }
      //   }
      //   else {
      //     setState(prevState => ({
      //       ...prevState,
      //       loggedIn: false,
      //       errorMsg: "username is invalid"
      //     }))
      //   }
    });
  }

  return (state.loggedIn ? <StartUp /> :
    <>
      <Col lg="5" md="7">
        <Card className="shadow border-0 dft-bg-primary-color-lite">
          <CardHeader className="bg-transparent py-4">
            <div className="text-muted text-center">
              <h3 className="mb-0">{state.loggedIn ? "Welcome " + state.name : "Sign In"}</h3>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center">
              <h4 className="text-danger">{state.errorMsg}</h4>
            </div>
            <Form role="form" noValidate onSubmit={validateLogin}>
              <FormGroup
                className={state.emailError ? "has-danger mb-3" : "mb-3"}
              >
                <Label>Email</Label>
                <InputGroup
                  className={
                    state.emailError
                      ? "is-invalid input-group-alternative"
                      : "input-group-alternative"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="email"
                    placeholder="Email"
                    autoFocus
                    type="email"
                    autoComplete="new-email"
                    onChange={handleChange}
                    className={state.emailError ? "is-invalid" : ""}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup
                className={state.passwordError ? "has-danger mb-3" : "mb-3"}
              >
                <Label>Password</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    className={state.passwordError ? "is-invalid" : ""}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4 dft-bg-primary-color" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
            <Row className="">
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    to="/auth/forgotpassword"
                    tag={Link}
                  >
                    <span className="dft-primary-color nav-link-inner--text">
                      Forgot Password
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
      updateUserDetail: (userData) => dispatch(UserInfoActions.setUserDetail(userData)),
      updateUserSec: (userSec) => dispatch(UserInfoActions.setUserSec(userSec)),
      updateUserSites: (userSites) => dispatch(UserInfoActions.setUserSites(userSites))
  }
}

const mapStateToProps = (state) => {
  return {
      userSites: state.userInfo.userSites,
      userSec: state.userInfo.security
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
