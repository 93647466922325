import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as beersActions from '../beers/store/beers.actions';
import {
    Card,
    CardHeader,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    Label,
    CardBody,
    Button
} from "reactstrap";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from 'classnames';
import {printReport} from '../../utility/export/export-print'
import {DownloadPdf} from '../../utility/export/export.pdf';
//import WaterfallChart from "./waterfall";
//import { ResponsiveBar } from "@nivo/bar";
import {convertValueByMetric} from '../../utility/units-conversion'
import * as kegRoomActions from '../kegroom/store/kegroom.actions'
import ReactApexChart from "react-apexcharts";
import {sortByDataType} from '../../utility/common';

var DatePicker = require("reactstrap-date-picker");

class TotalPourChartByCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: new Date(moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            beerData:[],
            pourDetailHistData:[],
            data:[],
            beers:[],
            selDistributor:null,
            enablePourChartTab:false,
            enableBeerChartTab:false,
            chartTitle:'',
            showChart:false,
            activeTab:"2",
            errorMessage:'',
            loading:false,
            waterFallData:[],
            distributors:[],
            lineChart:{
                series:[],
                options:{}
            }
        };
    }

    async componentDidMount() {
        //this.fetchKegRooms();
        await this.props.getKegRooms(this.props.site_id);
        await this.props.getBeersByKegRoom(this.props.kegRooms[0].kr_id)
        await this.props.getBeerDistributors(this.props.kegRooms[0].kr_id);
    }

    static getDerivedStateFromProps(props,state){
        let _beers=[],_kegRooms=[];
        if(props.beers_kr.length>0){
            _beers.push({value:0,label:"All Beers"})
            props.beers_kr.forEach((item)=>{
                _beers.push({value:item.id,label:item.name});
            })
        }
        if(props.kegRooms.length>0){
            //_kegRooms.push({value:0,label:"All Beers"})
            props.kegRooms.forEach((item)=>{
                _kegRooms.push({value:item.kr_id,label:item.kr_name});
            })
        }
        if (_beers.length !== 0) {
            return {
              ...state,
              kegRooms:_kegRooms,
              beers: _beers,
              distributors:props.distributors,
              selKegRoom:_kegRooms.length>0?_kegRooms[0]:null,
              selDistributor:state.selDistributor===null?{value:0,label:"All Distributors"}:state.selDistributor
            }
          }
          return null;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            ...this.state,
            activeTab: tab
          });
        }
      }

    getChartData=()=>{
        const units=this.props.units;
        this.setState({loading:true,showChart:false,showHcChart:false});
        let newState=this;
        var params={
            from_date:moment(this.state.from_date.substring(0,10)).format("YYYY-MM-DD"),
            to_date:moment(this.state.to_date.substring(0,10)).format("YYYY-MM-DD"),
            kr_id:this.state.selKegRoom.value,
            distributor:this.state.selDistributor.value!==0?this.state.selDistributor.label.replace(new RegExp("'", 'g'), "''"):null
        }
        var title=`Total Pour - From ${params.from_date} To ${params.to_date}`;
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/charts/TotalPourReportByDistributor`,{params:params})
        .then(function(response){
             if(response.data!==undefined && response.data.length>0){
                response.data.map(function(item){
                    var datePart=item.end_date.substring(0,10)+ " 00:00:00";
                    var d=moment(datePart);
                    //var d1=moment(item.local_end_period);
                    item["end_date"]=moment(d._d).format("YYYY-MM-DD");
                    return item
                }) 
                response.data.sort((a, b) => new Date(a.end_date) - new Date(b.end_date));
                var groupByDistributor=  response.data.reduce(function (r, a) {
                    r[a.distributor] = r[a.distributor] || [];
                    r[a.distributor].push(a);
                    return r;
                }, Object.create(null));
                var beerData=[],counter=0,total_avg_duration=0,total_volume=0,total_avg_volume=0,total_pints=0,total_count=0,total_avg_temp=0;
                for(var key in groupByDistributor){
                    var _subData=groupByDistributor[key];
                    var beer_volume = _subData.map(o => o.pour_volume).reduce((a, c) => { return a + c });
                    var beer_duration = _subData.map(o => o.duration_sec).reduce((a, c) => { return a + c });
                    var beer_ambient = _subData.map(o => o.ambient_c).reduce((a, c) => { return a + c });
                    var beer_temp = _subData.map(o => convertValueByMetric(o.temperature_c,units,"c")).reduce((a, c) => { return a + c });
                    total_avg_duration+=(beer_duration/_subData.length);
                    total_volume+=beer_volume;
                    total_avg_volume+=(beer_volume/_subData.length);
                    total_pints+=beer_volume/_subData[0].mlperpint;
                    total_count+=_subData.length;
                    total_avg_temp+=beer_temp/_subData.length;
                    beerData.push({
                        name:key,
                        duration:(beer_duration/_subData.length).toFixed(1),
                        total_volume:convertValueByMetric(beer_volume,units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        avg_volume:(convertValueByMetric(beer_volume,units,"vol")/_subData.length).toFixed(2),
                        number_pints:(beer_volume/_subData[0].mlperpint).toFixed(2),
                        count:_subData.length,
                        temp:(beer_temp/_subData.length).toFixed(2),
                        ambient:(convertValueByMetric(beer_ambient,units,"c")/_subData.length).toFixed(2),
                        pour_id:counter
                    })
                    counter++;
                }
                beerData.push({
                    name:"Summary",
                    duration:(total_avg_duration/counter).toFixed(1),
                    total_volume:convertValueByMetric(total_volume,units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    avg_volume:(convertValueByMetric(total_avg_volume,units,"vol")/counter).toFixed(2),
                    number_pints:total_pints.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    count:total_count.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    temp:(total_avg_temp/counter).toFixed(2),
                    pour_id:counter+1
                })
                
                newState.setState({
                    ...newState.state,
                    beerData:beerData,
                    data:response.data,
                    showChart:true,
                    chartTitle:title,
                    loading:false,
                    errorMessage:""
                  })
             }
             else{
                newState.setState({
                    ...newState.state,
                    showChart:true,
                    loading:false,
                    errorMessage:"No data found"
                  })
             }
              
        })
    }

    

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    pourHistChart=(data)=>{
        var histData=[];
        var maxPoint=Math.max(...data.map(s=>parseFloat(s.pour_volume)));
        var counter1=0,increment=Math.round(maxPoint/15);
        increment=increment+((this.props.units==="metric"?100:(100*0.033814))-(increment%100))
        maxPoint+=increment;
        while(counter1<maxPoint){
            // eslint-disable-next-line no-loop-func
            var hisSubData=data.filter(s=>s.pour_volume>=(counter1-increment) && s.pour_volume<=counter1);
            histData.push({id:counter1,value:hisSubData.length})
            counter1+=increment;
        }
        this.setState({
            ...this.state,
            activeTab:"3",
            pourDetailHistData:histData
        })
    }

    rowClicked=(row,data)=>{
        var context=this;
        var cats=[];
        var seriesData=[];
        data.forEach((item)=>{
            if(item.name!=="Summary"){
                cats.push(item.name);
                seriesData.push(parseFloat(item.total_volume.replace(/,/g, '')).toFixed(2));
            }
        })
        var chartData={
            series: [{
                name:'Volume',
                data: seriesData
              }],
              options: {
                chart: {
                  type: 'bar',
                  height: 350
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                    distributed: true,
                  },
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
                },
                xaxis: {
                  categories: cats,
                  labels: {
                    rotate: -45,
                    formatter: function(val, opts) {
                        return val.split(' ')[0]
                    }
                  },
                },
                legend: {
                    show: false,
                },
                yaxis: {
                  title: {
                    text: 'Volume Poured (ml)'
                  },
                  labels: {
                    formatter: function(val, opts) {
                      return  parseFloat(val).toFixed(0)
                    }
                  }
                },
                fill: {
                  opacity: 1
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return parseFloat(val).toFixed(2)
                    }
                  }
                }
              }
        }
        context.setState({
            ...context.state,
            activeTab:"1",
            //lineChartData:_waterfall_data,
            lineChart:chartData,
            showChart:true
        });
    }

    showPourDetailsByDate=(row)=>{
        const columns = [
            {
                dataField: "pour_id",
                text: "Pour Id",
                hidden: true,
            },
            {
                dataField: "name",
                text: "Distributor",
                sort: true,
                headerAlign:'center'
            },
            {
                dataField: "total_volume",
                text: this.props.units==="metric"?"Total Volume (ml)":"Total Volume (ou)",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center'
             },
            {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                align:'right',
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                headerAlign:'center'
            },
            {
              dataField: "temp",
              text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
              sort: true,
              align: 'right',
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              headerAlign:'center'
            },
          ];

      
        var pourByKeg=this.state.data.filter(p=>p.distributor===row.name);
        var groupByDate=  pourByKeg.reduce(function (r, a) {
            //var end_date=moment(a.end_date.substring(0,10)).format("YYYY-MM-DD")
            r[a.beer_name] = r[a.beer_name] || [];
            r[a.beer_name].push(a);
            return r;
        }, Object.create(null));
        var pourData=[];
        var totalTemp=0;
        var totalAmbient=0;
        var totalDuration=0;
        var totalVolume=0;
        var counter=1;
        debugger
        for(var key in groupByDate){
            var _subData=groupByDate[key];
            var beer_volume = _subData.map(o => o.pour_volume).reduce((a, c) => { return a + c });
            var beer_duration = _subData.map(o => o.duration_sec).reduce((a, c) => { return a + c });
            var beer_ambient = _subData.map(o => o.ambient_c).reduce((a, c) => { return a + c });
            var beer_temp = _subData.map(o => convertValueByMetric(o.temperature_c,this.props.units,"c")).reduce((a, c) => { return a + c });
                  
            pourData.push({
                //end_date:key,
                pour_id:counter,
                name:key,
                duration:(beer_duration/_subData.length).toFixed(1),
                total_volume:convertValueByMetric(beer_volume,this.props.units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                avg_volume:(convertValueByMetric(beer_volume,this.props.units,"vol")/_subData.length).toFixed(2),
                count:_subData.length,
                temp:(beer_temp/_subData.length).toFixed(2),
                number_pints:(beer_volume/_subData[0].mlperpint).toFixed(2),
                ambient:(convertValueByMetric(beer_ambient,this.props.units,"c")/_subData.length).toFixed(2)
            })
            totalTemp+=beer_temp;
            totalAmbient+=beer_ambient;
            totalDuration+=beer_duration;
            totalVolume+=beer_volume;
            counter++;
        }
        if(pourData.length>0){
          pourData.push({
            //end_date:"",
            name:"Summary",
            duration:(totalDuration/pourByKeg.length).toFixed(1),
            total_volume:convertValueByMetric(totalVolume,this.props.units,"vol").toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            avg_volume:(convertValueByMetric(totalVolume,this.props.units,"vol")/pourByKeg.length).toFixed(2),
            count:_subData.length,
            number_pints:(totalVolume/_subData[0].mlperpint).toFixed(2),
            temp:(totalTemp/pourByKeg.length).toFixed(2),
            ambient:(convertValueByMetric(totalAmbient,this.props.units,"c")/pourByKeg.length).toFixed(2)
        })
        }
        if(pourData.length!==0){
            return(
                <>
                    <div style={{padding:'10px',float:'right'}}>
                        <button className="btn btn-sm btn-info" onClick={() => this.rowClicked(row,pourData)}>Chart</button>
                        <Button onClick={()=>printReport(columns,pourData,"ifmcontentstoprint")} size="sm">Print</Button>
                        <button onClick={()=>DownloadPdf(columns,pourData,this.state.chartTitle,this.props.companyLogo,this.props.userLogo,"Total-Pour-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                        <ReactHTMLTableToExcel
                            className="btn btn-secondary btn-sm"
                            table="tbl_pours_ByDate"
                            filename="Total Pour"
                            sheet="Pour Data"
                            buttonText="Download Excel"/>
                    </div>
                    <BootstrapTable id="tbl_pours_ByDate"
                            classes="custom-table-css dft-bg-primary-color tbl-inner"
                            striped
                            hover
                            condensed
                            keyField="name"
                            data={pourData}
                            columns={columns}>
                    </BootstrapTable>
                </>
            )
        }
        else{
            return("")
        }
    }

    showWaterFallChart=()=>{
        debugger
        if(this.state.activeTab==="1"){
            return(<>
                    <div style={{margin:'25px 0 0 0'}}>
                        <ReactApexChart type="bar" options={this.state.lineChart.options} series={this.state.lineChart.series} height="400" />
                    </div>
            </>)
        }
        return "";
    }

    expandBeerColumn=(expanded)=>{
        this.setState({...this.state,enableBeerChartTab:expanded,enablePourChartTab:expanded})
        if (expanded) {
            return (
              <i className="fa fa-caret-down fa-md"></i>
            );
          }
          return (
            <i className="fa fa-caret-right fa-md"></i>
          );
    }
  

    render() {
        const {showPourDetailsByDate}=this;
        const expandBeerRow = {
            onlyOneExpanding: true,
            parentClassName: 'selected',
            renderer: (row) =>{
                return showPourDetailsByDate(row)
            },
            showExpandColumn: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
              if (isAnyExpands) {
                return <i className="fa fa-caret-down fa-md"></i>;
              }
              return <i className="fa fa-caret-right fa-md"></i>;
            },
            expandColumnRenderer: ({ expanded }) => {
              return this.expandBeerColumn(expanded)
            }
          };
        const columns = [
            {
              dataField: "pour_id",
              text: "Pour Id",
              hidden: true,
            },
            {
              dataField: "name",
              text: "Distributor",
              sort: true,
              headerAlign:'center'
            },
            {
                dataField: "total_volume",
                text: this.props.units==="metric"?"Total Volume (ml)":"Total Volume (ou)",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
             },
            {
                dataField: "number_pints",
                text: "#Pints",
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
                align:'right',
                headerAlign:'center'
            },
            {
              dataField: "temp",
              text: this.props.units==="metric"?"Avg Temp (C)":"Avg Temp (F)",
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) =>{return sortByDataType(a,b,order,'float')},
              align: 'right',
              headerAlign:'center'
            }
          ];
          
        return (
            <React.Fragment>
            <iframe id="ifmcontentstoprint" style={{height:'0',width:'0',position:'absolute'}} title="print"></iframe>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Total Pour Chart</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Distributor</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selDistributor}
                                    options={this.state.distributors}
                                    onChange={(e) => this.handleChangeSelection(e, 'selDistributor')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                {/* <Button  size="sm" onClick={this.getChartData}>Chart</Button> */}
                                <Button size="sm" onClick={this.getChartData} disabled={this.state.loading ? 'disabled' : ''}>{this.state.loading?"Please wait...":"Report"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                    <Row>
                        <Col lg="12" sm="12" md="12">
                        <Nav tabs>
                        <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}            >
                                View Data
                                </NavLink>
                            </NavItem>
                            {this.state.enableBeerChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Chart
                                </NavLink>
                            </NavItem>:""
                            }
                            {/* {this.state.enablePourChartTab?
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}            >
                                Pour Detail Histogram
                                </NavLink>
                            </NavItem>:""
                            } */}
                           
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="2">
                        {/* <Button onClick={()=>this.printPourVolume(columns)} size="sm">Print</Button>
                        <button onClick={this.generatePdf} className="btn btn-secondary btn-sm">Download Pdf</button> */}
                        <div style={{padding:'10px',float:'right'}}>
                            <Button onClick={()=>printReport(columns,this.state.beerData,"ifmcontentstoprint")} size="sm">Print</Button>
                            <button onClick={()=>DownloadPdf(columns,this.state.beerData,this.state.chartTitle,this.props.companyLogo,this.props.userLogo,"Total-Pour-Report.pdf")} className="btn btn-secondary btn-sm">Download Pdf</button>
                            <ReactHTMLTableToExcel
                                className="btn btn-secondary btn-sm"
                                table="tbl_Beers"
                                filename="Total Pour"
                                sheet="Beers"
                                buttonText="Download Excel"/>
                        </div>
                            <BootstrapTable id="tbl_Beers"
                                classes="custom-table-css dft-bg-primary-color "
                                striped
                                hover
                                condensed
                                keyField="pour_id"
                                data={this.state.beerData}
                                expandRow={expandBeerRow}
                                //pagination={paginationFactory(pagingOptions)}
                                columns={columns}>
                            </BootstrapTable>
                        </TabPane>
                        <TabPane tabId="1">
                        {this.showWaterFallChart()}
                       
                        </TabPane>
                        {/* <TabPane tabId="3">
                            <div style={{width:'100%',height:'400px'}}>
                                        <ResponsiveBar 
                                        borderRadius={1}
                                        data={this.state.pourDetailHistData}
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        //padding={{left:50,right:50,top:50,bottom:50}}
                                        // valueScale={{"type":"linear"}}
                                        xScale={{
                                            type: "linear",
                                            min: 0,
                                            max: 100
                                        }}
                                        yScale={{
                                            type:"linear",
                                            max:'auto'
                                        }}
                                        colorBy="id"
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: this.props.units==="metric"?'Pour Volume (ML)':'Pour Volume (OU)',
                                            legendPosition: 'middle',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Count',
                                            legendPosition: 'middle',
                                            legendOffset: -30,
                                            tickValues:10
                                        }}
                                        enableGridX={true}
                                        enableGridY={true}
                                        gridXValues={20}

                                    />
                                </div>
                        </TabPane> */}
                        
                    </TabContent>
                           
                        </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        companyLogo:state.userInfo.companyLogoBase64,
        userLogo:state.userInfo.profileLogoBase64,
        beers_kr: state.beers.beers_kr,
        units:state.userInfo.userDetails.units??state.userInfo.security.units,
        distributors:state.beers.distributors,
        kegRooms:state.kegRooms.kegRooms,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
        getBeersByKegRoom: (kr_id) => dispatch(beersActions.getBeersByKegRoom(kr_id)),
        getBeerDistributors:(kr_id)=>dispatch(beersActions.getBeerDistributors(kr_id))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TotalPourChartByCompany);
