import * as actionTypes from "../../../constants/actionTypes";
import store from '../../../redux/store';
import axios from "axios";
import { infoToaster } from '../../toaster/toaster';

export const initialState = {
    selLine: {
        line_id:0,
        local_line_number:'',
        sensor_id:0,
        keg_id:0,
        sensor_serial_number:'',
        draft_beer_name:'',
        pressure:'',
        line_length:''
    },
    showList:false,
    lineCleaningStatus:false,
    lines: [],
    currPage: 1
};

export const getLines = () => {
    const state = store.getState();
    return (dispatch) => {  
        // if (state.lines.lines.length > 0) {
        //     return;
        // }
        return new Promise(function (resolve, reject) {
            var siteParams={};
            if(state.kegRooms.selKegRoom.kr_id!==0){
                siteParams.kr_id=state.kegRooms.selKegRoom.kr_id
            }
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/line`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    //const site=state.sites.sites.find(p=>p.site_id===site_id);
                    let _lines = [];
                    response.data.forEach((item) => {
                        _lines.push({
                            line_id: item.line_id,
                            local_line_number: item.local_line_number,
                            sensor_id: item.sensor_id,
                            keg_id: item.keg_id,
                            sensor_serial_number: item.sensor_serial_number,
                            draft_beer_name: item.keg_name,
                            pressure: item.pressure,
                            line_length: item.line_length
                       })
                    });
                    _lines = _lines.sort((a, b) => a.local_line_number - b.local_line_number);
                    dispatch(handleActionDispatch(actionTypes.SET_LINES, _lines));
                    resolve(_lines);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const startLineCleaning = (kr_id) => {
    const state = store.getState();
    return (dispatch) => {  
        if (state.lines.lineCleaningStatus) {
            return;
        }
        return new Promise(function (resolve, reject) {
            var siteParams={kr_id:kr_id};
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/line/StartLineCleaning`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    dispatch(handleActionDispatch(actionTypes.SET_LINE_CLEANING_STATUS, true));
                    infoToaster("Line Cleaning Started");
                    resolve(true);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const stopLineCleaning = (kr_id) => {
    //const state = store.getState();
    return (dispatch) => {  
        // if (!state.lines.lineCleaningStatus) {
        //     return;
        // }
        return new Promise(function (resolve, reject) {
            var siteParams={kr_id:kr_id};
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/line/StopLineCleaning`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    dispatch(handleActionDispatch(actionTypes.SET_LINE_CLEANING_STATUS, false));
                    infoToaster("Line Cleaning Stopped");
                    resolve(false);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const calculateLineCleaning = (kr_id) => {
    //const state = store.getState();
    return (dispatch) => {  
        // if (!state.lines.lineCleaningStatus) {
        //     return;
        // }
        return new Promise(function (resolve, reject) {
            var siteParams={kr_id:kr_id};
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/line/CalculateLineCleaning`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    resolve(true);
                }
            },
                (error) => {
                    reject(error);
                });
        })
    }
}

export const getLineCleaningStatus = (kr_id) => {
    return (dispatch) => {  
        return new Promise(function (resolve, reject) {
            var siteParams={kr_id:kr_id};
            axios(`${process.env.REACT_APP_API_BASE_URL}/api/line/LineCleaningStatus`,{params:siteParams}).then((response) => {
                if (response.data !== undefined) {
                    dispatch(handleActionDispatch(actionTypes.SET_LINE_CLEANING_STATUS, response.data.length>0));
                    if(response.data.length>0){
                        infoToaster("Line Cleaning In Progress");
                    }
                    resolve(response.data.length>0);
                }
            },
                (error) => {
                    console.log(error)
                    reject(error);
                });
        })
    }
}

export const setCurrPage = (page) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_CURR_LINE_PAGE, page));
    }
}

export const setSelLine = (kegRoom) => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.SET_SELECTED_LINE, kegRoom));
    }
}

export const clearSelKegRoom = () => {
    return setSelLine(initialState.selKegRoom);
}

export const clearStates = () => {
    return (dispatch) => {
        dispatch(handleActionDispatch(actionTypes.CLEAR, initialState));
    }
}


export const handleActionDispatch = (actionName, data) => {
    return {
        type: actionName,
        data: data
    }
}