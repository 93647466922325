import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/css/main.css"
import "react-datetime/css/react-datetime.css";
import axios from 'axios';
import {failureToaster} from './views/toaster/toaster'


// axios.interceptors.request.use(
//   config => {
//       const token = localStorage.getItem('accessToken')
//       if (token) {
//           config.headers['Authorization'] = token;
//       }
//       return config;
//   },
//   error => {
//       Promise.reject(error)
//   });
axios.defaults.withCredentials = true;


axios.interceptors.response.use(response => {
   return response;
}, error => {
       console.log(error);
       if (error.response.status === 400) {
           //window.location = '/#/admin/badrequest';
           failureToaster(error.response.data.message);
       }
       else if (error.response.status === 403) {
           localStorage.clear();
           window.location = '/#/auth/login';
       }
       else if (error.response.status === 404) {
            failureToaster("Page not found");
       }
       else if (error.response.status === 500) {
        failureToaster(error.response.data.message);
           //window.location = '/#/admin/internalservererror';
       }
       else if (error.response.status === 401) {
           localStorage.clear();
           window.location = '/#/auth/login';
       }
       return Promise.reject(error);
})

ReactDOM.render(
//   <React.StrictMode>
    <App />
//   </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
