import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import axios from "axios";
import moment from 'moment';
import * as beersActions from '../beers/store/beers.actions'
import {
    Card,
    CardHeader,
    Row,
    Col,
    Label,
    CardBody,
    Button
} from "reactstrap";
var DatePicker = require("reactstrap-date-picker");

class PourConsolidatedReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date:new Date(moment().startOf('month').format('DD/MM/YYYY')).toISOString(),
            to_date: new Date().toISOString(),
            selKegRoom: null,
            kegRooms: [],
            beers:[],
            selBeer:null,
            kegRepData: [],
            report_url:'',
            showReport:false,
            errorMessage:'',
            fileUrls:[]
        };
    }

    componentDidMount() {
        this.fetchKegRooms();
        //this.props.getBeers();
    }

    static getDerivedStateFromProps(props,state){
        let _beers=[];
        if(props.beers_kr.length>0){
            props.beers_kr.forEach((item)=>{
                _beers.push({value:item.id,label:item.name});
            })
        }
        if (state.beers.length === 0) {
            return {
              ...state,
              beers: _beers,
            }
          }
          return null;
    }

    fetchKegRooms = () => {
        axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom`, { params: { site_id: this.props.site_id } }).then(response => {
            if (response.data !== undefined) {
                const kegRooms = response.data.map((kr) => {
                    return {
                        value: kr.kr_id,
                        label: kr.kr_name
                    }
                });
                
                this.setState({kegRooms: kegRooms,selKegRoom: kegRooms[0]},()=>{this.props.getBeersByKegRoom(kegRooms[0].value)});
            }
        });
    }

    getReprtData = () => {
        this.setState({showReport:true});
        const formData = {
            customer_email:this.props.userDetails.person.email,
            kr_id: this.state.selKegRoom.value,
            beer_in:this.state.selBeer==null?null:this.state.selBeer.label,
            start_date: moment(this.state.from_date).format('YYYY-MM-DD'),
            end_date: moment(this.state.to_date).format('YYYY-MM-DD')
        };
        axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/reports/PourConsolidatedReport`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'true'
        },
            data: formData
        })
            .then((response) => {
                var urls=response.data.response;
                if(!response.data.error){
                    urls.forEach((url)=>{
                        const link = document.createElement('a');
                        link.href = url;
                        link.target="_blank";
                        link.setAttribute(
                        'download',
                        `Report.pdf`,
                        );
                        // Append to html link element page
                        document.body.appendChild(link);
                        // Start download
                        link.click();
                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                    })
                    this.setState({...this.state,showReport:false})
                    // if(Array.isArray(urls)){
                    //     this.setState({...this.state,fileUrls:urls,showReport:false})
                    // }
                    // else{
                    //     this.setState({...this.state,fileUrls:[urls],showReport:false})
                    // }
                }
                else{
                    this.setState({...this.state,errorMessage:response.data.message,showReport:false})
                }
            });
    }

    handleChangeSelection = (item,inputPropName) => {
        this.setState({ [inputPropName]: item }, () => {
            if(inputPropName==="selKegRoom"){
                this.props.getBeersByKegRoom(item.value)
            }
        });
    }

    handleDatepickerChange = (value, formattedValue, inputPropName) => {
        this.setState({
            [inputPropName]: value
        }, () => {
        });
    }   

    render() {
        return (
            <React.Fragment>
                <Card className="bg-secondary shadow border-0" id="/admin/customer">
                    <CardHeader className="bg-transparent py-3 border-0">
                        <h5>Total Pour Report</h5>
                        <Row>
                            <Col md="3" sm="3" lg="3">
                                <Label>keg Room</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selKegRoom}
                                    options={this.state.kegRooms}
                                    onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>Select Beer</Label>
                                <Select className="dft-header-drpdwn-sites" size="xs" value={this.state.selBeer}
                                    options={this.state.beers}
                                    onChange={(e) => this.handleChangeSelection(e, 'selBeer')}>
                                </Select>
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>From Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.from_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'from_date')} />
                            </Col>
                            <Col md="3" sm="3" lg="3">
                                <Label>To Date</Label>
                                <DatePicker size="sm"
                                    value={this.state.to_date}
                                    onChange={(v, f) => this.handleDatepickerChange(v, f, 'to_date')} />
                            </Col>
                           
                        </Row>
                        <br />
                        <Row>
                        <Col md="3" sm="3" lg="3">
                                <Button onClick={this.getReprtData} disabled={this.state.showReport ? 'disabled' : ''}>{this.state.showReport?"Please wait...":"Search"}</Button>
                            </Col>
                        </Row>
                        <p style={{'color':'red'}}>{this.state.errorMessage}</p>
                    </CardHeader>
                    <CardBody >
                        <Row>
                        {
                                this.state.fileUrls.map((item,index)=>{
                                    return <p><a href={item} target="_blank" rel="noreferrer" key={index}>View Report {index+1} |</a></p>
                                })
                            }
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userSites: state.userInfo.userSites,
        site_id: state.userInfo.security.site_id,
        userDetails:state.userInfo.userDetails,
        beers_kr: state.beers.beers_kr
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBeersByKegRoom: (kr_id) => dispatch(beersActions.getBeersByKegRoom(kr_id))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PourConsolidatedReport);
