import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../utility/common";
import { initialState } from "./keg.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.SET_SELECTED_KEG):
            return setSelKeg(state, action);
        case (actionTypes.SET_KEGS):
            return setKegs(state, action);
        case (actionTypes.SET_CURR_KEG_PAGE):
            return setCurrPage(state, action);
        case (actionTypes.CLEAR):
            return clearState(state, action);
        case (actionTypes.ADD_KEG):
            return addKeg(state, action);
        case (actionTypes.UPDATE_KEG):
            return updateKeg(state, action);
        default:
            return state;
    }
};

const addKeg = (state, action) => {
    let kegs = [...state.kegs];
    kegs.push(action.data);
    return updateObject(state, {
        kegs: kegs
    });
};

const updateKeg = (state, action) => {
    const index = state.kegs.findIndex(b => b.keg_id === action.data.keg_id);
    return {
        ...state,
        kegs: [...state.kegs.slice(0, index), action.data, ...state.kegs.slice(index + 1)]
    };
};


const setSelKeg = (state, action) => {
    return updateObject(state, {
        selKeg: action.data
    });
};

const setKegs = (state, action) => {
    return updateObject(state, {
        kegs: action.data,
        showList:true
    });
};

const setCurrPage = (state, action) => {
    return updateObject(state, {
        currPage: action.data
    });
};

const clearState = (state, action) => {
    return updateObject(state, {
        selKeg: initialState.selKegRoom,
        kegs: initialState.kegRooms,
        currPage: initialState.currPage
    });
};

export default reducer;