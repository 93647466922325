import React from "react";
import { ResponsiveBar } from "@nivo/bar";



class CustomStackedBar extends React.Component{
  constructor(props){
    super(props)
    this.state={
      keys:props.keys,
      data:props.data,
      title:props.title,
      xtitle:props.xtitle,
      ytitle:props.ytitle
    }
  }

  Title=(w,h)=>{
    const style = {fontWeight: 'bold',textAlign:'center'}
  return (
    <text
    x={500}
    y={-10}
    textAnchor="middle"
    style={style}
    >
    {this.props.title}
    </text>
    )
  }

  formatLabel=(x,y)=>{
    return x.id==="subtotal"?"":parseFloat(x.formattedValue).toFixed(2);
  }

  customToolTip=(point)=>{
    var totalVolume=0;
    for(var key in point.data){
      if(key!=="date" && key!=="fromDate" && key!=="toDate"){
        totalVolume+=parseFloat(point.data[key])
      }
      
    }
    return(
      <div style={{padding: 5,color:'#fff',background: point.color}}>
          <strong>{point.label}</strong> <br />
          <strong>Volume : {point.formattedValue}</strong><br />
          <strong>Total Volume : {totalVolume.toFixed(2)}</strong>
        </div>
    )
  }

  componentDidMount(){
    //this.setState({...this.state,waterfallData:calcWaterfallDataWSign(this.state.data,this.state.initialValue)})
  }

  
  render(){
    
    return(<>
    <div style={{width:'100%',height:'500px',padding:'10px'}}>
      {/* <h4 style={{textAlign:'center'}}>{this.state.title}</h4> */}
      <ResponsiveBar
        data={this.state.data}
        keys={this.state.keys}
        colors={{ scheme: 'category10' }}
        indexBy="date"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -30,
            legend: this.props.xtitle,
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            //tickValues:[1,2],
            legend: this.props.ytitle,
            legendPosition: 'middle',
            legendOffset: -50
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        tooltip={(d) => this.customToolTip(d)}
        layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations',this.Title]}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
      </div>
    </>)
  }
}

export default CustomStackedBar;