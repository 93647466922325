import React from "react";
import Select from "react-select";
//import axios from 'axios';
import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  //Input,
  Label,
  Row,
  Col,
} from "reactstrap";
//import { successToaster } from '../toaster/toaster';
import SimpleReactValidator from "simple-react-validator";
import { isObject } from "reactstrap/lib/utils";
import { connect } from "react-redux";
import * as kegSizeActions from '../keg-sizes/store/keg-sizes.actions';
import * as kegActions from '../keg/store/keg.actions';
import * as kegRoomActions from '../kegroom/store/kegroom.actions';
import * as beerActions from '../beers/store/beers.actions';

class KegDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selKeg: {
        keg_id:0,
        volume:'',
        percent_remaining:0,
        volume_poured:0,
        connected:false,
        draft_beer_id:0,
        draft_beer_name:'',
        kr_id:0,
        kr_name:''
      },
      selKegRoom:null,
      selBeer:null,
      selKegSize:null,
      kegSizes:[],
      beers: [],
      kegRooms: [],
      saveInProgress: false
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        selectValidator: {
          message: 'Please select an option',
          rule: (val) => {
            return isObject(val)
          },
          required: true  // optional
        }
      }
    });
  }

  async componentDidMount() {
    await this.props.getKegSizes();
    await this.props.getBeers();
    await this.props.getKegRooms(this.props.site_id);
    let _selBeer={},_selKr={},_selKs={};
    if(this.props.selKeg.keg_id!==0 && this.props.beers.length>0){
      _selBeer=this.props.beers.find(b=>b.id===this.props.selKeg.draft_beer_id);
    }
    else{
      _selBeer=this.props.beers[0]
    }
    if(this.props.selKeg.keg_id!==0 && this.props.kegRooms.length>0){
      _selKr=this.props.kegRooms.find(b=>b.kr_id===this.props.selKeg.kr_id);
    }
    else{
      _selKr=this.props.kegRooms[0]
    }
    if(this.props.selKeg.keg_id!==0 && this.props.kegSizes.length>0){
      _selKs=this.props.kegSizes.find(b=>parseFloat(b.capacity_l)*1000===parseFloat(this.props.selKeg.volume.replace(',','')));
    }
    else{
      _selKs=this.props.kegSizes[0]
    }
    this.setState({
      ...this.state,
      selBeer:{label:_selBeer.name,value:_selBeer.id},
      selKegRoom:{label:_selKr.kr_name,value:_selKr.kr_id},
      selKegSize:_selKs===undefined?null:{label:`${_selKs.nickname} - (${_selKs.capacity_l} L)`,value:parseFloat(_selKs.capacity_l)*1000} 
    })
  }

  static getDerivedStateFromProps(props, state) {
    var _sizes=[],_beers=[],_kr_rooms=[];
    if(props.kegSizes.length>0){
      _sizes =props.kegSizes.map((item)=>{
        return {
          label:`${item.nickname} - (${item.capacity_l} L)`,
          value:parseFloat(item.capacity_l)*1000
        }
      })
    }
    if(props.beers.length>0){
      _beers =props.beers.map((item)=>{
        return {
          label:item.name,
          value:item.id
        }
      })
    }
    if(props.kegRooms.length>0){
      _kr_rooms =props.kegRooms.map((item)=>{
        return {
          label:item.kr_name,
          value:item.kr_id
        }
      })
    }
    // let _selBeer={},_selKr={},_selKs={};
    // if(props.selKeg.keg_id!==0 && props.beers.length>0){
    //   _selBeer=props.beers.find(b=>b.id===props.selKeg.draft_beer_id);
    //   _selBeer={label:_selBeer.name,value:_selBeer.id}
    // }
    // if(props.selKeg.keg_id!==0 && props.kegRooms.length>0){
    //   _selKr=props.kegRooms.find(b=>b.kr_id===props.selKeg.kr_id);
    //   _selKr={label:_selKr.kr_name,value:_selKr.kr_id}
    // }
    // if(props.selKeg.keg_id!==0 && props.kegSizes.length>0){
    //   _selKs=props.kegSizes.find(b=>parseFloat(b.capacity_l)*1000===parseFloat(props.selKeg.volume.replace(',','')));
    //   _selKs=_selKs===undefined?{}:{label:`${_selKs.nickname} - (${_selKs.capacity_l} L)`,value:parseFloat(_selKs.capacity_l)*1000} 
    // }
    return {
      kegSizes:_sizes,
      beers:_beers,
      kegRooms:_kr_rooms,
      selKeg:props.selKeg,
      // selBeer:props.selKeg.keg_id===0?(_beers[0]??{}):_selBeer,
      // selKegRoom:props.selKeg.keg_id===0?(_kr_rooms[0]??{}):_selKr,
      // selKegSize:props.selKeg.keg_id===0?(_sizes[0]??{}): _selKs 
    }
  }

  // fetchbeers = () => {
  //   // Load the list
  //   axios(`${process.env.REACT_APP_API_BASE_URL}/api/beers`).then((response) => {
  //     if (response.data !== undefined) {
  //       let beersData = [];
  //       response.data.forEach((item) => {
  //         beersData.push({
  //           value: item.draft_beer_id,
  //           label: item.name,
  //         });
  //       });
  //       beersData = beersData.sort((a, b) => a.label.localeCompare(b.label));
  //       this.setState({ beers: beersData,keg:{...this.state.keg,selectedBeer:beersData[0]} }, () => {
  //         this.fetchkegrooms();
  //       });
  //     }
  //   });
  // }

  // fetchkegrooms = () => {
  //   // Load the list
  //   //let _site_id=  parseInt(localStorage.getItem("site_id"));
  //   axios(`${process.env.REACT_APP_API_BASE_URL}/api/kegroom?site_id=${this.props.site_id}`,).then((response) => {
  //     if (response.data !== undefined) {
  //       let kegroomsData = [];
  //       response.data.forEach((item) => {
  //         kegroomsData.push({
  //           value: item.kr_id,
  //           label: item.kr_name,
  //         });
  //       });
  //       kegroomsData = kegroomsData.sort((a, b) => a.label.localeCompare(b.label));
  //       this.setState({ kegrooms: kegroomsData,keg:{...this.state.keg,selectedKegroom:kegroomsData[0]} }, () => {
  //         this.fetchKeg();
  //       });
  //     }
  //   });
  // }

  // fetchKeg = () => {
  //   const keg_id = this.state.keg.keg_id;
  //   if (keg_id !== 0) {
  //     axios(`${process.env.REACT_APP_API_BASE_URL}/api/keg/${keg_id}`).then(response => {
  //       if (response.data) {
  //         let newState = Object.assign({}, this.state);
  //         newState.keg.keg_id = response.data.keg_id;
  //         newState.keg.kr_id = response.data.kr_id;
  //         newState.keg.draft_beer_id = response.data.draft_beer_id;
  //         newState.keg.volume = response.data.volume;
  //         newState.keg.percent_remaining = response.data.percent_remaining;
  //         newState.keg.kr_in_date = response.data.kr_in_date;
  //         newState.keg.kr_out_date = response.data.kr_out_date;
  //         newState.keg.volume_poured = response.data.volume_poured;
  //         newState.keg.connected_start_time = response.data.connected_start_time;
  //         newState.keg.connected_end_time = response.data.connected_end_time;
  //         newState.keg.connected = response.data.connected;

  //         let kegSize=this.state.kegSizes.find(p=>p.value===parseFloat(response.data.volume));
  //         newState.keg.selKegSize=kegSize===undefined?{}:kegSize;
  //         debugger
  //         if (response.data.draft_beer_id != null) {
  //           let beer = this.state.beers.find(p => p.value === response.data.draft_beer_id);
  //           if (beer) {
  //             newState.keg.selectedBeer = { value: beer.value, label: beer.label };
  //           }
  //         } else {
  //           newState.keg.selectedBeer = {};
  //         }

  //         if (response.data.kr_id != null) {
  //           let kegroom = this.state.kegrooms.find(p => p.value === response.data.kr_id);
  //           if (kegroom) {
  //             newState.keg.selectedKegroom = { value: kegroom.value, label: kegroom.label };
  //           }
  //         } else {
  //           newState.keg.selectedKegroom = {};
  //         }

  //         this.setState(newState);
  //       }
  //     });
  //   }
  //   else {
  //     let newState = Object.assign({}, this.state);

  //     if (this.state.keg.kr_id != null) {
  //       let kegroom = this.state.kegrooms.find(p => p.value === this.state.keg.kr_id);
  //       if (kegroom) {
  //         newState.keg.selectedKegroom = { value: kegroom.value, label: kegroom.label };
  //       }
  //     } else {
  //       newState.keg.selectedKegroom = {};
  //     }
  //     newState.keg.selKegSize=newState.kegSizes.length>0?newState.kegSizes[0]:{};
  //     this.setState(newState);
  //   }
  // }

  handleChangeInput = (value, propName) => {
    let newState = Object.assign({}, this.state);
    newState.person[propName] = value;
    this.setState(newState);
  };

  handleInputChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.keg[inputPropName] = event.target.value;
    }
    this.setState(newState);
  }

  handleCheckboxChange = (event, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (event.target) {
      newState.keg[inputPropName] = event.target.checked;
    }
    this.setState(newState);
  }

  handleDatepickerChange = (value, formattedValue, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if (value) {
      newState.keg[inputPropName] = value;
    }
    this.setState(newState);
  }

  handleChangeSelection = (item, inputPropName) => {
    const newState = Object.assign({}, this.state);
    if(inputPropName==="selBeer"){
      const _beer=this.state.beers.find(b=>b.value===item.value);
      newState.selBeer=_beer;
    }
    else if(inputPropName==="selKegRoom"){
      const _kr=this.state.kegRooms.find(b=>b.value===item.value);
      newState.selKegRoom=_kr;
    }
    else if(inputPropName==="selKegSize"){
      const _size=this.state.kegSizes.find(b=>b.value===item.value);
      newState.selKegSize=_size;
    }
    this.setState(newState);
  }

  saveKeg = async() => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    } 
    this.setState({ saveInProgress: true });
    const kegInfo = this.state.selKeg;
    const formData = {
      keg_id: kegInfo.keg_id,
      draft_beer_id: this.state.selBeer.value,
      kr_id: this.state.selKegRoom.value,
      draft_beer_name: this.state.selBeer.label,
      kr_name: this.state.selKegRoom.label,
      volume: this.state.selKegSize.value,
      percent_remaining: 100,//kegInfo.percent_remaining,
      kr_in_date: Date.now(),
      kr_out_date: null,
      volume_poured: 0,//kegInfo.volume_poured,
      connected_start_time: Date.now(),
      connected_end_time: null,
      connected: kegInfo.connected,
    };
    if (kegInfo.keg_id === 0) {
      await this.props.addKeg(formData);
    }
    else {
      await this.props.updateKeg(formData);
    }
    this.setState({ saveInProgress: false });
    this.handleCancel();
  }

  handleCancel = () => {
    this.props.clearSelKeg();
    this.props.history.goBack();
  }

  render() {

    return (
      <>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent py-4">

            <Row className="text-muted">
              <Col md="6" lg="6">
                <h3 className="dft-primary-color">{this.state.selKeg.keg_id === 0 ? "Add Keg" : "Edit Keg"}</h3>
              </Col>

            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col md="4" sm="6" lg="4">
                <Label> Beer<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.selBeer}
                  options={this.state.beers}
                  onChange={(e) => this.handleChangeSelection(e, 'selBeer')}></Select>
                  {this.validator.message('Select Beer', this.state.selBeer, 'required|selectValidator:""')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Keg Room<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.selKegRoom}
                  options={this.state.kegrooms}
                  onChange={(e) => this.handleChangeSelection(e, 'selKegRoom')}></Select>
                  {this.validator.message('Select Kegroom', this.state.selKegRoom, 'required|selectValidator:""')}
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Volume (L)<span style={{color:'red'}}>*</span></Label>
                <Select size="sm" value={this.state.selKegSize}
                  options={this.state.kegSizes}
                  onChange={(e) => this.handleChangeSelection(e, 'selKegSize')}></Select>
                  {this.validator.message('Select Size', this.state.selKegSize, 'required|selectValidator:""')}
              </Col>
               {/*<Col md="4" sm="6" lg="4">
                <Label> Percentage Remaining (%)</Label>
                <Input value={this.state.keg.percent_remaining} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'percent_remaining')} autoComplete="new-name" />
              </Col>
               <Col md="4" sm="6" lg="4">
                <Label> Keg Room In Date</Label>
                <DatePicker size="sm"
                  value={this.state.keg.kr_in_date}
                  onChange={(v, f) => this.handleDatepickerChange(v, f, 'kr_in_date')} />
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Keg Room Out Date</Label>
                <DatePicker size="sm"
                  value={this.state.keg.kr_out_date}
                  onChange={(v, f) => this.handleDatepickerChange(v, f, 'kr_out_date')} />
              </Col> 
              <Col md="4" sm="6" lg="4">
                <Label> Volume Poured (ml)</Label>
                <Input value={this.state.keg.volume_poured} bsSize="sm" type="text" onChange={(e) => this.handleInputChange(e, 'volume_poured')} autoComplete="new-name" />
              </Col>*/}
              {/* <Col md="4" sm="6" lg="4">
                <Label> Connected Start Time</Label>
                <DatePicker size="sm"
                  value={this.state.keg.connected_start_time}
                  onChange={(v, f) => this.handleDatepickerChange(v, f, 'connected_start_time')} />
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label> Connected End Time</Label>
                <DatePicker size="sm"
                  value={this.state.keg.connected_end_time}
                  onChange={(v, f) => this.handleDatepickerChange(v, f, 'connected_end_time')} />
              </Col>
              <Col md="4" sm="6" lg="4">
                <Label>Connected</Label>
                <div>
                  <label class="switch">
                    <Input type="checkbox" checked={this.state.keg.connected} size="sm" type="checkbox" onChange={(e) => this.handleCheckboxChange(e, 'connected')} />
                    <span class="slider round"></span>
                  </label>
                </div>
              </Col> */}
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="3" lg="3" >
                <div>
                  <button className="btn btn-sm dft-bg-primary-color" onClick={this.saveKeg} disabled={this.state.saveInProgress ? 'disabled' : ''}>
                    {this.state.selKeg.keg_id === 0 ? "Add" : "Update"}
                  </button>
                  <button className="btn btn-sm btn-warning" onClick={this.handleCancel}>Cancel</button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    site_id: state.userInfo.security.site_id,
    kegSizes:state.kegSizes.kegSizes,
    beers: state.beers.beers,
    kegRooms: state.kegRooms.kegRooms,
    selKeg:state.kegs.selKeg
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getKegSizes:()=>dispatch(kegSizeActions.getKegSizes()),
    getKegs:(site_id)=>dispatch(kegActions.getKegs(site_id)),
    clearSelKeg:()=>dispatch(kegActions.clearSelKeg()),
    getBeers: () => dispatch(beerActions.getBeers()),
    getKegRooms:(site_id)=>dispatch(kegRoomActions.getKegRooms(site_id)),
    addKeg:(keg)=>dispatch(kegActions.addKeg(keg)),
    updateKeg:(keg)=>dispatch(kegActions.updateKeg(keg))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KegDetail);
