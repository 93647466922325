export const GetComponent=(columns,data)=>{
  return(<>
    <table cellSpacing="0" cellPadding="2" style={{width:'100%',border:'1px solid #ccc',borderCollapse:'collapse'}} border="1">
      <thead>
        <tr>
          {columns.filter(col => col.hidden===undefined || !col.hidden).map(col=><th key={col.dataField} style={{padding:'5px',border:'1px solid #ccc',textAlign:'left'}}>{col.text}</th>)}
        </tr>
      </thead>
      <tbody>
      {data.map((d,index)=>(
        <tr>
           {columns.filter(col => col.hidden===undefined || !col.hidden).map((col,index1)=>(
              <td style={{padding:'5px',border:'1px solid #ccc'}}>
              {typeof data[index][col.dataField]==="string" && data[index][col.dataField].indexOf('http')!==-1
              ?
              <img src={data[index][col.dataField]} alt={data[index][col.text]} style={{width:'50px'}} />
              :<span>{data[index][col.dataField]}</span>}
              </td>
           ))}
        </tr>
      ))}
      </tbody>
    </table>
      </>)
}